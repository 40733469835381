import React from "react";
import styles from "./ManagePracticeProviders.module.scss";
import {DataService} from './../../store/DataService';
import ProviderDetail from "../../components/ProviderDetail";
import GenButton from './../../components/GenButton';

var provider_row_counter = 0;

const ManagePracticeProviders = (props) => {
    
    const [provdata,setProvData] = React.useState({callgroups:[],contacttypes:[],providers:[]})
    const [providers,setProviders] = React.useState([]);
    const [allspecialties,setAllSpecialties] = React.useState([]);
    const [showinactive,setShowInactive] = React.useState(false);
    const [showdetail,setShowDetail] = React.useState(false);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [selectedprov,setSelectedProvider] = React.useState({});

    React.useEffect(() => {
        let isMounted = true;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getproviders(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                }
            } else {
                if (isMounted) {
                    setProvData(data);
                    setProviders(data.providers);
                    setAllSpecialties(data.allspecialties);
                    console.log("MPP allspecialties len="+data.allspecialties.length);
                }
            }
        })
        
        return () => {
            isMounted = false 
        };
    
    },[props.globals.practiceid,refreshneeded,props.globals.sessionguid])



    const ShowHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.leftside}>
                    <div className={styles.title}>Manage Practice Providers</div>
                    <div className={styles.subtitle}>
                    <div style={{display:"flex",alignItems:"center"}}>
                    View, Add, Update and Remove providers
                    <div style={{display:"flex",marginLeft:"22px",alignItems:"center",fontSize:"16px"}}>
                    <input type="checkbox" checked={showinactive} onChange={(e)=>setShowInactive(!showinactive)} /> Show Inactive
                    </div>
                    </div>
                    </div>
                </div>
                <div className={styles.rightside}>
                    <div style={{fontFamily:"Inter",fontSize:"16px"}}>
                        <GenButton buttontext="Add New" bgcolor="white" color="black" onclick={NewProvider} />
                    </div>
                </div>
            </div>
        )
    }

    const ShowTopRow = () => {

        return (
            <div className={styles.listheader}>
                <div>ID</div>
                <div>Last Name</div>
                <div>First Name</div>
                <div>Provider Name</div>
                <div>OnCall Provider</div>
                <div>Accepts New Pts</div>
                <div>Practice Member</div>
                <div>Specialties</div>
                <div>Credentials</div>
                <div>Active</div>
            </div>
        )
    }

    const DoClose = (obj) => {
        setShowDetail(false);
        setSelectedProvider({});
        if (obj !== null) {
            // Ugly but maintains sorted order
            let newprov = [];
            let found = false;
            providers.forEach((prov)=> {
                if (prov.ID===obj.ID) {
                    newprov.push(obj);
                    found = true;
                } else {
                    newprov.push(JSON.parse(JSON.stringify(prov)));
                }
            });
            if (!found) {
                newprov.push(obj);
                setRefreshNeeded(true);
            }
            setProviders(newprov);
        }
    }

    const updateProvider = (obj) => {
        if (obj !== null) {
            // Ugly but maintains sorted order
            let newprov = [];
            let found = false;
            providers.forEach((prov)=> {
                if (prov.ID===obj.ID) {
                    newprov.push(obj);
                    found = true;
                } else {
                    newprov.push(JSON.parse(JSON.stringify(prov)));
                }
            });
            if (!found) {
                newprov.push(obj);
            }
            setProviders(newprov);
            setSelectedProvider(obj);
            setRefreshNeeded(true);
        }
    }

    const ClickRow = (e) => {
        let id = e.target.getAttribute("data-id") || "0";
        id = parseInt(id);
        if (id < 1) {
            id = e.target.parentNode.getAttribute("data-id") || "0";
            id = parseInt(id);
        }
        if (id > 0) {
            providers.forEach((obj) => {
                if (obj.ID===id) {
                    setSelectedProvider(obj);
                    setShowDetail(true);
                }
            })
        }
        console.log("Row ID "+id);
    }

    const NewProvider = (e) => {
        let obj = {
            ID:0,
            ProviderId:0,
            PersonId:0,
            LastName:"",
            FirstName:"",
            MiddleInitial:"",
            DEANumber:"",
            ProvName:"",
            IsOnCallProvider:"N",
            AcceptsNewPatients:"N",
            DisplayOnInfo:"Y",
            ActiveFlag:"Y",
            PracticeMember:"Y",
            SpecialtyList:[],
            CredentialList:[],
            OnCallGroups:[],
            ContactMethods:[]
        }
        setSelectedProvider(obj);
        setShowDetail(true);
    }



    const ShowRow = ({row}) => {
        
        if (!showinactive && row.ActiveFlag==="N") {
            return null;
        }
        provider_row_counter += 1;
        const creds = row.CredentialList.map((x)=>x.desc).join(", ");
        const specs = row.SpecialtyList.map((x) => x.desc).join(", ");
        const selid = selectedprov.ID || 0;
        let bgcolor = selid===row.ID ? "lightblue" : "white";
        if (row.ActiveFlag !== 'Y') {
            bgcolor = "rgb(221, 226, 144)";
        } else {
            if (provider_row_counter & 1) {
                bgcolor="rgb(243,243,243)";
            }
        }
        
       
        return (
            <div className={styles.listrow} style={{"--hover_color":"lightgray","--custom_color":bgcolor}} data-id={row.ID} onClick={ClickRow}>
                <div>{row.ID}</div>
                <div>{row.LastName}</div>
                <div>{row.FirstName}</div>
                <div>{row.ProvName}</div>
                <div>{row.IsOnCallProvider}</div>
                <div>{row.AcceptsNewPatients}</div>
                <div>{row.PracticeMember}</div>
                <div>{specs}</div>
                <div>{creds}</div>
                <div>{row.ActiveFlag}</div>
            </div>
        )
    }

    const ShowRows = () => {
        provider_row_counter = 0;
        return (
            <div>
                {providers.map((row,i) => {
                    return <ShowRow key={"row_"+i} row={row} index={i} />
                })}
            </div>
        )
    }

    if (provdata.contacttypes.length) {
        return (
            <React.Fragment>
                <div className={styles.main}>
                    <ShowHeader />
                    <ShowTopRow />
                    <ShowRows />
                    {showdetail && <ProviderDetail 
                                        globals={props.globals} 
                                        methods={props.methods}
                                        onClose={DoClose}
                                        onUpdate={updateProvider} 
                                        provider={selectedprov} 
                                        callgroups={provdata.callgroups}
                                        contacttypes={provdata.contacttypes}
                                        allspecialties={allspecialties}
                                        securegroups={provdata.securegroups}
                                    />}
                </div>
            </React.Fragment>
        )
    }
    else {
        return <div className={styles.loader}>
            <img alt="Loading..." style={{width:"400px"}} src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
        </div>
    }
}

export default ManagePracticeProviders;