import React from "react";
import styles from "./CallNotes.module.scss";
import { GenButton } from "../components";
import {DataService} from './../store/DataService';



const SysMsg = (props) => {
    const dataservice = new DataService({guid:props.globals.sessionguid});
    const [smobj,setSMObj] = React.useState(props.globals.sysmsgs.pop());
    const handleClick = (e) => {
        setSMObj(props.globals.sysmsgs.pop());
    }

    const handleDel = (e) => {
        dataservice.deletesysmsg(smobj.SysMsgUserId,props.globals.useraccountid,props.globals.userpracticeid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setSMObj(props.globals.sysmsgs.pop());
        })
    }

    if (!smobj) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={styles.sysmsg}>
                <div className={styles.outer}>
                    <div className={styles.inner}>
                        <div className={styles.header}>System Messages</div>
                            <div className={styles.note}>{smobj.DateSent} {smobj.Subject}
                                <div>{smobj.Body}</div>
                            </div>
                        <div>
                            <div style={{display:"flex",marginTop:"16px"}}>
                                <GenButton onclick={handleClick} buttontext="Ok" />
                                <div style={{marginLeft:"22px"}}>
                                    <GenButton onclick={handleDel} buttontext="Delete" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SysMsg;