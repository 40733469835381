import React from "react";
import styles from "./GenSelect.module.scss";

const GenSelect = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    //const [firstIndex,setFirstIndex] = React.useState(0);

    //console.log("opt len="+props.options.length);

    const changeOpen = (v) => {
      const cd = props.disabled || false;
      if (cd===true) {
          return;
        }
      setIsOpen(v);
    }

    const Menu = ({ children }) => {
        const [head, ...tail] = React.Children.toArray(children);
        const wt = props.width || 270;
        const menudis = props.disabled || false;
        const ms = menudis ? styles.menudisabled : styles.menu;
        const tt = props.tooltip || "";
        return (
          <div
            className={ms}
            style={{width:`${wt}px`}}
            title={tt}
            onMouseEnter={() => changeOpen(true)}
            onMouseLeave={() => changeOpen(false)}
          >
            {head}
            {isOpen && <div className={styles.open}>{tail}</div>}
          </div>
        );
      };
      
      const Item = ({ children, onClick, value, disabled }) => {
        const bgcolor = props.backColor || "#f1f2f5";
        const hovcolor = props.hoverColor || "#8cb8ff";
        const txtcolor = props.textColor || "black";
        const ts = disabled ? styles.itemdisabled : styles.item;
        return (
          <div 
            className={ts} 
            onClick={(e)=>onClick(value)}
            style={{"--bgcolor":bgcolor,"--hovcolor":hovcolor,"--txtcolor":txtcolor}}
            >
            {children}
          </div>
        );
      };

      const Itemtop = ({ children, onClick , value}) => {
        const bgcolor = props.backColor || "#f1f2f5";
        const hovcolor = props.hoverColor || "#8cb8ff";
        const txtcolor = props.textColor || "black";
        const ts = isOpen ? styles.itemtop2 : styles.itemtop;
        return (
          <div 
            className={ts} 
            onClick={(e)=>onClick(value)}
            style={{"--bgcolor":bgcolor,"--hovcolor":hovcolor,"--txtcolor":txtcolor}}
            >
            {children}
          </div>
        );
      };

      const Itemlast = ({ children, onClick, value }) => {
        return (
          <div className={styles.itemlast} onClick={(e)=>onClick(value)}>
            {children}
          </div>
        );
      };      

      const DummyClick = (e) => {
        console.log("Dummy called e="+e);
      }

      const DoClick = (v) => {
        console.log("DoClick V="+v);
        const cb = props.callback || DummyClick;
        cb(v);
      }

    const TopItem = () => {
      if (!props.options || props.options.length < 1) return null;
      let i,opt;

      for (i=0;i<props.options.length;i++) {
        opt = props.options[i];
        if (opt.selected) {
          return <Itemtop onClick={DoClick} value={opt.value}>{opt.text}</Itemtop>
        }
      }

      if (props.placeholder) {
        return <Itemtop onClick={DummyClick} value="">{props.placeholder} </Itemtop>
      } else {
        return <Itemtop onClick={DummyClick} value=""> </Itemtop>
      }
    }

      const MenuInstance = () => {
        const opts = props.options || [];
                
        return (
            <Menu>
                <TopItem />
                {opts.map((obj,i) => 
                    obj.selected===true ? null 
                    : <Item 
                        key={i} 
                        onClick={DoClick} 
                        value={obj.value}
                        disabled={obj.disabled || false}
                        >
                        {obj.text}
                      </Item>
                )}
            </Menu>
        )
      }
      



    return (
        <MenuInstance />
    )

}

export default GenSelect;
