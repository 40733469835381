import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomeCommon from './../pages/HomeCommon';
import ViewMyMessages from './../pages/external/ViewMyMessages';
import ViewPracticeMessages from './../pages/external/ViewPracticeMessages';
import ViewPracticeMessagesInt from './../pages/internal/ViewPracticeMessagesInt';
import ViewArchiveMessages from './../pages/internal/ViewArchiveMessages';
import ViewUrgentMessages from "../pages/internal/ViewUrgentMessages";
import OnCallManager from './../pages/external/OnCallManager';
import OnCallProtocol from "./../pages/internal/OnCallProtocol";
import OnCallEdit from "./../pages/internal/OnCallEdit";
import ManagePracticeProviders from "./../pages/external/ManagePracticeProviders";
import ManagePracticeUsers from "../pages/external/ManagePracticeUsers";
import TakeMessage from "../pages/internal/TakeMessage";
import TakeNewMessage from "../pages/internal/TakeNewMessage";
import LocationMgmt from "../pages/internal/LocationMgmt";
import SpecialtyMgmt from "../pages/internal/SpecialtyMgmt";
import PracticeMgmt from "../pages/internal/PracticeMgmt";
import CallNoteMgmt from "../pages/internal/CallNoteMgmt";
import SystemLists from "../pages/internal/SystemLists";
import SysMsgMgmt from "../pages/internal/SysMsgMgmt";
import MessageContent from "../pages/internal/MessageContent";
import OnCallQA from "../pages/internal/OnCallQA";
import SystemConfig from "../pages/internal/SystemConfig";
import SystemStatus from "../pages/internal/SystemStatus";
import MessageAction from "../pages/internal/MessageAction";
import PracticeReporting from "../pages/PracticeReporting";
import ViewSchedMessages from "../pages/internal/ViewSchedMessages";
import OBTickets from "../pages/internal/OBTickets";
import WorkingTickets from "../pages/internal/WorkingTickets";
import SearchTickets from "../pages/internal/SearchTickets";
import ClosedTickets from "../pages/internal/ClosedTickets";
import WorkFlowDesign from "../pages/internal/WorkFlowDesign";
import TransferNoteMgmt from "../pages/internal/TransferNoteMgmt";
import EscalatedTickets from "../pages/internal/EscalatedTickets";
import HoldingTickets from "../pages/internal/HoldingTickets";
import TicketDashboard from "../pages/internal/TicketDashboard";
import AgentDashboard from "../pages/internal/AgentDashboard";

function Wrapper({ children }) {
  const location = useLocation();
  return React.cloneElement(children, { key: location.pathname });
}

function RouteMgr(props) {
  const location = useLocation();

  return (
      <>
      <Routes>
        <Route path="/extview" element={<ViewMyMessages globals={props.globals} methods={props.methods} />} />
        <Route path="/extviewpm" element={<ViewPracticeMessages globals={props.globals} methods={props.methods} />} />
        <Route path="/extmgtoncall" element={<OnCallManager globals={props.globals} methods={props.methods} /> } />
        <Route path="/extmgtpracprov" element={<ManagePracticeProviders globals={props.globals} methods={props.methods} />} />
        <Route path="/extmgtpracusers" element={<ManagePracticeUsers globals={props.globals} methods={props.methods} />} />
        <Route path="/intviewpm" element={<ViewPracticeMessagesInt globals={props.globals} methods={props.methods} archive={'N'} />} />
        <Route path="/intviewarc" element={<ViewArchiveMessages globals={props.globals} methods={props.methods} archive={'Y'} />} />
        <Route path="/intviewurg" element={<ViewUrgentMessages globals={props.globals} methods={props.methods} />} />
        <Route path="/intviewsched" element={<ViewSchedMessages globals={props.globals} methods={props.methods} />} />
        <Route path="/takemsg" element={<TakeNewMessage globals={props.globals} methods={props.methods} />} />
        <Route path="/msgact/:id" element={<MessageAction globals={props.globals} methods={props.methods} />} />
        <Route path="/intlocdem" element={<LocationMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/intspec" element={<SpecialtyMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/intmgtprac" element={<PracticeMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/intmgtcallnotes" element={<CallNoteMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/intmgtxfernotes" element={<TransferNoteMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/intmsgcontent" element={<MessageContent globals={props.globals} methods={props.methods} />} />
        <Route path="/intocqq" element={<OnCallQA globals={props.globals} methods={props.methods} />} />
        <Route path="/intocgrp" element={<OnCallEdit globals={props.globals} methods={props.methods} />} />
        <Route path="/intocps" element={<OnCallProtocol globals={props.globals} methods={props.methods} />} />
        <Route path="/sysconfig" element={<SystemConfig globals={props.globals} methods={props.methods} />} />
        <Route path="/syslists" element={<SystemLists globals={props.globals} methods={props.methods} />} />
        <Route path="/sysmsgs" element={<SysMsgMgmt globals={props.globals} methods={props.methods} />} />
        <Route path="/sysstatus" element={<SystemStatus globals={props.globals} methods={props.methods} />} />
        <Route path="/pracrpt" element={<PracticeReporting globals={props.globals} methods={props.methods} />} />
        <Route path="/obtickets" element={<WorkingTickets globals={props.globals} methods={props.methods} />} />
        <Route path="/searchtickets" element={<SearchTickets globals={props.globals} methods={props.methods} />} />
        <Route path="/agentdb" key={location.pathname} element={<Wrapper><AgentDashboard globals={props.globals} methods={props.methods} /></Wrapper>} />
        <Route path="/ticketdb" key={location.pathname} element={<Wrapper><TicketDashboard globals={props.globals} methods={props.methods} editthresholds={false} /></Wrapper>} />
        <Route path="/thresholds" key={location.pathname} element={<Wrapper><TicketDashboard globals={props.globals} methods={props.methods} editthresholds={true} /></Wrapper>} />
        <Route path="/holdtickets" element={<HoldingTickets globals={props.globals} methods={props.methods} />} />
        <Route path="/esctickets" element={<EscalatedTickets globals={props.globals} methods={props.methods} />} />
        <Route path="/closedtickets" element={<ClosedTickets globals={props.globals} methods={props.methods} />} />
        <Route path="/workflow" element={<WorkFlowDesign globals={props.globals} methods={props.methods} />} />
        <Route path="/home/:msgid" element={<HomeCommon globals={props.globals} methods={props.methods} popalert={true} resetlogin={false} />} />
        <Route exact path="/" element={<HomeCommon globals={props.globals} methods={props.methods} popalert={false} resetlogin={props.resetlogin} />} />
      </Routes> 
      </>       
  )
  
}

export default RouteMgr;