import React from "react";
import styles from "./AgentDashboard.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenDropdownCheck from "../../components/GenDropdownCheck";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITIES, Date12, calculateAgeInYears} from "../../AppConstants";

const AgentDashboard = (props) => {
    const [matrix,setMatrix] = React.useState({});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [tickets,setTickets] = React.useState([]);
    const [showtickets,setShowTickets] = React.useState(false);
    const [ticket,setTicket] = React.useState(null);
    const [showticket,setShowTicket] = React.useState(false);
    const [selectedtids,setSelectedTids] = React.useState('');
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');
    const [holding, setHolding] = React.useState(0);
    const [agentsortfield,setAgentSortField] = React.useState('');
    const [agentsortorder,setAgentSortOrder] = React.useState('');

    const initDashboard = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const obj = {practices:[],
            priorities:[],
            msgtypes:[]
        }
        console.log('ADB Init called')
        dataservice.initagentdashboard(props.globals.useraccountid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.MATRIX);
            setTickets([]);

            console.log('DB Init matrix ready')
        })
    }

    React.useEffect(()=> {
        initDashboard();
    },[refreshneeded])

    const handleRefresh = (e) => {
        initDashboard();
    }


    function decimalToHoursMinutes(num) {
        const total = Math.floor(num);
        const hours = Math.floor(total / 3600); // Calculate hours
        const minutes = Math.floor((total % 3600) / 60); // Calculate minutes
        const seconds = total % 60; // Calculate remaining seconds
      
        // Pad single-digit values with leading zeros
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; // Return formatted string
      }

    const findAgent = (uaid) => {
        const agents = matrix.AGENTS || [];
        let agent = null;
        agents.every((ag)=> {
            if (''+ag.uaid===''+uaid) {
                agent = ag;
                return false;
            }
            return true;
        })
        return agent;
    }

    const readTickets = (ticklist=null) => {
        if (!ticklist) ticklist = selectedtids;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticklist(props.globals.useraccountid,ticklist).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            let tlist = JSON.parse(JSON.stringify(data.TICKETS || []));
            const now = new Date();
            tlist.forEach((t)=> {
                const dt = new Date(t.eventdate || null);
                const tm = Math.abs(now.getTime() - dt.getTime());
                const m = Math.floor(tm / (1000 * 60));
                t.age = m;
            })
            setTickets(tlist);
            setShowTickets(true);
        })
    }

    const handleSelect = (e) => {
        const tid = e.target.id;
        const sa = tid.split("_");
        setShowTickets(false);
        if (sa.length > 1) {
            const uaid = sa[1];
            const cmd = sa[0];
            const ag = findAgent(uaid);
            let tidparm = '';
            let tidlist = [];
            if (ag) {
                console.log("Found "+uaid+" = " + ag.name);
                if (cmd==='wl') {
                    tidparm = ag.working;
                }
                if (cmd==='av') {
                    tidparm = ag.available;
                }
                if (cmd==='cl') {
                    tidparm = ag.closed;
                }
                if (tidparm.length) {
                    readTickets(tidparm.join(','));
                }
            } else {
                console.log("Unable to find Agent for "+uaid);
            }

        }
    }

    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTicket(data.TICKET);
            setShowTicket(true);
        })
    };

    const ShowSelectedTickets = () => {
        if (tickets.length === 0) {
            return (
                <React.Fragment>
                    <div style={{marginTop:"30px",marginLeft:"18px"}}>Click on ticket numbers to display ticket information</div>
                </React.Fragment>
            )
        }

        if (ticketsortfield.length > 0) {
            tickets.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }
        const formatMinutesToHoursMinutes = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes}`;
          }

        return (
            <React.Fragment>
                <div style={{marginTop:"20px",marginLeft:"30px"}}>Priorities with Yellow backgrounds indicates caller is On-Hold</div>
                <div className={styles.listrowhdr} style={{marginTop:"10px"}}>
                    <div id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="priority" onClick={handleSortClick}>Priority{ticketsortfield==='priority' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='priority' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="eventname" onClick={handleSortClick}>State{ticketsortfield==='eventname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='eventname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="age" onClick={handleSortClick}>Time in State{ticketsortfield==='age' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='age' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                <div style={{height:"10px"}}></div>
                {tickets.map((entry,i) => {
                    const ptage = calculateAgeInYears(entry.dob);
                    let phones = "";
                    let bg = null;
                    if (entry.holding===1 && holding===0) {
                        bg = "yellow";
                    }
                    let rowbg = "white";
                    if (entry===selectedEntry) {
                        rowbg = "lightgreen";
                    }
                    if (entry.callbackphone.length > 0) {
                        phones = formatPhoneNumber(entry.callbackphone);
                    }
                    if (entry.secondaryphone.length > 0) {
                        if (phones.length > 0) phones += " , "
                        phones += formatPhoneNumber(entry.secondaryphone);
                    }
                    return (
                        <div key={"phone_"+i} className={styles.listrow} onClick={()=>handleEntryClick(entry)}>
                            <div>{entry.ticketid}</div>
                            <div>{entry.msgname}</div>
                            <div style={{backgroundColor:bg}}>{PRIORITIES[entry.priority]}</div>
                            <div>{Date12(entry.createdate)}</div>
                            <div>{entry.eventname}</div>
                            <div>{formatMinutesToHoursMinutes(entry.age)}</div>
                            <div className={styles.gridcell}>{entry.firstname}</div>
                            <div className={styles.gridcell}>{entry.lastname}</div>
                            <div>{DateOnly(entry.dob)} ({ptage})</div>
                            <div>{phones}</div>
                            <div className={styles.gridcell}>{entry.pracname}</div>
                        </div>
                    )
                    
                })}
            </React.Fragment>
        )
    }

    const ShowAgents = () => {
        const agents = matrix.AGENTS || [];

        if (agentsortfield.length > 0) {
            agents.sort((a,b)=> {
                let fldA = a[agentsortfield];
                let fldB = b[agentsortfield];
                if (agentsortfield==='working' || agentsortfield==='closed' || agentsortfield==='available') {
                    fldA = fldA.length;
                    fldB = fldB.length;
                }
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return agentsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleAgentSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = agentsortfield;
            let torder = agentsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setAgentSortField(fld);
            setAgentSortOrder(torder);
        }

        return (
            <div className={styles.matrixblock}>
                <div className={styles.matrixhdr} style={{cursor:"pointer",userSelect:"none"}}>
                    <div id="name" onClick={handleAgentSortClick}>Agent {agentsortfield==='name' && agentsortorder === 'desc' ? '↓' : agentsortfield==='name' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="created" onClick={handleAgentSortClick}>Login Time {agentsortfield==='created' && agentsortorder === 'desc' ? '↓' : agentsortfield==='created' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="duration" onClick={handleAgentSortClick}>Login Duration {agentsortfield==='duration' && agentsortorder === 'desc' ? '↓' : agentsortfield==='duration' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pwt" onClick={handleAgentSortClick}>Percent Work Time {agentsortfield==='pwt' && agentsortorder === 'desc' ? '↓' : agentsortfield==='pwt' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="working" onClick={handleAgentSortClick}>Total Tickets Served {agentsortfield==='working' && agentsortorder === 'desc' ? '↓' : agentsortfield==='working' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="closed" onClick={handleAgentSortClick}>Tickets Completed {agentsortfield==='closed' && agentsortorder === 'desc' ? '↓' : agentsortfield==='closed' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="closedperhour" onClick={handleAgentSortClick}>Tickets Completed per Hour {agentsortfield==='closedperhour' && agentsortorder === 'desc' ? '↓' : agentsortfield==='closedperhour' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="att" onClick={handleAgentSortClick}>Average Time per Ticket {agentsortfield==='att' && agentsortorder === 'desc' ? '↓' : agentsortfield==='att' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="available" onClick={handleAgentSortClick}>Potential Remaining Tickets {agentsortfield==='available' && agentsortorder === 'desc' ? '↓' : agentsortfield==='available' && agentsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                {agents.map((ag,i)=> {
                    return (
                        <div key={"agrow_"+i} className={styles.matrixrow}>
                            <div>{ag.name}</div>
                            <div className={styles.matrixblockcell} style={{textAlign:"left"}}>{Date12(ag.created)}</div>
                            <div className={styles.matrixblockcell}>{decimalToHoursMinutes(ag.duration)}</div>
                            <div className={styles.matrixblockcell} style={{paddingLeft:"10px"}}>{ag.pwt}</div>
                            <div className={styles.matrixcellselect} id={"wl_"+ag.uaid} onClick={handleSelect}>{ag.working.length}</div>
                            <div className={styles.matrixcellselect} id={"cl_"+ag.uaid} onClick={handleSelect}>{ag.closed.length}</div>
                            <div className={styles.matrixblockcell} style={{paddingLeft:"10px"}}>{ag.closedperhour}</div>
                            <div className={styles.matrixblockcell}>{decimalToHoursMinutes(ag.att)}</div>
                            <div className={styles.matrixcellselect} id={"av_"+ag.uaid} onClick={handleSelect}>{ag.available.length}</div>
                        </div>
                    )
                })}
            </div>
        )
    }
    
    const ticketOnClose = (ticketid,stateid,priority) => {
        setShowTicket(false);
    }

    return (
        <React.Fragment>
                        <div>
                <div className={styles.title}>Agent Dashboard
                <span style={{marginLeft:"50px"}}><button style={{height:"26px",fontSize:"16px"}} onClick={handleRefresh}>Refresh</button></span>
                </div>
                {ShowAgents()}
                {showtickets && ShowSelectedTickets()}
                {showticket && <TicketDetail globals={props.globals} methods={props.methods} allowpriorityedit={false} disableworkflow={true} ticket={ticket} doClose={ticketOnClose} />}                
            </div>
        </React.Fragment>
    )
}

export default AgentDashboard;