import React from 'react';
import styles from './MessageSchedListHeader.module.scss';
import GenCheckbox from './GenCheckbox';
import {SORTBY_MSGID} from './../AppConstants';

const MessageSchedListHeader = (props) => {
    let chk = props.lastCheck || false;
    if (props.showClosed) chk = false;
    const [cbcheck,setCbCheck] = React.useState(chk);

    const cbCallback = (e) => {
        if (props.showClosed) return false;
        props.allCallback(!cbcheck);
        setCbCheck(!cbcheck);
    }

    const callback = props.cbCallback || cbCallback;
    const fsize = props.globals.intext===1 ? "14px" : "18px";
    const status_column = props.defsortby === SORTBY_MSGID ? 'Message ID' : 'Status';
    return (
        <div className={styles.listheader} style={{fontSize:fsize}}>
            {!props.noCheck && <div><GenCheckbox cbCallback={callback} cbid={0} checked={cbcheck} disabled={props.showClosed || false} /></div>}
            {props.noCheck && <div>&nbsp;</div>}
            <div>Date</div>
            <div>Time</div>
            <div>Patient Name</div>
            <div>Type</div>
            {props.globals.intext===1 && <div>Practice</div>}
            <div>{status_column}</div>
            <div>Details</div>
            
        </div>
    )

}

export default MessageSchedListHeader;