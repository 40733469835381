import React from "react";
import styles from "./ManagePracticeUsers.module.scss";
import {DataService} from './../../store/DataService';
import UserDetail from "../../components/UserDetail";
import GenButton from './../../components/GenButton';


var user_row_counter = 0;

const ManagePracticeUsers = (props) => {
    
    const [userdata,setUserData] = React.useState({roles:[],locations:[],allservices:[],allsystems:[],practices:[],indexedpractices:{},indexedsystems:{},users:[]});
    const [showinactive,setShowInactive] = React.useState(false);
    const [showdetail,setShowDetail] = React.useState(false);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [selecteduser,setSelectedUser] = React.useState({});
    
    React.useEffect(() => {
        let isMounted = true;
        if (refreshneeded) {
            setRefreshNeeded(false);
        }
        if (props.globals.practiceid > 0) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.getusers(props.globals.practiceid).then((data) => {
                if (data.ERROR) {
                    if (data.ERROR.length > 0) {
                        alert(data.ERROR);
                    }
                } else {
                    if (isMounted) {
                        let ipracs = {};
                        let pracs = data.practices || [];
                        
                        pracs.forEach((p)=> {
                            ipracs[''+p.pracid] = p.name;
                        })
                        let isys = {};
                        let sys = data.allsystems || [];
                        sys.forEach((s)=> {
                            isys[''+s.stid] = s.stdesc;
                        })
                        let imt = {};
                        let mts = data.allmsgtypes || [];
                        mts.forEach((m)=> {
                            imt[m.mtid] = m.Name;
                        })
                        data["indexedmsgtypes"] = imt;
                        data["indexedpractices"] = ipracs;
                        data["indexedsystems"] = isys;
                        setUserData(data);
                    }
                }
            })
        }
        
        return () => {
            isMounted = false 
        };
    
    },[props.globals.practiceid,refreshneeded,props.globals.sessionguid])

    const ShowHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.leftside}>
                    <div className={styles.title}>Manage Practice Users</div>
                    <div className={styles.subtitle}>
                    <div style={{display:"flex",alignItems:"center"}}>
                    View, Add, Update and Remove users
                    <div style={{display:"flex",marginLeft:"22px",alignItems:"center",fontSize:"16px"}}>
                    <input type="checkbox" checked={showinactive} onChange={(e)=>setShowInactive(!showinactive)} /> Show Inactive
                    </div>
                    </div>
                    </div>
                </div>
                <div className={styles.rightside}>
                    <div style={{fontFamily:"Inter",fontSize:"16px"}}>
                        <GenButton buttontext="Add New" bgcolor="white" color="black" onclick={NewUser} />
                    </div>
                </div>
            </div>
        )
    }

    const ShowTopRow = () => {

        return (
            <div className={styles.listheader}>
                <div>Last Name</div>
                <div>First Name</div>
                <div>Middle Init</div>
                <div>Role</div>
                <div>User ID</div>
                <div>Email</div>
                <div>Default Location</div>
                <div>Active</div>
            </div>
        )
    }

    const DoClose = (obj) => {
        setShowDetail(false);
        setSelectedUser({});
        if (obj !== null) {
            // Ugly but maintains sorted order
            let newuser = [];
            let found = false;
            userdata.users.forEach((user)=> {
                if (user.UserAccountID===obj.UserAccountID) {
                    newuser.push(obj);
                    found = true;
                } else {
                    newuser.push(JSON.parse(JSON.stringify(user)));
                }
            });
            if (!found) {
                newuser.push(obj);
            }
            //let udata = {"roles":userdata.roles,"locations":userdata.locations,"users":newuser};
            let udata = JSON.parse(JSON.stringify(userdata));
            udata.users = newuser;
            setUserData(udata);
            //setRefreshNeeded(true);
        }
    }

    const ClickRow = (e) => {
        let id = e.target.getAttribute("data-id") || "0";
        id = parseInt(id);
        if (id < 1) {
            id = e.target.parentNode.getAttribute("data-id") || "0";
            id = ''+id;
        }

        if (id > 0) {
            userdata.users.every((obj) => {
                if (obj.UserAccountID===id) {
                    setSelectedUser(obj);
                    setShowDetail(true);
                    return false;
                }
                return true;
            })
        }
        console.log("Row ID "+id);
    }

    const NewUser = (e) => {
        let obj = {
            UserAccountID:0,
            PersonID:0,
            RoleID:0,
            LastName:"",
            FirstName:"",
            MiddleInitial:"",
            Username:"",
            RestrictedAccess:"N",
            PasswordExpiration:"NULL",
            DefaultLocationId:0,
            PracticeId:props.globals.practiceid,
            Email:"",
            Location:"",
            RoleTypeId:0,
            RoleDescription:"",
            ActiveFlag:"Y",
            services:[],
            msgtypeskills:[],
            practiceskills:[],
            systemskills:[]
        }
        setSelectedUser(obj);
        setShowDetail(true);
    }

    const ShowRow = ({row}) => {
        
        if (!showinactive && row.ActiveFlag==="N") {
            return null;
        }
        user_row_counter += 1;
        const selid = selecteduser.UserAccountID || 0;
        let bgcolor = "white";
        if (row.ActiveFlag === "N") {
            bgcolor = "rgb(221, 226, 144)";
        } else {
            if (user_row_counter & 1) bgcolor="rgb(243,243,245)";
        }
        

        return (
            <div className={styles.listrow} style={{"--hover_color":"lightgray","--custom_color":bgcolor}} data-id={row.UserAccountID} onClick={ClickRow}>
                <div>{row.LastName}</div>
                <div>{row.FirstName}</div>
                <div>{row.MiddleInitial}</div>
                <div>{row.RoleDescription}</div>
                <div>{row.Username}</div>
                <div>{row.Email}</div>
                <div>{row.Location}</div>
                <div>{row.ActiveFlag}</div>
            </div>
        )
    }

    const ShowRows = () => {
        return (
            <div>
                {userdata.users.map((row,i) => {
                    return <ShowRow key={"row_"+i} row={row}  />
                })}
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <ShowHeader />
                <ShowTopRow />
                <ShowRows />
                {showdetail && <UserDetail 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    onClose={DoClose} 
                                    user={selecteduser} 
                                    userdata={userdata}
                                />}
            </div>
        </React.Fragment>
    )

}

export default ManagePracticeUsers;