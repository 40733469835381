import React from "react";
import styles from "./OnCallQA.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenMsgBox from "../../components/GenMsgBox";
import qicon from "../../assets/question.svg";
import aicon from "../../assets/answer.svg";
import gicon from "../../assets/call-center.svg";
import plusicon from "../../assets/plusbox.svg";
import minusicon from "../../assets/minusbox.svg";

var maxquestionid = 0;

const OnCallQA = (props) => {
    const [showdelconfirm,setShowDelConfirm] = React.useState(false);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [prompt,setPrompt] = React.useState("");
    const [nodeadded,setNodeAdded] = React.useState("");
    const [inputtext,setInputText] = React.useState("");
    const [groupid,setGroupID] = React.useState(0);
    const [showinput,setShowInput] = React.useState(false);
    const [showgroups,setShowGroups] = React.useState(false);
    const [showgrperror,setShowGroupError] = React.useState(false);
    const [matrix,setMatrix] = React.useState({oclist:[],qlist:[]});

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getqualquestions(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.matrix);

        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const findNodeById = (id,nodes) => {
        var found = null;
        if (!nodes) return found;
        for (let i=0;i < nodes.length;i++) {
            var node = nodes[i];
            if (node.qualquestionid===id) {
                found = node;
                break;
            }
            if (node.children.length) {
                found = findNodeById(id,node.children);
                if (found) break;
            }
        }
        return found;
    }

    const findSelectedNode = (nodes) => {
        var found = null;
        if (!nodes) return found;
        for (let i=0;i < nodes.length;i++) {
            var node = nodes[i];
            if (node.selected) {
                found = node;
                break;
            }
            if (node.children.length) {
                found = findSelectedNode(node.children);
                if (found) break;
            }
        }
        return found;        
    }

    const clearSelected = (nodes) => {
        for (let i=0;i<nodes.length;i++) {
            var node = nodes[i];
            node.selected = false;
            if (node.children.length) {
                clearSelected(node.children);
            }
        }
    }

    const addNode = (parentnode,nodetype,nodetext,oncallid) => {
        let node = null;
        const nodes = matrix.qlist || [];
        const qlen = nodes.length;
        maxquestionid = maxquestionid - 1;
        let curtree = "";
        if (parentnode) {
            curtree = selnode.treenode;
            let nxtnodenum = selnode.children.length + 1;
            curtree += nxtnodenum + ".";
        } else {
            if (!qlen) {
                curtree = "1.";
            } else {
                return node;
            }
        }
        node = {
            qualquestionid:''+maxquestionid,
            treenode:curtree,
            typenode:nodetype,
            selected:false,
            expanded:false,
            practiceid:props.globals.practiceid,
            oncallid:oncallid,
            nodetext:nodetext,
            value:nodetype+"-"+curtree+" "+nodetext,
            children:[]
        }

        return node;
    }


    const nonSelected = (nodes) => {
        var nlist = [];
        for (let i=0;i<nodes.length;i++) {
            let node = nodes[i];
            if (!node.selected) {
                nlist.push(node);
                if (node.children.length) {
                    node.children = nonSelected(node.children);
                }
            }
        }
        return nlist;
    }

    const deleteSelectedNode = () => {
        let nodes = JSON.parse(JSON.stringify(matrix.qlist || []));
        nodes = nonSelected(nodes);
        setMatrix(values=>({...values,qlist:nodes}));
        setShowDelConfirm(false);
        setDirtyFlag(true);
    }

    const deleteNodeCancel = (e) => {
        setShowDelConfirm(false);
    }

    const handleClick = (e) => {
        var id = e.target.id;
        var sa = id.split("_");
        if (sa[0] !== 'qq') return;
        id = sa[1];
        var qlist = matrix.qlist || [];
        var nodes = JSON.parse(JSON.stringify(qlist));
        
        clearSelected(nodes);
        var node = findNodeById(id,nodes);
        if (node) node.selected = true;
        setMatrix(values=>({...values,qlist:nodes}))
    }

    const handleExp = (e) => {
        var name = e.target.name;
        var sa = name.split('_');
        if (sa[0] !== 'icon') return;
        name = sa[1];
        var nodes = JSON.parse(JSON.stringify(matrix.qlist || []));
        clearSelected(nodes);
        var node = findNodeById(name,nodes);
        if (node) {
            node.selected = true;
            node.expanded = !node.expanded;
        }
 
        setMatrix(values=>({...values,qlist:nodes}))
    }

    const ShowNode = ({node,level}) => {
        if (!node) return null;

        const indent = 14 * level;
        let icon = qicon;
        if (node.typenode==='A') icon = aicon;
        if (node.typenode==='G') icon = gicon;

        let expicon = null;
        if (node.children.length) {
            expicon = plusicon;
            if (node.expanded) expicon = minusicon;
        }
        let ntstyle = styles.nodetext;
        if (node.selected) {
            ntstyle = styles.nodetextsel;
        }
        let barstyle = styles.nodenone;
        if (level > 1) {
            barstyle = styles.nodebar;
        }
        const ShowBar = ({curlevel}) => {
            let lpos = curlevel * 14;
            let mleft = curlevel * -12;
            return <div style={{position:"relative",marginLeft:`${mleft}px`,left:`${lpos}px`}} className={styles.nodebar}></div>
        }
        // Not working yet
        var bars = [];
        for (let i=200;i<=level;i++) {
            bars.push(<ShowBar curlevel={i} />);
        }
        return (
            <React.Fragment>
                <div className={styles.node} style={{paddingLeft:`${indent}px`}}>
                    <div>{bars}</div>
                    <div>
                        {expicon !== null && <img className={styles.nodeiconexp} alt="" name={"icon_"+node.qualquestionid} onClick={handleExp} src={expicon}></img>}
                    </div>
                    <div>
                        <img className={styles.nodeicon} alt="" src={icon} ></img>
                    </div>
                    <div id={"qq_"+node.qualquestionid} className={ntstyle} onClick={handleClick}> - {node.nodetext}</div>
                </div>
                {node.expanded===true && node.children.length > 0 && node.children.map((child,i) => {
                    return <ShowNode key={"chld_"+i+node.treenode} node={child} level={level+1} />
                    })
                }
            </React.Fragment>
        )
    }

    const saveAll = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const nodes = matrix.qlist || [];
        dataservice.savequalquestions(props.globals.practiceid,props.globals.useraccountid,nodes).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }

            setMatrix(values=>({...values,qlist:data.matrix.qlist}));
            setDirtyFlag(false);
        })
    }

    const addQuestion = (e) => {
        setNodeAdded("Q");
        setPrompt("Add Question");
        setInputText("");
        setShowInput(true);
    }

    const editQuestion = (e) => {
        const nodes = matrix.qlist || [];
        const selnode = findSelectedNode(nodes);
        if (selnode) {
            setNodeAdded("Q");
            setPrompt("Edit Question");
            setInputText(selnode.nodetext);
            setShowInput(true);
        }
    }

    const addAnswer = (e) => {
        setNodeAdded("A");
        setPrompt("Add Answer");
        setInputText("");
        setShowInput(true);
    }

    const editAnswer = (e) => {
        const nodes = matrix.qlist || [];
        const selnode = findSelectedNode(nodes);
        if (selnode) {
            setNodeAdded("A");
            setPrompt("Edit Answer");
            setInputText(selnode.nodetext);
            setShowInput(true);
        }
    }

    const addGroup = (e) => {
        setNodeAdded("G");
        setPrompt("Add Group");
        setGroupID(0);
        setShowGroups(true);
    }

    const editGroup = (e) => {
        const nodes = matrix.qlist || [];
        const selnode = findSelectedNode(nodes);
        if (selnode) {
            setNodeAdded("G");
            setPrompt("Edit Group");
            setGroupID(selnode.oncallid);
            setShowGroups(true);
        }
    }

    const deleteNode = (e) => {
        setShowDelConfirm(true);
    }

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    }

    const showInputOK = (e) => {
        let nodes = JSON.parse(JSON.stringify(matrix.qlist || []));
        let selnode = findSelectedNode(nodes);
        if (selnode) {
            const sa = prompt.split(' ');
            if (sa[0]==='Edit') {
                selnode.nodetext = inputtext;
                selnode.value = selnode.typenode+'-'+selnode.treenode+" "+inputtext;
            } else {
                let node = addNode(selnode, nodeadded, inputtext, -1);
                if (node) {
                    selnode.children.push(node);
                }
            }
            setMatrix(values=>({...values,qlist:nodes}));
            setDirtyFlag(true);
        } else {
            if (nodes.length < 1) {
                let newnode = addNode(null, nodeadded, inputtext, -1)
                if (newnode) {
                    nodes.push(newnode);
                    setMatrix(values=>({...values,qlist:nodes}));
                    setDirtyFlag(true);
                }
    
            }
        }
        setShowInput(false);
    }

    const showInputCancel = (e) => {
        setShowInput(false);
    }

    const handleGroupChange = (e) => {
        setGroupID(e.target.value);
    }

    const showGroupsOK = (e) => {
        if (parseInt(groupid) < 1) {
            setShowGroupError(true);
            return;
        }
        let nodes = JSON.parse(JSON.stringify(matrix.qlist || []));
        let selnode = findSelectedNode(nodes);
        if (selnode) {
            const groups = matrix.oclist || [];
            let nodetext = "";
            groups.every((grp)=> {
                if (grp.oncallid===groupid) {
                    nodetext = grp.description;
                    return false;
                }
                return true;
            })
            const sa = prompt.split(' ');
            if (sa[0]==='Edit') {
                selnode.oncallid=groupid;
                selnode.nodetext = nodetext;
                selnode.value = selnode.typenode+'-'+selnode.treenode+" "+nodetext;
            } else {
                let node = addNode(selnode, nodeadded, nodetext, groupid);
                if (node) {
                    selnode.children.push(node);
                }
            }
            setMatrix(values=>({...values,qlist:nodes}));
            setDirtyFlag(true);
        }
        setShowGroups(false);
    }

    const showGroupsCancel = (e) => {
        setShowGroups(false);
    }

    const ShowInput = () => {

        return (
            <div className={styles.buttons}>
                <div>{prompt}:<input style={{marginLeft:"6px",width:"800px"}} type="text" value={inputtext} onChange={handleInputChange} /></div>
                <GenButton width="60" buttontext="Ok" onclick={showInputOK} />
                <GenButton width="80" buttontext="Cancel" onclick={showInputCancel} />
            </div>
        )
    }

    const ShowGroups = () => {
        const groups = matrix.oclist || [];
        const doErrOK = (e) => {
            setShowGroupError(false);
        }
        return (
            <React.Fragment>
            <div className={styles.buttons}>{prompt}:
                <select style={{marginLeft:"6px",width:"300px"}} name="selgroups" value={groupid} onChange={handleGroupChange}>
                <option key={"grp_00"} value={'0'}>{''}</option>
                    {groups.map((opt,i)=> {
                        return <option key={"grp_"+i} value={opt.oncallid}>{opt.description}</option>
                    })}
                </select>
                <GenButton width="60" buttontext="Ok" onclick={showGroupsOK} />
                <GenButton width="80" buttontext="Cancel" onclick={showGroupsCancel} />
        
            </div>
            {showgrperror && <GenMsgBox text="Invalid Group Selected" onOK={doErrOK} />}
            </React.Fragment>
        )
    }

    const ShowButtons = () => {
        
        const nodes = matrix.qlist || [];
        const selnode = findSelectedNode(nodes);
        const numnodes = nodes.length;
        let ntype = "";
        let numkids = 0;
        if (selnode) {
            ntype = selnode.typenode;
            numkids = selnode.children.length;
        }
        let sbnum = 0;
        if (ntype==="Q") {
            sbnum = 1;
            if (numkids > 0) sbnum = 2;
        }
        if (ntype==="A") {
            sbnum = 3;
            if (numkids > 0) sbnum = 4;
        }
        if (ntype==="G") {
            sbnum = 5;
            
        }
        return (
            <div className={styles.buttons}>
                
                {sbnum===0 && <GenButton width="100" buttontext="Add Question" onclick={addQuestion} />}
                {sbnum===1 && <React.Fragment>
                    <GenButton width="100" buttontext="Edit Question" onclick={editQuestion} />
                    <GenButton width="100" buttontext="Add Answer" onclick={addAnswer} />
                </React.Fragment>}
                {sbnum===2 && <React.Fragment>
                    <GenButton width="100" buttontext="Add Answer" onclick={addAnswer} />
                    <GenButton width="100" buttontext="Edit Question" onclick={editQuestion} />
                </React.Fragment>}
                {sbnum===3 && <React.Fragment>
                    <GenButton width="100" buttontext="Add Question" onclick={addQuestion} />
                    <GenButton width="100" buttontext="Edit Answer" onclick={editAnswer} />
                    <GenButton width="100" buttontext="Add Group" onclick={addGroup} />
                </React.Fragment>}
                {sbnum===4 && <React.Fragment>
                    <GenButton width="100" buttontext="Edit Answer" onclick={editAnswer} />
                </React.Fragment>}
                {sbnum===5 && <React.Fragment>
                    <GenButton width="100" buttontext="Edit Group" onclick={editGroup} />
                </React.Fragment>}
                {sbnum > 0 && <GenButton width="100" buttontext="Delete" onclick={deleteNode} />}
                <GenButton width="100" buttontext="Save" onclick={saveAll} disabled={!dirtyflag} />
            </div>
        )


    }

    const nodes = matrix.qlist || [];
    let pnode = null;
    let selnode = null;
    let selnodetext = "";
    let kidtext = "";
    if (nodes.length > 0) {
        pnode = nodes[0];
        selnode = findSelectedNode(nodes);
        if (selnode) {
            selnodetext = selnode.nodetext;
            if (selnode.children.length > 0) {
                kidtext = "And all of this nodes children!"
            }
        }
    }
    return (
        <React.Fragment>
            <div className={styles.nodebox}>
                {pnode !== null && <ShowNode node={pnode} level={1} />}
            </div>
            {(showinput===false && showgroups===false) && <ShowButtons />}
            {showinput && ShowInput()}
            {showgroups && ShowGroups()}
            {showdelconfirm && <GenConfirm onCancel={deleteNodeCancel} 
            onConfirm={deleteSelectedNode}
            confirmText={"Ok"} 
            width={500}
            text={`Are you sure you want to delete this Node?\n${selnodetext}\n${kidtext}`}
        />
}
        </React.Fragment>
    )
}

export default OnCallQA;