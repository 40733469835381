import React from "react";
import styles from "./ScheduleDetail.module.scss";
import GenButton from "./GenButton";
import AddNote from "./AddNote";
import {ReactComponent as Closer} from './../assets/close.svg';
import {DataService} from './../store/DataService';
import { RELATIONSHIPS, APPTREASONS, ACTIONADDNOTE, ACTIONREASONS, formatPhoneNumber, Date12 } from "./../AppConstants";
import GenConfirm from './GenConfirm';


const ScheduleDetail = (props) => {
    const [practiceid,setPracticeID] = React.useState(props.practiceid);
    const [showhistory,setShowHistory] = React.useState(false);
    const [shownote,setShowNote] = React.useState(false);
    const [showactions,setShowActions] = React.useState(false);
    const [actionvalue,setActionValue] = React.useState(0);
    const [showtransfer,setShowTransfer] = React.useState(false);
    const [showtransferic,setShowTransferIC] = React.useState(false);
    const [showpatch,setShowPatch] = React.useState(false);
    const [showrestart,setShowRestart] = React.useState(false);
    const [msgdetail,setMsgDetail] = React.useState({detail:{},history:{}});
    const [showcloseconfirm,setShowCloseConfirm] = React.useState(false);
    const [showopenconfirm,setShowOpenConfirm] = React.useState(false);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [transferinputs, setTransferInputs] = React.useState({selxferreason:0,anxfertext:"",selxferrecp:0});
    const [pnvalue,setPNValue] = React.useState("");
    const [phonetype,setPhoneType] = React.useState("");
    const [patchtext,setPatchText] = React.useState("");
    const [pnrestart,setPNRestart] = React.useState("");
    const [restarttext,setRestartText] = React.useState("");
    const [archive,setArchive] = React.useState(props.archive || 'N');

    React.useEffect(()=> {
        let isMounted = true;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getscheduledetail(practiceid,props.msgnum).then((data) => {
            setRefreshNeeded(false);
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                if (isMounted) {
                    let ntype = "EvePhone2";
                    let n = data.detail.EvePhone2;
                    if (!n.length) {
                        ntype = "DayPhone2";
                        n = data.detail.DayPhone2;
                    }
                    if (!n.length) {
                        ntype = "EvePhone1";
                        n = data.detail.EvePhone1;
                    }
                    if (!n.length) {
                        ntype = "DayPhone1";
                        n = data.detail.DayPhone1;
                    }
                    if (n.length) {
                        setPNValue(formatPhoneNumber(n));
                        setPNRestart(formatPhoneNumber(n));
                        setRestartText(data.detail.MessageBody);
                    }
                    setPhoneType(ntype);
                    setMsgDetail(data);
                    setShowActions(props.useactions || false);
                }
            }
        })
        return () => { isMounted = false };
    },[props.msgnum,props.useactions,practiceid,refreshneeded,props.globals.sessionguid])

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.validatesession();
    },[props.globals.sessionguid])

    const onClose = (e) => {
        props.doClose(0);
    }
    
    console.log("TypeID="+msgdetail.detail.MessageTypeID);
    const statuses = ['Closed','Open','2','3','4','5','6'];
    const fdate = msgdetail.detail.MessageCreateDate || "";
    //const sa = fdate.split(" ");
    //const fmtdate = sa[0];
    //const fmttime = sa[1];
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    const fd = new Date(fdate);
    const fmted = fd.toLocaleDateString(undefined,options);
    const timeopts = {hour:"numeric",minute:"numeric",hour12:true};
    const fmttime = fd.toLocaleTimeString("en-us",timeopts);
    let ptdob = new Date(msgdetail.detail.PatientDOB).toLocaleDateString(undefined,options);
    if (ptdob==="Invalid Date" || ptdob.indexOf("1900") >= 0) ptdob = "";
    const ptage = msgdetail.detail.PatientAge || "";
    const patient = msgdetail.detail.PatientFirstName + " " + msgdetail.detail.PatientLastName;
    const pfn = msgdetail.detail.FirstName || "";
    const midin = msgdetail.detail.MiddleInitial || "";
    const pln = msgdetail.detail.LastName || "";
    const cr = msgdetail.detail.CallerRelationship || "";
    const histbutton = showhistory ? "Hide History" : "Show History";
    const histicon = showhistory ? "minus" : "plus";

    let relationship = "";
    if (cr.length > 0) {
        relationship = RELATIONSHIPS[cr];
    }
    let provider = pfn + " ";
    if (midin.length > 0) {
        provider += midin + " "
    }
    provider += pln;
    const cfn = msgdetail.detail.CallerFirstName || "";
    const cln = msgdetail.detail.CallerLastName || "";
    const caller = cfn + " " + cln;
    const inf = msgdetail.info || [];
    const hasinfo = inf.length > 0;


    const GetDataObj = (cmd) => {
        let oncallid = 0;
        let oncallpersonid = 0;
        
        if (msgdetail.history.length > 0) {
            let hist = msgdetail.history[0];
            oncallid = hist.ContactGroupID;
            oncallpersonid = hist.ContactPersonID;
        }

        var obj = {
            cmd:cmd,
            msgid:msgdetail.detail.MessageSchedID,
            oncallgroupid:oncallid,
            oncallproviderid:0,
            oncallpersonid:oncallpersonid,
            personphoneid:0,
            callbacknum:"",
            phonetype:phonetype,
            comments:"",
            reason:"",
            ismanual:false,
            isvalid:true,
            isfinal:false,
            respid:msgdetail.detail.ResponsibleID,
            resptype:msgdetail.detail.ResponsibleType,
            uaid:props.globals.useraccountid,
            uapracticeid:props.globals.userpracticeid,
            escalationseq:1,
            schedoncallpersonid:0,
        }
        return obj;
    }


    const addNote = (e) => {
        setShowNote(true);
    }

    const cancelNote = () => {
        setShowNote(false);
        setActionValue(0);
    }

    const saveNote = (reason,cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            msgid:msgdetail.detail.MessageSchedID,
            msgtype:msgdetail.detail.MessageTypeID,
            msgdt:"",
            respid:msgdetail.detail.ResponsibleID,
            resptype:msgdetail.detail.ResponsibleType,
            uacid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            cmt:cmt,
            reason:reason,
            ctmeth:"",
            ctpersonid:"",
            ctgroupid:"",
            auacid:props.globals.useraccountid,
            apracid:props.globals.userpracticeid,
            recname:""
        }
        dataservice.savenote(obj).then((data) => {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    setShowNote(false);
                    setActionValue(0);
                    props.doClose(0);
                }
            })
        
    }

   
    const onConfirm = () => {
        setShowCloseConfirm(false);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.closeschedmessages(props.globals.useraccountid,props.globals.userpracticeid,msgdetail.detail.MessageSchedID).then((data) => {
            const de = data.ERROR || "";
            if (de.length > 0) {
                alert(de);
            } else {
                props.doRefresh(true);
                onClose();
            }
        })
    }
    const onCancel = () => {
        setShowCloseConfirm(false);
        setActionValue(0);
    }


    const doAction = (e) => {
        setShowCloseConfirm(true);
    }

    const onOpenConfirm = (pflag) => {
        setShowOpenConfirm(false);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        // Note: 1 at end of parms is the "reopen" flag to the server - same function closes and reopens messages
        const reopen = pflag ? 2 : 1;
        dataservice.closeschedmessages(props.globals.useraccountid,props.globals.userpracticeid,msgdetail.detail.MessageSchedID,reopen).then((data) => {
            const de = data.ERROR || "";
            if (de.length > 0) {
                alert(de);
            } else {
                props.doRefresh(true);
                onClose();
            }
        })

    }

    const onOpenCancel = () => {
        setShowOpenConfirm(false);
        setActionValue(0);
    }

    const doOpen = (e) => {
        setShowOpenConfirm(true);
    }

    const toggleHistory = (e) => {
        setShowHistory(!showhistory);
    }

    const ShowAppt = () => {
        const reason = msgdetail.detail.ApptDesc || "";
        const doptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric',minute:'numeric',hours24:false};
        //const doptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        let apptdate = "";
        const ad = msgdetail.detail.ApptDate || "";
        if (ad.length > 0) {
            const dt = new Date(ad);
            apptdate = dt.toLocaleDateString(undefined,doptions);
            if (apptdate==="Mon, Jan 1, 1900") apptdate = "";
        }
        const pritext = ['Low','Normal','High'];
        const priority = parseInt(msgdetail.detail.Priority || '0');
        const apptloc = msgdetail.detail.LocationName || "";
        let onfilephone = msgdetail.detail.OnFilePhone || "";
        let callbackphone = msgdetail.detail.CallbackPhone || "";
        if (!callbackphone.length) {
            callbackphone = msgdetail.detail.DayPhone1 || "";
        }
        return (
            <React.Fragment>
            <div className={styles.partheader}>Appointment Data</div>
            <div className={styles.listrowhdr}>
                <div>Date</div><div>Location</div><div>Reason</div>
            </div>
            <div className={styles.listrow}>
                <div>{apptdate}</div><div>{apptloc}</div><div>{reason}</div>
            </div>
            <div className={styles.listrowhdr}>
                <div>On-File Phone</div><div>Callback Phone</div><div>Priority</div>
            </div>
            <div className={styles.listrow}>
                <div>{formatPhoneNumber(onfilephone)}</div>
                <div>{formatPhoneNumber(callbackphone)}</div>
                <div>{pritext[priority]}</div>
            </div>
            </React.Fragment>
        )
    }

    const ShowInfo = () => {
        return (
            <React.Fragment>
            <div className={styles.partheader}>Extra Information</div>
            <div className={styles.listrowhdr}>
                {msgdetail.info.map((obj,i) => {
                    return <div key={'desc_'+i}>{obj.Description}</div>
                })}
            </div>
            <div className={styles.listrow}>
                {msgdetail.info.map((obj,i) => {
                    return <div key={'val_'+i}>{obj.Val}</div>
                })}
            </div>
            </React.Fragment>
        )
    }

    const ShowRestart = () => {
        const doRestartCancel = (e) => {
            setShowRestart(false);
            setActionValue(0);
        }

        const handlePNChange = (e) => {
            setPNRestart(e.target.value);
        }

        const handleTextChange = (e) => {
            setRestartText(e.target.value);
        }

        const doRestartSave = (e) => {
            let obj = GetDataObj("RESTART");
            obj.callbacknum = pnrestart;
            obj.comments = restarttext;
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.contactaction(obj).then((data)=> {
                setShowRestart(false);
                setActionValue(0);
                props.doClose(-props.msgnum);            
            })

        }
       
        return (
            <React.Fragment>
                <div className={styles.dlgbox}>
                    <div className={styles.dlgboxouter}>
                        <div className={styles.dlgboxinner}>
                            <div className={styles.dlgboxheader}>
                                <div>Restart Protocol</div>
                                <div className={styles.dlgboxheaderright} onClick={doRestartCancel}><Closer /></div>
                            </div>
                            <hr/>
                            <div>
                                <div className={styles.dlgboxgrid2}>
                                    <div style={{marginTop:"4px"}}>New phone number:</div>
                                    <div style={{marginLeft:"2px"}}>
                                        <input type="text" style={{height:"20px",width:"190px",borderRadius:"5px"}} value={pnrestart} onChange={handlePNChange}></input>
                                    </div>
                                </div>
                            </div>
                        <div className={styles.dlgboxtext} style={{marginTop:"10px"}}>Additional Message:</div>
                        <div><textarea rows="5" cols="79" maxLength="240" name="ptext" style={{borderRadius:"6px"}} value={restarttext} onChange={handleTextChange} /></div>
                        <div className={styles.dlgboxbuttons}>
                        <div>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Save Restart"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                        onclick={doRestartSave}
                        />
                        </div>
                        <div style={{marginLeft:"40px"}}>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Cancel"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                            onclick={doRestartCancel}
                        />
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

        
    }


    const ShowPatch = () => {
        const doPatchCancel = (e) => {
            setShowPatch(false);
            setActionValue(0);
            setPatchText("");
        }
        const handlePNChange = (e) => {
            
            const value = e.target.value;
            setPNValue(value);
        }
        const handleTextChange = (e) => {
            setPatchText(e.target.value);
        }

        const doPatchSave = (e) => {
            let obj = GetDataObj("PATCHED");
            obj.callbacknum = pnvalue;
            obj.comments = patchtext;
            obj.contactmethod = "Patched to " + pnvalue;
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.contactaction(obj).then((data)=> {
                setShowPatch(false);
                setActionValue(0);
                setPatchText("");
                props.doClose(0);
            })
        }

        let pnlist = [];
        let n = msgdetail.detail.DayPhone1;
        if (n.length) {
            pnlist.push(formatPhoneNumber(n));
        }
        n = msgdetail.detail.DayPhone2;
        if (n.length) {
            pnlist.push(formatPhoneNumber(n));
        }
        n = msgdetail.detail.EvePhone1;
        if (n.length) {
            pnlist.push(formatPhoneNumber(n));
        }
        n = msgdetail.detail.EvePhone2;
        if (n.length) {
            pnlist.push(formatPhoneNumber(n));
        }
        
        return (
            <React.Fragment>
                <div className={styles.dlgbox}>
                    <div className={styles.dlgboxouter}>
                        <div className={styles.dlgboxinner}>
                            <div className={styles.dlgboxheader}>
                                <div>Patch Caller</div>
                                <div className={styles.dlgboxheaderright} onClick={doPatchCancel}><Closer /></div>
                            </div>
                            <hr/>
                            <div>
                                <div className={styles.dlgboxgrid}>
                                    <div>Patch To:</div>
                                    <div>
                                        <div style={{marginLeft:"9px"}}>
                                            <input type="text" style={{height:"20px",width:"190px",borderRadius:"5px"}} value={pnvalue} onChange={handlePNChange}></input>
                                        </div>
                                        <div style={{marginTop:"6px"}}>
                                            <select style={{width:"200px",height:"28px",marginLeft:"8px",borderRadius:"6px"}} name="selpn" value={pnvalue} onChange={handlePNChange}>
                                                <option value=""></option>
                                                {pnlist.map((obj,i) => {
                                                    return <option key={"pn_"+i} value={obj}>{obj}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className={styles.dlgboxtext} style={{marginTop:"10px"}}>Comment:</div>
                        <div><textarea rows="5" cols="79" maxLength="240" name="ptext" style={{borderRadius:"6px"}} value={patchtext} onChange={handleTextChange} /></div>
                        <div className={styles.dlgboxbuttons}>
                        <div>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Save Patch"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                        onclick={doPatchSave}
                        />
                        </div>
                        <div style={{marginLeft:"40px"}}>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Cancel"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                            onclick={doPatchCancel}
                        />
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )        
    }

    const ShowTransfer = (isInternal) => {
        const doTransferCancel = (e) => {
            if (isInternal) {
                setShowTransfer(false);
            } else {
                setShowTransferIC(false);
            }
            setActionValue(0);
        }
        const HandleTransferChange = (event) => {
            const name = event.target.name;
            const value = event.target.value;
            setTransferInputs(values => ({...values, [name]: value}));
            
        }
        const doTransferSave = (event) => {
            let cmd = "TRANSIC";
            if (isInternal) {
                cmd = "TRANSINT";
            }
            let obj = GetDataObj(cmd);
            obj.respid = transferinputs.selxferrecp;
            let reasoncode = parseInt(transferinputs.selxferreason);
            if (reasoncode > 0) {
                obj.reason = ACTIONREASONS[reasoncode];
            }
            obj.comments = transferinputs.anxfertext;
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.contactaction(obj).then((data)=> {
                if (isInternal) {
                    setShowTransfer(false);
                } else {
                    setShowTransferIC(false);
                }
                setActionValue(0);
                props.doClose(0);
            })
        }

        const rec = msgdetail.recipients || [];
        let recoptions = [];
        rec.forEach((obj)=> {
            const rid = obj.roleid;
            const desc = obj.desc;
            recoptions.push({value:rid,label:desc});
        })
        let title = "Transfer to IC";
        if (isInternal) {
            title = "Transfer Internally";
        }
        return (
            <React.Fragment>
                <div className={styles.dlgbox}>
                    <div className={styles.dlgboxouter}>
                        <div className={styles.dlgboxinner}>
                            <div className={styles.dlgboxheader}>
                                <div>{title}</div>
                                <div className={styles.dlgboxheaderright} onClick={doTransferCancel}><Closer /></div>
                            </div>
                            <hr/>
                            {isInternal && 
                                <div>
                                    <div className={styles.dlgboxgrid}>
                                    <div>Recipient:</div>
                                    <div>
                                    <select style={{width:"300px",height:"28px",marginLeft:"8px",borderRadius:"6px"}} name="selxferrecp" value={transferinputs.selxferrecp} onChange={HandleTransferChange}>
                                        <option value="0"></option>
                                        {rec.map((obj,i) => {
                                            const rid = obj.roleid;
                                            let desc = obj.desc;
                                            if (rid.startsWith("R")) {
                                                desc = 'Role:'+desc;
                                            }
                                            return <option key={"sr_"+i} value={rid}>{desc}</option>
                                        })}
                                    </select>
                                    </div>
                                    </div>
                                </div>
                            }
                            <div>
                                <div className={styles.dlgboxgrid}>
                                <div>Select Reason:</div>
                                <div>
                                <select style={{width:"300px",height:"28px",marginLeft:"8px",borderRadius:"6px"}} name="selxferreason" value={transferinputs.selxferreason} onChange={HandleTransferChange}>
                                    <option value="0"></option>
                                    {Object.keys(ACTIONREASONS).map((ky,i) => {
                                        return <option key={i} value={ky}>{ACTIONREASONS[ky]}</option>
                                    })}
                                </select>
                                </div>
                                </div>
                            </div>
                        <div className={styles.dlgboxtext} style={{marginTop:"10px"}}>Comment:</div>
                        <div><textarea rows="5" cols="79" maxLength="240" name="anxfertext" style={{borderRadius:"6px"}} value={transferinputs.anxfertext} onChange={HandleTransferChange} /></div>
                        <div className={styles.dlgboxbuttons}>
                        <div>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Save Transfer"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                        onclick={doTransferSave}
                        />
                        </div>
                        <div style={{marginLeft:"40px"}}>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Cancel"
                            id="0"
                            height="30"
                            width="90"
                            bgcolor="white"
                            color="black"
                            onclick={doTransferCancel}
                        />
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }


    const ShowActions = () => {
        let alist = msgdetail.actions;


        const doActionChange = (e) => {
            let av = parseInt(e.target.value);
            setActionValue(av);
            if (av===4) {
                setTransferInputs({selxferreason:0,anxfertext:"",selxferrecp:0});
                setShowTransfer(true);
            }
            if (av===5) {
                setTransferInputs({selxferreason:0,anxfertext:"",selxferrecp:0});
                setShowTransferIC(true);
            }
            if (av===6) {
                addNote();
            }
            if (av===7) {
                setShowCloseConfirm(true);
            }
            if (av===8) {
                setShowOpenConfirm(true);
            }
            if (av===15) {
                setShowPatch(true);
            }
            if (av===33) {
                setShowRestart(true);
            }

        }

        return (
            <React.Fragment>
                <hr/>
                <div className={styles.actions}>
                    <div>Actions:</div>
                    <div>
                        <select style={{width:"200px"}} value={actionvalue} onChange={doActionChange}>
                            <option value="0"></option>
                            {alist.map((obj,i) => {
                                if (obj.actionid==="15") return null;
                                if (obj.actionid==="33") return null;
                                if (obj.actionid==="6" && props.showClosed===true) return null;
                                if (obj.actionid==="7" && (msgdetail.detail.status===0 || props.showClosed===true)) return null;
                                if (obj.actionid==="8" && msgdetail.detail.status===1) return null;
                                return <option key={"opt_"+i} value={obj.actionid}>{obj.action}</option>
                            })}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ShowHistory = () => {
        let history = msgdetail.history || [];
        if (!Array.isArray(history)) {
            history = [];
        }
        if (!history.length) {
            return (
                <React.Fragment>
                <div className={styles.partheader}>History</div>
                <div>No History for this message</div>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
            <div className={styles.partheader}>History</div>
            {history.map((obj,i) => {
                let detail = obj.Details.replaceAll(/(?:\r\n)/g, '<br>');
                let edits = obj.EditRows || [];
                console.log("detail "+detail);
                detail = detail.split('<br>');
                let msgdate = Date12(obj.CreateDate);
                return (
                    <div key={'hist_'+i}>
                        <div className={styles.histrowhdr}>
                            <div>Date</div><div>Event</div><div>Who</div><div>Recipient</div><div>Details</div>
                        </div>
                        <div className={styles.histrow}>
                            <div>{msgdate}</div><div>{obj.ActionTaken}</div><div>{obj.Who}</div><div>{obj.Recipient}</div><div className={styles.details}>
                                {detail.map((ln,i)=> {
                                    if (!ln.length) ln = "\n";
                                    return <div key={"ln_"+i} className={styles.newline}>{ln}</div>
                                })}
                                {edits.map((ln,i)=> {
                                    if (!ln.length) ln = "\n";
                                    return <div key={"lned_"+i} className={styles.newline}>{ln}</div>
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
            </React.Fragment>
        )
    }
    let andisabled = false;
    let sc = props.showClosed || false;
    if (sc) andisabled = true;
    

    let cbdisabled = false;
    let cbtitle = "";
    let isclosed = false;
    if (props.showClosed === true) {
        cbtitle = "Cannot close while Showing Closed Messages.";
        cbdisabled = true;
    }
    if (msgdetail.detail.Ack==='N') {
        cbtitle = "Message has not been Acknowledged yet.";
        cbdisabled = true;
    }
    if (msgdetail.detail.Status===0) {
        cbtitle = "Message already closed.";
        cbdisabled = true;
        if (props.globals.intext===1) isclosed = true;
    }
    let tmsg = "Message details";
    if (archive !== 'N') {
        andisabled = true;
        cbdisabled = true;
        tmsg += " (Archived)";
    }
    const pracname = msgdetail.detail.PracName || "";
    tmsg += " " + pracname;
    
    return (
        <div className={styles.detail}>
            <div className={styles.outer}>
                <div className={styles.inner}>
                    <div className={styles.detailheader}>
                        <div>{tmsg}</div>
                        <div className={styles.headerright} onClick={onClose}><Closer /></div>
                    </div>
                    {shownote && <AddNote globals={props.globals} methods={props.methods} onSave={saveNote} onCancel={cancelNote} />}
                    <hr/>
                    <div className={styles.actionheader}>
                        <div style={{display:"flex"}}>
                            <GenButton globals={props.globals} methods={props.methods}
                                buttontext={histbutton}
                                id="0"
                                preicon={histicon}
                                iconcolor="white"
                                height="30"
                                onclick={toggleHistory}
                                />
                            <span style={{marginLeft:"10px",marginTop:"8px"}}>Message ID: {props.msgnum}</span>
                        </div>
                        <div className={styles.actionheader2}>
                            <div>
                                <GenButton globals={props.globals} methods={props.methods}
                                    buttontext="Add Note"
                                    id="0"
                                    preicon="plus"
                                    iconcolor="black"
                                    bgcolor="white"
                                    color="black"
                                    width="100"
                                    height="30"
                                    onclick={addNote}
                                    disabled={andisabled}
                                    />
                            </div>
                            <div style={{width:"30px"}}>&nbsp;</div>
                            <div>
                            {!isclosed && <GenButton globals={props.globals} methods={props.methods}
                                buttontext="Close Message"
                                id="0"
                                bgcolor="white"
                                color="black"
                                width="100"
                                height="30"
                                disabled={cbdisabled}
                                buttontitle={cbtitle}
                                onclick={doAction}
                            />}
                            {isclosed && <GenButton globals={props.globals} methods={props.methods}
                                buttontext="ReOpen Message"
                                id="0"
                                bgcolor="white"
                                color="black"
                                width="100"
                                height="30"
                                buttontitle={cbtitle}
                                onclick={doOpen}
                            />}

                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={styles.lefthalf}>
                        <div className={styles.listrowhdr}>
                            <div>Date</div><div>Time</div><div>Status</div>
                        </div>
                        <div className={styles.listrow}>
                            <div>{fmted}</div><div>{fmttime}</div><div>{statuses[msgdetail.detail.Status]}</div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>Patient</div><div>Date of Birth</div><div>Provider</div>
                        </div>
                        <div className={styles.listrow}>
                            <div>{patient}</div><div>{ptdob} ({ptage})</div><div>{provider}</div>
                        </div>
                        <div className={styles.listrowhdr}>
                        <div>Message-Type</div><div>Caller</div><div>Relationship</div>
                        </div>
                        <div className={styles.listrow}>
                        <div>{msgdetail.detail.Name}</div><div>{caller}</div><div>{relationship}</div>
                        </div>
                        <div className={styles.listrowhdr}>
                        <div>Contact Phone 1</div><div>Contact Phone 2</div><div>Email</div>
                        </div>
                        <div className={styles.listrow}>
                        <div>{formatPhoneNumber(msgdetail.detail.DayPhone1)}</div><div>{formatPhoneNumber(msgdetail.detail.DayPhone2)}</div><div>{msgdetail.detail.CallereMail}</div>
                        </div>
                        <ShowAppt />
                        {hasinfo && <ShowInfo />}
                        
                    </div>
                    <div className={styles.righthalf}>
                        <div><b>Message</b><br/>{msgdetail.detail.MessageBody}</div>
                    </div>
                    {showhistory && <ShowHistory />}
                    {showactions && <ShowActions />}
                    {showtransfer && ShowTransfer(true)}
                    {showtransferic && ShowTransfer(false)}
                    {showpatch && ShowPatch()}
                    {showrestart && ShowRestart()}
                    <hr/>
                    <div>
                        <GenButton 
                        onclick={onClose} 
                        id={props.msgnum} 
                        buttontext="Close Detail"
                        bgcolor="white"
                        color="black"
                        width="100"
                        height="30"
                        />
                    </div>
                </div>
            </div>
            {showcloseconfirm && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                text="Are you sure you wish to Close this message?"
                onCancel = {onCancel}
                onConfirm = {onConfirm}
                confirmText = "Yes" 
                cancelText = "No" />}

            {showopenconfirm && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                text="Are you sure you wish to Re-Open this message?"
                onCancel = {onOpenCancel}
                onConfirm = {onOpenConfirm}
                checkText = "Reopen with Priority"
                confirmText = "Yes" 
                cancelText = "No" />}
    
        </div>
    )
}

export default ScheduleDetail;