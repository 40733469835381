import React from "react";
import styles from "./GenInput.module.scss";
import Search from './Search';

const GenInput = (props) => {

    const DoCallback = (e) => {
        console.log("GenInput Generic callback");
    }
    const DoChange = (e) => {}
    const DoKeyPress = (e) => {};
    const wt = props.width || "300px";
    const ht = props.height || "30px";
    const handleChange = props.change || DoChange;
    const keyPress = props.onKeyPress || DoKeyPress;
    const callback = props.callback || DoCallback;
    const ph = props.placeholder || "Submit";
    const showsearch = props.showsearch || false;
    const inpname = props.inputname || "";
    const inptype = props.inputtype || "text";
    const maxlen = props.maxlen || "255";

    return (
        <div className={styles.geninput} style={{width:wt,height:ht}}>
            <div className={styles.content}>
                <input type={inptype} maxLength={maxlen} onChange={handleChange} onKeyPress={(e)=>keyPress(e)} name={inpname} className={styles.innerinput} placeholder={ph} />
            </div>
            {showsearch && <Search globals={props.globals} methods={props.methods} callback={callback} />}
        </div>
    )
}

export default GenInput;
