import React from "react";
import styles from "./WorkingTickets.module.scss";
import AddNote from "../../components/AddNote";
import { DataService } from "../../store/DataService";
import {useNavigate} from 'react-router-dom'; 
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import TicketDetail from "../../components/TicketDetail";
import CountdownTimer from "../../components/CountdownTimer";
import { RELATIONSHIPS, APPTREASONS, ACTIONADDNOTE, ACTIONREASONS, formatPhoneNumber, Date12,WORKFLOW_ACTION_WAITING,WORKFLOW_ACTION_CLOSE } from "../../AppConstants";

const WorkingTickets = (props) => {
    const [tickets,setTickets] = React.useState([]);
    const [selectedticket,setSelectedTicket] = React.useState(null);
    const [nowork,setNoWork] = React.useState(false);
    const [timerstarted,setTimerStarted] = React.useState(false);
    const lastticketRef = React.useRef({practiceid:0,ticketid:0,priority:0});
    const [isTimerActive,setIsTimerActive] = React.useState(false);
    const [timerDuration,setTimerDuration] = React.useState(15);
    const inTimeout = React.useRef(false);
    const history = useNavigate();

    React.useEffect(()=> {
        if (!timerstarted) {
            getAvailTickets();
            setTimerStarted(true);
        } else {
            const timer = setInterval(()=> {
                if (nowork && selectedticket === null) {
                    getAvailTickets();
                }
            },30000);
            return () => {
                clearInterval(timer);

            };
        }

    },[nowork,selectedticket])

    const getAvailTickets = () => {
        if (inTimeout.current) return;
        const pid = lastticketRef.current.practiceid;
        const tid = lastticketRef.current.ticketid;
        const pri = lastticketRef.current.priority;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        
        setNoWork(false);
        
        dataservice.getwork(props.globals.practiceid,props.globals.useraccountid,pid,tid,pri).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    if (data.ACTION) {
                        if (data.ACTION.length) {
                            if (data.ACTION==="LOGIN") {
                                const dataservice = new DataService({guid:props.globals.sessionguid});
                                dataservice.dologout(props.globals.useraccountid).then((data)=> {
                                    props.methods.SetUseraccountid(0);
                                    history("/");
                                })
                            }
                            if (data.ACTION==="EXIT") {
                                history("/");
                            }
                        }
                    }
                    return;
                }
            }
            setTickets(data.TICKETS);
            setNoWork(true);
            if (data.TICKETS.length > 0) {
                setIsTimerActive(true);
            }
        })
    }

    const setSelected = () => {
        if (isTimerActive) {
            setIsTimerActive(false);
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const tk = tickets[0];
        if (!tk) return;
        const ticketid = tk.ticketid;
        const evname = tk.eventname || "";
        if (evname==='QUEUE' || evname==='INPROGRESS') {
            const priority = tk.evpriority || 0;
            const stateid = tk.workingstateid || 0;
            dataservice.gototicketstate(ticketid,stateid,priority,props.globals.useraccountid).then((data)=>{
                if (data.hasOwnProperty('ERROR')) {
                    if (data.ERROR.length > 0) {
                        alert(data.ERROR);
                        return;
                    }
                }
                let closed = data.ISCLOSED || 0;
                if (closed === 1) {
                    alert("Ticket is Closed");
                    getAvailTickets();
                    return;
                }
    
                
                if (data.hasOwnProperty('ACTION')) {
                    let action = data.ACTION;
                    if (action === 'exit') {
                        getAvailTickets();
                        return;
                    }
                    if (action === 'inuse') {
                        alert("Ticket is already IN-PROGRESS.");
                        getAvailTickets();
                        return;
                    }
                } 
                        
                dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
                    if (data.ERROR) {
                        if (data.ERROR.length > 0) {
                            alert(data.ERROR);
                            return;
                        }
                    }
                    lastticketRef.current.practiceid = data.TICKET.practiceid || 0;
                    lastticketRef.current.ticketid = data.TICKET.ticketid || 0;
                    lastticketRef.current.priority = data.TICKET.priority || 0;
                    
                    setSelectedTicket(data.TICKET);
                })
            })
    
        }

    }

    const handleTimerComplete = (takeTicket) => {
        if (takeTicket) {
            setSelected();
        }
        setIsTimerActive(false);
        if (!takeTicket) {
            setSelectedTicket(null);
            const tk = tickets[0];
            setTickets([]);
            const priority = tk.evpriority || 0;
            const stateid = tk.restartstateid || 0;
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.gototicketstate(tk.ticketid,stateid,priority,props.globals.useraccountid).then((data)=>{
                if (data.hasOwnProperty('ERROR')) {
                    if (data.ERROR.length > 0) {
                        alert(data.ERROR);
                        return;
                    }
                }
                inTimeout.current = true;
                alert("Ticket timer has expired. Press OK to get available tickets");
                inTimeout.current = false;
                getAvailTickets();
            });
            
        }
    }

    const ShowAvailable = () => {

        if (tickets.length === 0) {
            return (
                <React.Fragment>
                    <div className={styles.title}>Available Working Tickets</div>
                    <div>No Available Tickets</div>
                </React.Fragment>
            )
        }
        let tk = tickets[0];
        return (
            <React.Fragment>
                <div className={styles.title}>Available Working Tickets</div>
                <div className={styles.tickethdr}>
                    <div style={{textAlign:"right",paddingRight:"16px"}}>Ticket #</div>
                    <div>Practice</div>
                    <div>Message Type</div>
                    <div>Patient Name</div>
                    <div>Callback Phone</div>
                    <div>Created Date</div>
                    </div>
                    <div className={styles.ticketrow} onClick={setSelected}>
                        <div style={{textAlign:"right",paddingRight:"16px"}}>{tk.ticketid}</div>
                        <div>{tk.pracname}</div>
                        <div>{tk.msgname}</div>
                        <div>{tk.firstname} {tk.lastname}</div>
                        <div>{tk.callbackphone}</div>
                        <div>{Date12(tk.createdate)}</div>
                    </div>
                        
                {isTimerActive && 
                <div style={{marginTop:"30px",marginLeft:"160px"}}>
                    <CountdownTimer initialSeconds={timerDuration} onComplete={handleTimerComplete} />
                </div>
                }
                
            </React.Fragment>
        )
    }

    const ShowDetail = () => {
        const onDetailClose = (ticketid,stateid,priority) => {
            
            if (stateid===0) stateid = selectedticket.eventstatus;
            if (stateid===selectedticket.workingstateid) {
                const dataservice = new DataService({guid:props.globals.sessionguid});
                const ticketid = selectedticket.ticketid;
                const priority = selectedticket.evpriority || 0;
                const stateid = selectedticket.restartstateid || 0;
                dataservice.gototicketstate(ticketid,stateid,priority,props.globals.useraccountid).then((data)=>{
                    if (data.hasOwnProperty('ERROR')) {
                        if (data.ERROR.length > 0) {
                            alert(data.ERROR);
                            return;
                        }
                    }
                    let closed = data.ISCLOSED || 0;
                    if (closed === 1) {
                        alert("Ticket is Closed");
                        getAvailTickets();
                        return;
                    }
        
                    
                    if (data.hasOwnProperty('ACTION')) {
                        let action = data.ACTION;
                        if (action === 'exit' || action === 'inuse') {
                            getAvailTickets();
                            return;
                        }
                    }
                    setSelectedTicket(null);
                    getAvailTickets();
                })
            } else {
                setSelectedTicket(null);
                getAvailTickets()
            }
        }

        return (
            <React.Fragment>
                <TicketDetail globals={props.globals} methods={props.methods} ticket={selectedticket} doClose={onDetailClose} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {selectedticket===null && <ShowAvailable />}
            {selectedticket !== null && <ShowDetail />}
        </React.Fragment>
    )

}

export default WorkingTickets;