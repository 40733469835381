import React from 'react';
import styles from './ViewArchiveMessages.module.scss';
import MessageHeaderInt from './../../components/MessageHeaderInt';
import {DataService} from './../../store/DataService';
import GenMsgBox from './../../components/GenMsgBox';
import {ReactComponent as Calendar} from './../../assets/calendar.svg';
import ShowCalendar from '../../components/ShowCalendar';
import { DateOnly, Date12, formatPhoneNumber } from '../../AppConstants';

const ViewArchiveMessages = (props) => {
    const [inputs,setInputs] = React.useState({ptlast:'',ptfirst:'',providerid:0,msgtype:5,msgfrom:'',msgto:''})
    const [msgs,setMessages] = React.useState([]);
    const [totalcount,setTotalCount] = React.useState(0);
    const [msgdetail,setMsgDetail] = React.useState({});
    const [showdetail,setShowDetail] = React.useState(false);
    const [providers,setProviders] = React.useState([]);
    const [msgtypes,setMsgTypes] = React.useState([]);
    const [showfromcal,setShowFromCal] = React.useState(false);
    const [showtocal,setShowToCal] = React.useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        setInputs({...inputs,[name]:val})
    }

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});

        dataservice.searcharchive(props.globals.practiceid,inputs).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            let provs = data.PROVS || [];
            provs.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });

            setProviders(provs);
            let types = data.TYPES || [];
            types.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });

            setMsgTypes(types);
            setTotalCount(data.TOTAL);
            setMessages(data.MSGS);
        })

    },[props.globals.sessionguid])

    const doSearch = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});

        dataservice.searcharchive(props.globals.practiceid,inputs).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }

            setMessages(data.MSGS);
            setTotalCount(data.TOTAL);
        })
    }

    const setFromDate = (v) => {
        setShowFromCal(false);        
        if (v !== null) {
            setInputs(values => ({...values,msgfrom:DateOnly(v)}));
        }
    }

    const setToDate = (v) => {
        setShowToCal(false);
        if (v !== null) {
            setInputs(values => ({...values,msgto:DateOnly(v)}));
        }
    }

    const doDetail = (e) => {
        let nm = e.target.parentNode.id;
        let sa = nm.split("_");
        if (sa.length < 2) return;
        let msgid = sa[1];
        const dataservice = new DataService({guid:props.globals.sessionguid});

        dataservice.getarchivemessage(props.globals.practiceid,msgid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            setMsgDetail(data.MSG);
            setShowDetail(true);
            
        })
        
    }

    const DetailBox = () => {
        const onOK = (e) => {
            setShowDetail(false);
        }

        const arcmsgid = msgdetail.arcmsgid || 0;
        if (arcmsgid < 1) {
            return null;
        }

        let extrainfo = msgdetail.extrainfo || [];
        extrainfo.sort(function(a,b) {
            if (a.sortorder < b.sortorder) return -1;
            if (a.sortorder > b.sortorder) return 1;
            return 0;
        })
        const history = msgdetail.history || [];
        const apptinfo = msgdetail.apptinfo || {};
        const rxinfo = msgdetail.rxinfo || {};
        const rxnumber = rxinfo.rxnumber || "Unknown";
        const rxdosage = rxinfo.dosage || "Unknown";
        const rxquantity = rxinfo.quantity || "Unknown";
        const rxdir = rxinfo.directionsforuse || "Unknown";
        const medname = rxinfo.medication || "Unknown";
        const medreason = rxinfo.medicationreason || "";
        const prescriber = rxinfo.ppname || "Unknown";
        let pharmplace = "Pharmacy";
        if (rxinfo.rxdelivery !== 'P') pharmplace = rxinfo.rxdelivery || "";
        const pharminfo = rxinfo.pharmacy || {};
        const pharmname = pharminfo.pharmacyname || "";
        let pharmphone = pharminfo.phonenumber || "";
        if (pharmphone.length > 0) pharmphone = formatPhoneNumber(pharmphone);

        let pharmfax = pharminfo.faxnumber || "";
        if (pharmfax.length > 0) pharmfax = formatPhoneNumber(pharmfax);

        const pharmadd1 = pharminfo.address1 || "";
        const pharmadd2 = pharminfo.address2 || "";
        let csz = pharminfo.city || "";
        const pharmstate = pharminfo.state || "";
        const pharmzip = pharminfo.zipcode || "";
        if (pharmstate.length > 0) {
            if (csz.length > 0) csz += ", ";
            csz += pharmstate;
            if (pharmzip.length > 0) {
                csz += " " + pharmzip;
            }
        }
        let apptdate = apptinfo.apptdate || "";
        if (apptdate.length > 0) {
            apptdate = Date12(apptdate);
            if (apptdate.indexOf("Invalid") >= 0) apptdate = "";
            let sa = apptdate.split(",");
            apptdate = sa[0];
        }
        let msghdr = "Message: ";
        if (msgdetail.msghandling==='U') {
            msghdr = "Urgent Message: "
        }
        let msgbody = msgdetail.msgbody;
        let newpt = "No";
        if (msgdetail.newpt === 'Y') newpt = "Yes";
        let ptage = msgdetail.ptage
        let ptdob = msgdetail.ptdob;
        ptdob = ptdob.trim();
        if (ptdob.length > 0) {
            ptdob = DateOnly(ptdob);
            if (ptdob === "Invalid Date" || ptdob === '01/01/1900') {
                ptage = 'n/a';
                ptdob = "";
            }
        }
        const getDays = (dypref) => {
            let days = "";
            let darr = [];
            if ((dypref>>0) % 2) darr.push("Mon");
            if ((dypref>>1) % 2) darr.push("Tue");
            if ((dypref>>2) % 2) darr.push("Wed");
            if ((dypref>>3) % 2) darr.push("Thu");
            if ((dypref>>4) % 2) darr.push("Fri");
            if ((dypref>>5) % 2) darr.push("Sat");
            if ((dypref>>6) % 2) darr.push("Sun");
            days = darr.join(",");
            return days;
        }

        const getTimes = (tmpref) => {
            let times = "";
            if (tmpref === 1) times = "AM";
            if (tmpref === 2) times = "PM";
            if (tmpref === 3) times = "AM or PM";
            return times;
        }

        return (
            <React.Fragment>
                <div className={styles.backdrop} />
                <div className={styles.modal}>
                    <div style={{display:"flex"}}>
                        <div style={{width:"80%",fontWeight:"bold",fontSize:"22px"}}>Message Detail</div>
                        <div className={styles.dtcola}>ArchiveID:</div><div className={styles.dtcolb}>{msgdetail.arcmsgid}</div>
                        <div className={styles.dtcola} style={{marginLeft:"20px"}}>ID:</div><div className={styles.dtcolb}>{msgdetail.msgid}</div>
                    </div>
                    <div className={styles.detailmain}>
                        <div style={{borderRight:"1px solid blue"}}>
                            <div className={styles.detailmsg}>
                                <div className={styles.detailtbl}>
                                    <div className={styles.dtcola}><b>{msghdr}</b></div><div className={styles.dtcolb}>{msgbody}</div>
                                    <div className={styles.dtcola}>Patient:</div><div className={styles.dtcolb}>{msgdetail.ptfirst+" "+msgdetail.ptlast}</div>
                                    <div className={styles.dtcola}>DOB:</div><div className={styles.dtcolb}>{ptdob}</div>
                                    <div className={styles.dtcola}>Age:</div><div className={styles.dtcolb}>{ptage}</div>
                                    <div className={styles.dtcola}>New:</div><div className={styles.dtcolb}>{newpt}</div>
                                    <div className={styles.dtcola}>Recipient:</div><div className={styles.dtcolb}>{msgdetail.recipient}</div>
                                    <div className={styles.dtcola}>Provider:</div><div className={styles.dtcolb}>{msgdetail.provname}</div>
                                    <div className={styles.dtcola}>Practice:</div><div className={styles.dtcolb}><b>{msgdetail.pracname}</b></div>
                                    <div className={styles.dtcola}>Location:</div><div className={styles.dtcolb}>{msgdetail.locname}</div>
                                    {extrainfo.length > 0 && <div>
                                        {extrainfo.map((eobj,j)=> {
                                            return <div key={"ei_"+j}>
                                                <div className={styles.dtcola}>{eobj.desc}:</div><div className={styles.dtcolb}>{eobj.value}</div>
                                            </div>
                                        })}
                                    </div>}
                                </div>
                                <div className={styles.detailtbl}>
                                    <div className={styles.dtcola}>Caller:</div><div className={styles.dtcolb}>{msgdetail.callerfirst+" "+msgdetail.callerlast}</div>
                                    <div className={styles.dtcola}>Rel:</div><div className={styles.dtcolb}>{msgdetail.relname}</div>
                                    <div className={styles.dtcola}>Org:</div><div className={styles.dtcolb}>{msgdetail.callerorg}</div>
                                    <div className={styles.dtcola}>Email:</div><div className={styles.dtcolb}>{msgdetail.email}</div>
                                </div>
                                <div className={styles.detailtbl}>
                                    <div className={styles.dtcola}>Day1:</div><div className={styles.dtcolb}>{msgdetail.dayphone1}</div>
                                    <div className={styles.dtcola}>Day2:</div><div className={styles.dtcolb}>{msgdetail.dayphone2}</div>
                                    <div className={styles.dtcola}>Eve1:</div><div className={styles.dtcolb}>{msgdetail.evephone1}</div>
                                    <div className={styles.dtcola}>Eve2:</div><div className={styles.dtcolb}>{msgdetail.evephone2}</div>
                                </div>
                            </div>
                            {msgdetail.msgtypeid===2 && <div>
                                    <div style={{height:"22px",backgroundColor:"darkblue",color:"white",textAlign:"center",fontSize:"14px"}}>Appointment Detail</div>
                                    <div className={styles.detailtblappt} style={{textAlign:"left",marginLeft:"5%",marginTop:"20px",width:"80%"}}>
                                        <div className={styles.dtcola}>Reason:</div><div className={styles.dtcolb}>{apptinfo.reasondesc}</div>
                                        <div className={styles.dtcola}>Date:</div><div className={styles.dtcolb}>{apptdate}</div>
                                        <div className={styles.dtcola}>Provider:</div><div className={styles.dtcolb}>{apptinfo.provname}</div>
                                        <div className={styles.dtcola}>Location:</div><div className={styles.dtcolb}>{apptinfo.locname}</div>
                                        <div className={styles.dtcola}>Days:</div><div className={styles.dtcolb}>{getDays(apptinfo.daypref)}</div>
                                        <div className={styles.dtcola}>Times:</div><div className={styles.dtcolb}>{getTimes(apptinfo.timepref)}</div>
                                    </div>
                                </div>}
                            {msgdetail.msgtypeid===3 && <div>
                                    <div style={{height:"22px",backgroundColor:"darkblue",color:"white",textAlign:"center",fontSize:"14px"}}>Rx Detail</div>
                                    <div className={styles.detailmsg}>
                                        <div className={styles.detailtbl}>
                                            <div className={styles.dtcola}>Medication:</div><div className={styles.dtcolb}>{medname}</div>
                                            <div className={styles.dtcola}>Reason:</div><div className={styles.dtcolb}>{medreason}</div>
                                            <div className={styles.dtcola}>Prescriber:</div><div className={styles.dtcolb}>{prescriber}</div>
                                            <div className={styles.dtcola}>Delivery Place:</div><div className={styles.dtcolb}>{pharmplace}</div>
                                            <div className={styles.dtcola}>Name:</div><div className={styles.dtcolb}>{pharmname}</div>
                                            <div className={styles.dtcola}>Phone:</div><div className={styles.dtcolb}>{pharmphone}</div>
                                            <div className={styles.dtcola}>Fax:</div><div className={styles.dtcolb}>{pharmfax}</div>
                                            <div className={styles.dtcola}>Address:</div><div className={styles.dtcolb}>{pharmadd1}</div>
                                            {pharmadd2.length > 0 && <React.Fragment><div className={styles.dtcola}></div><div className={styles.dtcolb}>{pharmadd2}</div></React.Fragment>}
                                            <div className={styles.dtcola}></div><div className={styles.dtcolb}>{csz}</div>
                                        </div>
                                        <div className={styles.detailtbl}>
                                            <div className={styles.dtcola}>Rx Number:</div><div className={styles.dtcolb}>{rxnumber}</div>
                                            <div className={styles.dtcola}>Dosage:</div><div className={styles.dtcolb}>{rxdosage}</div>
                                            <div className={styles.dtcola}>Quantity:</div><div className={styles.dtcolb}>{rxquantity}</div>
                                            <div className={styles.dtcola} style={{height:"32px"}}>{" "}</div><div className={styles.dtcolb} style={{height:"32px"}}>{" "}</div>
                                            <div className={styles.dtcola}>Directions:</div><div className={styles.dtcolb}>{rxdir}</div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        <div className={styles.detailhistory}>
                            {msgdetail.history.map((obj,i)=> {
                                let bname = obj.firstname + " " + obj.lastname;
                                let mi = obj.middleinit + '';
                                mi = mi.trim();
                                if (mi.length > 0) bname += " " + mi + ".";
                                bname = bname.trim();
                                if (!bname.length) bname=obj.personhistory;
                                if (!bname.length) bname="Patient";
                                return <div key={"mhist_"+i} style={{paddingLeft:"4px"}}>
                                    <div><b>{obj.actiontaken}</b></div>
                                    <div>on {Date12(obj.msgdate)}</div>
                                    <div>by {bname}</div>
                                    {(obj.actionid==='2' || obj.actionid==='12' || obj.actionid==='15') && <div>
                                            <div>{obj.contactmethod}</div>
                                            <div>{obj.oncallname}</div>
                                        </div>}
                                    {obj.actionid==='zzzzzz' && <div>
                                            <div>{obj.contactmethod}</div>
                                            <div>{obj.personhistory}</div>
                                        </div>}
                                    {obj.actionid==='24' && <div>
                                        <div>Signing Provider: {obj.oncallname}</div>
                                        </div>}
                                    <div>{obj.comment}</div>
                                </div>
                            })}
                        </div>
                    </div>

                    <div style={{width:"100%",textAlign:"center",marginTop:"8px"}}>
                        <button className={styles.btn} onClick={onOK}>Close</button>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }

    const ShowHeader = () => {

        return (
            <div className={styles.header}>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{marginLeft:"auto",userSelect:"none"}}>Patient Name, First:</div>
                        <div className={styles.inputs} style={{marginRight:"20px"}}><input style={{height:"20px",border:"none",padding:"0",margin:""}} type="text" name="ptfirst" onChange={handleChange} value={inputs.ptfirst} /></div>
                    </div>
                    <div style={{display:"flex",marginTop:"8px"}}>
                        <div style={{marginLeft:"auto",userSelect:"none"}}>Last:</div>
                        <div className={styles.inputs} style={{marginRight:"20px"}}><input style={{height:"20px",border:"none",padding:"0",margin:""}} type="text" name="ptlast" onChange={handleChange} value={inputs.ptlast} /></div>
                    </div>
                </div>                
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{marginLeft:"auto",userSelect:"none"}}>Provider:</div>
                        <div className={styles.selects} style={{marginRight:"20px"}}>
                            <select name="providerid" value={inputs.providerid} style={{height:"22px",minWidth:"200px"}} onChange={handleChange}>
                                <option value={0}></option>
                                {providers.map((pobj,i)=> {
                                    return <option key={"pr_"+i} value={pobj.provid}>{pobj.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:"flex",marginTop:"8px"}}>
                    <div style={{marginLeft:"auto",userSelect:"none"}}>Message Type:</div>
                        <div className={styles.selects} style={{marginRight:"20px"}}>
                            <select name="msgtype" value={inputs.msgtype} style={{height:"22px",minWidth:"200px"}} onChange={handleChange}>
                                <option value={0}></option>
                                {msgtypes.map((pobj,i)=> {
                                    return <option key={"mt_"+i} value={pobj.typeid}>{pobj.name}</option>
                                })}
                            </select>
                        </div>
                    </div>

                </div>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{marginLeft:"auto",userSelect:"none"}}>Date Created From:</div>
                        <div className={styles.inputs} style={{marginRight:"20px"}}><input style={{height:"20px",border:"none",padding:"0",margin:""}} type="text" name="msgfrom" onChange={handleChange} value={inputs.msgfrom} /></div>
                        <Calendar onClick={(e)=>setShowFromCal(true)} />
                    </div>
                    <div style={{display:"flex",marginTop:"8px"}}>
                        <div style={{marginLeft:"auto",userSelect:"none"}}>To:</div>
                        <div className={styles.inputs} style={{marginRight:"20px"}}><input style={{height:"20px",border:"none",padding:"0",margin:""}} type="text" name="msgto" onChange={handleChange} value={inputs.msgto} /></div>
                        <Calendar onClick={(e) =>setShowToCal(true)} />
                    </div>

                </div>
                <div style={{width:"140px"}}>
                    <div style={{textAlign:"right"}}>
                    <button onClick={doSearch}>Search</button>
                    </div>
                </div>
            </div>
        )
    }

    const ShowMessages = () => {

        return (
            <React.Fragment>
                <div className={styles.msgtable} style={{borderTop:"1px solid black"}}>
                    <div className={styles.msgheader}>Date</div>
                    <div className={styles.msgheader}>Time</div>
                    <div className={styles.msgheader}>Patient</div>
                    <div className={styles.msgheader}>Message Type</div>
                    <div className={styles.msgheader}>Caller</div>
                    <div className={styles.msgheader}>Provider</div>
                </div>
                <div style={{paddingBottom:"12px"}}>
                    {msgs.length < 1 && <div style={{width:"100%",textAlign:"center",paddingTop:"10px",fontSize:"22px"}}>No Messages found</div>}
                    {msgs.length > 0 && msgs.map((obj,i)=> {
                        let sa = obj.cdate.split(' ');
                        if (sa.length < 2) {
                            sa = [obj.cdate,''];
                        }
                        let cd = sa[0];
                        let ct = sa[1];
                        return <div className={styles.msgrows} key={"msg_"+i} id={"mid_"+obj.msgid} onDoubleClick={doDetail}>
                                <div style={{textAlign:"right",paddingTop:"3px",paddingRight:"3px",borderRight:"1px solid green"}}>{cd}</div>
                                <div style={{textAlign:"right",paddingTop:"3px",paddingRight:"3px",borderRight:"1px solid green"}}>{ct}</div>
                                <div style={{paddingLeft:"6px",paddingTop:"3px",paddingRight:"3px",borderRight:"1px solid green"}}>{obj.ptname}</div>
                                <div style={{paddingLeft:"6px",paddingTop:"3px",paddingRight:"3px",borderRight:"1px solid green"}}>{obj.typename}</div>
                                <div style={{paddingLeft:"6px",paddingTop:"3px",paddingRight:"3px",borderRight:"1px solid green"}}>{obj.callername}</div>
                                <div style={{paddingLeft:"6px",paddingTop:"3px"}}>{obj.provname}</div>
                            </div>
                    })}
                    <div style={{marginTop:"8px",width:"100%",textAlign:"center"}}>Showing {msgs.length} message{msgs.length > 1 && "s"} out of {totalcount}</div>
                </div>
            </React.Fragment>
        )
    }

    let mindate = inputs.msgfrom || "";
    if (mindate.length) mindate = new Date(mindate);
    let maxdate = inputs.msgto || "";
    if (maxdate.length) maxdate = new Date(maxdate);
    const fromdate = inputs.msgfrom || new Date();
    const todate = inputs.msgto || new Date();
    //console.log("Showing archive messages")
    return (
        <div className={styles.viewmsg}>
            <h2 className={styles.title}>Archive Search</h2>
            <div className={styles.content}>View and filter all Messages.</div>
            {ShowHeader()}
            <ShowMessages />
            {showfromcal && <ShowCalendar value={new Date(fromdate)} onChange={setFromDate} maxDate={maxdate} />}
            {showtocal && <ShowCalendar value={new Date(todate)} onChange={setToDate} minDate={mindate} />}
            {showdetail && <DetailBox />}
        </div>
    )
}

export default ViewArchiveMessages;