
import React from "react";
import {useState} from "react";
import Framework from './pages/Framework';
import Login from './pages/Login';
import ResetPassword from "./pages/ResetPassword";
//import LoginReg from './pages/LoginReg';

var GlobalDirtyData = false;
var GlobalReadAll = false;

function App() {

	const [appGlobals,SetAppGlobals] = useState(
	{
		intext: 2,
		personid: 0,
		practiceid: 0,
		locationid: 0,
		useraccountid: 0,
		userpracticeid:0,
		roleid: 0,
		fmtic: false,
		fmticadmin: false,
		pwdexpired: 'N',
		firstname: '',
		lastname: '',
		middle: '',
		name: '',
		email: '',
		providerid: 0,
		oncallid: 0,
		specialties: [],
		credentials: [],
		contacts: [],
		practice: {},
		practiceaccount: {},
		callnotes: [],
		sysmsgs: [],
		mypractices: [],
		menuselected: "",
		submenuselected: "",
		menupath: "",
		selecteddate: new Date(),
		dirtydata: false,
		callnotecount: 0,
		sessionguid: "",
		sessionexpired: false,
		dirtyfields: {},
		practicerefresh: false,
		sysusermsg:'',
	});

	

	const GetGlobalField = (fname) => {
		return appGlobals[fname];
	}

	const SetField = (fname,fvalue) => {
		const newag = {...appGlobals,[fname]:fvalue};
		//let newag = JSON.parse(JSON.stringify(appGlobals));
		//newag[fname]=fvalue;
		SetAppGlobals(newag);
		console.log("SetField fld,val="+fname+","+fvalue);
		console.log("AppGlobals["+fname+"]="+appGlobals[fname]);
	}

	const SetFieldObject = (fobj) => {
		const newag = {...appGlobals,...fobj};
		SetAppGlobals(newag);
		console.log("SetFieldObject");
	}

	const SetGlobalDirtyField = (fname,fvalue) => {
		const newfld = {...appGlobals.dirtyfields,[fname]:fvalue};
		SetField("dirtyfields",newfld);
	}
	const ClearGlobalDirtyFields = () => {
		let newfld = {...appGlobals.dirtyfields}
		Object.keys(appGlobals.dirtyfields).forEach((ky) => {
			newfld[ky] = false;
		});
		SetField("dirtyfields",newfld);
	}

	const DirtyGlobalFields = () => {
		let tf = false;
		Object.keys(appGlobals.dirtyfields).forEach((ky) => {
			tf = tf | appGlobals.dirtyfields[ky];
		})
		return tf;
	}

	const appMethods = {
		SetIntExt: function(v) {SetField("intext",v)},
		SetPersonid: function(v) {SetField("personid",v)},
		SetPracticeid: function(v) {SetField("practiceid",v)},
		SetLocationid: function(v) {SetField("locationid",v)},
		SetUseraccountid: function(v) {SetField("useraccountid",v)},
		SetRoleid: function(v) {SetField("roleid",v)},
		setFMTIC: function(v) {SetField("fmtic",v)},
		setFMTICAdmin: function(v) {SetField("fmticadmin",v)},
		SetPwdexpired: function(v) {SetField("pwdexpired",v)},
		SetFirstname: function(v) {SetField("firstname",v)},
		SetLastname: function(v) {SetField("lastname",v)},
		SetMiddle: function(v) {SetField("middle",v)},
		SetName: function(v) {SetField("name",v)},
		SetEmail: function(v) {SetField("email",v)},
		SetProviderid: function(v) {SetField("providerid",v)},
		SetOnCallid: function(v) {SetField("oncallid",v)},
		SetSpecialties: function(v) {SetField("specialties",v)},
		SetCredentials: function(v) {SetField("credentials",v)},
		SetContacts: function(v) {SetField("contacts",v)},
		SetPractice: function(v) {SetField("practice",v)},
		SetPracticeaccount: function(v) {SetField("practiceaccount",v)},
		SetCallnotes: function(v) {SetField("callnotes",v)},
		SetSysMsgs: function(v) {SetField("sysmsgs",v)},
		SetMyPractices: function(v) {SetField("mypractices",v)},
		SetMenuSelected: function(v) {SetFieldObject({"menuselected":v,"submenuselected":""})},
		SetSubMenuSelected: function(v) {SetFieldObject({"submenuselected":v,"menuselected":""})},
		SetMenuPath: function(v) {SetField("menupath",v)},
		SetSelectedDate: function(v) {SetField("selecteddate",v)},
		SetObject: function(obj) {SetFieldObject(obj)},
		SetDirtyData: function(v) {SetField("dirtydata",v);GlobalDirtyData=v},
		SetCallNoteCount: function(v) {SetField("callnotecount",v)},
		SetSessionExpired: function(v) {SetField("sessionexpired",v)},
		SetDirtyField: function(f,v) {SetGlobalDirtyField(f,v)},
		ClearDirtyFields: function() {ClearGlobalDirtyFields()},
		DirtyFields: function() {return DirtyGlobalFields()},
		GetField: function(f) {return GetGlobalField(f)},
		GetGlobalDirtyData:function() {return GlobalDirtyData},
		GetGlobalReadAll:function() {return GlobalReadAll},
		SetGlobalReadAll:function(v) {GlobalReadAll=v},
		SetPracticeRefresh:function(v) {SetField("practicerefresh",v)},
		SetSysUserMsg:function(v) {SetField("sysusermsg",v)},
		SetSessionGUID:function(v) {SetField("sessionguid",v)},
		/*
		SetUserId: function(v) {SetField("userid",v)},
		SetUserName: function(v) {SetField("username",v)},
		SetPracticeId: function(v) {SetField("practiceid",v)},
		SetPracticeName: function(v) {SetField("practicename",v)},
		SetProviderid: function(v) {SetField("providerid",v)},
		SetProviderName: function(v) {SetField("providername",v)},
		SetCallGroupId: function(v) {SetField("callgroupid",v)},
		SetCallGroupName: function(v) {SetField("callgroupname",v)},
		SetObject: function(obj) {SetFieldObject(obj)}
		*/
	}

	const ShowGlobals = () => {
		/*
		for (const [key, value] of Object.entries(appGlobals)) {
			console.log(`${key}: ${value}`);
		  }
		*/
		console.log('DD = ' + appGlobals['dirtydata']);
		console.log("IntExt = " + appGlobals['intext']);
		console.log("RoleID = " + appGlobals['roleid']);
		return null;
	}

	const ResetLogin = (smsg='') => {
		//appMethods.SetSessionExpired(false);
		//appMethods.SetDirtyData(false);
		//appMethods.SetOnCallid(0);
		//appMethods.SetUseraccountid(0);
		const pobj = {sessionexpired:false,dirtydata:false,oncallid:0,useraccountid:0,sysusermsg:smsg}
		appMethods.SetObject(pobj);
		GlobalDirtyData=0;
	}

	return (
		
		<React.Fragment>
			{appGlobals.useraccountid === 0 && <Login globals={appGlobals} methods={appMethods} />}
			{appGlobals.pwdexpired === 'Y' && <ResetPassword globals={appGlobals} methods={appMethods} />}
			{appGlobals.pwdexpired !== 'Y' && appGlobals.useraccountid > 0 && <Framework globals={appGlobals} methods={appMethods} resetlogin={ResetLogin} />}
			<ShowGlobals />
		</React.Fragment>
	);
}

export default App;
