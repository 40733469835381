import React from 'react';
import styles from "./WorkFlowDesign.module.scss";
import { DataService } from "../../store/DataService";
import { DateOnly } from '../../AppConstants';
import GenButton from '../../components/GenButton';

const WorkFlowDesign = (props) => {
    const [workflows,setWorkFlows] = React.useState([]);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [saveflag,setSaveFlag] = React.useState(false);
    const [selectedworkflow,setSelectedWorkFlow] = React.useState({});
    const [selectedlinks,setSelectedLinks] = React.useState([]);
    const [msgtypes,setMsgTypes] = React.useState([]);
    const [allstates,setAllStates] = React.useState([]);
    const [inputs,setInputs] = React.useState({});

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.workflowsinit().then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            setMsgTypes(data.MSGTYPES);
            setWorkFlows(data.WORKFLOWS);
            setAllStates(data.ALLSTATES);
        })
    },[])

    const lookupState = (stateid) => {
        const state = allstates.find(st=>st.WorkFlowStateID===stateid);
        return state
    }

    const lookupWorkFlowStates = (wf) => {
        let obj = {};
        let states = allstates || [];
        obj.FirstState = states.find(st=>st.WorkFlowStateID===wf.FirstStateID) || {}
        obj.RestartState = states.find(st=>st.WorkFlowStateID===wf.RestartStateID) || {};
        obj.WorkingState = states.find(st=>st.WorkFlowStateID===wf.WorkingStateID) || {};
        obj.FinalState = states.find(st=>st.WorkFlowStateID===wf.FinalStateID) || {};

        return obj
    }

    const lookupLinkStates = (link) => {
        let obj = {}
        let states = allstates || [];
        obj.fromstateid = states.find(st=>st.WorkFlowStateID===link.FromStateID) || {};
        obj.tostateid = states.find(st=>st.WorkFlowStateID===link.ToStateID) || {};
        return obj;
    }

    const lookupMsgType = (mtid,msgtypes) => {
        const msgtype = msgtypes.find(mt=>mt.MessageTypeID===mtid);
        return msgtype;
    }
    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const selectWorkFlow = (wf) => {
        let newobj = JSON.parse(JSON.stringify(inputs));
        newobj.name = wf.Name;
        newobj.msgtype = wf.MessageTypeID;
        newobj.firststateid = wf.FirstStateID;
        newobj.restartstateid = wf.RestartStateID;
        newobj.workingstateid = wf.WorkingStateID;
        newobj.finalstateid = wf.FinalStateID;
        setInputs(values=>({...values,...newobj}));
        let newlinks = JSON.parse(JSON.stringify(wf.Links || []));
        setSelectedLinks(newlinks);
        setSelectedWorkFlow(wf);
        setSaveFlag(false);
    }

    const handleWorkFlowRemove = (e,index) => {
        e.stopPropagation();
        setWorkFlows(prevObjects => prevObjects.filter((obj, i) => i !== index));
        setDirtyFlag(true);
    };
    const handleWorkFlowAdd = () => {
        setWorkFlows([...workflows, { WorkFlowID:0,Name:"",CreatedDate:new Date(),MessageTypeID:0,FirstStateID:0,RestartStateID:0,WorkingStateID:0,FinalStateID:0,Active:'Y',States:allstates,Links:[{ WorkFlowLinkID:0,WorkFlowID:0,FromStateID:0,ToStateID:0,UserEnabled:'Y',SystemEnabled:'n',UserPermissions:0 }] }]);
        setDirtyFlag(true);
    };

    // 60px,120px,90px,110px,120px,120px,120px,120px
    const ShowWorkFlows = () => {
        const dismain = isEmpty(selectedworkflow) ? "all" : "none";
        const opc = dismain==='all' ? '1' : '0.5';
        return (
            <React.Fragment>
                <div className={styles.toppart} style={{pointerEvents:dismain,opacity:opc}}>
                <div className={styles.wfhdr}>
                    <div>Name</div>
                    <div>Created</div>
                    <div>Message Type</div>
                    <div>First State</div>
                    <div>Restart State</div>
                    <div>Working State</div>
                    <div>Final State</div>
                    <div>Actions</div>
                </div>
                {workflows.map((wf,i) => {
                    const obj = lookupWorkFlowStates(wf);
                    const msgtype = lookupMsgType(''+wf.MessageTypeID,msgtypes) || {};
                    
                    return (
                        <div className={styles.wfrow} key={"wfr_"+i} onClick={(e)=>selectWorkFlow(wf)}>
                            <div>{wf.Name}</div>
                            <div>{DateOnly(wf.CreatedDate)}</div>
                            <div>{msgtype.Name || ""}</div>
                            <div>{obj.FirstState.Name || ""}</div>
                            <div>{obj.RestartState.Name || ""}</div>
                            <div>{obj.WorkingState.Name || ""}</div>
                            <div>{obj.FinalState.Name || ""}</div>
                            <div><button onClick={(e)=>handleWorkFlowRemove(e,i)}>Remove</button></div>
                        </div>
                    )
                })}
                </div>
            </React.Fragment>
        )
    }

    const saveWorkFlow = (id) => {
        let wf = JSON.parse(JSON.stringify(selectedworkflow));
        wf.Name = inputs.name;
        wf.MessageTypeID = inputs.msgtype;
        wf.FirstStateID = inputs.firststateid;
        wf.RestartStateID = inputs.restartstateid;
        wf.WorkingStateID = inputs.workingstateid;
        wf.FinalStateID = inputs.finalstateid;
        wf.Active = 'Y';
        wf.Links = selectedlinks;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.saveworkflow(props.globals.useraccountid,wf).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            let wf = data.WORKFLOW || {}
            if (!isEmpty(wf)) {
                wf.MessageTypeID = parseInt(wf.MessageTypeID);
                wf.FirstStateID = parseInt(wf.FirstStateID);
                wf.RestartStateID = parseInt(wf.RestartStateID);
                wf.WorkingStateID = parseInt(wf.WorkingStateID);
                wf.FinalStateID = parseInt(wf.FinalStateID);
                let oldwf = JSON.parse(JSON.stringify(workflows));
                let oldwfid = selectedworkflow.WorkFlowID || 0;
                let newwf = [];
                oldwf.forEach((wfp)=> {
                    if (wfp.WorkFlowID != oldwfid) {
                        newwf.push(wfp);
                    }
                })
                newwf.push(wf);
                newwf.sort((a,b)=>{
                    if (a.Name < b.Name) return -1;
                    if (a.Name > b.Name) return 1;
                    return 0;
                })
                setWorkFlows(newwf);
            }
            setSelectedWorkFlow({});
            setSelectedLinks([]);
            setSaveFlag(false);
        })
    }

    const handleSaveAll = () => {
        let okaytosave = true;
        workflows.every((wf)=> {
            if (wf.MessageTypeID === 0) {
                okaytosave = false;
                alert("Message Type is required in all WorkFlows. Unable to save");
                return false;
            }
            return true;
        })
        
        if (!okaytosave) return;

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.saveallworkflows(props.globals.useraccountid,workflows).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            const wfs = data.WORKFLOWS || [];
            setWorkFlows(wfs);
            setDirtyFlag(false);
        })
        
    }

    const cancelWorkFlow = (id) => {
        setSelectedWorkFlow({});
        setSelectedLinks([]);
        setSaveFlag(false);
    }

    const ShowLinks = () => {
        const states = allstates || [];
        let newindex = -1;

        const handleChange = (index,key,value) => {
            let v = value;
            if (key==="UserEnabled" || key==="SystemEnabled") {
                v = 'N';
                if (value===true) v = 'Y';
            }
            setSelectedLinks(prevLinks => {
                return prevLinks.map((obj,i) => {
                    if (i===index) {
                        return {...obj,[key]:v}
                    }
                    return obj;
                })
            })
            setSaveFlag(true);
        }
        const handleRemove = (index) => {
            setSelectedLinks(prevObjects => prevObjects.filter((obj, i) => i !== index));
            setSaveFlag(true);
        };
        const handleAdd = () => {
            const newId = newindex;
            newindex -= 1;
            setSelectedLinks([...selectedlinks, { WorkFlowLinkID:newindex,WorkFlowID:selectedworkflow.WorkFlowID,FromStateID:0,ToStateID:0,UserEnabled:'Y',SystemEnabled:'n',UserPermissions:0 }]);
        };
        return (
            <React.Fragment>
                <div style={{fontSize:"24px",marginLeft:"10px"}}>State Table <span style={{fontSize:"12px",marginLeft:"16px"}}><button onClick={()=>handleAdd()}>Add Link</button></span></div>
                <div className={styles.linkhdr}>
                    <div>From State</div>
                    <div>To State</div>
                    <div style={{width:"100%",textAlign:"center"}}>User Enabled</div>
                    <div style={{width:"100%",textAlign:"center"}}>System Enabled</div>
                    <div>Permissions</div>
                    <div>Actions</div>
                </div>
                {selectedlinks.map((lnk,i)=> {
                    const obj = lookupLinkStates(lnk);

                    return (
                    <div key={"lnkrow_"+i} className={styles.linkrow}>
                    <div key={"from_"+lnk.WorkFlowLinkID}>
                        <select value={lnk.FromStateID} onChange={(e)=>handleChange(i,"FromStateID",e.target.value)}>
                            <option value="0"></option>
                            {states.map((st,idx)=> {
                                return <option key={"fromopt_"+idx} value={st.WorkFlowStateID}>{st.Name}</option>
                            })}
                        </select>
                    </div>
                    <div key={"to_"+lnk.WorkFlowLinkID}>
                        <select value={lnk.ToStateID} onChange={(e)=>handleChange(i,"ToStateID",e.target.value)}>
                            <option value="0"></option>
                            {states.map((st,idx)=> {
                                return <option key={"toopt_"+idx} value={st.WorkFlowStateID}>{st.Name}</option>
                            })}
                        </select>
                    </div>
                    <div style={{width:"100%",textAlign:"center"}}><input type="checkbox" checked={lnk.UserEnabled==='Y'} onChange={(e)=>handleChange(i,"UserEnabled",e.target.checked)} /></div>
                    <div style={{width:"100%",textAlign:"center"}}><input type="checkbox" checked={lnk.SystemEnabled==='Y'} onChange={(e)=>handleChange(i,"SystemEnabled",e.target.checked)} /></div>
                    <div><input type="text" value={lnk.UserPermissions} onChange={(e)=>handleChange(i,"UserPermissions",parseInt(e.target.value))} style={{width:"40px"}} /></div>
                    <div><button onClick={()=>handleRemove(i)}>Remove</button></div>
                    </div>
                    )
                })}
            </React.Fragment>
        )
    }

    const ShowDetail = () => {
        const states = allstates || [];

        const handleChange = (e)=> {
            const name = e.target.name;
            const value = e.target.value;
            let obj = {[name]:value}
            setInputs(values => ({...values, ...obj}));
            setSaveFlag(true);
        }

        const showStates = (lblname,nm) => {
            return (
                <React.Fragment>
                    <div>{lblname}</div>
                    <div>
                        <select name={nm} value={inputs[nm]} onChange={handleChange} style={{width:"180px"}}>
                            <option value="0"></option>
                            {states.map((st,i)=> {
                                return <option key={"st"+nm+"_"+i} value={st.WorkFlowStateID} style={{textAlign:"left"}}>{st.Name}</option>
                            })}
                        </select>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className={styles.bottompart}>
                    <div style={{fontSize:"24px",marginLeft:"10px"}}>WorkFlow Detail</div>
                    <div className={styles.detailrow}>
                        <div>Name:</div>
                        <div><input type="text" name="name" value={inputs['name']} onChange={handleChange} style={{width:"167px",textAlign:"left"}}/></div>
                        <div>Message Type:</div>
                        <div>
                            <select name="msgtype" value={inputs["msgtype"]} onChange={handleChange} style={{textAlign:"left",width:"180px"}}>
                                <option value="0"></option>
                                {msgtypes.map((mt,i)=> {
                                    return <option key={"mtopt_"+i} value={mt.MessageTypeID} style={{textAlign:"left"}}>{mt.Name}</option>
                                })}
                            </select>
                        </div>
                        {showStates("First State:","firststateid")}
                    </div>
                    <div className={styles.detailrow}>
                           {showStates("Restart State:","restartstateid")} 
                           {showStates("Working State:","workingstateid")} 
                           {showStates("Final State:","finalstateid")} 
                    </div>
                    <hr/>
                    {ShowLinks()}
                    <div style={{marginTop:"16px",marginLeft:"60px",display:"flex"}}>
                        {saveflag && <div><GenButton buttontext="Save" onclick={saveWorkFlow} width={90}/></div>}
                        <div style={{marginLeft:"40px"}}><GenButton buttontext="Cancel" onclick={cancelWorkFlow} width={90} /></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const isSelected = !isEmpty(selectedworkflow);
    return (
        <React.Fragment>
            <div>
                <div className={styles.title}>Work Flow Design
                    <span style={{fontSize:"12px",marginLeft:"16px"}}>
                        <button onClick={()=>handleWorkFlowAdd()}>Add WorkFlow</button>
                    </span>
                    {(dirtyflag===true && isSelected===false) && <span style={{fontSize:"12px",marginLeft:"16px"}}>
                        <button onClick={()=>handleSaveAll()}>Save Changes</button>
                    </span>}
                </div>
                <ShowWorkFlows />
                {isSelected && ShowDetail()}
            </div>

        </React.Fragment>
    )
}

export default WorkFlowDesign;