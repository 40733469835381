import React, { useState } from 'react';
import styles from "./GenDropdownCheck.module.scss";

const GenDropdownCheck = ({items,selectedItems,btntext,cbSelect,ddwidth}) => {
    const [isOpen, setIsOpen] = useState(false);
    //const [selectedItems, setSelectedItems] = useState(props.selected || []);
    //const [btntext,setButtonText] = useState(props.btntext || "Select Items");
    //const [items,setItems] = useState(props.items || []);
    //const ddwidth = React.useRef(props.width || 350);
    const orgBtnText = React.useRef(btntext || "Select Items");
    const dropdownRef = React.useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const dummy = (idlist) => {}

    const handleCheckboxChange = (event,itemId) => {
        event.stopPropagation();
        console.log('Calling '+orgBtnText.current+' callback');
        const updatedSelectedItems = selectedItems.includes(itemId)
                ? selectedItems.filter((id) => id !== itemId)
                : [...selectedItems, itemId];

        let cb = cbSelect || dummy;
        cb(updatedSelectedItems);

    };

    const handleDropdownClick = (event) => {
        event.stopPropagation(); // Prevent event bubbling
      };

      const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !dropdownRef.current.contains(event.target.parentNode)
          ) {
            setIsOpen(false);
          }
      };
    
      React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
    return (
    <div className={styles.dropdown} ref={dropdownRef}>
        <button className={styles.dropdowntoggle} onClick={toggleDropdown}>
        {btntext + ' ('+selectedItems.length+')'}
        </button>
        {isOpen && (
        <div className={styles.dropdownmenu} style={{width:`${ddwidth}px`}}>
            {items.map((item) => (
            <div key={item.id} className={styles.dropdownitem}>
                <input
                type="checkbox"
                id={`item-${item.id}`}
                checked={selectedItems.includes(item.id)}
                onChange={(event) => handleCheckboxChange(event, item.id)}
                />
                <label htmlFor={`item-${item.id}`}>{item.name}</label>
            </div>
            ))}
        </div>
        )}
    </div>
    );
};

export default GenDropdownCheck;