import React from "react";
import styles from "./ApptCancel.module.scss";

import ApptDateTime from "./ApptDateTime";

const ApptCancel = (props) => {
    const pinp = props.inputs || {};
    const [inputs,setInputs] = React.useState({...pinp});
    const locations = props.locations || [];
    const askdesc = props.askdesc || false;

    const DummyCB = (name,value) => {

    }

    const cbChange = (nm,v) => {
        let obj = {[nm]:v}

        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(nm,v);
    }

    const cbChangeMove = (nm,v) => {
        let name = 'move'+nm;
        let obj = {[name]:v}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,v);

    }

    const HandleCheck = (event) => {
        const name = event.target.name;
        let value = event.target.checked;
        const cb = props.onChange || DummyCB;
        if (name==='reschedule' || name==='holding') {
            if (value===true) {
                value = 'Y';
                if (name==='reschedule') {
                    setInputs(values => ({...values,...{holding:'N'}}))
                    cb('holding','N');
                }
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        
        cb(name,value);
    }
    
    const HandleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name==='okleavemsg') {
            if (value===true) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        if (name==='cancel' && value==='V') {
            obj.reschedule = 'N';
        }
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value); 
    }

    return (
        <React.Fragment>
            
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Cancel or Confirm:</div>
                <label style={{userSelect:"none"}}><input type="radio" name="cancel" onChange={HandleChange} value="C" checked={inputs.cancel==='C'} />Cancelling</label>
                <label style={{marginLeft:"10px",userSelect:"none"}}><input type="radio" name="cancel" checked={inputs.cancel==='V'} onChange={HandleChange} value="V" />Confirming</label>
            </div>
            {inputs.cancel==='C' && 
                <div className={styles.formctrl}>
                    <div className={styles.formlabel}>Are you rescheduling?:</div>
                    <label style={{userSelect:"none"}}><input type="checkbox" name="reschedule" onChange={HandleCheck} checked={inputs.reschedule==='Y'} /> Yes</label>
                </div>
            }
            
            <ApptDateTime locations={locations} onChange={cbChange} askdesc={askdesc}  mtype={props.mtype} calltype={props.calltype} inputs={props.inputs} />
            {/*<div className={styles.formctrl}>
                        <div className={styles.formlabel}>Phone Number on file:</div>
                        <input className={styles.forminput} type="text" name="numberonfile" maxLength="16" onChange={HandleChange} value={inputs.numberonfile} />
                        <span className={styles.formlabelsm} style={{paddingLeft:"25px"}}>Ext:</span>
                        <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="nofext" maxLength="4" onChange={HandleChange} value={inputs.nofext} />
            </div>*/}
            {/*<div className={styles.formblock}>
                <div className={styles.formlabel} style={{marginLeft:"15px"}}>
                    <div>Appt Description:</div>
                    <div className={styles.req} style={{paddingLeft:"8px"}}>*</div>
                </div>
                <div className={styles.formmsg}>
                    <textarea name="apptdesc" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="2" maxLength="2000" onChange={HandleChange} value={inputs.apptdesc}></textarea>
                </div>
            </div>*/}
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Appt Description:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} type="text" name="apptdesc" maxLength="40" onChange={HandleChange} value={inputs.apptdesc} />
            </div>
            <div className={styles.formblock}>
                <div className={styles.formlabel}><div>Message:</div><div className={styles.req} style={{paddingLeft:"8px"}}>*</div></div>
                <div className={styles.formmsg}>
                    <textarea name="message" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="5" maxLength="2000" onChange={HandleChange} value={inputs.message}></textarea>
                </div>
            </div>
            <div className={styles.formctrl}>
                        <div className={styles.formlabel}>Callback Phone:</div><div className={styles.req}>*</div>
                        <input className={styles.forminputreq} type="text" name="dayphone1" maxLength="16" onChange={HandleChange} value={inputs.dayphone1} />
                        <span className={styles.formlabelsm} style={{paddingLeft:"25px"}}>Ext:</span>
                        <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="dayext1" maxLength="4" onChange={HandleChange} value={inputs.dayext1} />
            </div>

            {/*inputs.reschedule==='Y' &&
                    <React.Fragment>
                    <div style={{marginTop:"16px",width:"100%",borderBottom:"1px solid black"}}></div>
                    <div className={styles.formlabel} style={{marginTop:"10px"}}>Preferred Appointment Info:</div>
                    <ApptDateTime locations={locations} onChange={cbChangeMove} askdesc={true} />
                    </React.Fragment>
            */}
        </React.Fragment>
    )
}

export default ApptCancel