import React from "react";
import styles from "./ApptDateTime.module.scss";
import {ReactComponent as Calendar} from "./../assets/calendar.svg";
import ShowCalendar from "./ShowCalendar";
import { DateOnly, CALLTYPE_APPT_INQUIRY } from "./../AppConstants";

// Needs locations list and onChange callback
const ApptDateTime = (props) => {
    const pinp = props.inputs || {};
    const [inputs,setInputs] = React.useState({
        apptdate:pinp.apptdate || '',
        appttime:pinp.appttime || '',
        apptdesc:pinp.apptdesc || '',
        location:pinp.location || '0'
    });
    const [showcal,setShowCal] = React.useState(false);
    const locations = props.locations || [];
    const askdesc = props.askdesc || false;
    const [reqflds,setReqFields] = React.useState({
        apptdate: props.calltype !== ''+CALLTYPE_APPT_INQUIRY,
        appttime:true,
        location:true,
        apptdesc: props.calltype !== ''+CALLTYPE_APPT_INQUIRY,
    });
    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }
    const [apptDateRef, setApptDateFocus] = useFocus();
    const [apptTimeRef, setApptTimeFocus] = useFocus();

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const DummyCB = (name,value) => {

    }

    const cbChange = (name,value) => {
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value);
    }

    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        cbChange(name,value);
    }

    const setApptDate = (v) => {
        setShowCal(false);
        
        if (v !== null) {
            let vd = DateOnly(v);
            cbChange('apptdate',vd);
        }
    }

    const checkDate = (e) => {
        let v = e.target.value;
        if (!v.length) {
            return;
        }
        
        let dt = new Date(v);
        if (!isValidDate(dt)) {
            cbChange('apptdate','');
            alert(dt);
            setApptDateFocus();
        }
        dt = new Date(DateOnly(dt));
        let nw = new Date(DateOnly(new Date()));
        if (dt < nw) {
            cbChange('apptdate','');
            alert("Cannout use Appointment date in the past!");
            setApptDateFocus();
        }
    }

    const validateTime = (timeString) => {
        const regex = /^(1[0-2]|0?[1-9]):([0-5][0-9])\s?(AM|PM)$/i;
        return regex.test(timeString);
      }

    const checkTime = (e) => {
        let v = e.target.value;
        if (!v.length) {
            return;
        }
        v = v.toLowerCase()
        let x = v.indexOf('am');
        if (x < 0) x = v.indexOf('pm');
        if (x > 0) {
            let a = v.slice(0,x);
            let b = v.slice(x);
            v = a + " " + b;
            
        }
        if (x < 0) {
            alert("Must include AM or PM in time.");
            cbChange('appttime','');
            setApptTimeFocus();
            return;
        }
        v = v.replace(/ +/g, ' ');

        if (props.calltype === ''+CALLTYPE_APPT_INQUIRY) {
            if (!validateTime(v)) {
                cbChange('appttime','');
                alert(dt+' or invalid Time');
                setApptTimeFocus();
            }
            return;
        }

        v = inputs.apptdate + ' ' + v;
        let dt = new Date(v);
        if (!isValidDate(dt)) {
            cbChange('appttime','');
            alert(dt+' or invalid Time');
            setApptTimeFocus();
            
        }
        dt = new Date(dt);
        let nw = new Date(DateOnly(new Date()));
        if (dt < nw) {
            cbChange('appttime','');
            alert("Cannout use Appointment date in the past!");
            setApptTimeFocus();
            
        }
    }

    const setToday = (e) => {
        const nw = DateOnly(new Date());
        cbChange('apptdate',nw);
    }

    const tileDisabled = ({ activeStartDate, date, view }) => {
        let dt1 = new Date(DateOnly(date));
        let dt2 = new Date(DateOnly(new Date()));
        return dt1 < dt2
     }

     const handleKeyPress = (event) => {
        if (event.key === 't' || event.key === 'T') {
            const nw = DateOnly(new Date());
            cbChange('apptdate',nw);
        }
      };

    const ShowReq = (props) => {
        const nm = props.name || "";
        if (reqflds[nm] === true) {
            return <div className={styles.req}>*</div>
        } else {
            return null;
        }            
    }

    const getInputClass = (nm) => {
        let st = styles.forminputreq;
        if (reqflds[nm]===false) st = styles.forminput;
        return st
    }

    const getMsgClass = (nm) => {
        let st = styles.formmsgreq;
        if (reqflds[nm]===false) st = styles.formmsg;
        return st
    }

    const getSelectClass = (nm) => {
        let st = styles.formselectreq;
        if (reqflds[nm]===false) st = styles.formselect;
        return st;
    }

    return (
        <React.Fragment>
            <div key="ctrl_200" className={styles.formctrl}>
                <div className={styles.formlabel}>Appt Date:</div><ShowReq name="apptdate" />
                <input className={getInputClass("apptdate")} key={"adinp372"} type="text" name="apptdate" maxLength="10" onChange={HandleChange} value={inputs.apptdate} onKeyDown={handleKeyPress} onBlur={checkDate} ref={apptDateRef} />                
                <Calendar onClick={(e) =>setShowCal(true)} />
                <button style={{marginLeft:"12px",marginTop:"5px",height:"16px",width:"46px",fontSize:"10px"}} onClick={setToday}>Today</button>
            </div>
            <div key="ctrl_200a0" className={styles.formctrl}>
                <div className={styles.formlabel}>Time:</div>
                <input style={{marginLeft:"6px"}} className={styles.forminput} key={"adpinp372a"} placeholder="HH:MM AM/PM" type="text" name="appttime" maxLength="8" onChange={HandleChange} value={inputs.appttime} onBlur={checkTime} ref={apptTimeRef} />
            </div>
            <div key="ctrl_200A" className={styles.formctrl}>
                <div className={styles.formlabel}>Location:</div>
                <select className={styles.formloc} name="location" onChange={HandleChange} value={inputs.location}>
                        <option value="0">Unknown</option>
                        {locations.map((loc,i)=> {
                            return <option key={"apptlocopt2_"+i} value={loc.locid}>{loc.locname}</option>
                        })}
                </select>
            </div>
            {askdesc &&
                        <div className={styles.formblock}>
                            <div className={styles.formlabelmsg}>
                                <div>Appt Description:</div>
                                <div style={{paddingLeft:"8px"}}><ShowReq name="apptdesc" /></div>
                            </div>
                            <div className={getMsgClass("apptdesc")}>
                                <textarea name="apptdesc" cols="50" rows="2" maxLength="2000" onChange={HandleChange} value={inputs.apptdesc}></textarea>
                            </div>
                        </div>
            }
            {showcal && <ShowCalendar value={new Date(inputs.apptdate || new Date())} onChange={setApptDate} tileDisabled={tileDisabled} />}
        </React.Fragment>
    )
}

export default ApptDateTime;