import React from "react";
import styles from "./TransferNoteMgmt.module.scss";
import { DataService } from "../../store/DataService";
import {Date24, Date12, DateOnly, Time24, CleanDate} from "../../AppConstants";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import {ReactComponent as CalendarIcon} from "../../assets/calendar.svg";
import ShowCalendar from "../../components/ShowCalendar";

var origocinputs = null;
const TransferNoteMgmt = (props) => {

    const [notes,setNotes] = React.useState([]);
    const [oncall,setOnCall] = React.useState([]);
    const [providers,setProviders] = React.useState([]);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [showdelete,setShowDelete] = React.useState(false);
    const [savedisabled,setSaveDisabled] = React.useState(false);
    const [inputs,setInputs] = React.useState({
        ptnid:0,
        seqnum:0,
        inst:""
    })

    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }
    const [inputStartRef, setStartFocus] = useFocus()
    const [inputEndRef, setEndFocus] = useFocus()

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        
        if (parseInt(props.globals.practiceid) < 1) {
            let newobj = {};
            newobj.ptnid = 0;
            newobj.seqnum = 0;
            newobj.inst = '';    
            setInputs(newobj);
            setNotes([]);
            return;
        }
        
        dataservice.transfernoteinit(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }

            let lst = data.NOTES || [];
            setNotes(lst);
            
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const findNote = (ptnid) => {
        let n = null;
        for (let i=0;i<notes.length;i++) {
            let note = notes[i];
            if (note.practicetransfernoteid===ptnid) {
                n = note;
                break;
            }
        }
        return n;
    }


    const setNameValue = (name,value) => {
        let newobj = JSON.parse(JSON.stringify(inputs));
        
        if (origocinputs===null) {
            origocinputs = JSON.parse(JSON.stringify(inputs));
        }
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        setDirtyFlag(!compareObj(newobj,origocinputs));

    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setNameValue(name,value);
    }

    const doSave = (e) => {
        if (savedisabled) return;
        setSaveDisabled(true);
        let newobj = JSON.parse(JSON.stringify(inputs));
        if (newobj.inst.length < 1) {
            alert("Must enter a Transfer Instruction.");
            setSaveDisabled(false);
            return;
        }

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savetransfernote(props.globals.practiceid,props.globals.useraccountid,newobj).then((data) => {
            setSaveDisabled(false);
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newobj = getNewInputs();
            setInputs(newobj);
            origocinputs = JSON.parse(JSON.stringify(newobj));
            setDirtyFlag(false);

            let lst = data.NOTES || [];
            setNotes(lst);
            
        })

    }

    const getNewInputs = () => {
        let newobj = {};
        newobj.ptnid = 0;
        newobj.seqnum = 0;
        newobj.inst = '';
        return newobj;
    }

    const handleAddNew = (e) => {
        let newobj = getNewInputs();
        setInputs(newobj);
        origocinputs = JSON.parse(JSON.stringify(newobj));
        setDirtyFlag(false);
    }

    const moveUp = (e) => {
        let seqnum = inputs.seqnum || 0;
        if (seqnum < 2) {
            alert("Invalid instruction selected");
            return;
        }
        
        const ptnid = inputs.ptnid;
        let sequences = [];
        notes.forEach((nt)=> {
            if (nt.seqnum===seqnum) {
                sequences.push({practicetransfernoteid:nt.practicetransfernoteid,seqnum:nt.seqnum-1})
            }
            if (nt.seqnum===seqnum-1) {
                sequences.push({practicetransfernoteid:nt.practicetransfernoteid,seqnum:seqnum})
            }
        })
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.updatetransfersequence(props.globals.practiceid,props.globals.useraccountid,sequences).then((data) => {
            setSaveDisabled(false);
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }

            setDirtyFlag(false);

            let lst = data.NOTES || [];
            setNotes(lst);
            let obj = {ptnid:0,seqnum:0,note:""}
            lst.forEach((nt)=> {
                if (nt.practicetransfernoteid===ptnid) {
                    obj.ptnid = ptnid;
                    obj.seqnum = nt.seqnum;
                    obj.inst = nt.note;
                }
            })
            setInputs(obj);
        })
    }

    const moveDown = (e) => {
        let seqnum = inputs.seqnum || 0;
        if (seqnum === notes.length) {
            alert("Invalid instruction selected");
            return;
        }
        const ptnid = inputs.ptnid;
        let sequences = [];
        notes.forEach((nt)=> {
            if (nt.seqnum===seqnum) {
                sequences.push({practicetransfernoteid:nt.practicetransfernoteid,seqnum:nt.seqnum+1})
            }
            if (nt.seqnum===seqnum+1) {
                sequences.push({practicetransfernoteid:nt.practicetransfernoteid,seqnum:seqnum})
            }
        })

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.updatetransfersequence(props.globals.practiceid,props.globals.useraccountid,sequences).then((data) => {
            setSaveDisabled(false);
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let lst = data.NOTES || [];
            setNotes(lst);
            let obj = {ptnid:0,seqnum:0,note:""}
            lst.forEach((nt)=> {
                if (nt.practicetransfernoteid===ptnid) {
                    obj.ptnid = ptnid;
                    obj.seqnum = nt.seqnum;
                    obj.inst = nt.note;
                }
            })
            setInputs(obj);
        })
    }

    const handleDeleteOK = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.deletetransfernote(props.globals.practiceid,props.globals.useraccountid,inputs.ptnid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newobj = getNewInputs();
            setInputs(newobj);
            origocinputs = JSON.parse(JSON.stringify(newobj));
            setDirtyFlag(false);
            let lst = data.NOTES || [];
            setNotes(lst);
            setShowDelete(false);
        })
        
    }

    const handleDeleteCancel = (e) => {
        setShowDelete(false);
    }

    const handleDelete = (e) => {
        setShowDelete(true);
    }

    const handleRowChange = (ptnid) => {
        let newobj = JSON.parse(JSON.stringify(inputs));
        let note = findNote(ptnid);
        if (!note) return;
        newobj.ptnid = ptnid;
        newobj.seqnum = note.seqnum;        
        newobj.inst = note.note;
        setInputs(newobj);
        origocinputs = JSON.parse(JSON.stringify(newobj));
        setDirtyFlag(false);
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
      }

    const ShowTransferNote = (noteprop) => {
        let ntext = noteprop.noteobj.note || "";
        ntext = ntext.replaceAll("\r\n","<br/>");
        const ptnid = noteprop.noteobj.practicetransfernoteid || 0;
        let bg="white";
        if ((noteprop.noteobj.seqnum & 1)===0) bg="lightgray";
        if (ptnid===inputs.ptnid) bg="yellow";
        return (
            <React.Fragment>
                <div className={styles.noterow} style={{backgroundColor:bg}}onClick={(e)=>handleRowChange(ptnid)}>
                    <div dangerouslySetInnerHTML={{ __html:ntext}}></div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Transfer Instruction Management</div>
                <div className={styles.notes}>
                    <div className={styles.notehdrrow}>
                        <div className={styles.notehdr}>Instructions</div>
                    </div>
                    {notes.map((noteobj,i)=> {
                        return <ShowTransferNote noteobj={noteobj} key={"cn_"+i} />
                    })}
                </div>
                <div className={styles.edit}>
                    <div className={styles.editblock}>
                        <div className={styles.editfirst}>Instruction:</div>
                        <div>
                            <textarea name="inst" value={inputs.inst} maxLength="2000" onChange={handleChange} className={styles.editarea}></textarea>
                        </div>
                        
                        <div style={{marginLeft:"20px",marginTop:"-120px"}}>
                            <button style={{width:"80px"}} disabled={inputs.seqnum===0 || inputs.seqnum ===1} onClick={moveUp}>Up</button>
                        </div>
                        <div></div>
                        <div style={{marginLeft:"20px",marginTop:"-80px"}}>
                            <button style={{width:"80px"}} disabled={inputs.seqnum===0 || inputs.seqnum === notes.length} onClick={moveDown}>Down</button>
                        </div>
                        <div></div>
                        
                    </div>
                    <div style={{width:"100%",marginTop:"20px",textAlign:"center",justifyContent:"center",display:"flex"}}>
                        <div>
                            <GenButton width="160" buttontext="Save" disabled={!dirtyflag || savedisabled} onclick={doSave} />
                        </div>
                        <div style={{marginLeft:"30px"}}>
                            <GenButton width="160" buttontext="Add New" disabled={inputs.ptnid===0} onclick={handleAddNew} />
                        </div>
                        <div style={{marginLeft:"30px"}}>
                            <GenButton width="160" buttontext="Delete" disabled={inputs.ptnid===0} onclick={handleDelete} />
                        </div>
                    
                    </div>
                </div>
                {showdelete && <GenConfirm 
                                    confirmText="Yes" 
                                    onCancel={handleDeleteCancel} 
                                    onConfirm={handleDeleteOK} 
                                    width={500}
                                    text={"Are you sure you want to delete this Transfer Instruction?"} 
                                    />}
            </div>
        </React.Fragment>
    )
}

export default TransferNoteMgmt;