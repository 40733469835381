
import React from "react";
import styles from "./SecureMessage.module.scss";
import {DataService} from './../store/DataService';
import GenButton from "./GenButton";
import GenPrompt from "./GenPrompt";
import GenConfirm from "./GenConfirm";
import {ReactComponent as Closer} from './../assets/close.svg';
import AlertBox from "./AlertBox";

let nextgroupnum = -1;
const SecureMessage = (props) => {
    const [shownewprompt,setShowNewPrompt] = React.useState(false);
    const [showconfirm,setShowConfirm] = React.useState(false);
    const [doctor,setDoctor] = React.useState({pdid:0,ptsid:0,cellnumber:"",name:"",GroupNum:"",GroupDesc:""})
    const [docgroups,setDocGroups] = React.useState(props.securegroups)
    let sgn = props.securegroupnum;
    if (sgn===undefined) {
        sgn = -1000;
    }
    const [selectgroup,setSelectGroup] = React.useState(sgn);
    
    let cellphones = [];

    props.contactmethods.forEach((cm)=> {
        if (cm.ContactTypeID==='6') {
            cellphones.push({ppid:cm.PersonPhoneID,phone:cm.PhoneNumber});
        }

    })
    const [cells,setCells] = React.useState(cellphones);
    let cell = {ppid:0,phone:""};
    //if (cellphones.length) {
    //    cell = cellphones[0];
    //}
    if (cellphones.length) {
        cellphones.every((cp)=> {
            if (cp.phone===props.securephone) {
                cell = cp;
                return false;
            }
            return true;
        })
    }
    if (cell.ppid===0 && cellphones.length > 0) {
        cell = cellphones[0];
    }
    const [selectcell,setSelectCell] = React.useState(cell);
    const dummy = (e) => {}

    const onClose = (cm,gnum=null,gname=null) => {
        const cb = props.onclose || dummy;
        cb(cm,gnum,gname);
    }

    const onSaveNo = (e) => {
        setShowConfirm(false);
    }

    const onSaveOK = () => {
        setShowConfirm(false);
        let groupname = "";
        let groupnum = "";
        let gnum = parseInt(selectgroup);

        docgroups.every((grp)=> {
            if (grp.groupnum===gnum) {
                groupname = grp.desc;
                groupnum = gnum;
                return false;
            }
            return true;
        })

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savesecure(selectcell.ppid,groupname,groupnum).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            const gnum = parseInt(data.groupnum);
            if (gnum >= 0) {
                onClose(data.contactmethods,groupnum,groupname);
            } else {
                if (gnum === -1)
                    AlertBox("Something went wrong with the save.\nPlease report to System Admin.");
                if (gnum === -2) {
                    AlertBox("Provider is already enrolled in Secure Messaging.\nPlease report to System Admin.");
                    onClose(null);
                }
            }
        })

    }

    const onSave = (e) => {
        let groupname = "";
        let groupnum = "";
        let gnum = parseInt(selectgroup);
        if (gnum === -1000) {
            AlertBox("Group cannot be blank.");
            return;
        }
        docgroups.every((grp)=> {
            if (grp.groupnum===gnum) {
                groupname = grp.desc;
                groupnum = gnum;
                return false;
            }
            return true;
        })
        if (groupname.length < 1) {
            AlertBox("Unable to find Group Name.\nPlease report to System Admin.");
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});

        dataservice.lookupsecure(selectcell.ppid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            let pdid = data.pdid;
            if (pdid < 1) {
                onSaveOK();
                return;
            }
            setDoctor(data.doctor);
            setShowConfirm(true);
        })

    }

    const resetPin = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.resetsecure(props.provider.PersonId,selectcell.phone).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            const pdid = parseInt(data.pdid);
            if (pdid > 0) {
                AlertBox("Secure pin has been reset to 1234");
            } else {
                AlertBox("Unable to reset pin. Contact System Admin.");
            }
        })
    }

    const ShowCells = () => {

        const handleChange = (e) => {
            const ppid = e.target.value;
            cellphones.every((cp) => {
                if (cp.ppid===ppid) {
                    setSelectCell(cp);
                    return false;
                }
                return true;
            })
        }

        return (
            <React.Fragment>
                <div style={{marginTop:"6px"}}>Select Cell Phone to use
                    <div style={{marginTop:"6px"}}>
                        <select name="seccell" value={selectcell.ppid} onChange={handleChange} >
                            {cellphones.map((cell,i) => {
                                return <option key={"sc_"+i} value={cell.ppid}>{cell.phone}</option>
                            })}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ShowGroups = () => {
        

        const onGroupChange = (e) => {
            setSelectGroup(e.target.value);
        }

        return (
            <React.Fragment>
                <div style={{marginTop:"10px"}}>Select group that Provider will belong to
                    <div style={{marginTop:"6px"}}>
                        <select name="secgroup" value={selectgroup} onChange={onGroupChange}>
                            <option value="-1000"></option>
                            {docgroups.map((obj,i) => {
                                return (
                                    <option key={"sg_"+i} value={obj.groupnum}>{obj.desc}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const handleNewOK = (group) => {
        let found = false;
        if (group.length) {
            const groups = docgroups;
            
            let newgrp = group.toLowerCase();
            groups.every((grp)=> {
                if (grp.desc.toLowerCase()===newgrp) {
                    found = true;
                    return false;
                }
            })
        } else {
            AlertBox("Group name cannot be blank.");
            return;
        }
        if (found) {
            AlertBox("Group name already exists. Please enter another.");
            return;
        }

        let newgrps = JSON.parse(JSON.stringify(docgroups));
        let grpobj = {groupnum:nextgroupnum,desc:group}
        newgrps.push(grpobj);
        newgrps.sort((a,b)=> {
            let adesc = a.desc.toLowerCase();
            let bdesc = b.desc.toLowerCase();
            if (adesc < bdesc) return -1;
            if (adesc > bdesc) return 1;
            return 0;
        })
        setDocGroups(newgrps);
        setSelectGroup(nextgroupnum);
        nextgroupnum -= 1;
        

        setShowNewPrompt(false);

    }

    const handleNewCancel = (e) => {
        setShowNewPrompt(false);
    }

    let confirmtext = "Secure Cell Phone already in use for\n" + doctor.name + " in group "+doctor.groupdesc+".\nDo you want to overwrite this entry?";
    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.outer}>
                    <div className={styles.inner}>
                        <div className={styles.mainheader}>
                            <div>Secure Messaging</div>
                            <div className={styles.headerright} onClick={(e)=>onClose(null)}><Closer /></div>
                        </div>
                        <ShowCells />
                        <ShowGroups />
                        <div style={{display:"flex",width:"100%",marginTop:"30px",justifyContent:"center"}}>
                            <div><GenButton buttontext="Save" width="50" onclick={onSave} /></div>
                            <div style={{marginLeft:"20px"}}><GenButton buttontext="New Group" width="70" onclick={(e)=>setShowNewPrompt(true)} /></div>
                            <div style={{marginLeft:"20px"}}><GenButton buttontext="Reset Pin" width="70" disabled={!props.alreadysecure} onclick={resetPin} /></div>
                            <div style={{marginLeft:"20px"}}><GenButton buttontext="Cancel" width="50" onclick={(e)=>onClose(null)}/></div>
                        </div>
                    </div>
                </div>
                {shownewprompt && <GenPrompt text="Group Name:" onConfirm={handleNewOK} onCancel={handleNewCancel} width={300}/>}
            
                {showconfirm && <GenConfirm 
                    globals={props.globals} 
                    methods={props.methods}
                    text={confirmtext}
                    width={620}
                    onCancel = {onSaveNo}
                    onConfirm = {(e)=>onSaveOK()}
                    confirmText = "Yes" 
                    cancelText = "No" />}
                </div>
        </React.Fragment>
    )
}

export default SecureMessage;