import React from "react";
import styles from "./PracticeInfo.module.scss";
import ShowOnCall from "./ShowOnCall";
import { formatPhoneNumber } from "./../AppConstants";

const PracticeInfo = (props) => {
    let refdata = props.refdata || {
        greets:[],
        callnotes:[],
        locations:[]
    }
    const mtype = props.msgtype || 0;
    const [msgtype,setMsgType] = React.useState(mtype);
 
    const [inputs,setInputs] = React.useState({});
    let greeting = "";
    let inst = "";
    const patype = props.globals.practiceaccount.AccountType;

    React.useEffect(() => {
        let defloc = 0;
        refdata.locations.forEach((loc)=> {
            if (loc.primaryloc > 0) {
                defloc = loc.locid;
            }
        })
        if (defloc===0) {
            defloc = props.globals.practice.PrimaryLocationID;
        }
        if (defloc > 0) {
            setInputs(values=>({...values,location:""+defloc}))
        }
    },[refdata.locations,props.globals.practice.PrimaryLocationID]);


    refdata.greets.forEach((g)=> {
        if (g.accttype===patype || g.accttype===0) {
            greeting += g.greet;
            inst += g.inst;
        }
    })
    let notes = [];
    refdata.callnotes.forEach((cn)=> {
        let sa = cn.notes.split("\n");
        sa.forEach((s)=> notes.push(s))
    })
    let hours = [];
    let phones = [];
    let directions = "";
    let comments = "";
    let add1 = "";
    let add2 = "";
    let csz = "";
    const locid = parseInt(inputs.location) || 0;
    refdata.locations.forEach((loc)=> {
        if (parseInt(loc.locid)===locid) {
            loc.lochours.forEach((lh)=> {
                hours.push(lh.dow + " "+lh.stime+" - "+lh.etime);
            })
            directions = loc.directions;
            comments = loc.comments;
            add1 = loc.addr1 || "";
            add2 = loc.addr2 || "";
            csz = loc.city + ", " + loc.state + " " + loc.zip;
            phones = loc.phone || [];
        }
    })
    const notIsSched = parseInt(msgtype) !== 16;

    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let obj = {[name]:value}
        /*
        const selfrel = "" + RELATIONSHIP_SELF;
        if (name==="relationship" && value===selfrel) {
            obj["patientfirst"] = inputs.callerfirst;
            obj["patientlast"] = inputs.callerlast;
            setInputFocus(); // sets focus to DOB field
        }
        */
        if (name==='okleavemsg') {
            if (value===true) {
                obj['okleavemsg'] = 'Y';
            } else {
                obj['okleavemsg'] = 'N';
            }
        }
        setInputs(values => ({...values, ...obj}));
    }

    return (
        <div className={styles.info}>
            {notIsSched &&<div><b>Greetings and Instructions:</b>
            <div className={styles.instblock}>
                <div className={styles.formgreet} dangerouslySetInnerHTML={{ __html:greeting}} />
                <div className={styles.forminst} dangerouslySetInnerHTML={{ __html:inst}} />
                    <div>
                        {notes.map((cn,i) => {
                            return (
                                <div key={"cn"+i} className={styles.formnote} dangerouslySetInnerHTML={{ __html:cn}} />
                            )
                        })}
                    </div>
                </div>
            </div>}
            {notIsSched && <hr/>}
            {notIsSched && <ShowOnCall globals={props.globals} methods={props.methods} />}
            
            <div className={styles.formlocblock}><b>Locations:</b>
                <select className={styles.formloc} name="location" onChange={HandleChange} value={inputs.location}>
                    {refdata.locations.map((loc,i)=> {
                        return <option key={"locopt_"+i} value={loc.locid}>{loc.locname}</option>
                    })}
                </select>
            </div>
            
            <div style={{display:"flex",marginTop:"10px"}}>
                <div className={styles.formlocblock}><b>Hours:</b>
                    <div className={styles.formlochours}>
                        {hours.map((h,i) => {
                            return <div key={"lh_"+i}>{h}</div>
                        })}
                        
                    </div>
                </div>
                <div className={styles.formlocblock}><b>Location Detail:</b>
                <div className={styles.locdetailbox}>
                <div className={styles.locdetailrow} style={{fontWeight:"bold"}}>
                    <div>Method</div>
                    <div>Phone Number</div>
                </div>
                {phones.map((phone,i)=> {
                    let pn = phone.phonenumber;
                    pn = formatPhoneNumber(pn);
                    return (<div key={"pn_"+i} className={styles.locdetailrow}>
                        <div>{phone.contacttypename}</div>
                        <div>{pn}</div>
                    </div>)
                })}
                <hr/>
                <div className={styles.locaddressblock}>
                    {add1.length > 0 && <div className={styles.locaddressrow}><div className={styles.locaddresslabel}>Address:</div><div>{add1}</div></div>}
                    {add2.length > 0 && <div className={styles.locaddressrow}><div className={styles.locaddresslabel}></div><div>{add2}</div></div>}
                    <div className={styles.locaddressrow}><div className={styles.locaddresslabel}>City, State Zip:</div><div>{csz}</div></div>
                </div>
                {directions.length > 0 && 
                    <div><hr/>
                        <div style={{fontWeight:"bold"}}>Directions:</div>
                        <div>{directions}</div>
                    </div>}
                </div>
                </div>
            </div>
            <div style={{display:"block",paddingBottom:"20px",marginTop:"10px"}}>
                <div className={styles.formlocblock}><b>Comments:</b>
                    <div className={styles.formlochours} style={{width:"600px",height:"80px"}}>{comments}</div>
                </div>
            </div>
            
        </div>
    )

}

export default PracticeInfo;