import React from 'react';
import styles from './MenuInternal.module.scss';
import MenuTab from './MenuTab';
import {APP_VERSION} from './../AppConstants';

const providerOptions = [
    {menutext:"View My Messages",shortname:"extview",needprac:1},
    {menutext:"Message Management",shortname:"extmm",needprac:1},
    {menutext:"Manage On Call Calendar",shortname:"extoncall",needprac:1},
    {menutext:"Practice Administration",shortname:"extpa",needprac:1}
];

var messageOptions = [];
if (APP_VERSION===1) {
    messageOptions = [
        {menutext:"Practice Messages",shortname:"extviewpm",needprac:1},
        {menutext:"Urgent Messages",shortname:"intviewurg",needprac:0},
        /* {menutext:"Scheduled Messages",shortname:"intviewsched",needprac:2}, */
        {menutext:"Take Message (F2)",shortname:"takemsg",needprac:1}
    ];
} else {
    messageOptions = [
        {menutext:"View Practice Messages",shortname:"intviewpm",needprac:1},
        {menutext:"Send Practice Message",shortname:"extsend",needprac:1},
        {menutext:"Practice Message Archive",shortname:"extarch",needprac:1},
        {menutext:"Send Notifications",shortname:"extsendnot",needprac:1}
    ];
}

const onCallOptions = [
    {menutext:"Manage On Call Calendars",shortname:"extmgtoncall",needprac:1}
];

var paOptions = [];
if (APP_VERSION===0) {
    paOptions = [
        {menutext:"Manage Practice Providers",shortname:"extmgtpracprov",needprac:1},
        {menutext:"Manage Practice Users",shortname:"extmgtpracusers",needprac:1}
    ];

} else {
    paOptions = [
        
        {menutext:"Practice Locations and Demographics",shortname:"intlocdem",needprac:1},
        {menutext:"Practice Specialties",shortname:"intspec",needprac:1},
        {menutext:"Manage Practice Call Notes",shortname:"intmgtcallnotes",needprac:1},
        {menutext:"Manage Practice Transfer Instructions",shortname:"intmgtxfernotes",needprac:1},
        {menutext:"Manage Practice Providers",shortname:"extmgtpracprov",needprac:1},
        {menutext:"Manage Practice Users",shortname:"extmgtpracusers",needprac:1},
        {menutext:"Message Content-Alpha Page",shortname:"intmsgcontent",needprac:1},
        {menutext:"OnCall Determination",shortname:"intocqq",needprac:1},
        {menutext:"OnCall Groups",shortname:"intocgrp",needprac:1},
        {menutext:"OnCall Protocol Setup",shortname:"intocps",needprac:1},
        {menutext:"Archive Messages",shortname:"intviewarc",needprac:1},
        {menutext:"Practice Reporting",shortname:"pracrpt",needprac:1}
    ];

}

const systemOptionsOLD = [
    {menutext:"Configuration",shortname:"sysconfig",needprac:2},
    {menutext:"Lists",shortname:"syslists",needprac:2},
    {menutext:"Manage Practices",shortname:"intmgtprac",needprac:2},
    {menutext:"System Messages",shortname:"sysmsgs",needprac:2},
    {menutext:"Review Messages",shortname:"intviewpm",needprac:0},
    {menutext:"Status",shortname:"sysstatus",needprac:2}
];
const systemOptions = [
    {menutext:"Agent Dashboard",shortname:"agentdb",needprac:2},
    {menutext:"Ticket Dashboard",shortname:"ticketdb",needprac:2},
    {menutext:"Closed Tickets",shortname:"closedtickets",needprac:2},
    {menutext:"Thresholds",shortname:"thresholds",needprac:2},
    {menutext:"Configuration",shortname:"sysconfig",needprac:2},
    {menutext:"Lists",shortname:"syslists",needprac:2},
    {menutext:"Manage Practices",shortname:"intmgtprac",needprac:2},
    {menutext:"System Messages",shortname:"sysmsgs",needprac:2},
    {menutext:"Review Messages",shortname:"intviewpm",needprac:2},
    {menutext:"Status",shortname:"sysstatus",needprac:2},
    {menutext:"WorkFlows",shortname:"workflow",needprac:2}
];

const outboundOptions = [
    {menutext:"Working Tickets",shortname:"obtickets",needprac:2},
    {menutext:"Search Tickets",shortname:"searchtickets",needprac:2},
    {menutext:"Holding Tickets",shortname:"holdtickets",needprac:2}
];

const outboundOptionsSup = [
    {menutext:"Working Tickets",shortname:"obtickets",needprac:2},
    {menutext:"Search Tickets",shortname:"searchtickets",needprac:2},
    {menutext:"Holding Tickets",shortname:"holdtickets",needprac:2},
    {menutext:"Escalated Tickets",shortname:"esctickets",needprac:2}
    
];

const MenuInternal = (props) => {
    console.log("*******RoleID="+props.globals.roleid);
    const icperson = props.globals.fmtic===true && props.globals.fmticadmin===false;
    return (
        <div className={styles.horizmenu}>
            {APP_VERSION !== 1 && <MenuTab globals={props.globals} methods={props.methods} menutext="Provider" options={providerOptions} />}
            <MenuTab globals={props.globals} methods={props.methods} menutext="Messages" options={messageOptions} />
            <MenuTab globals={props.globals} methods={props.methods} menutext="On Call Calendar" options={onCallOptions} />
            {icperson && <MenuTab globals={props.globals} methods={props.methods} menutext="Outbound" options={outboundOptions} />}
            {props.globals.fmticadmin && <MenuTab globals={props.globals} methods={props.methods} menutext="Outbound" options={outboundOptionsSup} />}
            {props.globals.fmticadmin && <MenuTab globals={props.globals} methods={props.methods} menutext="Practice Admin" options={paOptions} />}
            {props.globals.fmticadmin && <MenuTab globals={props.globals} methods={props.methods} menutext="System Admin" options={systemOptions} />}
        </div>
    )
}

export default MenuInternal;