import React from 'react';
import styles from './MessageSchedListRow.module.scss';
import GenCheckbox from './GenCheckbox';
import GenButton from './GenButton';
import {SORTBY_MSGID} from '../AppConstants';

const MessageSchedListRow = (props) => {
    const [chked,setCheck] = React.useState(props.msg.checked);    
    const archive = props.Archive || 'N';
    const reviewmsg = props.Review || 'N';
    const DoCallback = (id) => {
        if (props.showClosed) return false;
        props.cbCallback(id);
        setCheck(!chked);
    }

    const DoDetail = (id) => {
        props.doDetail(id);
    }

    const handleClick = (e) => {
      if (e.detail===2) {
        let node = e.target.parentNode;
        while (node) {
          let mnum = node.getAttribute('data-id');
          if (mnum !== null && mnum !== 'undefined') {
            props.doDetail(mnum);
            return;
          }
          node = node.parentNode;
        }
        
      }

    }
    const getHighlightedText = (text, highlight) => {
        if (!highlight.trim()) {
          return text;
        }
        const regex = new RegExp(`(${highlight})`, "gi");
        const parts = text.split(regex);
        const updatedParts = parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? <mark key={i}>{part}</mark> : part
          );
        let newText = "";
        [...updatedParts].map(
          (parts) =>
            (newText =
              newText +
              (typeof parts === "object"
                ? `<${parts["type"]}>${highlight}</${parts["type"]}>`
                : parts))
        );
        return newText;
      };

      const Highlighted = ({ text = "", highlight = "" }) => {
        if (!highlight.trim()) {
          return <span>{text}</span>;
        }
        const regex = new RegExp(`(${highlight})`, "gi");
        const parts = text.split(regex);
      
        return (
          <span>
            {parts.filter(String).map((part, i) => {
              return regex.test(part) ? (
                <mark key={i}>{part}</mark>
              ) : (
                <span key={i}>{part}</span>
              );
            })}
          </span>
        );
      };

    const searchstr = props.searchstr || "";
    const msgid = props.cbid || 0;
    const msgnum = props.msg.MessageSchedID || 0;
    const fdate = props.msg.MessageDate || "";
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    
    const fd = new Date(fdate);
    const fmtdate = fd.toLocaleDateString(undefined,options);
    const ftime = props.msg.MessageTime || "";
    let s = props.msg.PatientLastName + ", " + props.msg.PatientFirstName;
    const fptname = s || "";
    let fstatus = props.msg.ActionTaken || "";
    if (props.defsortby===SORTBY_MSGID) fstatus = "" + props.msg.MessageSchedID;
    const fmsgtype = props.msg.Name || "";
    s = props.msg.CallerFirstName + " " + props.msg.CallerLastName;
    const fcaller = s || "";
    s = props.msg.FirstName + " " + props.msg.LastName;
    const fprovider = s || "";
    const pracname = props.msg.PracticeName || "";
    if (searchstr.length) {
      const txtall = fmtdate +"|"+ftime+"|"+fptname+"|"+fstatus+"|"+fmsgtype+"|"+fcaller+"|"+fprovider;
      if (txtall.toLowerCase().includes(searchstr.toLowerCase()) === false) return null;
    }
    
    let bgcolor = props.msg.ID & 1 ? "rgb(243, 243, 243)" : "white";
    if (props.msg.Status===0 && archive==='N' && reviewmsg==='N') bgcolor = "rgb(221, 226, 144)";
    const hovcolor = "rgb(230,230,229)";
    const fsize = props.globals.intext===1 ? "14px" : "18px";

    return (
        <div className={styles.listrow} data-id={msgnum} onClick={handleClick} style={{fontSize: fsize,"--custom_color": bgcolor,"--hover_color":hovcolor}}>
            {!props.noCheck && <div><GenCheckbox checked={chked} cbCallback={DoCallback} cbid={msgid} disabled={props.showClosed || false} /></div>}
            {props.noCheck && <div>&nbsp;</div>}
            <div><Highlighted text={fmtdate} highlight={searchstr} /></div>
            <div><Highlighted text={ftime} highlight={searchstr} /></div>
            <div><Highlighted text={fptname} highlight={searchstr} /></div>           
            <div><Highlighted text={fmsgtype} highlight={searchstr} /></div>
            {props.globals.intext===1 && <div><Highlighted text={pracname} highlight={searchstr} /></div>}
            <div><Highlighted text={fstatus} highlight={searchstr} /></div>
            <div>
                <GenButton 
                    id={msgnum} 
                    onclick={DoDetail} 
                    buttontext="Detail"
                    bgcolor="white"
                    hovercolor="floralwhite"
                    color="black"
                    height="18" 
                    width="36"
                    />
            </div>
        </div>
    )

}

export default MessageSchedListRow;