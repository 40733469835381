import React from "react";
import styles from "./Alert.module.scss";

const Alert = (props) => {
    return (
        <div className={styles.alert}>
        {props.msg}
        </div>
    )
}

export default Alert;