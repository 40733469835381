import React from 'react';
import styles from './MenuExternal.module.scss';
import MenuTab from './MenuTab';
import {APP_VERSION} from './../AppConstants';

const providerOptions = [
    {menutext:"View My Messages",shortname:"extview",needprac:1},
    {menutext:"Message Management",shortname:"extmm",needprac:1},
    {menutext:"Manage On Call Calendar",shortname:"extoncall",needprac:1},
    {menutext:"Practice Administration",shortname:"extpa",needprac:1}
];

var messageOptions = [];
if (APP_VERSION===1) {
    messageOptions = [
        {menutext:"View Practice Messages",shortname:"extviewpm",needprac:1}
    ];
} else {
    messageOptions = [
        {menutext:"View Practice Messages",shortname:"extviewpm",needprac:1},
        {menutext:"Send Practice Message",shortname:"extsend",needprac:1},
        {menutext:"Practice Message Archive",shortname:"extarch",needprac:1},
        {menutext:"Send Notifications",shortname:"extsendnot",needprac:1}
    ];
}

const onCallOptions = [
    {menutext:"Manage On Call Calendars",shortname:"extmgtoncall",needprac:1}
];

var paOptions = [];
if (APP_VERSION===1) {
    paOptions = [
        {menutext:"Manage Practice Providers",shortname:"extmgtpracprov",needprac:1},
        {menutext:"Manage Practice Users",shortname:"extmgtpracusers",needprac:1},
        {menutext:"Archive Messages",shortname:"intviewarc",needprac:1}

    ];

} else {
    paOptions = [
        {menutext:"Practice Locations and Demographics",shortname:"extlocdem",needprac:1},
        {menutext:"Review Practice Instructions",shortname:"extrevprac",needprac:1},
        {menutext:"Manage Practice Providers",shortname:"extmgtpracprov",needprac:1},
        {menutext:"Manage Practice Users",shortname:"extmgtpracusers",needprac:1},
        {menutext:"Practice Reporting",shortname:"pracrpt",needprac:1}
    ];

}


const MenuExternal = (props) => {
    //console.log("RoleID="+props.globals.roleid);
    return (
        <div className={styles.horizmenu}>
            {APP_VERSION !== 1 && <MenuTab globals={props.globals} methods={props.methods} menutext="Provider" options={providerOptions} />}
            <MenuTab globals={props.globals} methods={props.methods} menutext="Messages" options={messageOptions} />
            <MenuTab globals={props.globals} methods={props.methods} menutext="On Call Calendar" options={onCallOptions} />
            {props.globals.roleid===3 && <MenuTab globals={props.globals} methods={props.methods} menutext="Practice Admin" options={paOptions} />}
        </div>
    )
}

export default MenuExternal;