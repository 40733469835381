import React from "react";
import styles from "./OnCallMonth.module.scss";
import {DataService} from './../../store/DataService';
import GenSelect from './../../components/GenSelect';
import GenButton from './../../components/GenButton';
import {ReactComponent as Trello} from './../../assets/trello.svg';
import CalButtons from './../../components/CalButtons';
import {Time24, DateOnly} from './../../AppConstants';
//import MonthRightMenu from "../../components/MonthRightMenu";
//import MonthContextMenu from './../../components/MonthContextMenu';

//const dataservice = new DataService({guid:''});

const OnCallMonth = (props) => {
    //const [oncalldata,setOnCallData] = React.useState({oncallgroups:[]});
    //const [refreshneeded,setRefreshNeeded] = React.useState(false);
    //const [groupoptions,setGroupOptions] = React.useState([]);
    //const [curdate,setCurDate] = React.useState(props.globals.selecteddate);
    const [checkedDays,setCheckedDays] = React.useState(new Array(32).fill(false));
    const numchecked = checkedDays.filter((x)=>x===true);
    var actionOptions = [
        {value:1,text:"Copy",selected:false,disabled:false},
        {value:2,text:"Paste",selected:false,disabled:(props.clipboardLen < 1 || numchecked.length < 1)},
        //{value:3,text:"Undo",selected:false,disabled:props.undolen < 1},
        //{value:4,text:"Save",selected:false,disabled:props.undolen < 1}
        {value:5,text:"Clear",selected:false,disabled:numchecked.length < 1}
    ]

    const DoActions = (v) => {
        console.log("Actions...");
        if (v===1) {
            props.onCopy();
        }
        if (v===2) {
            props.onPaste(checkedDays);
        }
        if (v===3) {
            props.onUndo();
        }
        if (v===4) {
            props.onSave();
        }
        if (v===5) {
            props.onClear(checkedDays);
        }
    }

    const DoDateChange = (v) => {
        let dt = new Date(v);
        if (dt.getMonth()===props.globals.selecteddate.getMonth() && dt.getFullYear()===props.globals.selecteddate.getFullYear()) {

        } else {
            if (!props.isdirty) {
                setCheckedDays(new Array(32).fill(false));
            }
        }
        props.onDateChange(v);
    }
    const setToday = (e) => {
        DoDateChange(new Date());
    }

    //<GenButton buttontext="Actions..." onclick={DoActions} />
    const ShowHeader = () => {
        //console.log("ShowHeader...");
        return (
            <div className={styles.header}>
                <div className={styles.leftside}>
                    <div className={styles.title}>Call Groups & Calendars</div>
                    <div className={styles.subtitle}>View and manage on-call shifts and resource groups</div>
                </div>
                <div className={styles.rightside2}>
                    <div style={{zIndex:"2"}}>
                        
                        <GenSelect 
                            options={actionOptions} 
                            callback={DoActions} 
                            placeholder="Actions..."
                            width={110}
                            backColor="rgba(48, 104, 174, 1)"
                            hoverColor="lightblue"
                            textColor="white" 
                        />
                    </div>
                </div>
            </div>
        )
    }

    const ShowOptions = () => {
        //const opts = groupoptions;
        //console.log("ShowOptions opts="+opts);
        //console.log("GroupOpts="+opts);
        return (
            <div className={styles.header} style={{borderTop:"1px solid lightgray"}}>
                <div className={styles.leftside2}>
                    <GenSelect options={props.groupoptions} callback={props.onGroupChange} placeholder="Select Group..." />
                </div>
                <div className={styles.rightside}>
                    <div>
                        <GenButton width="100" buttontext="Day View" bgcolor="white" color="black" fontsize="16px" onclick={props.onViewChange} />
                    </div>
                    <div style={{paddingLeft:"12px",display:"flex"}}>
                        <CalButtons curDate={props.globals.selecteddate} onSelect={DoDateChange} />
                        <GenButton width="30" buttontext="Today" bgcolor="white" color="black" fontsize="14px" onclick={setToday} />
                    </div>
                </div>
            </div>
        )
    }

    const MonthHeader = () => {
        return (
            <div className={styles.calrow}>
                <div>SUNDAY</div>
                <div>MONDAY</div>
                <div>TUESDAY</div>
                <div>WEDNESDAY</div>
                <div>THURSDAY</div>
                <div>FRIDAY</div>
                <div>SATURDAY</div>
            </div>
        )
    }

    const ChangeDay = (e) => {
        let x = e.detail;
        let dy = e.target.getAttribute("data-day");
        if (dy===null) return;
        let dt = new Date(props.globals.selecteddate);
        dy = parseInt(dy);
        if (dy===dt.getDate()) {
            if (x > 1) {
                props.onViewChange();
            }
            return;
        }
        dt.setDate(dy);
        props.onDateChange(dt);
        if (x > 1) {
            props.onViewChange();
        }
    }

    const DayBlock = ({dayobj,curdt}) => {
        const dbid = "db_"+dayobj.day;

        if (dayobj.blank) {
            return <div className={styles.calempty}>&nbsp;</div>
        }
        let cc = "white";
        let dom = curdt.getDate();
        let dt = new Date(props.globals.selecteddate);
        dt.setDate(dayobj.day);
        if (DateOnly(dt)===DateOnly(curdt)) cc = "rgb(186, 202, 236)";
        //if (dom===dayobj.day && curdt.getMonth()===props.globals.selecteddate.getMonth() && curdt.getFullYear()===props.globals.selecteddate.getFullYear()) cc = "lightyellow";
        dom = props.globals.selecteddate.getDate();
        if (dom===dayobj.day) {
            cc = "lightgreen";
        }
        const bkulen = dayobj.bkudata.length;
        let tip = "";
        if (bkulen > 0) {
            tip = dayobj.bkudata.join(" & ");
            //console.log("tip="+tip);
        }

        const ToggleChecked = (e) => {
            let newchecked = [...checkedDays];
            newchecked[dayobj.day] = !newchecked[dayobj.day];
            setCheckedDays(newchecked);
        }

        
        return (
            <React.Fragment>
            <div className={styles.calblock}  
                style={{"--custom_color":cc}} 
                title={tip} 
                data-day={dayobj.day}
                onClick={ChangeDay}
                id={dbid}
                >
                
                <div>
                <div style={{width:"100%",display:"flex"}} data-day={dayobj.day}>
                    {dayobj.before===false && <div><input type="checkbox" defaultChecked={checkedDays[dayobj.day]} onChange={ToggleChecked}/></div>}
                    <div style={{marginLeft:"auto",display:"flex"}} data-day={dayobj.day} >
                        {bkulen > 0 ? <div style={{display:"flex",marginTop:"3px",paddingRight:"6px"}}><Trello /></div> : null}
                        <div style={{display:"flex"}} data-day={dayobj.day}>{dayobj.day}</div>
                    </div>
                </div>
                
                <div style={{position:"absolute",top:"22px",left:"3px"}} data-day={dayobj.day}>
                    {dayobj.data.map((tobj,i)=> {
                        return <div key={'dy_'+i} title={tip} data-day={dayobj.day}>{tobj}</div>
                    })}
                </div>
                </div>
            </div>
            
            </React.Fragment>
        )
    }

    const WeekRow = ({days}) => {
        let dt = new Date();
        return (
            <React.Fragment>
                <div className={styles.weekrow}>
                    {days.map((dy,i) => {
                        return <DayBlock key={i} dayobj={dy} curdt={dt} />
                    })}
                </div>
            </React.Fragment>
        )
    }

    const MonthBody = () => {
        let dt = new Date(props.globals.selecteddate);
        dt.setDate(1); // Below doesn't work if last day of month, need to use a valid day for every month
        dt.setMonth(dt.getMonth()+1);
        dt.setDate(0);
        let lastday = dt.getDate();
        dt.setDate(1);
        let firstdow = dt.getDay();
        let runningdate = new Date(dt);
        let nowdate = new Date();
        nowdate.setHours(0);
        nowdate.setMinutes(0);
        nowdate.setSeconds(0);
        nowdate.setMilliseconds(0);
        dt.setDate(lastday);
        let weeks = [];
        let days = [];
        let dy,cdow,sdate,edate;
        cdow = 0;
        dy = 1;
        let cg = 0;
        let prilist = [];
        let bkulist = [];
        props.groupoptions.forEach((opt,i) => {
            if (opt.selected) {
                cg = opt.value;
            }
        })
        props.oncalldata.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                prilist = ocg.PrimaryCalendars;
                bkulist = ocg.BackupCalendars;
            }
        });

        if (cg < 1) {
            console.log("OnCall NOT found");
            return null;
        }

        const selMonth = props.globals.selecteddate.getMonth();
        const selYear = props.globals.selecteddate.getFullYear();
        //console.log("MonthBody CG="+cg+", prilen="+prilist.length);
        while (dy <= lastday) {
            runningdate.setDate(dy);
            let beforenow = false;
            if (runningdate < nowdate) beforenow=true;
            if (cdow < firstdow) {
                days.push({day:dy,dow:cdow,blank:true,before:beforenow,bkudata:[],data:[]});
            } else {
                let txtrows = [];
                let i = 0;
                for (i=0;i<prilist.length;i++) {
                    let o = prilist[i];
                    let cdt = new Date(o.StartDateTime);
                    let cdMonth = cdt.getMonth();
                    let cdYear = cdt.getFullYear();
                    //console.log("MB: selMonth "+selMonth+", selYear "+selYear+", cdMonth "+cdMonth+", cdYear "+cdYear);
                    if (o.OnCallID===cg && o.CalDay===dy && selMonth===cdMonth && selYear===cdYear) {
                        //console.log("ocid,calday="+o.OnCallID+","+o.CalDay);
                        let nm = o.ResourceName || o.GroupDescription;
                        txtrows.push(nm);
                        sdate = Time24(o.StartDateTime);
                        edate = Time24(o.EndDateTime);
                        txtrows.push(sdate+'-'+edate);
                    }
                }
                let bkurows = [];
                for (i=0;i<bkulist.length;i++) {
                    let o = bkulist[i];
                    if (o.OnCallID===cg && o.CalDay===dy) {
                        bkurows.push(o.ResourceName);
                    }
                }

                days.push({day:dy,dow:cdow,blank:false,before:beforenow,bkudata:bkurows,data:txtrows});
                firstdow = 0;
                dy += 1;
            }
            cdow += 1;
            if (cdow > 6) {
                cdow = 0;
                weeks.push(days);
                days = [];
            }
        }
        if (cdow < 6 && days.length > 0) {
            while (cdow <= 6) {
                days.push({day:dy,dow:cdow,blank:true,data:""});
                cdow += 1;
            }
        }
        if (days.length > 0) {
            weeks.push(days);
        }
        
        return (
            <React.Fragment>
                {weeks.map((obj,i) => {
                    return <WeekRow key={"wk"+i} days={obj} />
                })}
            </React.Fragment>
        )
    }

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.validatesession();
    },[props.globals.sessionguid])

    return (
        <React.Fragment>
            <ShowHeader />
            <ShowOptions />
            <div className={styles.main}>
                <MonthHeader />
                <MonthBody />
            </div>
            </React.Fragment>
    )
}

export default OnCallMonth;