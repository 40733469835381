import React from "react";
import {useParams} from "react-router-dom";
import Dashboard from "./internal/Dashboard";

const HomeCommon = (props) => {
    let {msgid} = useParams();
    console.log("PopAlert="+props.popalert);
    console.log("HOMECOMMON - MsgID="+msgid);
    if (!props.popalert) msgid = 0;
    return (
        <React.Fragment>
        {props.globals.intext === 1 && <Dashboard globals={props.globals} methods={props.methods} resetlogin={props.resetlogin} alertmsg={msgid} />}
        {props.globals.intext === 2 && <div style={{width:"100%",fontFamily:"Inter",fontSize:"18px",marginTop:"60px"}}>
            <div style={{width:"60%",margin:"auto",lineHeight:"24px"}}><b style={{fontSize:"22px"}}>Notice</b><br/>
            Welcome to the New Patient Source Portal, please bookmark this site in your browser.<br/>
            If you have any questions, 
            please email ptsourceops@patient-source.com or contact us at 518-732-6090.
            </div>
        </div>}
        </React.Fragment>
    )
}

export default HomeCommon;