import React from "react";
import {useNavigate} from 'react-router-dom';   
import styles from "./SubMenu.module.scss";
import GenConfirm from "../components/GenConfirm";

const SubMenu = (props) => {
    const [showconfirm,setShowConfirm] = React.useState(false);
    const history = useNavigate();
    const DoClk = (e) => {
        e.preventDefault();
        console.log("Setting SubMenuSelected to "+props.menutext);
        let dd = props.methods.GetGlobalDirtyData() || false;
        console.log("SubMenu dd="+dd);
        if (dd===true) {
            setShowConfirm(true);
            props.setConfirm(true);
        } else {
            DoClkCommon();
        }
    }

    const DoClkCommon = () => {
        const shortname = props.shortname || props.menutext;
        //props.methods.SetSubMenuSelected(props.menutext);
        const upd = {menuselected:"",submenuselected:props.menutext,menupath:shortname};
        props.methods.SetObject(upd);
        props.doclose();
        history("/"+shortname);
    }

    const DoClkCancel = (e) => {
        setShowConfirm(false);
        props.setConfirm(false);
    }

    const DoClkConfirm = (e) => {
        setShowConfirm(false);
        props.setConfirm(false);
        props.methods.SetDirtyData(false);
        DoClkCommon();
    }
    
    const ShowConfirm = () => {
        const txt = "You haven't saved your changes.\nSelect Cancel to go back and Save.\nSelect Confirm to disregard changes and continue.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={DoClkCancel}
                onConfirm={DoClkConfirm}
            />
        )
    }


    return (
        <React.Fragment>
        <div className={styles.submenu} onClick={DoClk}>
            {props.menutext}
        </div>
        {showconfirm && <ShowConfirm />}
        </React.Fragment>
    )
}

export default SubMenu;