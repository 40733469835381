import React from "react";
import styles from './AddNote.module.scss';
import GenButton from "./GenButton";
import {ReactComponent as Closer} from './../assets/close.svg';
import { ADDNOTEREASONS } from "../AppConstants";
import AlertBox from "./AlertBox";

const AddNote = (props) => {
    const [inputs, setInputs] = React.useState({});

    let showreason = true;
    if (props.hasOwnProperty("showreason")) {
        showreason = props.showreason;
    }

    //if (props.showreason !== undefined) {
    //    showreason = props.showreason;
    //}

    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        
    }

    const doSave = (e) => {
        const reasonid = inputs.selreason || "0";
        const txt = inputs.antext || "";
        let reason = "";
        if (showreason) {
            if (reasonid==="0" && txt === "") {
                AlertBox("Both Reason and Comment cannot be blank.");
            } else {
                if (reasonid !== "" && reasonid !== "0") {
                    reason = ADDNOTEREASONS[parseInt(reasonid)];
                }
                props.onSave(reason,txt);
            }    
        } else {
            if (txt === "") {
                AlertBox("Comment cannot be blank.");
            } else {
                props.onSave('',txt);
            }
        }
    }
    const doCancel = (e) => {
        props.onCancel();
    }




    return (
        <React.Fragment>
            <div className={styles.note}>
                <div className={styles.outer}>
                    <div className={styles.inner}>
                        <div className={styles.noteheader}>
                            <div>Add Note</div>
                            <div className={styles.headerright} onClick={doCancel}><Closer /></div>
                        </div>
                        <hr/>
                        {showreason && <div><div className={styles.text}>Select Reason:</div>
                            <select style={{height:"28px",marginLeft:"8px",borderRadius:"6px"}} name="selreason" onChange={HandleChange}>
                                <option value="0"></option>
                                {Object.keys(ADDNOTEREASONS).map((ky,i) => {
                                    return <option key={i} value={ky}>{ADDNOTEREASONS[ky]}</option>
                                })}
                            </select>
                        </div>}
                        <div className={styles.text} style={{marginTop:"10px"}}>Comment:</div>
                        <div><textarea rows="5" cols="79" maxLength="240" name="antext" style={{borderRadius:"6px"}} onChange={HandleChange} /></div>
                        <div className={styles.buttons}>
                            <div>
                            <GenButton globals={props.globals} methods={props.methods}
                                buttontext="Save Note"
                                id="0"
                                height="30"
                                width="90"
                                bgcolor="white"
                                color="black"
                            onclick={doSave}
                            />
                            </div>
                            <div style={{marginLeft:"40px"}}>
                            <GenButton globals={props.globals} methods={props.methods}
                                buttontext="Cancel"
                                id="0"
                                height="30"
                                width="90"
                                bgcolor="white"
                                color="black"
                                onclick={doCancel}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddNote;