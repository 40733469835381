import React from 'react';
import {useNavigate} from 'react-router-dom'; 
import styles from './ViewUrgentMessages.module.scss';
import {DataService} from './../../store/DataService';
import GenButton from "../../components/GenButton";
import { formatPhoneNumber, Date12, calculateAgeInYears, RELATIONSHIPS, BuildName } from '../../AppConstants';
import {INFOTYPE_DOB,INFOTYPE_CALLERNAME,INFOTYPE_RELATIONSHIP,INFOTYPE_DAYPHONE1,INFOTYPE_DAYPHONE2,INFOTYPE_EVEPHONE1,INFOTYPE_EVEPHONE2} from '../../AppConstants';

const ViewUrgentMessages = (props) => {
    const [msglist,setMsgList] = React.useState([]);
    const [msgcontent,setMsgContent] = React.useState({});
    const [inputs,setInputs] = React.useState({});
    const [haveacks,setHaveAcks] = React.useState(false);
    const [pnvalues,setPNValues] = React.useState({});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const history = useNavigate();
    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        if (refreshneeded) setRefreshNeeded(false);

        dataservice.geturgentmessages().then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let pnv = {};
                data.DATA.forEach((msg)=> {
                    pnv["pnt_"+msg.messageid] = formatPhoneNumber(msg.pnvalue);
                })
                setMsgContent(data.MSGCONTENT);
                setMsgList(data.DATA);
                setInputs({});
                setHaveAcks(false);
                setPNValues(pnv);
            }
        })
    },[props.globals.sessionguid,refreshneeded])

    const doSave = (id) => {
        console.log("saving");
        let alist = [];
        let key = '';
        for (key in inputs) {
            let tf = inputs[key];
            if (tf === true) {
                let msgid = key.replace('cb_','');
                let obj = findMsg(msgid);
                if (obj) {
                    alist.push({msgid:msgid,oncallid:obj.oncallgroupid,providerid:obj.providerid,contactpersonid:obj.contactpersonid});
                }
            }
        }
        if (alist.length) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            let obj = {
                uaid:props.globals.useraccountid,
                uapracticeid:props.globals.userpracticeid,
                comment:'',
                alist:alist
            }
            dataservice.batchack(obj).then((data) => {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    setInputs({});
                    setHaveAcks(false);
                    setRefreshNeeded(true);
                }
            })
        }

    }

    const findMsg = (msgid) => {
        for (let i=0;i<msglist.length;i++) {
            let obj = msglist[i];
            if (obj.messageid===msgid) {
                return obj;
            }
        }
        return null;
    }

    const findAck = (msgid) => {
        let id = "cb_"+msgid;
        let ack = 'N';
        if (inputs[id] !== 'undefined') {
            if (inputs[id] === true) {
                ack = 'Y';
            }
        }
        return ack;
    }

    const takeAlert = (msgid) => {
        history("/home/"+msgid);
    }

    const Patched = (e) => {
        console.log("patched");
        const name = e.target.id;
        const sa = name.split('_');
        if (sa.length > 1) {
            const msgid = sa[1];
            console.log("Patching msg "+msgid);
            let msgobj = findMsg(msgid);
            if (msgobj) {
                let pn = pnvalues["pvt_"+msgid] || "";
                let contactmethod = "";
                if (pn.length) {
                    contactmethod = "Patched to " + formatPhoneNumber(pn);
                }
                let obj = {
                    cmd:"PATCHED",
                    msgid:msgid,
                    uaid:props.globals.useraccountid,
                    uapracticeid:props.globals.userpracticeid,
                    oncallid:msgobj.oncallgroupid,
                    oncallproviderid:msgobj.providerid,
                    ack:findAck(msgid),
                    contactmethod:contactmethod
                }
                const dataservice = new DataService({guid:props.globals.sessionguid});
                dataservice.contactaction(obj).then((data)=> {
                    if (data.ERROR) {
                        alert(data.ERROR);
                    } else {
                        setRefreshNeeded(true);
                    }
                })
            }
        }
    }

    const cbHandler = (e) => {
        const name = e.target.id;
        const value = e.target.checked;
        var inpcopy = JSON.parse(JSON.stringify(inputs));
        inpcopy[name] = value;
        setInputs({...inputs,[name]:value});
        let tf = false;
        if (value===true) {
            tf = true;
        } else {
            let key = '';
            for (key in inpcopy) {
                if (inpcopy[key]===true) {
                    tf = true;
                    break;
                }
            }
        }
        setHaveAcks(tf);
    }

    const pntHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPNValues({...pnvalues,[name]:value});
    }

    const selHandler = (e) => {
        const selname = e.target.name;
        const value = e.target.value;
        let name = "pnt_" + selname.split("_")[1];
        setPNValues({...pnvalues,[name]:value});
    }

    function DateShort(dt) {
        if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
        const options={year:"numeric",month:"short",day:"2-digit"};
          return new Date(dt).toLocaleDateString("en-us",options);
      }

    var lastpracname = "";
    var oddeven = 1;
    var lastcontent = [];
    
    var dphone1 = 0;
    var dphone2 = 0;
    var ephone1 = 0;
    var ephone2 = 0;
    var showdob = 0;
    var showcaller = 0;
    var showrel = 0;

    const findInfoType = (mtid,itid) => {
        let val = 0;
        lastcontent.every((cobj)=> {
            if (cobj.mtid===mtid && cobj.itid===itid) {
                val = cobj.val;
                return false;
            }
            return true;
        })
        return val;
    }

    const ShowPractice = (pracname) => {
        if (pracname !== lastpracname) {
            lastpracname = pracname;
            
            return (
                <div className={styles.pracname}>{pracname}</div>
            )
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <div className={styles.title}>Urgent Messages</div>
            
            <div className={styles.header}>
                <div>Acknowledged</div>
                <div>Message</div>
                <div>Caller</div>
                <div>Phone</div>
                <div>Patient</div>
                <div>Action</div>
            </div>
            <div className={styles.data}>
                {msglist.map((obj,i) => {
                    oddeven ^= 1;
                    if (obj.practicename !== lastpracname) {
                        let msgtypeid = obj.messagetypeid || 0;
                        msgtypeid = parseInt(msgtypeid);
                        oddeven = 0;
                        lastcontent = msgcontent[obj.practiceid] || [];
                        
                        dphone1 = dphone2 = ephone1 = ephone2 = showdob = showcaller = showrel = 0;
                        if (lastcontent.length) {
                            dphone1 = findInfoType(msgtypeid,INFOTYPE_DAYPHONE1);
                            if (dphone1===0) dphone1 = findInfoType(0,INFOTYPE_DAYPHONE1);
                            dphone2 = findInfoType(msgtypeid,INFOTYPE_DAYPHONE2);
                            if (dphone2===0) dphone2 = findInfoType(0,INFOTYPE_DAYPHONE2);
                            ephone1 = findInfoType(msgtypeid,INFOTYPE_EVEPHONE1);
                            if (ephone1===0) ephone1 = findInfoType(0,INFOTYPE_EVEPHONE1);
                            ephone2 = findInfoType(msgtypeid,INFOTYPE_EVEPHONE2);
                            if (ephone2===0) ephone2 = findInfoType(0,INFOTYPE_EVEPHONE2);
                            showdob = findInfoType(msgtypeid,INFOTYPE_DOB);
                            if (showdob===0) showdob = findInfoType(0,INFOTYPE_DOB);
                            showcaller = findInfoType(msgtypeid,INFOTYPE_CALLERNAME);
                            if (showcaller===0) showcaller = findInfoType(0,INFOTYPE_CALLERNAME);
                            showrel = findInfoType(msgtypeid,INFOTYPE_RELATIONSHIP);
                            if (showrel===0) showrel = findInfoType(0,INFOTYPE_RELATIONSHIP);
                        }
                    }
                    const msgid = obj.messageid;
                    let editrows = obj.editrows || [];
                    let caller = BuildName(obj.callerfirstname,obj.callerlastname,'');
                    if (!caller.length) caller = "(No Caller Name)";
                    let patient = BuildName(obj.patientfirstname,obj.patientlastname,'');
                    if (!patient.length) patient ="(No Patient Name)";
                    const msgdate = Date12(obj.messagecreatedate);
                    let provname = BuildName(obj.provfirstname,obj.provlastname,obj.provmiddleinitial);
                    let rel = obj.callerrelationship;
                    if (rel.length) {
                        rel = parseInt(rel);
                        rel = RELATIONSHIPS[rel];
                    }

                    let takealert = obj.queuealertid > 0;
                    // put in logic to check if alert already popped for someone

                    let age = 0;
                    let ptdob = obj.patientdob;

                    if (!ptdob.length) {
                        ptdob = obj.reasonnodob;
                    } else {
                        age = calculateAgeInYears(ptdob);
                        ptdob = DateShort(ptdob);
                        if (age < 1) {
                            ptdob = obj.patientdob;
                        };
                        if (age > 122) {
                            ptdob = "Invalid DOB";
                            age = 0;
                        }
                    }
                    
                    let actionid = parseInt(obj.actionid);
                    let actionline1 = "No Action found";
                    let actionline2 = "";
                    let actionline3 = "";
                    let actionline4 = "";
                    let actionline5 = "";
                    let actionline6 = "";
                    let byname = BuildName(obj.mhfirstname, obj.mhlastname,obj.mhmiddleinitial);
                    if (actionid > 0) {
                        actionline1 = obj.actiontaken;
                        actionline2 = "at "+Date12(obj.messagedate);
                        actionline3 = "by ";
                        if (byname.length) {
                            actionline3 += byname;
                        } else {
                            actionline3 += "Patient";
                        } 
                        
                        if (actionid===2 || actionid===12 || actionid===13) {
                            actionline4 = obj.contactmethod;
                            if (actionid !== 13) {
                                actionline5 = BuildName(obj.pocfirstname,obj.poclastname,obj.pocmiddleinitial);
                            }
                            actionline6 = "Note: "+obj.comment;
                        }
                        if (actionid===4 || actionid===5) {
                            actionline4 = obj.reason;
                            actionline5 = "Note: "+obj.comment;
                        }
                        if (actionid===6 || actionid===7) {
                            actionline4 = "Note: "+obj.comment;
                        }
                        if (actionid===8) {
                            actionline4 = obj.reason;
                        }
                    }
                    let rowclass = styles.datarow;
                    
                    var cbid = "cb_"+msgid;
                    var chked = inputs[cbid] || false;

                    if (oddeven) rowclass = styles.dataroweven;

                    let options = [];
                    
                    if (obj.dayphone1.length) {
                        options.push(formatPhoneNumber(obj.dayphone1))
                    }
                    if (obj.dayphone2.length) {
                        options.push(formatPhoneNumber(obj.dayphone2))
                    }
                    if (obj.evephone1.length) {
                        options.push(formatPhoneNumber(obj.evephone1))
                    }
                    if (obj.evephone2.length) {
                        options.push(formatPhoneNumber(obj.evephone2))
                    }

                    let minfo = obj.msginfo || [];

                    return (
                        <React.Fragment>
                        {ShowPractice(obj.practicename)}
                        <div key={"obj_"+i} className={rowclass}>
                            <div className={styles.firstcol}>
                                <div>
                                    <label><input type="checkbox" id={cbid} checked={chked} onChange={cbHandler}></input>
                                    <span className={styles.ack}>Ack</span></label>
                                </div>
                                <div>
                                    <div>
                                        <button id={"btn_"+msgid} style={{height:"22px",border:"1px solid lightblue",borderRadius:"5px",color:"white",backgroundColor:"blue",marginLeft:"26px",width:"90px"}} onClick={Patched}>Patched</button>
                                    </div>
                                    <div>
                                        <input type="text" name={"pnt_"+msgid} style={{marginLeft:"14px",width:"120px",fontSize:"12px"}} value={pnvalues["pnt_"+msgid]} onChange={pntHandler} ></input>
                                    </div>
                                    <div>
                                        {options.length > 0 && <select name={"sel_"+msgid} style={{marginLeft:"14px",width:"128px",fontSize:"12px"}} value={pnvalues["pnt_"+msgid]} onChange={selHandler}>
                                            <option value=""></option>
                                            {options.map((opt,i)=> {
                                                return <option key={"pnopt_"+i} value={opt}>{opt}</option>
                                            })}
                                        </select>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div><b>ID:</b>{msgid}
                                    <span style={{paddingLeft:"10px"}}><b>Date:</b> {msgdate}</span>
                                    <span style={{paddingLeft:"10px"}}><b>Acct #:</b> {obj.accountnum}</span>
                                </div>
                                <div><b>Provider:</b> {provname}</div>
                                <div><b>Contact Name:</b> {obj.contactname}</div>
                                <div><b>Msg:</b> {obj.messagebody}</div>
                            </div>
                            <div style={{marginLeft:"20%"}}>
                                {showcaller>0 && <div>{caller}</div>}
                                {showrel>0 && <div><b>Rel:</b> {rel}</div>}
                            </div>
                            <div style={{marginLeft:"20%"}}>
                                {dphone1>0 && <div>Day1:{formatPhoneNumber(obj.dayphone1)}</div>}
                                {dphone2>0 && <div>Day2:{formatPhoneNumber(obj.dayphone2)}</div>}
                                {ephone1>0 && <div>Eve1:{formatPhoneNumber(obj.evephone1)}</div>}
                                {ephone2>0 && <div>Eve2:{formatPhoneNumber(obj.evephone2)}</div>}
                            </div>
                            <div style={{marginLeft:"20%"}}>
                                {patient}
                                {showdob>0 && <React.Fragment>
                                    <div><b>DOB:</b> {ptdob}</div>
                                    <div><b>Age:</b> {age}</div>
                                </React.Fragment>}
                                {minfo.map((info,i)=> {
                                    return <div>{info.desc}: {info.value}</div>
                                })}
                            </div>
                            <div style={{marginLeft:"20%"}}>
                                {takealert && <div><button id={"btn_"+msgid} style={{height:"22px",border:"1px solid lightblue",borderRadius:"5px",color:"white",backgroundColor:"blue",marginLeft:"26px",width:"90px"}} onClick={(e)=>{takeAlert(msgid)}}>Take Alert</button></div>}
                                <div>{actionline1}</div>
                                <div>{actionline2}</div>
                                <div>{actionline3}</div>
                                <div>{actionline4}</div>
                                <div>{actionline5}</div>
                                <div>{actionline6}</div>
                                {editrows.map((ln,j) => {
                                    return <div key={"er_"+j}>{ln}</div>
                                })}
                            </div>
                        </div>
                        </React.Fragment>
                    )
                })}
            </div>
            <div className={styles.buttons}>
                <GenButton id="btnsave" disabled={!haveacks} buttontext="Save" width="116" onclick={(e)=>doSave(0)} />
            </div>
        </React.Fragment>
    )
}

export default ViewUrgentMessages;