import React from "react";
import styles from "./MessageAction.module.scss";
import { DataService } from "../../store/DataService";


const MessageAction = (props) => {
    
    const [msgdata,setMsgData] = React.useState({});
    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        let mid = props.msgnum;
        if (mid) {
            dataservice.msgactioninit(props.globals.practiceid,mid).then((data)=> {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    setMsgData(data);
                }
            })
        }
    },[props.globals.sessionguid,props.globals.practiceid,props.msgnum])

    if (!msgdata.length) {
        return null;
    } else {
        return (
            <React.Fragment>
                <div className={styles.backdrop}>
                    <div className={styles.modal}>
                        <div className={styles.content}>
                            <div className={styles.title}>Message Action</div>
                            <div className={styles.page}>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default MessageAction;