import React from "react";
import styles from "./PracticeMgmt.module.scss";
import { DataService } from "../../store/DataService";
import { MSGTYPE_APPTCANCEL, MSGTYPE_APPTINQUIRY, MSGTYPE_APPTREQUEST, MSGTYPE_CLINICAL, MSGTYPE_NONCLINICAL, PTAUTHTYPES, formatPhoneNumber } from "../../AppConstants";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenPrompt from "../../components/GenPrompt";
import GenMsgBox from "../../components/GenMsgBox";

var originputs = {};
var currentpracticeid = 0;
var currentlocationid = 0;
var newacctname = "";

const PracticeMgmt = (props) => {
    const [matrix,setMatrix] = React.useState({practices:[],allservices:[],allsystems:[],allmsgtypes:[]})
    const [practices,setPractices] = React.useState([]);
    const [allservices,setAllServices] = React.useState([]);
    const [allsystems,setAllSystems] = React.useState([]);
    const [isloading,setIsLoading] = React.useState(false);
    const [selectedpracid,setSelectedPractice] = React.useState(0);
    const [selectedlocid,setSelectedLocation] = React.useState(0);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [showinactive,setShowInactive] = React.useState(false);
    const [showrowchange,setShowRowChange] = React.useState(false);
    const [showlocchange,setShowLocChange] = React.useState(false);
    const [doscroll,setDoScroll] = React.useState(false);
    const [addnew,setAddNew] = React.useState(false);
    const [showerr,setShowErr] = React.useState(false);
    const scrollref = React.useRef();
    const [acceptsmsgtypes,setAcceptsMsgType] = React.useState(0);
    const newtypes = [''+MSGTYPE_NONCLINICAL, ''+MSGTYPE_APPTCANCEL,''+MSGTYPE_APPTREQUEST,''+MSGTYPE_APPTINQUIRY];
    const [inputs,setInputs] = React.useState(
        {
            selaccounts:"",
            acctnumpt: "",
            acctphonept:"",
            acctgreetpt:"",
            acctinstpt:"",
            acctnumprov:"",
            acctphoneprov:"",
            acctgreetprov:"",
            acctinstprov:"",
            acctnumover:"",
            acctphoneover:"",
            acctgreetover:"",
            acctinstover:"",
            pracname:"",
            svcopt_1:false,
            svcopt_2:false,
            svcopt_3:false,
            svcopt_4:false,
            svcopt_5:false,
            svcopt_6:false,
            selptauth: 0,
            selrxtemp: 0,
            cbaccts: false,
            cbpwd: false,
            cbaccess: true,
            useraccountid:0,
            firstname:"",
            middleinitial:"",
            lastname:"",
            phone:"",
            phoneext:"",
            fax:"",
            email:"",
            username:"",
            password:"",
            password2:"",
            cbforce: false,
        }
    );

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setIsLoading(true);
        dataservice.managepracticeinit().then((data) => {
            let err = data.ERROR || "";
            if (err.length) {
                alert(err);
            } else {
                if (!practices.length) currentpracticeid=0;
                setMatrix(data);
                setPractices(data.practices);
                setAllServices(data.allservices);
                setAllSystems(data.allsystems);
            }
            
        }).finally(()=> {
            setIsLoading(false);
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    React.useEffect(()=> {
        if (practices.length > 0 && selectedpracid===0 && props.globals.practiceid > 0) {
            currentpracticeid = ''+props.globals.practiceid;
            practiceSelectOK(null);
            setDoScroll(true);
        }
    },[practices,props.globals.practiceid,selectedpracid])

    React.useEffect(()=> {
        if (doscroll) {
            scrollref.current.scrollIntoView();
            setDoScroll(false);
        }

    },[doscroll])


    const compareNames = (a,b) => {
        if (!a || !b) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    const validateFields = () => {
        const msgtypes = matrix.allmsgtypes || [];
        if (!inputs.pracname.trim().length) return 1;
        if (!inputs.firstname.trim().length) return 2;
        if (!inputs.lastname.trim().length) return 3;
        if (!inputs.phone.trim().length) return 4;
        if (!inputs.username.trim().length) return 5;
        if (acceptsmsgtypes==='0' || acceptsmsgtypes === '2') {
            let badflds = [];
            newtypes.forEach((nt)=> {
                if (inputs["mtopt_"+nt]==='0') {
                    msgtypes.every((mt)=> {
                        if (''+mt.mtid===nt) {
                            badflds.push(mt.name);
                            return false;
                        }
                        return true;
                    })
                }
            })
            if (badflds.length > 0) {
                alert("You need to choose Internal or External\nfor the following Message Types:\n"+badflds.join("\n"));
                return -1;
            }
        }
        return 0;
    }

    const fieldnames = ['','Practice Name','Admin FirstName','Admin LastName','Admin Phone','Admin Username'];

    const doSave = () => {
        const r = validateFields();
        if (r < 0) return;
        if (r > 0) {
            let st = fieldnames[r] + " is required.";
            alert(st);
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savepractice(selectedpracid,selectedlocid,props.globals.useraccountid,inputs).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return
                }
            }

            let prac = data.practice;
            let pracid = data.pracid || 0;
            pracid = '' + pracid;
            let found = false;
            if (prac) {
                let newpracs = [];
                practices.forEach((p)=> {
                    let pid = p.practiceid;
                    if (pid === pracid) {
                        newpracs.push(prac);
                        found = true;
                    } else {
                        newpracs.push(JSON.parse(JSON.stringify(p)));
                    }
                })
                if (!found) {
                    newpracs.push(prac);
                    newpracs.sort(compareNames);
                    props.methods.SetPracticeRefresh(true);
                }
                setPractices(newpracs);
                setSelectedPractice(pracid);
                setSelectedLocation(0);
                props.methods.SetPracticeRefresh(true);               
            }

        })
        setDirtyFlag(false);
    }

    const handleAddNew = () => {
        if (dirtyflag) {
            currentpracticeid = 0;
            setShowRowChange(true);
            return;
        }
        let obj = fillInputs(0);
        setInputs({...inputs,...obj});
        originputs = JSON.parse(JSON.stringify(obj));
        setDirtyFlag(false);
        setSelectedPractice(0);
        setSelectedLocation(0);
    }

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const comparePrac = (o1,o2) => {
        if (o1.pracname !== o2.pracname) return false;
        if (o1.svcopt_1 !== o2.svcopt_1) return false;
        if (o1.svcopt_2 !== o2.svcopt_2) return false;
        /*
        if (o1.svcopt_3 !== o2.svcopt_3) return false;
        if (o1.svcopt_4 !== o2.svcopt_4) return false;
        if (o1.svcopt_5 !== o2.svcopt_5) return false;
        if (o1.svcopt_6 !== o2.svcopt_6) return false;
        */
        if (o1.selptauth !== o2.selptauth) return false;
        if (o1.selrxtemp !== o2.selrxtemp) return false;
        if (o1.cbaccts !== o2.cbaccts) return false;
        if (o1.cbpwd !== o2.cbpwd) return false;
        if (o1.cbaccess !== o2.cbaccess) return false;
        if (o1.useraccountid !== o2.useraccountid) return false;
        if (o1.firstname !== o2.firstname) return false;
        if (o1.middleinitial !== o2.middleinitial) return false;
        if (o1.lastname !== o2.lastname) return false;
        if (o1.phone !== o2.phone) return false;
        if (o1.phoneext !== o2.phoneext) return false;
        if (o1.fax !== o2.fax) return false;
        if (o1.email !== o2.email) return false;
        if (o1.username !== o2.username) return false;
        if (o1.password !== o2.password) return false;
        if (o1.password2 !== o2.password2) return false;
        if (o1.cbforce !== o2.cbforce) return false;
        return true;
    };

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name==='acctphonept' || name==='acctphoneprov' || name==='acctphoneover') {
            value = value.replace('(','').replace(')','').replace('-','').replace(' ','')
        }
        let newobj = JSON.parse(JSON.stringify(inputs));
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        setDirtyFlag(!compareObj(newobj,originputs));

    }

    const handleAcceptsChange = (e) => {
        const msgtypes = matrix.allmsgtypes || [];
        let practice = findPractice(selectedpracid);
        let mtlist = [];
        if (practice) {
            mtlist = practice.pracmsgtypes || [];
        }
        let name = e.target.name;
        let value = e.target.value;
        let newobj = JSON.parse(JSON.stringify(inputs));
        
        if (name==='acceptsmsgtypes') {
            msgtypes.forEach((mt)=> {
                newobj['mtopt_'+mt.msgtypeid] = '0';
            })                
            mtlist.forEach((pmt)=> {
                newobj["mtopt_"+pmt.mtid] = ''+pmt.handled;
            })

            newobj['mtopt_'+MSGTYPE_CLINICAL] = '1';
            //newobj['mtopt_'+MSGTYPE_NONCLINICAL] = '1';

            if (value==='1') {
                mtlist.forEach((pmt)=> {
                    newobj["mtopt_"+pmt.mtid] = '0';
                })
                //newobj['mtopt_'+MSGTYPE_NONCLINICAL] = '0';

            }
            if (value==='2') {
                newobj['mtopt_'+MSGTYPE_CLINICAL] = '0';
            }
            setInputs(values=> ({...values,...newobj}));
            setAcceptsMsgType(value);
            setDirtyFlag(!compareObj(newobj,originputs));
        }
    }

    const handleCheckboxChange = (e) => {
        let name = e.target.name;
        let value = e.target.checked;
        let newobj = JSON.parse(JSON.stringify(inputs));
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        setDirtyFlag(!compareObj(newobj,originputs));        
    }

    const handleRadioChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let newobj = JSON.parse(JSON.stringify(inputs));
        if (e.target.checked) {
            setInputs(values => ({...values,[name]:value}));
            newobj[name] = value;
            setDirtyFlag(!compareObj(newobj,originputs)); 
        }
        let clinicalonly = 0;
        if (newobj["mtopt_"+MSGTYPE_CLINICAL]==='1' && newobj["mtopt_"+MSGTYPE_NONCLINICAL]=='0') {
            clinicalonly = 1;
        }
        if (newobj["mtopt_"+MSGTYPE_CLINICAL]==='0' && newobj["mtopt_"+MSGTYPE_NONCLINICAL]=='1') {
            clinicalonly = 2;
        }
        setAcceptsMsgType(clinicalonly);
    }

    const splitPhone = (pn) => {
        var pobj = {phone:"",ext:""};
        let sa = pn.split('x');
        pobj.phone = sa[0];
        if (sa.length > 1) {
            pobj.ext = sa[1];
        }
        return pobj;
    }

    const getEmptyInputs = () => {
        var obj = {
            selaccounts:"",
            acctnumpt: "",
            acctphonept:"",
            acctgreetpt:"",
            acctinstpt:"",
            acctnumprov:"",
            acctphoneprov:"",
            acctgreetprov:"",
            acctinstprov:"",
            acctnumover:"",
            acctphoneover:"",
            acctgreetover:"",
            acctinstover:"",
            pracname:"",
            //svcopt_1:false,
            //svcopt_2:false,
            //svcopt_3:false,
            //svcopt_4:false,
            //svcopt_5:false,
            //svcopt_6:false,
            selptauth: 0,
            selrxtemp: 0,
            cbaccts: false,
            cbpwd: false,
            cbaccess: true,
            useraccountid:0,
            firstname:"",
            middleinitial:"",
            lastname:"",
            phone:"",
            phoneext:"",
            fax:"",
            email:"",
            username:"",
            password:"",
            password2:"",
            cbforce: false,
            sysopt:0
        };
        allservices.forEach((s)=> {
            obj["svcopt_"+s.stid] = false;
        });
        const msgtypes = matrix.allmsgtypes || [];
        msgtypes.forEach((mt) => {
            obj["mtopt_"+mt.mtid] = '0';
        })

        return obj;
    }

    const getEmptyAccts = () => {
        var obj = {
            selaccounts:"",
            acctnumpt: "",
            acctphonept:"",
            acctgreetpt:"",
            acctinstpt:"",
            acctnumprov:"",
            acctphoneprov:"",
            acctgreetprov:"",
            acctinstprov:"",
            acctnumover:"",
            acctphoneover:"",
            acctgreetover:"",
            acctinstover:""
        };

        return obj;
    }

    const fillInputs = (pid,autoset=true) => {
        let obj = getEmptyInputs();
        let practice = findPractice(pid);
        let clinicalonly = 0;
        if (practice) {
            obj.pracname = practice.name;
            obj.selptauth = practice.ptauthtypeid;
            obj.selrxtemp = practice.templateid;
            obj.cbaccts = practice.uselocationaccounts==='Y';
            obj.cbpwd = practice.expirepasswords==='Y';
            obj.cbaccess = practice.isactive==='Y';
            obj.useraccountid = practice.admin.uaid || 0;
            obj.firstname = practice.admin.firstname || "";
            obj.middleinitial = practice.admin.middleinitial || "";
            obj.lastname = practice.admin.lastname || "";
            obj.phone = splitPhone(practice.admin.officephone).phone || "";
            obj.phoneext = splitPhone(practice.admin.officephone).ext || "";
            obj.fax = practice.admin.faxnum || "";
            obj.email = practice.admin.email || "";
            obj.username = practice.admin.username || "";
            
            for (let i=0;i<practice.services.length;i++) {
                let svc = practice.services[i];
                obj['svcopt_'+svc.stid] = true;
            }
            const syslist = practice.systems || [];
            syslist.forEach((s)=> {
                obj["sysopt"] = s.stid;
            })
            const mtlist = practice.pracmsgtypes || [];
            mtlist.forEach((pmt)=> {
                obj["mtopt_"+pmt.mtid] = ''+pmt.handled;
            })
            
            if (obj["mtopt_"+MSGTYPE_CLINICAL]==='1' && obj["mtopt_"+MSGTYPE_NONCLINICAL]=='0') {
                clinicalonly = 1;
            }
            if (obj["mtopt_"+MSGTYPE_CLINICAL]==='0' && obj["mtopt_"+MSGTYPE_NONCLINICAL]=='1') {
                clinicalonly = 2;
            }

        }
        if (autoset) {
            setInputs({...inputs,...obj});
            setAcceptsMsgType(clinicalonly);
        }
        
        return obj;
    }

    const fillAccts = (locid) => {
        let obj = getEmptyAccts();
        obj.selaccounts = locid;
        let prac = findPractice(selectedpracid);
        if (prac) {
            let loc = findLocation(locid);
            if (loc) {
                let accts = prac.accounts || [];
                for (let i=0;i<accts.length;i++) {
                    let acct = accts[i];
                    if (acct.locationid===locid) {
                        if (acct.accounttype===1) {
                            obj.acctnumpt = acct.accountnumber;
                            obj.acctphonept = acct.accountphone;
                            obj.acctgreetpt = acct.greeting;
                            obj.acctinstpt = acct.instructions;
                        }
                        if (acct.accounttype===2) {
                            obj.acctnumprov = acct.accountnumber;
                            obj.acctphoneprov = acct.accountphone;
                            obj.acctgreetprov = acct.greeting;
                            obj.acctinstprov = acct.instructions;
                        }
                        if (acct.accounttype===3) {
                            obj.acctnumover = acct.accountnumber;
                            obj.acctphoneover = acct.accountphone;
                            obj.acctgreetover = acct.greeting;
                            obj.acctinstover = acct.instructions;
                        }                                                
                    }
                }
            }
        }
        setInputs({...inputs,...obj});
        return obj;
    }

    const practiceSelectCancel = (e) => {
        setShowRowChange(false);
    }

    const practiceSelectOK = (e) => {
        setSelectedPractice(currentpracticeid);
        setSelectedLocation(0);
        let obj = fillInputs(currentpracticeid);
        originputs = JSON.parse(JSON.stringify(obj));
        let prac = findPractice(currentpracticeid);
        setShowRowChange(false);
        setDirtyFlag(false);
    }

    const handlePracticeSelect = (e) => {
        let pid = e.target.id;
        if (pid !== selectedpracid) {
            currentpracticeid=pid;
            if (dirtyflag) {
                setShowRowChange(true);
                return;
            } else {
                practiceSelectOK(null);
            }
        }
    }

    const doLocChangeOK = (e) => {
        setSelectedLocation(currentlocationid);
        fillAccts(currentlocationid);
        setDirtyFlag(!comparePrac(inputs,originputs));
        setShowLocChange(false);
    }

    const doLocChangeCancel = (e) => {
        setShowLocChange(false);
    }

    const handleAccountSelect = (e) => {
        const lid = e.target.value;
        if (parseInt(lid) === -1) {
            setAddNew(true);
            return;
        }
        currentlocationid = lid;
        if (dirtyflag) {
            setShowLocChange(true);
        } else {
            doLocChangeOK(null);
        }
    }

    const findPractice = (pid) => {
        let practice = null;
        for (let i=0;i<practices.length;i++) {
            if (practices[i].practiceid===pid) {
                practice = practices[i];
                break;
            }
        }
        return practice;
    }

    // Working on ShowAccounts - Selecting location, then finding practice accounts for that location
    const findLocation = (lid) => {
        let location = null;
        let practice = findPractice(selectedpracid);
        if (practice) {
            let loclist = practice.locations || [];
            for (let i=0;i<loclist.length;i++) {
                if (loclist[i].locationid===lid) {
                    location = loclist[i];
                    break;
                }
            }
        }
        return location;
    }

    const findService = (prac,svctype) => {
        let yesno = false;
        let svcs = prac.services || [];
        svcs.forEach((svc) => {
            if (svc.stid===svctype) {
                yesno = true;
            }
        })
        return yesno;
    }

    const handleInactive = (e) => {
        setShowInactive(e.target.checked);
    }

    const ShowPractices = () => {

        return (
            <React.Fragment>
            <div className={styles.boxtitle}>
            Practices
            <span style={{marginLeft:"10px",fontSize:"16px",fontWeight:"normal"}}><label><input type="checkbox" name="cbinactive" onChange={handleInactive} />Show Inactive</label></span>
            </div>
            <div className={styles.practicebox}>
                {(isloading && practices.length < 1) && <div>Loading...</div>}
                {practices.map((prac,i)=> {
                    let st = styles.practicerow;
                    if (!showinactive && prac.isactive !== 'Y') return null;
                    if (prac.isactive !== 'Y' && prac.practiceid !== selectedpracid) st = styles.practicerowinactive;
                    if (prac.practiceid===selectedpracid) {
                        st = styles.practicerowsel;
                        return <div key={"pr_"+i} ref={scrollref} id={prac.practiceid} className={st} onClick={handlePracticeSelect}>{prac.name}</div>
                    }
                    return <div key={"pr_"+i} id={prac.practiceid} className={st} onClick={handlePracticeSelect}>{prac.name}</div>
                })}
            </div>
            </React.Fragment>
        )
    }

    const doAdd = (e) => {
        newacctname = "";
        setAddNew(true);
    }

    const ShowAccounts = () => {
        
        let prac = findPractice(selectedpracid);
        let locations = [];
        if (prac) locations = prac.locations || [];
        /*
        if (!prac) {
            return (
                <React.Fragment>
                    <div className={styles.boxtitle}>Accounts</div>
                    <div className={styles.acctbox}></div>
                </React.Fragment>
            )
        }
        */
        return (
            <React.Fragment>
                <div className={styles.boxtitle}>
                Accounts
                <span className={styles.boxtitlesel}>
                    <select name="selaccounts" onChange={handleAccountSelect} value={inputs.selaccounts} style={{width:"250px",height:"20px"}}>
                        <option value="0"></option>
                        {selectedpracid > 0 && <option value="-1">* Add New Account *</option>}
                        {locations.map((loc,i)=> {
                            return (
                                <option key={"lopt_"+i} value={loc.locationid}>{loc.name}</option>
                            )
                        })}
                    </select>
                </span>

                </div>
                <div className={styles.acctbox}>
                    <div className={styles.accthdr}>Patient Toll-Free Account</div>
                    <div className={styles.acctrow4}>
                        <div className={styles.acctcollabel}>Account Number:</div>
                        <div><input type="text" name="acctnumpt" onChange={handleChange} value={inputs.acctnumpt} style={{width:"60px"}}></input></div>
                        <div className={styles.acctcollabel}>Phone:</div>
                        <div><input type="text" name="acctphonept" onChange={handleChange} value={formatPhoneNumber(inputs.acctphonept)} style={{width:"140px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Greeting:</div>
                        <div><input type="text" name="acctgreetpt" onChange={handleChange} value={inputs.acctgreetpt} style={{width:"290px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Instructions:</div>
                        <div><textarea rows={2} style={{width:"292px",resize:"none"}} name="acctinstpt" onChange={handleChange} value={inputs.acctinstpt} /></div>
                    </div>

                    <div className={styles.accthdr}>Provider Toll-Free Account</div>
                    <div className={styles.acctrow4}>
                        <div className={styles.acctcollabel}>Account Number:</div>
                        <div><input type="text" name="acctnumprov" onChange={handleChange} value={inputs.acctnumprov} style={{width:"60px"}}></input></div>
                        <div className={styles.acctcollabel}>Phone:</div>
                        <div><input type="text" name="acctphoneprov" onChange={handleChange} value={formatPhoneNumber(inputs.acctphoneprov)} style={{width:"140px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Greeting:</div>
                        <div><input type="text" name="acctgreetprov" onChange={handleChange} value={inputs.acctgreetprov} style={{width:"290px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Instructions:</div>
                        <div><textarea rows={2} style={{width:"292px",resize:"none"}} name="acctinstprov" value={inputs.acctinstprov} onChange={handleChange} /></div>
                    </div>

                    <div className={styles.accthdr}>Overflow Toll-Free Account</div>
                    <div className={styles.acctrow4}>
                        <div className={styles.acctcollabel}>Account Number:</div>
                        <div><input type="text" name="acctnumover" onChange={handleChange} value={inputs.acctnumover} style={{width:"60px"}}></input></div>
                        <div className={styles.acctcollabel}>Phone:</div>
                        <div><input type="text" name="acctphoneover" onChange={handleChange} value={formatPhoneNumber(inputs.acctphoneover)} style={{width:"140px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Greeting:</div>
                        <div><input type="text" name="acctgreetover" onChange={handleChange} value={inputs.acctgreetover} style={{width:"290px"}}></input></div>
                    </div>
                    <div className={styles.acctrow2}>
                        <div className={styles.acctcollabel}>Instructions:</div>
                        <div><textarea rows={2} style={{width:"292px",resize:"none"}} name="acctinstover" onChange={handleChange} value={inputs.acctinstover} /></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ShowPracticeMessageTypes = () => {
        const msgtypes = matrix.allmsgtypes || [];
        

        return (
            <React.Fragment>
                <div><select name="acceptsmsgtypes" value={acceptsmsgtypes} onChange={handleAcceptsChange}>
                    <option value="0">All Call Types</option>
                    <option value="1">Urgent Calls ONLY</option>
                    {/*<option value="2">All Calls EXCEPT Urgent</option>*/}
                </select>
                </div><div></div>
                {acceptsmsgtypes !== '1' && msgtypes.map((mt,i)=> {    
                    if (newtypes.indexOf(mt.mtid) < 0) return null;                          
                    return (
                        <div key={"mtopts_"+i} style={{fontSize:"12px"}}>
                            <label><input type="radio" name={"mtopt_"+mt.mtid} value={1} onChange={handleRadioChange} checked={inputs["mtopt_"+mt.mtid]==='1'}></input>Internal</label>
                            <label style={{marginLeft:"6px"}}><input type="radio" name={"mtopt_"+mt.mtid} value={2} onChange={handleRadioChange} checked={inputs["mtopt_"+mt.mtid]==='2'}></input>External</label>
                            <span style={{marginLeft:"8px",fontSize:"14px",fontWeight:"bold"}}>{mt.name}</span>
                        </div>
                    )
                })}
                <div style={{marginTop:"12px"}}></div><div></div>
            </React.Fragment>
        )

    }

    const ShowPracticeDetail = () => {
        const showlegacy = false;
        let msgtypes = matrix.allmsgtypes || [];
        let prac = findPractice(selectedpracid);
        let pracid = 0;
 
        if (prac) {
            pracid = prac.practiceid;
        }
        /*
        if (!prac) {
            return (
                <React.Fragment>
                    <div className={styles.boxtitle}>Practice Details</div>
                    <div className={styles.detailbox}></div>
                </React.Fragment>
            )
        }
        */
        return (
            <React.Fragment>
                <div className={styles.boxtitle}>Practice Details</div>
                <div className={styles.detailbox}>
                    <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>ID:</div>
                        <div className={styles.detailcoltwo}>{pracid}</div>
                    </div>
                    <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>* Practice Name:</div>
                        <div className={styles.detailcoltwo}>
                            <input style={{width:"470px"}} type="text" name="pracname" onChange={handleChange} value={inputs.pracname}></input>
                        </div>
                    </div>
                    {/*
                    <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>Service Options:</div>
                        <div className={styles.detailcolparts}>
                            <div>
                                <label>
                                    <input type="checkbox" name="svcopt_1" value="1" onChange={handleCheckboxChange} checked={inputs.svcopt_1}></input>
                                    After Hours/Paging
                                </label>
                            </div>
                            
                            <div></div>
                            <div>
                                <label>
                                    <input type="checkbox" name="svcopt_2" value="2" onChange={handleCheckboxChange} checked={inputs.svcopt_2}></input>
                                    Front Office Supplemental (Overflow)
                                </label>
                            </div>

                        </div>
                    </div>
                    */}
                    <div className={styles.detailrow} style={{marginTop:"6px",borderTop:"1px dashed black"}}>
                        <div className={styles.detailcolone}>System Options:</div>
                        <div className={styles.detailcolparts} style={{paddingBottom:"10px"}}>
                            <select name="sysopt" value={''+inputs["sysopt"]} onChange={handleChange}>
                                <option value="0"></option>
                                {allsystems.map((sys,i)=> {
                                    return <option key={"sysop_"+i} value={''+sys.stid}>{sys.stdesc}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={styles.detailrow} style={{marginTop:"6px",borderTop:"1px dashed black"}}>
                        <div className={styles.detailcolone}>Message Types:</div>
                        <div className={styles.detailcolparts}>
                            <ShowPracticeMessageTypes />
                            {/*{msgtypes.map((mt,i)=> {
                                return <div key={"mtopts_"+i}><label>
                                    <input type="checkbox" name={"mtopt_"+mt.mtid} value={mt.mtid} onChange={handleCheckboxChange} checked={inputs["mtopt_"+mt.mtid]}></input>

                                    {mt.name}
                                </label></div>
                                
                                return (
                                    <div key={"mtopts_"+i} style={{fontSize:"12px"}}>
                                        <label><input type="radio" name={"mtopt_"+mt.mtid} value={0} onChange={handleRadioChange} checked={inputs["mtopt_"+mt.mtid]==='0'}></input>N/A</label>
                                        <label><input type="radio" name={"mtopt_"+mt.mtid} value={1} onChange={handleRadioChange} checked={inputs["mtopt_"+mt.mtid]==='1'}></input>Int</label>
                                        <label><input type="radio" name={"mtopt_"+mt.mtid} value={2} onChange={handleRadioChange} checked={inputs["mtopt_"+mt.mtid]==='2'}></input>Ext</label>
                                        <span style={{marginLeft:"8px",fontSize:"14px"}}>{mt.name}</span>
                                    </div>
                                )
                            })}
                            */}
                        </div>
                    </div>
                    {showlegacy && <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>Patient Authorized Pages:</div>
                        <div className={styles.detailcoltwo}>
                            <select name="selptauth" onChange={handleChange} value={inputs.selptauth} style={{width:"310px"}}>
                            {Object.entries(PTAUTHTYPES).map( ([key, val]) => {
                                return <option value={key}>{val}</option> 
                            })}
                            </select>
                        </div>
                    </div>}
                    {showlegacy && <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>Rx Fax Template:</div>
                        <div className={styles.detailcoltwo}>
                            <select name="selrxtemp" onChange={handleChange} value={inputs.selrxtemp} style={{width:"310px"}}>
                                <option value="0"></option>
                            </select>
                        </div>
                    </div>}
                    <div className={styles.detailrow} style={{marginTop:"1px",borderTop:"1px dashed black"}}>
                        <div className={styles.detailcolone}>Accounts:</div>
                        <div className={styles.detailcoltwo}>
                            <label>
                                <input type="checkbox" name="cbaccts" onChange={handleCheckboxChange} checked={inputs.cbaccts}></input>
                                Check to define accounts per location.
                            </label>
                        </div>
                    </div>
                    <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>Passwords:</div>
                        <div className={styles.detailcoltwo}>
                            <label>
                                <input type="checkbox" name="cbpwd" onChange={handleCheckboxChange} checked={inputs.cbpwd} ></input>
                                Check to have passwords expire after 60 days.
                            </label>
                        </div>
                    </div>
                    <div className={styles.detailrow}>
                        <div className={styles.detailcolone}>Active:</div>
                        <div className={styles.detailcoltwo}>
                            <label>
                                <input type="checkbox" name="cbaccess" onChange={handleCheckboxChange} checked={inputs.cbaccess}></input>
                                Check to allow this practice system access.
                            </label>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

    const ShowPracticeAdmin = () => {
        /*
        let prac = findPractice(selectedpracid);
        if (!prac) {
            return (
                <React.Fragment>
                    <div className={styles.boxtitle}>Administrator</div>                
                    <div className={styles.adminbox}></div>
                </React.Fragment>
            )
        }
        */
        return (
            <React.Fragment>
                <div className={styles.boxtitle}>Administrator</div>            
                <div className={styles.adminbox}>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>* Administrator Name:</div>
                        <div className={styles.admincolparts}>
                            <div>
                                <span style={{marginLeft:"8px",fontWeight:"bold",paddingRight:"6px"}}>First:</span>
                                <input type="text" name="firstname" onChange={handleChange} value={inputs.firstname} style={{width:"140px"}}></input>
                            </div>
                            <div style={{fontWeight:"bold",textAlign:"right",paddingRight:"6px"}}>MI:</div>
                            <div><input type="text" name="middleinitial" onChange={handleChange} value={inputs.middleinitial} style={{width:"50px"}}></input></div>
                            <div style={{fontWeight:"bold",paddingRight:"6px"}}>Last:</div>
                            <div><input type="text" name="lastname" onChange={handleChange} value={inputs.lastname} style={{width:"150px"}}></input></div>
                        </div>
                    </div>                
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>* Phone:</div>
                        <div className={styles.admincolparts}>
                            <div><input type="text" name="phone" onChange={handleChange} value={formatPhoneNumber(inputs.phone)} style={{width:"190px"}}></input></div>
                            <div style={{fontWeight:"bold",textAlign:"right",paddingRight:"6px"}}>Ext:</div>
                            <div><input type="text" name="phoneext" onChange={handleChange} value={inputs.phoneext} style={{width:"50px"}}></input></div>
                        </div>
                    </div>                    
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>Fax:</div>
                        <div className={styles.admincoltwo}>
                            <div><input type="text" name="fax" onChange={handleChange} value={inputs.fax} style={{width:"190px"}}></input></div>
                        </div>
                    </div>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>Email:</div>
                        <div className={styles.admincoltwo}>
                            <div><input type="text" name="email" onChange={handleChange} value={inputs.email} style={{width:"190px"}}></input></div>
                        </div>
                    </div>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>* User ID:</div>
                        <div className={styles.admincoltwo}>
                            <div><input type="text" name="username" autoComplete="off" onChange={handleChange} value={inputs.username} style={{width:"190px"}}></input></div>
                        </div>
                    </div>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>Password:</div>
                        <div className={styles.admincoltwo}>
                            <div><input type="password" name="password" autoComplete="new-password" onChange={handleChange} value={inputs.password} style={{width:"190px"}}></input></div>
                        </div>
                    </div>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}>Repeat Password:</div>
                        <div className={styles.admincoltwo}>
                            <div><input type="password" name="password2" autoComplete="new-password" onChange={handleChange} value={inputs.password2} style={{width:"190px"}}></input></div>
                        </div>
                    </div>
                    <div className={styles.adminrow}>
                        <div className={styles.admincolone}></div>
                        <div className={styles.admincoltwo}>
                            <div>
                                <label>
                                    <input type="checkbox" name="cbforce" onChange={handleCheckboxChange} checked={inputs.cbforce}></input>
                                    Check to force this user to change their password at next login.
                                </label>
                            </div>
                        </div>
                    </div>                                                                                                                                  
                </div>
            </React.Fragment>
        )
    }



    const addNewCancel = (e) => {
        setAddNew(false);
    }

    const addNewConfirm = (desc) => {
        setAddNew(false);
        let result = false;
        let prac = findPractice(selectedpracid);
        let locations = [];
        if (prac) locations = prac.locations || [];
        let desclow = desc.toLowerCase();
        locations.every((loc) => {
            if (loc.name.toLowerCase()===desclow) {
                result = true;
                newacctname = desc;
                setShowErr(true);
                return false;
            }
            return true;
        })

        const dataservice = new DataService({guid:props.globals.sessionguid});
        const obj = {name:desc};
        dataservice.createlocation(selectedpracid,props.globals.useraccountid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return
                }
            }
            let loc = data.LOCATION || {};
            let locid = 0;
            if (Object.keys(loc).length > 0) {
                locid = loc.locationid || 0;
                let newpracs = [];
                practices.forEach((p)=> {
                    let pid = p.practiceid;
                    if (pid === selectedpracid) {
                        let newp = JSON.parse(JSON.stringify(p));
                        newp.locations.push(loc);
                        newpracs.push(newp);
                    } else {
                        newpracs.push(JSON.parse(JSON.stringify(p)));
                    }
                })

                setPractices(newpracs);
                let obj = fillInputs(currentpracticeid);
                originputs = JSON.parse(JSON.stringify(obj));
                setShowRowChange(false);
                setDirtyFlag(false);
                setSelectedLocation(locid);
                currentlocationid = locid;
                doLocChangeOK(null);
                props.methods.SetPracticeRefresh(true);  
            }
            
        })
    }

    const showErrOK = (e) => {
        setShowErr(false);
        setAddNew(true);
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Practice Management</div>
                <div className={styles.page}>
                    <div>
                        {ShowPractices()}
                        {ShowAccounts()}
                    </div>
                    <div>
                        {ShowPracticeDetail()}
                        {ShowPracticeAdmin()}
                    </div>
                </div>
                <div style={{width:"560px",marginLeft:"360px",marginTop:"10px",textAlign:"center",display:"flex"}}>
                <GenButton width="160" buttontext="Save" disabled={!dirtyflag} onclick={doSave} />
                <div style={{marginLeft:"30px"}}>
                <GenButton width="160" buttontext="Add New" disabled={selectedpracid===0} onclick={handleAddNew} />
                </div>
            </div>
            {showrowchange && <GenConfirm onCancel={practiceSelectCancel} 
            onConfirm={practiceSelectOK}
            confirmText={"Ok"} 
            width={500}
            text={"Practice has not been saved.\nPress Ok to change WITHOUT saving, Cancel to go back and save changes."}
            />
            }
            {showlocchange && <GenConfirm onCancel={doLocChangeCancel} 
            onConfirm={doLocChangeOK}
            confirmText={"Ok"} 
            width={500}
            text={"Practice/Location has not been saved.\nPress Ok to change WITHOUT saving, Cancel to go back and save changes."}
            />
            }
            {addnew && <GenPrompt text="New Account Name:" onConfirm={addNewConfirm} onCancel={addNewCancel} inpvalue={newacctname} width={300} />}
            {showerr && <GenMsgBox text="Duplicate Account Name.<br/>Please change the name." onOK={showErrOK} />}
            </div>
        </React.Fragment>
    )
}

export default PracticeMgmt;