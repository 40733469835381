import React from "react";
import styles from "./GenPrompt.module.scss";

const GenPrompt = (props) => {
    const [inpvalue,setInpValue] = React.useState(props.inpvalue || "");
    const cancelText = props.cancelText || "Cancel";
    const confirmText = props.confirmText || "Ok";

    const doCancel = (e) => {}
    const doConfirm = (e) => {}
    const doBdClick = (e) => {}
    const handleChange = (e) => {
        setInpValue(e.target.value);
    }
    const onCancel = props.onCancel || doCancel;
    const onConfirm  = (e) => {
        const cb = props.onConfirm || doConfirm;
        cb(inpvalue);
    }
    const onBdClick = props.onBdClick || doBdClick;
    const wt = props.width || (props.text.length * 10);
    const btwt = props.buttonwidth || 130;
    const lines = props.text.split('\n');

    const onKey = (e) => {
        console.log("Key pressed was "+e.key);
        if (e.key==='Enter') {
            onConfirm(e);
        }
        if (e.key==='Escape') {
            console.log("Calling cancel");
            onCancel(e);
        }
    }
    const canKey = (e) => {
        if (e.key==='Enter' || e.key==='Escape') {
            onCancel(e);
        }
    }

    return (
        <React.Fragment>
            <div className={styles.backdrop} onClick={onBdClick} />
            <div className={styles.modal} style={{width:`${wt}px`}} onKeyDown={onKey}>
                {lines.map((ln,i) => {
                    return <p key={'ln_'+i}>{ln}</p>
                })}
                <div>
                    <input type="text" maxLength="100" name="prompt" value={inpvalue} onChange={handleChange} onKeyDown={onKey} autoFocus={true} />
                </div>
                <div className={styles.buttons} onKeyDown={onKey}>
                    <button className={styles.btn} styles={{width:`${btwt}px`}} onClick={onConfirm} onKeyDown={onKey}>
                        {confirmText}
                    </button>
                    <button className={styles.btnalt} styles={{width:`${btwt}px`}} onClick={onCancel} onKeyDown={canKey}>
                        {cancelText}
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GenPrompt;