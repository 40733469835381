import React from "react";
import styles from "./MessageExtraInfo.module.scss";
import { CALLTYPE_TRANSFER_CALL } from "../AppConstants";
const MessageExtraInfo = (props) => {
    const pinp = props.inputs || {};
    const [inputs,setInputs] = React.useState({...pinp});
    const mtype = props.mtype || 0;
    const calltype = props.calltype || 0;
    const extrainfo = props.extrainfo || [];
    
    const cbDummy = (nm,v) => {

    }

    const callback = props.onChange || cbDummy;

    const HandleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        callback(name,value);
    }

    if (mtype === 0 || calltype === 0 || calltype === CALLTYPE_TRANSFER_CALL) {
        return null;
    } else {
        return (
            <React.Fragment>
                {extrainfo.map((ex,i)=> {
                    const nm = "ei_"+ex.infotype;
                    if (''+ex.msgtype !== ''+mtype && ex.msgtype !== 0) return null;
                    return (
                        <div className={styles.formctrl}>
                        <div className={styles.formlabel}>{ex.desc}:</div>
                            <input className={styles.forminput} type="text" name={nm} maxLength="50" onChange={HandleChange} value={inputs[nm]} />
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }
}

export default MessageExtraInfo