import React from "react";
import styles from "./IconOutlinedUser.module.scss";

const IconOutlinedUser = () => {
  return (
    <div className={styles.iconOutlinedUser}>
      <img
        alt=""
        className={styles.vector}
        src="https://static.overlay-tech.com/assets/ec6cdc60-5537-4fd5-aeb4-cff42c447116.svg"
      />
    </div>
  );
};

export default IconOutlinedUser;