import React from "react";
//import styles from "./OnCallManager.module.scss";
import {DataService} from './../../store/DataService';
//import { FRAMEWORK_INTERNAL, FRAMEWORK_EXTERNAL } from "../../AppConstants";
import OnCallDay from "./OnCallDay";
import OnCallMonth from "./OnCallMonth";
import GenConfirm from "./../../components/GenConfirm";
import {CompareDates, Date24, DateOnly, CompareSameDayTimes, DateBetween} from './../../AppConstants';

const MONTH_VIEW = 0;
const DAY_VIEW = 1;


//var dirtyflag = false;
var nextview = MONTH_VIEW;
var undodata = [];
var clipboard = {prical:[],bkucal:[]};

const OnCallManager = (props) => {
    const dataservice = new DataService({guid:props.globals.sessionguid});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [oncalldata,setOnCallData] = React.useState({oncallgroups:[]});
    const [groupoptions,setGroupOptions] = React.useState([]);
    const [view,setView] = React.useState(MONTH_VIEW);
    const [showdirty,setShowDirty] = React.useState(false);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [pastedaylist,setPasteDayList] = React.useState([]);
    const [showpaste,setShowPaste] = React.useState(false);
    const [cleardaylist,setClearDayList] = React.useState([]);
    const [showclear,setShowClear] = React.useState(false);

    /*
    (()=> {
        window.addEventListener("beforeunload",(evt)=> {
            console.log("before unload "+evt.target.name);
            if (props.methods.DirtyFields()===1) {
                alert("You have unsaved data");
                evt.preventDefault();
                return;
            }
        });
    })();    
    */

    React.useEffect(()=> {
        let isMounted = true;
        if (refreshneeded) setRefreshNeeded(false);
        
        dataservice.getoncall(props.globals.practiceid,Date24(props.globals.selecteddate),"","").then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                }
            } else {
                if (isMounted) {
                    let opts=[];
                    
                    data.oncallgroups.forEach(ocg => {
                        let selval = ocg.OnCallID===props.globals.oncallid;
                        opts.push({value:ocg.OnCallID,text:ocg.Description,selected:selval});
                        let cals = ocg.PrimaryCalendars.concat(ocg.BackupCalendars);
                        cals.forEach(obj=> {
                            obj.StartDateTime = Date24(obj.StartDateTime);
                            obj.EndDateTime = Date24(obj.EndDateTime);
                        })
    
                    });
                    if (opts.length === 1) {
                        opts[0].selected = true;
                        props.methods.SetOnCallid(opts[0].value);
                    }
                    setGroupOptions(opts);
                    setOnCallData(data);
                    setDirtyFlag(false);
                }
            }
        })
        
        return () => {
            isMounted = false 
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.globals.selecteddate,props.globals.practiceid,props.globals.oncallid,refreshneeded])

    const SaveOnCallCalendar = (newocd) => {
        console.log("Entering saveoncallcal");
        const cg = props.globals.oncallid;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        newocd.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let cals = ocg.PrimaryCalendars.concat(ocg.BackupCalendars);
                console.log("Found calendar - calling saveoncallcalendar");
                dataservice.saveoncallcalendar(props.globals.practiceid,cg,props.globals.useraccountid,cals).then((data) => {
                    if (data.ERROR) {
                        if (data.ERROR.length) {
                            alert(data.ERROR);
                            return;
                        }
                    }
                    setDirtyFlag(false);
                    props.methods.SetDirtyData(false);
                    undodata = [];
                    clipboard = {prical:[],bkucal:[]};
                })
            }
        })
        console.log("Leaving saveoncallcal");
    }

    const SaveOnCall = () => {
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        const cg = props.globals.oncallid;
        //const compareopts = {month:"numeric",day:"numeric",year:"numeric"};
        //const saveopts = {month:"2-digit",day:"2-digit",year:"numeric",hour:"2-digit",minute:"2-digit",second:"2-digit",hour12:false};

        const savedate = DateOnly(props.globals.selecteddate);
        let calendar = [];
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let grplist = ocg.GroupList;
                let cals = ocg.PrimaryCalendars.concat(ocg.BackupCalendars);
                cals.forEach(obj => {
                    
                    let startdt = DateOnly(obj.StartDateTime);
                    if (startdt===savedate) {
                        let responcallid = 0;
                        let respprovid = 0;
                        let nm = obj.ResourceName;
                        console.log("SOC ResName "+nm);
                        if (nm.length > 0) {
                            let grpobj = grplist.find(o => o.Description.trim() === nm);
                            if (grpobj) {
                                console.log("SOC ResName find "+grpobj+", keys "+Object.keys(grpobj));
                                respprovid = grpobj.ResourceGroupId.replace("R","");
                            }
                        }
                        nm = obj.GroupDescription;
                        console.log("SOC GrpDes "+nm);
                        if (nm.length > 0) {
                            let grpobj = grplist.find(o => o.Description.trim() === nm);
                            console.log("SOC GrpDes find "+grpobj);
                            if (grpobj) {
                                responcallid = grpobj.ResourceGroupId.replace("G","");
                            }
                        }
                        let sd = Date24(obj.StartDateTime);
                        let ed = Date24(obj.EndDateTime);
                        console.log("SD "+sd+", ED "+ed);
                        calendar.push(
                            {responcallid:responcallid,
                                respprovid:respprovid,
                                backupsequence:obj.BackupSequence,
                                startdate:sd,
                                enddate:ed
                            });
                    }
                });
                dataservice.saveoncall(props.globals.practiceid,cg,props.globals.selecteddate,props.globals.useraccountid,calendar).then((data) => {
                    if (data.ERROR) {
                        alert(data.ERROR);
                    } else {
                        setDirtyFlag(false);
                        props.methods.SetDirtyData(false);
                        props.methods.SetDirtyField("oncallpaste",false);
                        setRefreshNeeded(true);
                    }
                });
            }
        });

    }

    const PushUndo = () => {
        console.log("PUSHUNDO "+undodata.length);
        undodata.push(JSON.parse(JSON.stringify(oncalldata)));
        setDirtyFlag(true);
        props.methods.SetDirtyData(true);
    }

    const PopUndo = () => {
        //console.log("POPUNDO "+undodata.length);
        if (undodata.length > 0) {
            let newdata = undodata.pop();
            if (undodata.length > 0) {
                setDirtyFlag(true);
            } else {
                setDirtyFlag(false);
                props.methods.SetDirtyData(false);
            }
            setOnCallData(newdata);
        } else {
            setDirtyFlag(false);
            props.methods.SetDirtyData(false);
        }
    }

    const CopyToClipboard = () => {
        console.log("Copy to clipboard");
        clipboard={prical:[],bkucal:[]};
        let prical = [];
        let bkucal = [];
        const seldate = DateOnly(props.globals.selecteddate);
        const cg = props.globals.oncallid;
        oncalldata.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let cal = ocg.PrimaryCalendars;
                cal.forEach(obj=> {
                    if (DateOnly(obj.StartDateTime)===seldate) {
                        prical.push(JSON.parse(JSON.stringify(obj)));
                    }
                });
                cal = ocg.BackupCalendars;
                cal.forEach(obj=> {
                    if (DateOnly(obj.StartDateTime)===seldate) {
                        bkucal.push(JSON.parse(JSON.stringify(obj)));
                    }
                })
            }
        });
        clipboard = {prical:prical,bkucal:bkucal};
        setRefreshNeeded(true);
    }

    const ClearSelected = (daylist) => {
        if (!daylist.length) return;
        setClearDayList(daylist);
        setShowClear(true);
    }

    const ClearCancel = () => {
        setShowClear(false);
    }

    const ClearConfirm = () => {
        //let prical = clipboard.prical || [];
        //let bkucal = clipboard.bkucal || [];
        //if (prical.length < 1 && bkucal.length < 1) return;
        PushUndo();
        let newoncal = JSON.parse(JSON.stringify(oncalldata));
        const cg = props.globals.oncallid;
        var cal = [];
        newoncal.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let pridels = [];
                let bkudels = [];
                cal = ocg.PrimaryCalendars;
                cal.forEach((obj,i)=> {
                    if (cleardaylist[obj.CalDay]===true) {
                        obj.GroupDescription="";
                        obj.ResourceName="";
                        pridels.push(i);
                    }
                });
                ocg.PrimaryCalendars = cal;

                cal = ocg.BackupCalendars;
                cal.forEach((obj,i)=> {
                    if (cleardaylist[obj.CalDay]===true) {
                        obj.GroupDescription="";
                        obj.ResourceName = 0;
                        bkudels.push(i);
                    }
                });
                ocg.BackupCalendars = cal;
            }
        });
        console.log("End of clear - setting on call data");
        setOnCallData(newoncal);
        props.methods.SetDirtyData(true);
        props.methods.SetDirtyField("oncallclear",true);
        setShowClear(false);
        console.log("Clear: Going to SaveOnCallCalendar");
        SaveOnCallCalendar(newoncal);
        newoncal.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let pridels = [];
                let bkudels = [];
                cal = ocg.PrimaryCalendars;
                cal.forEach((obj,i)=> {
                    if (cleardaylist[obj.CalDay]===true) {
                        obj.GroupDescription="";
                        obj.ResourceName="";
                        pridels.push(i);
                    }
                });
                while (pridels.length > 0) {
                    let index=pridels.pop();
                    cal.splice(index,1);
                }
                ocg.PrimaryCalendars = cal;

                cal = ocg.BackupCalendars;
                cal.forEach((obj,i)=> {
                    if (cleardaylist[obj.CalDay]===true) {
                        obj.GroupDescription="";
                        obj.ResourceName = 0;
                        bkudels.push(i);
                    }
                });
                while (bkudels.length > 0) {
                    let index=bkudels.pop();
                    cal.splice(index,1);
                }
                ocg.BackupCalendars = cal;
            }
        });

        
        setOnCallData(newoncal);
    }

    const PasteFromClipboard = (daylist) => {
        console.log("Paste from clipboard");
        if (!daylist.length) return;
        let prical = clipboard.prical || [];
        let bkucal = clipboard.bkucal || [];
        if (prical.length < 1 && bkucal.length < 1) return;
        setPasteDayList(daylist);
        setShowPaste(true);
    }

    const PasteConfirm = () => {
        let prical = clipboard.prical || [];
        let bkucal = clipboard.bkucal || [];
        if (prical.length < 1 && bkucal.length < 1) return;
        PushUndo();
        let newoncal = JSON.parse(JSON.stringify(oncalldata));
        let pMonth = props.globals.selecteddate.getMonth();
        let pYear = props.globals.selecteddate.getFullYear();
        const cg = props.globals.oncallid;
        var cal = [];
        newoncal.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let dels = [];
                cal = ocg.PrimaryCalendars;
                cal.forEach((obj,i)=> {
                    if (pastedaylist[obj.CalDay]===true) {
                        dels.push(i);
                    }
                });
                while (dels.length > 0) {
                    let index=dels.pop();
                    cal.splice(index,1);
                }
                for (let dy=1;dy<pastedaylist.length;dy++) {
                    if (pastedaylist[dy]===true) {
                        // eslint-disable-next-line no-loop-func
                        prical.forEach(obj=> {
                            let newobj = JSON.parse(JSON.stringify(obj));
                            let dt = new Date(newobj.StartDateTime);
                            dt.setMonth(pMonth);
                            dt.setFullYear(pYear);
                            dt.setDate(dy);
                            newobj.StartDateTime = Date24(dt);
                            dt = new Date(newobj.EndDateTime);
                            dt.setMonth(pMonth);
                            dt.setFullYear(pYear);
                            dt.setDate(dy);
                            newobj.EndDateTime = Date24(dt);
                            newobj.CalDay=dy;
                            cal.push(newobj);
                        });
                    }
                }
                ocg.PrimaryCalendars = cal;

                cal = ocg.BackupCalendars;
                cal.forEach((obj,i)=> {
                    if (pastedaylist[obj.CalDay]===true) dels.push(i);
                });
                while (dels.length > 0) {
                    let index=dels.pop();
                    cal.splice(index,1);
                }
                for (let dy=1;dy<pastedaylist.length;dy++) {
                    if (pastedaylist[dy]===true) {
                        // eslint-disable-next-line no-loop-func
                        bkucal.forEach(obj=> {
                            let newobj = JSON.parse(JSON.stringify(obj));
                            let dt = new Date(newobj.StartDateTime);
                            dt.setMonth(pMonth);
                            dt.setFullYear(pYear);
                            dt.setDate(dy);
                            newobj.StartDateTime = Date24(dt);
                            dt = new Date(newobj.EndDateTime);
                            dt.setMonth(pMonth);
                            dt.setFullYear(pYear);
                            dt.setDate(dy);
                            newobj.EndDateTime = Date24(dt);
                            newobj.CalDay=dy;
                            cal.push(newobj);
                        });
                    }
                }
                ocg.BackupCalendars = cal;
            }
        });
        console.log("End of paste - setting on call data");
        setOnCallData(newoncal);
        props.methods.SetDirtyData(true);
        props.methods.SetDirtyField("oncallpaste",true);
        setShowPaste(false);
        console.log("Going to SaveOnCallCalendar");
        SaveOnCallCalendar(newoncal);
    }

    const PasteCancel = (e) => {
        setShowPaste(false);
    }

    const ClipboardReady = () => {
        const pcal = clipboard.prical || [];
        const bcal = clipboard.bkucal || [];
        return (pcal.length + bcal.length);
    }

    const ClearPrimary = () => {
        console.log("Clear Primary");
        const cg = props.globals.oncallid;
        PushUndo();
        let thedate = DateOnly(props.globals.selecteddate);
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let pc = ocg.PrimaryCalendars;
                let indexes = [];
                for (let i=0;i<pc.length;i++) {
                    if (DateOnly(pc[i].StartDateTime)===thedate) {
                        indexes.push(i);
                    }
                }
                while (indexes.length > 0) {
                    pc.splice(indexes.pop(),1);
                }
                ocg.PrimaryCalendars = pc;
            }
        });
        setOnCallData(newoncall);
    }

    const ClearBackup = (bknum) => {
        console.log("Clear Backup "+bknum);
        const cg = props.globals.oncallid;
        let startdt = new Date(props.globals.selecteddate);
        let enddt = new Date(props.globals.selecteddate);
        startdt.setHours(0);
        startdt.setMinutes(0);
        startdt.setSeconds(0);
        enddt.setHours(23);
        enddt.setMinutes(59);
        enddt.setSeconds(0);

        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        let thedate = DateOnly(startdt);
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                ocg.BackupCalendars.forEach((bc)=> {
                    if (bc.BackupSequence===bknum && DateOnly(bc.StartDateTime)===thedate) {
                        bc.ResourceName="";
                        bc.Index=9999;
                        bc.StartDateTime=startdt;
                        bc.EndDateTime=enddt;
                    }
                })
            }
        });
        setOnCallData(newoncall);
    }

    const DelBackup = (bknum) => {
        console.log("Delete Backup "+bknum);
        const cg = props.globals.oncallid;
        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        let thedate = DateOnly(props.globals.selecteddate);
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let bku = ocg.BackupCalendars.filter(obj=>DateOnly(obj.StartDateTime)===thedate && obj.BackupSequence !== bknum);
                bku.forEach((bobj) => {
                    if (DateOnly(bobj.StartDateTime)===thedate && bobj.BackupSequence > bknum) bobj.BackupSequence -= 1;
                })
                ocg.BackupCalendars = bku;
            }
        });
        setOnCallData(newoncall);
    }

    const DelResource = (cell) => {
        console.log("Delete Resource");
        const cg = props.globals.oncallid;
        const bknum = cell.cnum;
        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let calendar = ocg.PrimaryCalendars;
                if (bknum > 1) calendar = ocg.BackupCalendars;

                let newcal = [];
                calendar.forEach(obj => {
                    if (obj.BackupSequence===bknum &&
                        obj.ResourceName===cell.lastname &&
                        obj.GroupDescription===cell.groupname &&
                        obj.StartDateTime.valueOf()===cell.startdate.valueOf() &&
                        obj.EndDateTime.valueOf()===cell.enddate.valueOf()) {

                        } else {
                            newcal.push(obj);
                        }
                })

                if (bknum > 1) {
                    ocg.BackupCalendars = newcal;
                } else {
                    ocg.PrimaryCalendars = newcal;
                }
            }
        });
        setOnCallData(newoncall);

    }

    const SetEndDateTime = (cell) => {
        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        const cg = props.globals.oncallid;
        let bknum = cell.bknum;
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                let calendar = ocg.PrimaryCalendars;
                if (bknum > 1) calendar = ocg.BackupCalendars;
                const memname = cell.name;
                const sd = new Date(cell.StartDate);
                const ed = new Date(cell.EndDate);
                calendar.forEach(obj=> {
                    let resname = obj.ResourceName || obj.GroupDescription;
                    if (CompareDates(sd,obj.StartDateTime)===0 && CompareDates(ed,obj.EndDateTime)===0 && memname===resname) {
                        obj.EndDateTime = cell.NewEnd;
                    }
                });
            }
        });
        setOnCallData(newoncall);
    }

    const AddResource = (cell) => {
        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        let maxindex = 0;
        const cg = props.globals.oncallid;
        const bknum = cell.cnum;
        console.log("AddRes lastname "+cell.lastname+", Groupname "+cell.groupname);
        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                ocg.PrimaryCalendars.forEach(obj => {
                    if (obj.Index < 9999 && obj.Index > maxindex) maxindex=obj.Index;
                });
                ocg.BackupCalendars.forEach(obj => {
                    if (obj.Index < 9999 && obj.Index > maxindex) maxindex=obj.Index;
                });
                maxindex += 1;
                let calendar = ocg.PrimaryCalendars;
                if (bknum > 1) calendar = ocg.BackupCalendars;

                let sd = new Date(cell.startdate);
                sd = sd.toLocaleDateString(undefined,{month:"2-digit",day:"2-digit",year:"numeric",hour:"2-digit",minute:"2-digit",second:"2-digit",hourCycle: "h23"});
                let prevdt = new Date(cell.startdate);
                prevdt.setMinutes(prevdt.getMinutes()-1);
                prevdt = prevdt.toLocaleDateString(undefined,{month:"2-digit",day:"2-digit",year:"numeric",hour:"2-digit",minute:"2-digit",second:"2-digit",hourCycle: "h23"});
                let ed = new Date(cell.enddate);
                let addnew = true;
                calendar.forEach(obj=> {
                    if (DateBetween(sd,obj.StartDateTime,obj.EndDateTime)===0 && obj.BackupSequence===bknum) {
                        let nIndex = obj.index || 0;
                        if (nIndex < 1) nIndex = obj.Index || 0;
                        if (nIndex < 9999 && CompareSameDayTimes(sd,obj.StartDateTime) === 0) {
                            console.log("Same Start Day/Time");
                            addnew = false;
                            obj.ResourceName = cell.lastname;
                            obj.GroupDescription = cell.groupname;
                            obj.StartDateTime = Date24(sd);
                            obj.EndDateTime = Date24(ed);
                            obj.OnCallID = cg;
                        } else {
                            console.log("Setting to previous minute "+prevdt);
                            obj.EndDateTime=prevdt;    
                        }
                    }
                    if (CompareSameDayTimes(sd,obj.StartDateTime)===-1 && CompareSameDayTimes(ed,obj.StartDateTime)===1 && obj.BackupSequence===bknum) {
                        console.log("CSDT ed "+ed+", SDT "+obj.StartDateTime+", Name "+(obj.ResourceName || obj.GroupDescription));
                        ed = new Date(obj.StartDateTime);
                        ed.setMinutes(ed.getMinutes()-1);
                    }
                });
                if (addnew) {
                    let newres = {
                        Index:maxindex,
                        ResourceName:cell.lastname,
                        GroupDescription:cell.groupname,
                        CalDay:props.globals.selecteddate.getDate(),
                        OnCallID:cg,
                        StartDateTime:Date24(sd),
                        EndDateTime:Date24(ed),
                        BackupSequence:bknum
                    }
            
                    calendar.push(newres);
    
                }

                if (bknum > 1) {
                    ocg.BackupCalendars = calendar;
                } else {
                    ocg.PrimaryCalendars = calendar;
                }
            }
        });
        setOnCallData(newoncall);
        
    }

    const AddBackup = (e) => {
        console.log("Add Backup");
        const cg = props.globals.oncallid;
        PushUndo();
        let newoncall = JSON.parse(JSON.stringify(oncalldata));
        let startdt = new Date(props.globals.selecteddate);
        let enddt = new Date(props.globals.selecteddate);
        startdt.setHours(0);
        startdt.setMinutes(0);
        startdt.setSeconds(0);
        let thedate = DateOnly(startdt)
        enddt.setHours(23);
        enddt.setMinutes(59);
        enddt.setSeconds(0);
        let maxseq = 1;

        newoncall.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                ocg.BackupCalendars.forEach((obj) => {

                    if (DateOnly(obj.StartDateTime)===thedate && obj.BackupSequence > maxseq) {
                        maxseq = obj.BackupSequence;
                    }
                })
                maxseq += 1;
                ocg.BackupCalendars.push(
                    {
                    Index:9999,
                    ResourceName:"",
                    GroupDescription:"",
                    CalDay:startdt.getDate(),
                    OnCallID:props.globals.oncallid,
                    StartDateTime:startdt,
                    EndDateTime:enddt,
                    BackupSequence:maxseq
                    }
                )
            }
        });
        setOnCallData(newoncall);
        
    }

    const DoUndo = () => {
        PopUndo();
    }

    const GroupChange = (value) => {
        const oldgrp = groupoptions.find((o)=>o.selected===true);
        if (oldgrp && oldgrp.value===value) return;
        let opts = groupoptions.map((o,i) => {
            return {value:o.value,text:o.text,selected:o.value===value}
        });
        
        undodata = [];
        clipboard = {prical:[],bkucal:[]};
        setGroupOptions(opts);
        props.methods.SetOnCallid(value);
        
    }

    const DayView = (e) => {
        //console.log("DayView seldate "+props.globals.selecteddate);
        nextview = DAY_VIEW;
        if (dirtyflag) {
            setShowDirty(true);
        } else {
            setView(DAY_VIEW);
        }
    }

    const MonthView = (e) => {
        nextview = MONTH_VIEW;
        if (dirtyflag) {
            setShowDirty(true);
        } else {
            setView(MONTH_VIEW);
        }
    }

    const dirtyCancel = (e) => {
        setShowDirty(false);
    }

    const dirtyConfirm = (e) => {
        setDirtyFlag(false);
        if (undodata.length > 0) {
            let newdata = undodata[0];
            setOnCallData(newdata);
            undodata = [];
        }
        setShowDirty(false);
        setView(nextview);
    }

    const ShowDirty = () => {
        const txt = "You haven't saved your changes.\nSelect Cancel to go back and Save.\nSelect Confirm to disregard changes and continue.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={dirtyCancel}
                onConfirm={dirtyConfirm}
            />
        )
    }

    const ShowPaste = () => {
        const txt = "Are you sure you want to Paste into selected dates?\nSelect Cancel to not Paste.\nSelect Confirm to Paste AND Save calendar.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={PasteCancel}
                onConfirm={PasteConfirm}
            />
        )
    }

    const ShowClear = () => {
        const txt = "Are you sure you want to Clear the selected dates?\nSelect Cancel to not Clear.\nSelect Confirm to Clear AND Save calendar.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={ClearCancel}
                onConfirm={ClearConfirm}
            />
        )
    }

    const DateChange = (v) => {
        if (dirtyflag) {
            setShowDirty(true);
            return;
            /*
            let dt = new Date(v);
            if (dt.getMonth() !== props.globals.selecteddate.getMonth() || dt.getFullYear() !== props.globals.selecteddate.getFullYear()) {
                setShowDirty(true);
                return;
            }
            */
        }
        props.methods.SetSelectedDate(v);
    }
    console.log("OCM refresh");
    return (
        <div>
            {view===MONTH_VIEW && <OnCallMonth 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    onDateChange={DateChange}
                                    onViewChange={DayView}
                                    onGroupChange={GroupChange}
                                    oncalldata={oncalldata}
                                    groupoptions={groupoptions}
                                    isdirty={dirtyflag}
                                    clipboardLen={ClipboardReady()}
                                    onCopy={CopyToClipboard}
                                    onPaste={PasteFromClipboard} 
                                    onClear={ClearSelected}
                                    onUndo={DoUndo}
                                    undolen={undodata.length}
                                    onSave={SaveOnCallCalendar}
                                    />}
            {view===DAY_VIEW && <OnCallDay
                                    globals={props.globals} 
                                    methods={props.methods}
                                    onDateChange={DateChange}
                                    onViewChange={MonthView}
                                    onGroupChange={GroupChange}
                                    onAddBackup={AddBackup}
                                    onClearPrimary={ClearPrimary}
                                    onClearBackup={ClearBackup}
                                    onDeleteBackup={DelBackup}
                                    onDeleteResource={DelResource}
                                    onAddResource={AddResource}
                                    onSave={SaveOnCall}
                                    onUndo={DoUndo}
                                    undolen={undodata.length}
                                    oncalldata={oncalldata}
                                    groupoptions={groupoptions}
                                    isdirty={dirtyflag}
                                    setDirty={setDirtyFlag} 
                                    updateEnd={SetEndDateTime}
                                    />}
            {showdirty && <ShowDirty />}
            {showpaste && <ShowPaste />}
            {showclear && <ShowClear />}
        </div>
    )
}

export default OnCallManager;