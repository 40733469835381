import React from "react";
import styles from "./ApptRequest.module.scss";

const ApptRequest = (props) => {
    const pinp = props.inputs || {};
    const [inputs,setInputs] = React.useState({...pinp});

    const locations = props.locations || [];
    const askdesc = props.askdesc || false;

    const DummyCB = (name,value) => {

    }

    const cbChange = (nm,v) => {
        let obj = {[nm]:v}

        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(nm,v);
    }

    const HandleCheck = (event) => {
        const name = event.target.name;
        let value = event.target.checked;
        
        if (name==='newpatient') {
            if (value===true) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value);
    }
    
    const HandleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name==='okleavemsg') {
            if (value===true) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value); 
    }

    return (
        <React.Fragment>

            <div className={styles.formctrl}>
                <div className={styles.formlabel}>New Patient:</div>
                <label style={{userSelect:"none"}}><input type="checkbox" name="newpatient" onChange={HandleCheck} checked={inputs.newpatient==='Y'} /></label>
                {/*
                <label style={{userSelect:"none"}}><input type="radio" name="newpatient" onChange={HandleChange} value="Y" checked={inputs.newpatient==='Y'} />Yes</label>
                <label style={{marginLeft:"10px",userSelect:"none"}}><input type="radio" name="newpatient" checked={inputs.newpatient==='N'} onChange={HandleChange} value="N" />No</label>
                */}
            </div>
            <div key="ctrl_200A" className={styles.formctrl}>
                <div className={styles.formlabel}>Location:</div>
                <select className={styles.formloc} name="location" onChange={HandleChange} value={inputs.location}>
                        <option value="0">Unknown</option>
                        {locations.map((loc,i)=> {
                            return <option key={"apptlocopt2_"+i} value={loc.locid}>{loc.locname}</option>
                        })}
                </select>
            </div>
            {/*<div className={styles.formblock}>
                <div className={styles.formlabel} style={{marginLeft:"15px"}}>
                    <div>Appt Description:</div>
                    <div className={styles.req} style={{paddingLeft:"8px"}}>*</div>
                </div>
                <div className={styles.formmsg}>
                    <textarea name="apptdesc" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="2" maxLength="2000" onChange={HandleChange} value={inputs.apptdesc}></textarea>
                </div>
            </div>*/}
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Appt Description:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} type="text" name="apptdesc" maxLength="40" onChange={HandleChange} value={inputs.apptdesc} />
            </div>
            <div className={styles.formblock}>
                <div className={styles.formlabel} style={{marginLeft:"15px"}}>
                    <div>Message:</div>
                    <div className={styles.req} style={{paddingLeft:"8px"}}>*</div>
                </div>
                <div className={styles.formmsg}>
                    <textarea name="message" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="5" maxLength="2000" onChange={HandleChange} value={inputs.message}></textarea>
                </div>
            </div>
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Callback Phone:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} type="text" name="dayphone1" maxLength="16" onChange={HandleChange} value={inputs.dayphone1} />
                <span className={styles.formlabelsm} style={{paddingLeft:"14px"}}>Ext:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="dayext1" maxLength="4" onChange={HandleChange} value={inputs.dayext1} />
            </div>
        </React.Fragment>
    )
}

export default ApptRequest;
