import React from "react";
import styles from "./LocationMgmt.module.scss";
import { DataService } from "../../store/DataService";
import {PhoneTest, formatPhoneNumber} from "../../AppConstants";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenMsgBox from "../../components/GenMsgBox";

var originputs = {};
var new_location_id = 0;

const LocationMgmt = (props) => {
    const [locations,setLocations] = React.useState([]);
    const [locid,setLocID] = React.useState(0);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [showrowchange,setShowRowChange] = React.useState(false);
    const [hasoverflow,setHasOverflow] = React.useState(false);
    const [inputs,setInputs] = React.useState({
        locname:"",
        locaddr1:"",
        locaddr2:"",
        loccity:"",
        locstate:"",
        loczip:"",
        locphone:"",
        locfax:"",
        locdir:"",
        locprimary:false,
        loctimezone:"",
        locdst:true,
        lochourinfo:"",
        cpfirst:"",
        cplast:"",
        cphome:"",
        cpcell:"",
        cppager:"",
        cppagertype:"",
        cpnetwork:"",
        cppin:"",
        cpemail:"",
        overflowhandle:"",
        overflowphone:"",
        overflowattempts:0,
        overflowfinal:"",
        hrsfrom_0:"",
        hrsfrom_1:"",
        hrsfrom_2:"",
        hrsfrom_3:"",
        hrsfrom_4:"",
        hrsfrom_5:"",
        hrsfrom_6:"",
        hrsto_0:"",
        hrsto_1:"",
        hrsto_2:"",
        hrsto_3:"",
        hrsto_4:"",
        hrsto_5:"",
        hrsto_6:"",
    })

    const findService = (svcnum) => {
        const svcs = props.globals.practice.services || [];
        let result = false;
        svcs.every((svc)=> {
            if (parseInt(svc)===svcnum) {
                result = true;
                return false;
            }
            return true;
        })
        return result;
    }

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name==='locprimary') value = e.target.checked;
        if (name==='locdst') {
            value = e.target.checked;
            if (value) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let newobj = JSON.parse(JSON.stringify(inputs));
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        setDirtyFlag(!compareObj(newobj,originputs));

    }

    const findPhone = (loc,ctype) => {
        var pn = "";
        if (loc) {
            loc.phone.forEach((phone) => {
                if (phone.contacttypeid===ctype) {
                    pn = phone.phonenumber;
                }
            })
        }
        return pn;
    }

    const fmtPhone = (pn) => {
        /*
        if (pn.length < 10) return pn;
        let ln = 10;
        if (pn.indexOf('(') >= 0) ln += 1;
        if (pn.indexOf(')') >= 0) ln += 1;
        if (pn.indexOf('-') >= 0) ln += 1;
        if (pn.indexOf(' ') >= 0) ln += 1;
        if (pn.length < ln) return pn;
        */
        return formatPhoneNumber(pn); 
    }

    const findPagerType = (loc,ctype) => {
        var pn = "";
        if (loc) {
            loc.phone.forEach((phone) => {
                if (phone.contacttypeid===ctype) {
                    pn = phone.pagertypeid;
                }
            })
        }
        return pn;
    }

    const findOverflowPhone = (loc) => {
        //let handling = loc.overflowhandling || 0;
        //handling = parseInt(handling);
        var retobj = {pn:"",pgt:0};
        let pn = "";
        let pgt = 0;
        let pt = '4';
        pn = findPhone(loc,pt);
        if (!pn.length) {
            pt='3';
            pn = findPhone(loc,pt);
        }
        if (pn.length) {
            pgt = findPagerType(loc,pt);
        }
        retobj.pn = pn;
        retobj.pgt = pgt;
        return retobj;
    }

    const findHoursStart = (loc,dowid) => {
        var st = "";
        if (loc) {
            if (loc.hours) {
                loc.hours.every((hr)=> {
                    if (hr.dowid===dowid) {
                        st = hr.starttime || "";
                        st = st.trim();
                        let sa = st.split(' ');
                        if (sa.length < 2) st += ' AM';
                        return false;
                    }
                    return true;
                })
            }
        }
        return st;
    }

    const findHoursEnd = (loc,dowid) => {
        var et = "";
        if (loc) {
            if (loc.hours) {
                loc.hours.every((hr)=> {
                    if (hr.dowid===dowid) {
                        et = hr.endtime || "";
                        et = et.trim();
                        let sa = et.split(' ');
                        if (sa.length < 2) et += ' PM';
                        return false;
                    }
                    return true;
                })

            }
        }
        return et;
    }


    const setInputsFromLocation = (loc) => {
        if (!loc) return;
        var obj = {
            locationid:loc.locationid,
            locname:loc.name,
            locaddr1:loc.address1 || "",
            locaddr2:loc.address2 || "",
            loccity:loc.city || "",
            locstate:loc.state || "",
            loczip:loc.zipcode || "",
            locdir:loc.directions || "",
            locprimary:loc.primaryloc,
            locdst:loc.dstparticipates,
            loctimezone:loc.timezone,
            locphone:findPhone(loc,'1'),
            lochourinfo:loc.comments || "",
            locfax:findPhone(loc,'2'),
            cpfirst:loc.contactfirstname,
            cplast:loc.contactlastname,
            cphome:loc.contacthomephone,
            cpcell:loc.contactcellphone,
            cppager:loc.contactpager,
            cppagertype:loc.contactpagertype,
            cpnetwork:"",
            cppin:"",
            cpemail:"",    
            overflowphone:findOverflowPhone(loc).pn,
            overflowpagertype:findOverflowPhone(loc).pgt,
            overflowhandle:loc.overflowhandling || 0,
            overflowattempts:loc.overflowattempts || 0,
            overflowfinal:loc.overflowinstruct || "",
            hrsfrom_0:findHoursStart(loc,0),
            hrsfrom_1:findHoursStart(loc,1),
            hrsfrom_2:findHoursStart(loc,2),
            hrsfrom_3:findHoursStart(loc,3),
            hrsfrom_4:findHoursStart(loc,4),
            hrsfrom_5:findHoursStart(loc,5),
            hrsfrom_6:findHoursStart(loc,6),
            hrsto_0:findHoursEnd(loc,0),
            hrsto_1:findHoursEnd(loc,1),
            hrsto_2:findHoursEnd(loc,2),
            hrsto_3:findHoursEnd(loc,3),
            hrsto_4:findHoursEnd(loc,4),
            hrsto_5:findHoursEnd(loc,5),
            hrsto_6:findHoursEnd(loc,6),
        }
        return obj;
    }


    const GetNewLoc = () => {
        var obj = {
            locationid:0,
			name:"",
			address1:"",
			address2:"",
			city:"",
			state:"",
			zipcode:"",
			timezone:"",
            primaryloc:false,
			dstparticipates:"Y",
			directions:"",
			comments:"",
			contactpersonid:0,
			websiteurl:"",
			overflowhandling:0,
			overflowattempts:0,
			overflowinstruct:"",
			practiceid:0,
            contactfirstname:"",
            contactlastname:"",
            contactmiddleinitial:"",
            contacthomephone:"",
            contactcellphone:"",
            contactpager:"",
            contactpagertype:0,
            phone:[],
            hours:[]
        }
        return obj;
    }

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getlocations(props.globals.practiceid).then((data) => {
            setHasOverflow(findService(2));
            setLocations(data);
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const doSave = (v) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savelocation(props.globals.practiceid,locid,props.globals.useraccountid,inputs).then((data) => {
            let status = data.STATUS || 'FAIL';
            let errmsg = '';
            let newlocid = data.LOCID || 0;
            if (status === 'FAIL') {
                errmsg = data.ERROR || "Unknown error";
                alert(errmsg);
                return;
            }
            if (newlocid > 0) {
                setDirtyFlag(false);
                originputs = JSON.parse(JSON.stringify(inputs));
                dataservice.getlocations(props.globals.practiceid).then((data) => {
                    setLocations(data);
                })
                alert("Location details saved.");
            } else {
                alert('ERROR - No Location information returned.')
            }
        })
    }

    const rowSelectCancel = (e) => {
        setShowRowChange(false);
    }

    const doRowChange = (lid) => {
        
        let newloc = GetNewLoc();
        locations.forEach((loc)=> {
            if (loc.locationid===lid) {
                newloc = loc;
            }
        })
        let obj = setInputsFromLocation(newloc);
        setInputs(values => ({...values,...obj}));
        originputs = JSON.parse(JSON.stringify(obj));
        setDirtyFlag(false);
        setLocID(lid);
    }

    const rowSelectOk = (e) => {
        setShowRowChange(false);
        doRowChange(new_location_id);

    }

    const handleRowSelect = (e) => {
        const p = e.currentTarget;
        const lid = p.id;
        if (lid !== locid) {
            if (dirtyflag) {
                new_location_id = lid;
                setShowRowChange(true);
            } else {
                doRowChange(lid);
            }
        }
    }

    const handleAddNew = (e) => {
        const lid = 0;
        if (dirtyflag) {
            new_location_id = lid;
            setShowRowChange(true);
        } else {
            doRowChange(lid);
        }
    }

    const compareNames = (a,b) => {
        if (!a || !b) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    const ShowLocations = () => {
        locations.sort(compareNames);
        return (
            <React.Fragment>
                <div className={styles.locations}><span className={styles.loctitle}>Current Locations:</span>
                <table className={styles.loctable}>
                    <thead>
                    <tr><th className={styles.lochdr}>Name</th><th className={styles.lochdr}>City, State</th></tr>
                    </thead><tbody>
                    {locations.map((loc,i) => {
                        if (loc.locationid===locid) {
                            return <tr key={i} id={loc.locationid} onClick={handleRowSelect} className={styles.locselect}><td className={styles.loccol1} style={{width:"260px"}}>{loc.name}</td><td style={{width:"260px"}}>{loc.city}, {loc.state}</td></tr>
                        } else {
                            return <tr key={i} id={loc.locationid} onClick={handleRowSelect} className={styles.location}><td className={styles.loccol1} style={{width:"260px"}}>{loc.name}</td><td style={{width:"260px"}}>{loc.city}, {loc.state}</td></tr>
                        }
                    })}
                    </tbody>
                    </table>
                    {locations.length < 1 && <div className={styles.noloc}>
                        No Locations for this practice. Enter new location and click Save
                        </div>}
                </div>
            </React.Fragment>
        )
    }

    const Req = () => {
        return <span className={styles.required}>*</span>
    }

    const States = [
        {code:"AL",name:"Alabama"},
        {code:"AK",name:"Alaska"},
        {code:"AZ",name:"Arizona"},
        {code:"AR",name:"Arkansas"},
        {code:"CA",name:"California"},
        {code:"CO",name:"Colorado"},
        {code:"CT",name:"Connecticut"},
        {code:"DE",name:"Delaware"},
        {code:"FL",name:"Florida"},
        {code:"GA",name:"Georgia"},
        {code:"HI",name:"Hawaii"},
        {code:"ID",name:"Idaho"},
        {code:"IL",name:"Illinois"},
        {code:"IN",name:"Indiana"},
        {code:"IA",name:"Iowa"},
        {code:"KS",name:"Kansas"},
        {code:"KY",name:"Kentucky"},
        {code:"LA",name:"Louisiana"},
        {code:"ME",name:"Maine"},
        {code:"MD",name:"Maryland"},
        {code:"MA",name:"Massachusetts"},
        {code:"MI",name:"Michigan"},
        {code:"MN",name:"Minnesota"},
        {code:"MS",name:"Mississippi"},
        {code:"MO",name:"Missouri"},
        {code:"MT",name:"Montana"},
        {code:"NE",name:"Nebraska"},
        {code:"NV",name:"Nevada"},
        {code:"NH",name:"New Hampshire"},
        {code:"NJ",name:"New Jersey"},
        {code:"NM",name:"New Mexico"},
        {code:"NY",name:"New York"},
        {code:"NC",name:"North Carolina"},
        {code:"ND",name:"North Dakota"},
        {code:"OH",name:"Ohio"},
        {code:"OK",name:"Oklahoma"},
        {code:"OR",name:"Oregon"},
        {code:"PA",name:"Pennsylvania"},
        {code:"RI",name:"Rhode Island"},
        {code:"SC",name:"South Carolina"},
        {code:"SD",name:"South Dakota"},
        {code:"TN",name:"Tennessee"},
        {code:"TX",name:"Texas"},
        {code:"UT",name:"Utah"},
        {code:"VT",name:"Vermont"},
        {code:"VA",name:"Virginia"},
        {code:"WA",name:"Washington"},
        {code:"WV",name:"West Virginia"},
        {code:"WI",name:"Wisconsin"},
        {code:"WY",name:"Wyoming"}
    ]
    

    const ShowDetail = () => {
        return (
            <React.Fragment>
            <div className={styles.detail}><span className={styles.detailtitle}>Location Details:</span>
                <div className={styles.detailrow}>
                    <div>Location Name:<Req/></div><div><input style={{textAlign:"start"}} type="text" maxLength="60" name="locname" onChange={handleChange} value={inputs.locname}></input></div>
                    <input id="cbpri" name="locprimary" type="checkbox" style={{marginLeft:"20px"}} onChange={handleChange} checked={inputs.locprimary}></input><label htmlFor="cbpri">Primary Location?</label>
                </div>
                <div className={styles.detailrow}>
                    <div>Office Address:<Req/></div><div><input style={{textAlign:"start"}} type="text" name="locaddr1" maxLength="60" onChange={handleChange} value={inputs.locaddr1}></input></div>
                </div>
                <div className={styles.detailrow}>
                    <div></div><div><input style={{textAlign:"start"}} type="text" name="locaddr2" maxLength="60" onChange={handleChange} value={inputs.locaddr2}></input></div>
                </div>
                <div className={styles.detailrow}>
                    <div>City:<Req/></div>
                    <div><input style={{textAlign:"start"}} type="text" name="loccity" maxLength="30" onChange={handleChange} value={inputs.loccity}></input></div>
                    <div style={{marginLeft:"8px",paddingRight:"4px"}}>State:<Req/></div>
                    <div>
                        <select className={styles.detailsel} name="locstate" onChange={handleChange} value={inputs.locstate}>
                            <option value="0"></option>
                            {States.map((st,i)=> {
                                return <option style={{textAlign:"left"}} value={st.code}>{st.code} - {st.name}</option>
                            })}

                        </select>
                    </div>
                    <div style={{marginLeft:"8px",paddingRight:"4px"}}>Zip:<Req/></div>
                    <div><input style={{textAlign:"start"}} type="text" name="loczip" onChange={handleChange} maxLength="10" value={inputs.loczip}></input></div>
                </div>
                <div className={styles.detailrow}>
                    <div>Location Phone:<Req/></div><div><input style={{textAlign:"start"}} type="text" name="locphone" maxLength="15" onChange={handleChange} value={fmtPhone(inputs.locphone)}></input></div>
                </div>
                <div className={styles.detailrow}>
                    <div>Location Fax:</div><div><input style={{textAlign:"start"}} type="text" name="locfax" maxLength="15" onChange={handleChange} value={fmtPhone(inputs.locfax)}></input></div>
                </div>
                <div className={styles.detailrow}>
                    <div>Directions:</div><div><textarea style={{textAlign:"start"}} cols="90" rows="5" maxLength="255" name="locdir" onChange={handleChange} value={inputs.locdir}></textarea></div>
                </div>
                <div className={styles.detailrow}>
                    <div>Time Zone:<Req/></div>
                    <div>
                        <select style={{width:"90px",textAlign:"left"}} name="loctimezone" onChange={handleChange} value={inputs.loctimezone}>
                            <option value=""></option>
                            <option value="EST">Eastern Time</option>
                            <option value="CST">Central Time</option>
                            <option value="MST">Mountain Time</option>
                            <option value="PST">Pacific Time</option>
                            <option value="AKST">Alaska</option>
                            <option value="HST">Hawaii</option>
                        </select>
                        <input id="cbdst" type="checkbox" style={{marginLeft:"20px"}} name="locdst" onChange={handleChange} checked={inputs.locdst==='Y'}></input>
                        <label htmlFor="cbdst" style={{textAlign:"left",margin:"0",padding:"0"}}>Participates in Daylight Savings Time (DST)</label>
                    </div>
                    
                    
               
                </div>
            </div>
            </React.Fragment>
        )
    }

    const handleDOWChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let sa = name.split("_");
        let sctrl = sa[0];
        let dowid = 0;
        if (sa.length > 1) {
            dowid = parseInt(sa[1]);
        }
        let newtime = "";
        let newampm = "";
        let inpname = "";
        let p = null;
        if (sctrl === "hrsfromtime") {
            newtime = value;
            p = document.getElementsByName("hrsfromampm_"+dowid);
            
            if (p) {
                newampm = p[0].value;
            }
            inpname = "hrsfrom_"+dowid;
        }
        if (sctrl === "hrsfromampm") {
            newampm = value;
            p = document.getElementsByName("hrsfromtime_"+dowid);
            if (p) {
                newtime = p[0].value;
            }
            inpname = "hrsfrom_"+dowid;
        }

        if (sctrl === "hrstotime") {
            newtime = value;
            p = document.getElementsByName("hrstoampm_"+dowid);
            if (p) {
                newampm = p[0].value;
            }
            inpname = "hrsto_"+dowid;
        }
        if (sctrl === "hrstoampm") {
            newampm = value;
            p = document.getElementsByName("hrstotime_"+dowid);
            if (p) {
                newtime = p[0].value;
            }
            inpname = "hrsto_"+dowid;
        }
        let newvalue = newtime + " " + newampm;
        let newobj = JSON.parse(JSON.stringify(inputs));
        newobj[inpname] = newvalue;
        setInputs(values=>({...values,[inpname]:newvalue}));
        setDirtyFlag(!compareObj(newobj,originputs));

    }

    const HourRow = (day) => {

        const getFromTime = (fld) => {
            if (!fld) return "";
            if (!inputs[fld]) return "";
            return inputs[fld].split(" ")[0];
        }
        const getFromAMPM = (fld) => {
            let s = "AM";
            if (!fld) return s;
            if (!inputs[fld]) return s;
            let sa = inputs[fld].split(" ");
            if (sa.length > 1) {
                s = sa[1];
            }
            return s;
        }
        return (
            <div key={"day_"+day.dowid} className={styles.hourrow}>
            <div className={styles.hourday}>{day.dow}</div>
            <div>From</div>
            <div><input key={"st_"+day.dowid} type="text" name={"hrsfromtime_"+day.dowid} maxLength="5" onChange={handleDOWChange} value={getFromTime("hrsfrom_"+day.dowid)} className={styles.hourinp} style={{textAlign:"start"}}></input></div>
            <div><select key={"selst_"+day.dowid} name={"hrsfromampm_"+day.dowid} onChange={handleDOWChange} value={getFromAMPM("hrsfrom_"+day.dowid)}><option value="AM">AM</option><option value="PM">PM</option></select></div>
            <div>To</div>
            <div><input key={"et_"+day.dowid} name={"hrstotime_"+day.dowid} type="text" maxLength="5" onChange={handleDOWChange} value={getFromTime("hrsto_"+day.dowid)} className={styles.hourinp} style={{textAlign:"start"}}></input></div>
            <div><select key={"selet_"+day.dowid} name={"hrstoampm_"+day.dowid} onChange={handleDOWChange} value={getFromAMPM("hrsto_"+day.dowid)}><option value="PM">PM</option><option value="AM">AM</option></select></div>
            </div>
            )
    }

    const ShowHours = () => {
        const days = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];


        return (
            <React.Fragment>
                <div className={styles.banner} style={{marginTop:"12px"}}>Hours</div>
                <div style={{width:"100%",textAlign:"center",marginLeft:"80px"}}>

                {days.map((dow,i) => {
                    return (HourRow({dow:dow,dowid:i}))
                })}

                </div>
                <div>Additional Information on Hours:</div>
                <textarea style={{textAlign:"start"}} cols="110" rows="3" maxLength="255" name="lochourinfo" onChange={handleChange} value={inputs.lochourinfo}></textarea>
            </React.Fragment>
        )
    }

    const ShowContactInfo = () => {
        return (
            <React.Fragment>
            <div className={styles.banner} style={{marginTop:"12px"}}>Contact Person Information</div>
            <div style={{width:"800px",textAlign:"center",marginLeft:"60px"}}>
                <div className={styles.contactrow}>
                    <div style={{textAlign:"right",paddingRight:"0px"}}>First Name:<Req/></div><div><input style={{textAlign:"start"}} type="text" name="cpfirst" onChange={handleChange} value={inputs.cpfirst}></input></div>
                    <div style={{textAlign:"right",paddingRight:"0px"}}>Pager Number:</div><div><input style={{textAlign:"start"}} type="text" name="cppager" onChange={handleChange} value={fmtPhone(inputs.cppager)}></input></div>
                </div>
                <div className={styles.contactrow}>
                    <div style={{textAlign:"right",paddingRight:"0px"}}>Last Name:<Req/></div><div><input style={{textAlign:"start"}} type="text" name="cplast" onChange={handleChange} value={inputs.cplast}></input></div>
                    <div style={{textAlign:"right",paddingRight:"0px"}}>Type:</div>
                    <div>
                        <select style={{height:"22px",width:"172px"}} name="cppagertype" onChange={handleChange} value={inputs.cppagertype}>
                            <option value="0"></option>
                            <option value="1">Digital</option>
                            <option value="2">Alpha</option>
                        </select>
                    </div>
                </div>
                <div className={styles.contactrow}>
                <div style={{textAlign:"right",paddingRight:"0px"}}>Home Phone:</div><div><input style={{textAlign:"start"}} type="text" name="cphome" onChange={handleChange} value={fmtPhone(inputs.cphome)}></input></div>
                <div style={{textAlign:"right",paddingRight:"0px"}}>Network:</div><div><input style={{textAlign:"start"}} type="text"></input></div>
                </div>
                <div className={styles.contactrow}>
                <div style={{textAlign:"right",paddingRight:"0px"}}>Cell Phone:</div><div><input style={{textAlign:"start"}} type="text" name="cpcell" onChange={handleChange} value={fmtPhone(inputs.cpcell)}></input></div>
                <div style={{textAlign:"right",paddingRight:"0px"}}>PIN:</div><div><input style={{textAlign:"start"}} type="text"></input></div>
                </div>
                <div className={styles.contactrow}>
                <div style={{textAlign:"right",paddingRight:"0px"}}></div><div></div>
                <div style={{textAlign:"right",paddingRight:"0px"}}>Email:</div><div><input style={{textAlign:"start"}} type="text" name="cpemail" onChange={handleChange} value={inputs.cpemail}></input></div>
            </div>
            </div>
            </React.Fragment>
        )
    }

    const ShowOverflow = () => {
        if (!hasoverflow) return null;
        return (
            <React.Fragment>
            <div className={styles.banner} style={{marginTop:"12px"}}>Overflow Handling</div>
            <div className={styles.overflowrow}>
                <div>Overflow Handling Method:</div>
                <div><select style={{textAlign:"left"}} name="overflowhandle" onChange={handleChange} value={inputs.overflowhandle}>
                    <option value=""></option>
                    <option value="1">Call the Must Answer phone number</option>
                    <option value="2">Patch through to the Must Answer phone number</option>
                    <option value="3">Page the Must Answer phone number</option>
                </select></div>
            </div>
            <div className={styles.overflowrow}>
                <div>"Must Answer" Number:</div>
                <div>
                    <input style={{textAlign:"start"}} type="text" name="overflowphone" onChange={handleChange} value={fmtPhone(inputs.overflowphone)}></input>
                    {inputs.overflowhandle===3 && <span style={{marginLeft:"10px"}}>Pager Type: 
                        <select style={{height:"20px",width:"90px",marginLeft:"6px"}} name="overflowpagertype" onChange={handleChange} value={inputs.overflowpagertype}>
                            <option value="0"></option>
                            <option value="2">Alpha</option>
                            <option value="1">Digital</option>
                        </select>
                    </span>}
                </div>
            </div>
            <div className={styles.overflowrow}>
                <div>Number of Attempts:</div>
                <div>
                    <select style={{width:"70px"}} name="overflowattempts" onChange={handleChange} value={inputs.overflowattempts}>
                        <option value="0"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                    </select>
                </div>
            </div>
            <div className={styles.overflowrow} style={{marginTop:"4px"}}>
                <div>Final Attempt Instructions:</div>
                <div><textarea style={{textAlign:"start"}} cols="80" rows="3" maxLength="200" name="overflowfinal" onChange={handleChange} value={inputs.overflowfinal}></textarea></div>
            </div>
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Locations for <span className={styles.content}>{props.globals.practice.name}</span></div>
                <div className={styles.page}>
                    <div>
                        <ShowLocations />
                    </div>
                    <div>
                        {ShowDetail()}
                        {ShowHours()}
                        {ShowContactInfo()}
                        {ShowOverflow()}
                    </div>
                    <div></div>
                    <div style={{width:"560px",marginLeft:"220px",textAlign:"center",display:"flex"}}>
                        <GenButton width="160" buttontext="Save" disabled={!dirtyflag} onclick={doSave} />
                        <div style={{marginLeft:"30px"}}>
                        <GenButton width="160" buttontext="Add New" disabled={locid===0} onclick={handleAddNew} />
                        </div>
                    </div>
                    <div style={{width:"160px",marginLeft:"0px",textAlign:"center"}}>
                    
                    </div>
                </div>
                <div style={{height:"30px"}}>
                
                </div>
                {showrowchange && <GenConfirm onCancel={rowSelectCancel} 
                                            onConfirm={rowSelectOk}
                                            confirmText={"Ok"} 
                                            width={500}
                                            text={"Location has not been saved.\nPress Ok to change WITHOUT saving, Cancel to go back and save changes."}
                                        />
                }
            </div>
        </React.Fragment>
    )
}

export default LocationMgmt;