import React from "react";
import styles from "./CalButtons.module.scss";
import {ReactComponent as LeftArrowIcon} from "./../assets/leftarrow.svg";
import {ReactComponent as RightArrowIcon} from "./../assets/rightarrow.svg";
import {ReactComponent as CalendarIcon} from "./../assets/calendar.svg";
import ShowCalendar from "./ShowCalendar";

const CalButtons = (props) => {
    const [showcal,setShowCal] = React.useState(false);
    const cdate = props.curDate || new Date();
    const [curdate,setCurDate] = React.useState(cdate);
    
    const ShowCal = (e) => {
        setShowCal(true);
    }
    const ChangeCurDate = (v) => {
        
        setShowCal(false);
        if (v !== null) {
            setCurDate(v);
            props.onSelect(v);
        }
        
    }
    const BackDate = () => {
        let dt = new Date(curdate);
        if (props.showday) {
            dt.setDate(dt.getDate() - 1);
        } else {
            dt.setMonth(dt.getMonth() - 1);
            dt.setDate(1);
        }
        setCurDate(dt);
        props.onSelect(dt);
        //console.log("BackMonth curdate="+curdate.toLocaleDateString());
    }
    const FwdDate = () => {
        let dt = new Date(curdate);
        if (props.showday) {
            dt.setDate(dt.getDate() + 1);
        } else {
            dt.setDate(1);
            dt.setMonth(dt.getMonth() + 1);
        }
        setCurDate(dt);
        props.onSelect(dt);
        //console.log("FwdMonth curdate="+curdate.toLocaleDateString());
    }
    let dtopts = {month:'long',year:'numeric'};
    if (props.showday) {
        dtopts["day"]="numeric";
    }
    return (
        <React.Fragment>
            <div className={styles.buttongroup}>
                <div className={styles.left} onClick={BackDate}><LeftArrowIcon /></div>
                <div className={styles.middle}>
                    <div style={{paddingLeft:"8px",paddingRight:"8px"}}>{curdate.toLocaleDateString(undefined,dtopts)}</div>
                    <div onClick={ShowCal} style={{marginLeft:"auto"}}><CalendarIcon /></div>
                </div>
                <div className={styles.right} onClick={FwdDate}><RightArrowIcon /></div>
            </div>
            {showcal && <ShowCalendar value={curdate} onChange={ChangeCurDate} />}
        </React.Fragment>
    )

}

export default CalButtons;