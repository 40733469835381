import React from "react";
import styles from "./MenuTab.module.scss";
import { ChevronDown } from "../components";
import SubMenu from "./SubMenu";

const MenuTab = (props) => {
    const [inconfirm,setInConfirm] = React.useState(false);
    const DoClk = (e) => {
        e.preventDefault();
        console.log("Setting MenuSelected to "+props.menutext);
        props.methods.SetMenuSelected(props.menutext);
    }

    const DoClose = (e) => {
        //e.preventDefault();
        if (inconfirm) {
            console.log("DoClose IN-CONFIRM");
            return;
        }
        console.log("DoClose MenuSelected to blank");
        props.methods.SetMenuSelected("");

    }
    const isSelected =  props.menutext===props.globals.menuselected;
    const bgcolor = isSelected ? "lightgray" : "#3068ae";
    const txtcolor = isSelected ? "black" : "white";
    const menuoptions = props.options || [];
    let options = [];
    if (props.globals.practiceid > 0) {
        menuoptions.forEach((obj)=> {
            if (obj.needprac < 2) {
                options.push(obj);
            }
        })
        //options = menuoptions;
    } else {
        menuoptions.forEach((obj)=> {
            if (obj.needprac !== 1) {
                options.push(obj);
            }
        })
    }
    const optcount = options.length;
    //console.log("MenuTab -> menuselected="+props.globals.menuselected);
    //console.log("MenuTab -> submenuselected="+props.globals.submenuselected);
    return (
        <div tabIndex="0">
        {optcount>0 && <div 
                className={styles.menutab} 
                onClick={DoClk} 
                style={{backgroundColor: bgcolor, color: txtcolor}}>
                    {props.menutext}
                    {optcount>0 && <span className={styles.menuright}><ChevronDown /></span>}
            </div>}
            {isSelected ? 
                <div onMouseLeave={DoClose}>
                {options.map((obj,i) => {
                return <SubMenu 
                            key={i} 
                            menutext={obj.menutext} 
                            shortname={obj.shortname} 
                            globals={props.globals} 
                            methods={props.methods} 
                            doclose={DoClose}
                            setConfirm={setInConfirm}
                            />
            })}
            </div>
                :null}
        </div>
    )
}

export default MenuTab;