import React from "react";
import styles from "./SpecialtyMgmt.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";

var origcblist = {}

const SpecialtyMgmt = (props) => {
    const [cbdirty,setCBDirty] = React.useState(false);
    const [data,setData] = React.useState({
        STATUS:"",
        ERROR:"",
        pracspecs:[],
        speclist:[],
        cblist:{}
    })


    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getpracticespecialties(props.globals.practiceid).then((psdata) => {
            if (psdata.ERROR) {
                if (psdata.ERROR.length) {
                    alert(psdata.ERROR);
                    return;
                }
            }

            setData(psdata);
            origcblist = JSON.parse(JSON.stringify(psdata.cblist));
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const doSave = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savepracticespecialties(props.globals.practiceid,props.globals.useraccountid,data.cblist).then((psdata) => {
            if (psdata.ERROR) {
                if (psdata.ERROR.length) {
                    alert(psdata.ERROR);
                    return;
                }
            }
            origcblist = JSON.parse(JSON.stringify(data.cblist));
            setCBDirty(false);
        })
    }

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const handleClick = (e) => {
        let id = e.target.id;
        let v = e.target.checked;
        let sa = id.split('_');
        if (sa.length > 1) {
            id = sa[1];
            let newlist = JSON.parse(JSON.stringify(data.cblist));
            newlist[id] = v;
            setData({...data,cblist:newlist})
            setCBDirty(!compareObj(origcblist,newlist));
        }
    }

    let matrix = [];
    let i2 = 30;
    let i3 = 60;
    let slen = data.speclist.length;

    for (let i=0;i<slen;i++) {
        let sp = null;
        if (i < 30) {
            sp = data.speclist[i];
        }
        
        matrix.push(sp);
        sp = null;
        if (i2 < slen && i2 < 60) {
            sp = data.speclist[i2];
        }
        matrix.push(sp);
        sp = null;
        if (i3 < slen && i3 < 90) {
            sp = data.speclist[i3];
        }
        matrix.push(sp);
        i2 += 1;
        i3 += 1;
    }

    const ShowRow = (sprop) => {
            let sp = sprop.sp;
            return (
            <div className={styles.specitem}>
                {sp && <label className={styles.speclabel}>
                    <input 
                        type="checkbox" 
                        id={"cb_"+sp.specialtyid} 
                        onChange={handleClick} 
                        checked={data.cblist[sp.specialtyid]}>
                    </input>
                    {sp.description}
                </label>
                }
            </div>
        )
    }

    if (props.globals.practiceid < 1) {
        return (
            <div className={styles.main}>
            <div className={styles.title}>Practice Specialties</div>
            </div>
        )
    }
    console.log("Refreshing now...");
    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Practice Specialties for <span className={styles.content}>{props.globals.practice.name}</span></div>
                <div className={styles.specbox}>
                    {matrix.map((item,i)=> {
                        return <ShowRow key={"sp_"+i} sp={item} />
                    })}
                </div>
                <div className={styles.buttons}>
                    <GenButton buttontext="Save" width="140" onclick={doSave} disabled={!cbdirty} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SpecialtyMgmt;