import React from "react";
import styles from "./PCSelect.module.scss";

const PCSelect = (props) => {
    const defvalue = props.defvalue || "";
    const [value,setValue] = React.useState(defvalue);
    const doNothing = (e) => {

    }
    const doChg = (e) => {
        setValue(e.target.value);
        const pchg = props.onchange || doNothing;
        pchg(e.target.value);
    }

    const opts = props.options || [];
    let minwt = props.minwidth || 0;
    if (minwt < 1) {
        opts.forEach((opt)=> {
            let w = opt.text.length * 12;
            if (w > minwt) minwt = w;
        });
    }

    return (
        <div className={styles.main} style={{minWidth:`${minwt}px`}}>
            <select className={styles.pcselect} defaultValue={value} onChange={doChg} style={{width:"100%"}}>
                {opts.map((opt,i) => {
                    return <option key={"opt_"+i} value={opt.value}>{opt.text}</option>
                })}
            </select>
        </div>
    )
}

export default PCSelect;