import React from "react";
import styles from "./MessageCommon.module.scss";
import MessageExtraInfo from "./MessageExtraInfo";
import { RELATIONSHIP_SELF,CleanDate,calculateAgeInYears,DateOnly,MSGTYPE_CLINICAL, MSGTYPE_APPTCANCEL,MSGTYPE_APPTINQUIRY,MSGTYPE_APPTREQUEST,MSGTYPE_NONCLINICAL, MSGTYPE_HANDLED_EXTERNAL, MSGTYPE_HANDLED_INTERNAL, CALLTYPE_URGENT } from "./../AppConstants";


const MessageCommon = (props) => {
    const pinp = props.inputs || {};
    const showmsgblock = props.showmsgblock || false;
    let relid = pinp.relationship || -1;
    relid = parseInt(relid);
    if (relid < 0) relid = RELATIONSHIP_SELF;
    const [inputs,setInputs] = React.useState({...pinp,relationship:relid});
    const [showcal,setShowCal] = React.useState(false);

    const [reqflds,setReqFields] = React.useState({
        patientfirst:true,
        patientlast:true,
        dob:true,
        relationship: true,
        callerfirst: true,
        callerlast: true,
        message:true,
        dayphone1:true
    });
    const [dFlags,setDFlags] = React.useState({
        bCaller:false,
        bDOB:false,
        bRelationship:false,
        bProvider:false,
        bDayPhone1:false,
        bDayPhone2:false,
        bEveningPhone1:false,
        bEveningPhone2:false,
        bOkLeaveMsg:false,
        bEmail:false,
        bMessage:false,
    })
    let orgrefdata = props.refdata || {
        msgfields:[],
        extrainfo:[],
        provlist:[],
        greets:[],
        callnotes:[],
        locations:[],
        msgtypes:[]
    }
    const rdata = props.refdata || orgrefdata;
    const [refdata,setRefData] = React.useState(rdata);

    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }
    const [inputRef, setInputFocus] = useFocus()
    const [inputProvider, setInputProvider] = useFocus()
    const [callerRef, setCallerFocus] = useFocus()
    const [patientRef, setPatientFocus] = useFocus()
    const [inputMsgType, setInputMsgType] = useFocus()
    const [dayPhone1Ref, setDayPhone1Focus] = useFocus()
    const [dayPhone2Ref, setDayPhone2Focus] = useFocus()
    const [evePhone1Ref, setEvePhone1Focus] = useFocus()
    const [evePhone2Ref, setEvePhone2Focus] = useFocus()

    const setDOBDate = (v) => {
        setInputs(values => ({...values, dob: DateOnly(v)}));
        cbChange("dob",DateOnly(v));
        setShowCal(false);
    }
    const reqtypes = [MSGTYPE_APPTCANCEL,MSGTYPE_APPTINQUIRY,MSGTYPE_APPTREQUEST,MSGTYPE_NONCLINICAL];
    const dobdate = inputs.dob || new Date();
    const mtype = parseInt(props.mtype || '0');
    const calltype = parseInt(props.calltype || '0');
    console.log("MTYPE = " + mtype);
    let firstfield = 3;


    React.useEffect(()=> {
        let newdf = JSON.parse(JSON.stringify(dFlags));
        console.log("IN useEffect, dFlags="+JSON.stringify(dFlags));
        console.log("msgfields length = "+refdata.msgfields.length);
        let showphones = true;
        if (mtype !== MSGTYPE_CLINICAL) showphones = false;
        const intext = props.intext || MSGTYPE_HANDLED_EXTERNAL;
        let showreq = false;
        //if (intext===MSGTYPE_HANDLED_INTERNAL || mtype === MSGTYPE_CLINICAL) {
        //    showreq = true;
        //}
        const obj = {relationship:showreq,callerfirst:showreq,callerlast:showreq}
        setReqFields(values=>({...values,...obj}));
        Object.keys(newdf).forEach((key)=>newdf[key]=false);
        if (calltype === 0 || calltype===2) {
            refdata.msgfields.forEach((mf)=> {
                if ((mf.msgtype===0 || mf.msgtype===mtype) && mf.ask==='1') {
                    switch(mf.infotype) {
                        case 4:
                            newdf['bDOB'] = true;
                            break;
                        case 7:
                            newdf['bProvider'] = true;
                        case 8:
                            newdf['bDayPhone1'] = true;
                            break;
                        default:
                            break;
                    }
                }
            })
        }

        if (calltype > 0 && calltype !== 2) {
            newdf.bMessage = true;
        
            refdata.msgfields.forEach((mf)=> {
                if ((mf.msgtype===0 || mf.msgtype===mtype) && mf.ask==='1') {
                    switch(mf.infotype) {
                        case 4:
                            newdf['bDOB'] = true;
                            break;
                        case 5:
                            newdf['bCaller'] = true;
                            firstfield = 1;
                            break;
                        case 6:
                            newdf['bRelationship'] = true;
                            break;
                        case 7:
                            newdf['bProvider'] = true;
                            break;
                        case 8:
                            if (showphones)
                                newdf['bDayPhone1'] = true;
                            break;
                        case 9:
                            if (showphones)
                                newdf['bDayPhone2'] = true;
                            break;
                        case 10:
                            if (showphones)
                                newdf['bEveningPhone1'] = true;
                            break;
                        case 11:
                            if (showphones)
                                newdf['bEveningPhone2'] = true;
                            break
                        case 12:
                            if (showphones)
                                newdf['bOkLeaveMsg'] = true;
                            break;
                        case 13:
                            if (showphones)
                                newdf['bEmail'] = true;
                            break;

                        default:
                            break;
                    }
                }
            })
        }
        setDFlags(newdf);
    },[mtype,refdata.msgfields])

    const DummyCB = (name,value) => {

    }

    const cbChange = (nm,v) => {
        const cb = props.onChange || DummyCB;
        cb(nm,v);
    }

    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let obj = {[name]:value}

        if (name==='okleavemsg') {
            if (value===true) {
                obj[name] = 'Y';
            } else {
                obj[name] = 'N';
            }
        }
        setInputs(values => ({...values, ...obj}));
        cbChange(name,obj[name]);
    }

    const RelBlur = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const selfrel = "" + RELATIONSHIP_SELF;
        
        if (name==="relationship" && value===selfrel) {
            let obj = {[name]:value}
            obj["callerfirst"] = inputs.patientfirst;
            cbChange("callerfirst",inputs.patientfirst);
            obj["callerlast"] = inputs.patientlast;
            cbChange("callerlast",inputs.patientlast);
            if (dFlags['bProvider']) {
                setInputProvider();
            }

            setInputs(values => ({...values, ...obj}));
        }

    }
    const DOBBlur = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let s = '';
        let age = 0;
        let emsg = '';
        if (name==="dob" && value.length > 0) {
            if (value.toLowerCase() !== 'unknown') {
                s = CleanDate(value);
                age = calculateAgeInYears(s);
                emsg = "";
                if (age < 0) {
                    emsg = "Cannot have DOB in the future!";
                } else {
                    if (s.indexOf("ERROR")===0) {
                        emsg = "Invalid DOB";
                    }
                }
            } else {s = value;}
            if (emsg.length > 0) {
                alert(emsg);
                inputRef.current.value="";
                let obj = {[name]:""}
                setInputs(values => ({...values, ...obj}));
                cbChange(name,"");
                setInputFocus(); // sets focus to DOB field
            } else {
                let obj = {[name]:s}
                setInputs(values => ({...values, ...obj}));
                cbChange(name,s);
            }
        }
    }

    const ShowReq = (props) => {
        const nm = props.name || "";
        if (reqflds[nm] === true) {
            return <div className={styles.req}>*</div>
        } else {
            return null;
        }            
    }

    const getInputClass = (nm) => {
        let st = styles.forminputreq;
        if (reqflds[nm]===false) st = styles.forminput;
        return st
    }

    const getSelectClass = (nm) => {
        let st = styles.formselectreq;
        if (reqflds[nm]===false) st = styles.formselect;
        return st;
    }

    let x = '';
    let yx= '';
    let bShowCaller = dFlags['bCaller'];
    if (''+inputs.relationship === ''+RELATIONSHIP_SELF) bShowCaller = false;
    return (
        <React.Fragment>

            <div key="ctrl_2" className={styles.formctrl}>
                <div className={styles.formlabel}>Patient Name, First:</div><ShowReq name="patientfirst" />
                <input className={getInputClass("patientfirst")} type="text" ref={patientRef} name="patientfirst" maxLength="20" onChange={HandleChange} value={inputs.patientfirst} />
            </div>
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Last:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} type="text" name="patientlast" maxLength="30" onChange={HandleChange} value={inputs.patientlast} />
            </div>
            {dFlags['bDOB'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Date of birth:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} ref={inputRef} type="text" name="dob" maxLength="10" onBlur={DOBBlur} onChange={HandleChange} value={inputs.dob} />
            </div>}

            {dFlags['bRelationship'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Caller Relationship:</div><ShowReq name="relationship" />
                <select className={getSelectClass("relationship")} name="relationship" onBlur={RelBlur} onChange={HandleChange} value={inputs.relationship} >
                    {/*<option value="-1">&nbsp;</option>*/}
                    <option value="0">Daughter</option>
                    <option value="1">Doctor</option>
                    <option value="2">Father</option>
                    <option value="3">Mother</option>
                    <option value="4">Not Related</option>
                    <option value="5">Other Family</option>
                    <option value="6">Self</option>
                    <option value="7">Son</option>
                    <option value="8">Spouse</option>
                    <option value="9">Unknown</option>
                </select>
            </div>}

            {bShowCaller && <div key="ctrl_1" className={styles.formctrl}>
                <div className={styles.formlabel}>Caller Name, First:</div><ShowReq name="callerfirst" />
                <input className={getInputClass("callerfirst")} type="text" ref={callerRef} name="callerfirst" maxLength="20" onChange={HandleChange} value={inputs.callerfirst} />
                
            </div>}
            {bShowCaller && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Last:</div><ShowReq name="callerlast" />
                <input className={getInputClass("callerlast")} type="text" name="callerlast" maxLength="20" onChange={HandleChange} value={inputs.callerlast} />
                
            </div>}

            {dFlags['bProvider'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Provider:</div>
                <select className={styles.formselect} ref={inputProvider} name="provider" rows="9" onChange={HandleChange} value={inputs.provider} >
                    <option value="0">Unknown</option>
                    {refdata.provlist.map((prov,i)=> {
                        return <option key={"popt_"+i} value={prov.providerid}>{prov.lastname}, {prov.firstname}</option>
                    })}
                </select>
                
            </div>}
            <MessageExtraInfo extrainfo={rdata.extrainfo} mtype={mtype} calltype={props.calltype}  onChange={cbChange} inputs={props.inputs} />
            {calltype===CALLTYPE_URGENT && <React.Fragment>
                
                <div className={styles.formblock}>
                    <div className={styles.formlabelmsg}><div>Message:</div><div className={styles.req} style={{paddingLeft:"8px"}}>*</div></div>
                    <div className={styles.formmsg}>
                        <textarea name="message" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="5" maxLength="2000" onChange={HandleChange} value={inputs.message}></textarea>
                    </div>
                </div>
            </React.Fragment>
            }
            {dFlags['bDayPhone1'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Callback Phone:</div><ShowReq name="dayphone1" />
                <input className={getInputClass("dayphone1")} type="text" ref={dayPhone1Ref} name="dayphone1" maxLength="16" onChange={HandleChange} value={inputs.dayphone1} />
                <span className={styles.formlabelsm}>Ext:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="dayext1" maxLength="4" onChange={HandleChange} value={inputs.dayext1} />
                
            </div>}
            {/*}
            {dFlags['bDayPhone2'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Day Phone 2:</div>
                <input className={styles.forminput} type="text" ref={dayPhone2Ref} name="dayphone2" maxLength="16" onChange={HandleChange} value={inputs.dayphone2} />
                <span className={styles.formlabelsm}>Ext 2:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="dayext2" maxLength="4" onChange={HandleChange} value={inputs.dayext2} />
            </div>}
            {dFlags['bEveningPhone1'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Evening Phone 1:</div>
                <input className={styles.forminput} type="text" ref={evePhone1Ref} name="eveningphone1" maxLength="16" onChange={HandleChange} value={inputs.eveningphone1} />
                <span className={styles.formlabelsm}>Ext 1:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="eveningext1" maxLength="4" onChange={HandleChange} value={inputs.eveningext1} />
            </div>}
            {dFlags['bEveningPhone2'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Evening Phone 2:</div>
                <input className={styles.forminput} style={{width:"90px"}} type="text" ref={evePhone2Ref} name="eveningphone2" maxLength="16" onChange={HandleChange} value={inputs.eveningphone2} />
                <span className={styles.formlabelsm}>Ext 2:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="eveningext2" maxLength="4" onChange={HandleChange} value={inputs.eveningext2} />
            </div>}
            */}
            {dFlags['bEmail'] && <div className={styles.formctrl}>
                <div className={styles.formlabel}>Caller Email:</div>
                <input className={styles.forminput} style={{width:"90px"}} type="text" name="email" maxLength="100" onChange={HandleChange} value={inputs.email} />
            </div>}
            {showmsgblock && <div className={styles.formblock}>
                <div className={styles.formlabel}><div>Message:</div><div className={styles.req} style={{paddingLeft:"8px"}}>*</div></div>
                <div className={styles.formmsg}>
                    <textarea name="message" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="5" maxLength="2000" onChange={HandleChange} value={inputs.message}></textarea>
                </div>
            </div>}            
        </React.Fragment>
    )
}

export default MessageCommon;