import React from "react";
import styles from "./ResetPassword.module.scss";
import {useNavigate} from 'react-router-dom';  
import GenButton from './../components/GenButton';
import GenInput from './../components/GenInput';
import Alert from './../components/Alert';
import {DataService} from './../store/DataService';



const ResetPassword = (props) => {
    const dataservice = new DataService({guid:props.globals.sessionguid});
    const history = useNavigate();
    const [errobj,setErrObj] = React.useState({show:false,errmsg:""});
    const [inputs, setInputs] = React.useState({oldpwd:"",newpwd:"",confpwd:""});
    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const SetPwd = (e) => {
        let oldpw = inputs.oldpwd;
        let newpw = inputs.newpwd;
        let confpw = inputs.confpwd;
        if (newpw !== confpw) {
            setErrObj({show:true,errmsg:"Confirm Password does NOT match new password."});
            return;
        }
        if (newpw.length < 6) {
            setErrObj({show:true,errmsg:"New password must be at least 6 characters."});
            return;
        }

        if (newpw===oldpw) {
            setErrObj({show:true,errmsg:"New password must be different than old password."});
            return;
        }

        dataservice.updatepwd(props.globals.userpracticeid,props.globals.useraccountid,oldpw,newpw).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                props.methods.SetPwdexpired('N');
                history("/");
            }
        }).catch(ex => {
            console.error(ex);
            setErrObj({show:true,errmsg:ex.message});
            return null;
        });
    }


    const handleKeypress = e => {
        //console.log("hkp called, key="+e.key);
        if (e.key === 'Enter') {
            SetPwd(e);
        }
    };

    return (
        <React.Fragment>
        <div className={styles.main}>
        <div className={styles.outer}>
            <div className={styles.inner}>
                <div style={{width:"100%",alignItems:"center",marginLeft:"auto",display:"flex"}}>
                </div>
                <form>
                    <div className={styles.textAndSupportingText}>
                        <p className={styles.text}>
                        Password Change
                        </p>
                        <p className={styles.supportingText}>
                        Your password must be changed to continue
                        </p>
                    </div>
                    <p className={styles.prompt}>Old Password</p>
                    <div className={styles.control} style={{marginTop:'3px'}}>
                        <GenInput inputname="oldpwd" maxlen="30" change={HandleChange} onKeyPress={handleKeypress} inputtype="password" width="250px" height="20px" placeholder="Old Password" />
                    </div>
                    <p className={styles.prompt}>New Password</p>
                    <div className={styles.control} style={{marginTop:'3px'}}>
                        <GenInput inputname="newpwd" maxlen="30" change={HandleChange} onKeyPress={handleKeypress} inputtype="password" width="250px" height="20px" placeholder="New Password" />
                    </div>
                    <div style={{display:"block",fontSize:"10px"}}>Password must be at least 6 characters</div>
                    <p className={styles.prompt}>Confirm New Password</p>
                    <div className={styles.control} style={{marginTop:'3px'}}>
                        <GenInput inputname="confpwd" maxlen="30" change={HandleChange} onKeyPress={handleKeypress} inputtype="password" width="250px" height="20px" placeholder="Confirm Password" />
                    </div>
                    {errobj.show && <div className={styles.control} style={{marginTop:'18px'}}><Alert msg={errobj.errmsg} /></div>}
                    <div className={styles.control} style={{marginTop:'28px'}}>
                        <GenButton onclick={SetPwd} buttontext="Submit" />
                    </div>
                </form>
            </div>
        </div>
        </div>        
        </React.Fragment>
    )
}

export default ResetPassword;
