import React from "react";
import styles from "./CallNoteMgmt.module.scss";
import { DataService } from "../../store/DataService";
import {Date24, Date12, DateOnly, Time24, CleanDate} from "../../AppConstants";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import {ReactComponent as CalendarIcon} from "../../assets/calendar.svg";
import ShowCalendar from "../../components/ShowCalendar";

var origocinputs = null;
const CallNoteMgmt = (props) => {

    const [notes,setNotes] = React.useState([]);
    const [oncall,setOnCall] = React.useState([]);
    const [providers,setProviders] = React.useState([]);
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [showstartcal,setShowStartCal] = React.useState(false);
    const [showendcal,setShowEndCal] = React.useState(false);
    const [showdelete,setShowDelete] = React.useState(false);
    const [savedisabled,setSaveDisabled] = React.useState(false);
    const [inputs,setInputs] = React.useState({
        pcnid:0,
        seltype:'1',
        startdate:DateOnly(new Date()),
        starttime:'00:00',
        enddate:DateOnly(new Date()),
        endtime:'00:00',
        notes:'',
        selgroup:'0',
        selprov:'0'
    })

    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }
    const [inputStartRef, setStartFocus] = useFocus()
    const [inputEndRef, setEndFocus] = useFocus()

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        
        if (parseInt(props.globals.practiceid) < 1) {
            let newobj = {};
            newobj.pcnid = 0;
            newobj.startdate = DateOnly(new Date());
            newobj.starttime = "00:00";
            newobj.enddate = DateOnly(new Date());
            newobj.endtime = "00:00";
            newobj.notes = '';
            newobj.seltype = '1';
            newobj.selgroup = '0';
            newobj.selprov = '0';
    
            setInputs(newobj);
            setNotes([]);
            setOnCall([]);
            setProviders([]);
            return;
        }
        
        dataservice.callnoteinit(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let d = data.DATA || {};
            let lst = d.notes || [];
            setNotes(lst);
            lst = d.oncall || [];
            setOnCall(lst);
            lst = d.providers || [];
            setProviders(lst);
            
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const findNote = (pcnid) => {
        let n = null;
        for (let i=0;i<notes.length;i++) {
            let note = notes[i];
            if (note.practicecallnotesid===pcnid) {
                n = note;
                break;
            }
        }
        return n;
    }


    const findGroup = (gid) => {
        let gname = "";
        for (let i=0;i<oncall.length;i++) {
            let oc = oncall[i];
            if (oc.oncallid===gid) {
                gname = oc.description;
                break;
            }
        }
        return gname;
    }

    const findProvider = (pid) => {
        let pname = "";
        for (let i=0;i<providers.length;i++) {
            let p = providers[i];
            if (p.providerid===pid) {
                pname = p.name;
                break;
            }
        }
        return pname;
    }

    const setNameValue = (name,value) => {
        let newobj = JSON.parse(JSON.stringify(inputs));
        
        if (origocinputs===null) {
            origocinputs = JSON.parse(JSON.stringify(inputs));
        }
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        setDirtyFlag(!compareObj(newobj,origocinputs));

    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setNameValue(name,value);
    }

    const handleStartDate = (value) => {
        setShowStartCal(false);
        
        if (value !== null) {
            let name = "startdate";
            setNameValue(name,DateOnly(value));    
        }
    }

    const handleEndDate = (value) => {
        setShowEndCal(false);
        
        if (value !== null) {
            let name = "enddate";
            setNameValue(name,DateOnly(value));
        }
    }

    const StartDateBlur = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value.length < 1) {
            alert("Start Date cannot be blank.");
            return;
        }
        if (name==="startdate" && value.length > 0) {
            let s = CleanDate(value);
            if (s.indexOf("ERROR")===0 || s.length < 1) {
                alert("Invalid Start Date");
                inputStartRef.current.value="";
                setStartFocus(); // sets focus to DOB field
            } else {
                let obj = {[name]:s}
                setInputs(values => ({...values, ...obj}));
            }
        }
    }

    const EndDateBlur = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value.length < 1) {
            alert("End Date cannot be blank.");
            return;
        }
        if (name==="enddate" && value.length > 0) {
            let s = CleanDate(value);
            if (s.indexOf("ERROR")===0 || s.length < 1) {
                alert("Invalid End Date");
                inputEndRef.current.value="";
                setEndFocus(); // sets focus to DOB field
            } else {
                let obj = {[name]:s}
                setInputs(values => ({...values, ...obj}));
            }
        }
    }
    
    const doSave = (e) => {
        if (savedisabled) return;
        setSaveDisabled(true);
        let newobj = JSON.parse(JSON.stringify(inputs));
        if (newobj.notes.length < 1) {
            alert("Must enter a Call Note.");
            setSaveDisabled(false);
            return;
        }
        let dt = DateOnly(newobj.startdate);
        if (!dt.length) {
            alert("Must enter a Start Date/Time");
            setSaveDisabled(false);
            return;
        }
        let tm = newobj.starttime;
        if (!tm.length) {
            tm = "00:00";
        } else {
            tm = Time24(dt + " " + tm);
        }

        let cd = dt + " " + tm;
        let newdt = new Date(cd);
        if (newdt.toString()==='Invalid Date') {
            alert("Invalid Start Date/Time");
            setSaveDisabled(false);
            return;
        }
        newobj.startdate = Date24(newdt);

        dt = DateOnly(newobj.enddate);
        if (!dt.length) {
            alert("Must enter an End Date/Time");
            setSaveDisabled(false);
            return;
        }
        tm = newobj.endtime;
        if (!tm.length) {
            tm = "00:00";
        } else {
            tm = Time24(dt + " " + tm);
        }
        cd = dt + " " + tm;
        newdt = new Date(cd);
        if (newdt.toString()==='Invalid Date') {
            alert("Invalid End Date/Time");
            setSaveDisabled(false);
            return;
        }
        newobj.enddate = Date24(newdt);

        let testsd = new Date(newobj.startdate);
        let tested = new Date(newobj.enddate);
        if (tested <= testsd) {
            alert("End Date must be greater than Start Date.");
            setSaveDisabled(false);
            return;
        }
        let nw = new Date();
        nw.setHours(0);
        nw.setMinutes(0);
        nw.setSeconds(0);
        nw.setDate(nw.getDate()+1);
        if (tested < nw) {
            alert("End Date must be 1 day greater than Today.");
            setSaveDisabled(false);
            return;
        }

        if (newobj.seltype === '2' && newobj.selgroup === '0') {
            alert("Please select an OnCall group.");
            setSaveDisabled(false);
            return;
        }
        if (newobj.seltype === '3' && newobj.selprov === '0') {
            alert("Please select a Provider.");
            setSaveDisabled(false);
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savecallnotes(props.globals.practiceid,props.globals.useraccountid,newobj).then((data) => {
            setSaveDisabled(false);
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newobj = getNewInputs();
            setInputs(newobj);
            origocinputs = JSON.parse(JSON.stringify(newobj));
            setDirtyFlag(false);

            let lst = data.notes || [];
            setNotes(lst);
            
        })

    }

    const getNewInputs = () => {
        let newobj = {};
        newobj.pcnid = 0;
        newobj.startdate = DateOnly(new Date());
        newobj.starttime = "00:00";
        newobj.enddate = DateOnly(new Date());
        newobj.endtime = "00:00";
        newobj.notes = '';
        newobj.seltype = '1';
        newobj.selgroup = '0';
        newobj.selprov = '0';

        return newobj;
    }

    const handleAddNew = (e) => {
        let newobj = getNewInputs();
        setInputs(newobj);
        origocinputs = JSON.parse(JSON.stringify(newobj));
        setDirtyFlag(false);
    }

    const handleDeleteOK = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.deletecallnote(props.globals.practiceid,props.globals.useraccountid,inputs.pcnid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newobj = getNewInputs();
            setInputs(newobj);
            origocinputs = JSON.parse(JSON.stringify(newobj));
            setDirtyFlag(false);
            let lst = data.notes || [];
            setNotes(lst);
            setShowDelete(false);
        })
        
    }

    const handleDeleteCancel = (e) => {
        setShowDelete(false);
    }

    const handleDelete = (e) => {
        setShowDelete(true);
    }


    const toDate = (v) => {
        let dt = new Date(v);
        if (!dt) dt = new Date();
        return dt;
    }

    const handleRowChange = (pcnid) => {
        let newobj = JSON.parse(JSON.stringify(inputs));
        let note = findNote(pcnid);
        if (!note) return;
        let dt = new Date(note.startdatetime);
        if (dt.toString()==='Invalid Date') {
            dt = new Date();
        }
        let tm = Time24(dt);
        newobj.pcnid = pcnid;
        newobj.startdate = DateOnly(dt);
        newobj.starttime = tm;
        
        dt = new Date(note.enddatetime);
        if (dt.toString()==='Invalid Date') {
            dt = new Date();
        }
        tm = Time24(dt);       
        newobj.enddate = DateOnly(dt);
        newobj.endtime = tm;
        
        newobj.notes = note.notes;
        let st = '1';
        let gid = '0';
        let pid = '0';
        if (note.oncallid > 0) {
            st = '2';
            gid = note.oncallid;
        }
        if (note.providerid > 0) {
            st = '3';
            pid = note.providerid;
            gid = '0';
        }
        newobj.seltype = st;
        newobj.selgroup = gid;
        newobj.selprov = pid;
        setInputs(newobj);
        origocinputs = JSON.parse(JSON.stringify(newobj));
        setDirtyFlag(false);
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
      }

    const ShowCallNote = (note) => {
        let ntext = note.note.notes || "";
        ntext = ntext.replaceAll("\r\n","<br/>");
        const pcnid = note.note.practicecallnotesid || 0;
        let sdate = note.note.startdatetime || "";
        if (sdate.length) sdate = Date12(sdate);
        let edate = note.note.enddatetime || "";
        if (edate.length) edate = Date12(edate);
        const ocid = note.note.oncallid || 0;
        const provid = note.note.providerid || 0;
        let nf = 'Entire Practice';
        if (ocid > 0) nf = findGroup(ocid);
        if (provid > 0) nf = findProvider(provid);
        return (
            <React.Fragment>
                <div className={styles.noterow} onClick={(e)=>handleRowChange(pcnid)}>
                    <div>{nf}</div>
                    <div dangerouslySetInnerHTML={{ __html:ntext}}></div>
                    <div>{sdate} thru {edate}</div>
                </div>
            </React.Fragment>
        )
    }

    const goStartCal = (e) => {
        let sd = inputs.startdate;
        if (sd.length > 0) sd = DateOnly(sd);
        if (sd==='Invalid Date' || sd.length < 1) {
            let value = new Date();
            setInputs(values => ({...values,'startdate':DateOnly(value)}));
        } else {
            setShowStartCal(true);
        }
    }

    const goEndCal = (e) => {
        let sd = inputs.enddate;
        if (sd.length > 0) sd = DateOnly(sd);
        if (sd==='Invalid Date' || sd.length < 1) {
            let value = new Date();
            setInputs(values => ({...values,'enddate':DateOnly(value)}));
        } else {
            setShowEndCal(true);
        }
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Call Note Management</div>
                <div className={styles.notes}>
                    <div className={styles.notehdrrow}>
                        <div className={styles.notehdr}>Note For</div>
                        <div className={styles.notehdr}>Note</div>
                        <div className={styles.notehdr}>Valid For</div>
                    </div>
                    {notes.map((note,i)=> {
                        return <ShowCallNote note={note} key={"cn_"+i} />
                    })}
                </div>
                <div className={styles.edit}>
                    <div className={styles.editrow}>
                        <div className={styles.editfirst}>Note is for:</div><div></div>
                        <div>
                            <select name="seltype" onChange={handleChange} value={inputs.seltype} className={styles.editsel}>
                                <option value="1">Practice</option>
                                <option value="2">Group</option>
                                <option value="3">Provider</option>
                            </select>
                        </div><div></div>
                        <div>
                        {inputs.seltype==='2' && <select name="selgroup" onChange={handleChange} value={inputs.selgroup}>
                            <option value="0"></option>
                            {oncall.map((oc,i)=> {
                                return <option key={"oc_"+i} value={oc.oncallid}>{oc.description}</option>
                            })}
                        </select>}
                        {inputs.seltype==='3' && <select name="selprov" onChange={handleChange} value={inputs.selprov}>
                            <option value="0"></option>
                            {providers.map((prov,i)=> {
                                return <option key={"pr_"+i} value={prov.providerid}>{prov.name}</option>
                            })}
                        </select>}
                        </div>

                    </div>
                    <div className={styles.editrow}>
                        <div className={styles.editfirst}>Start Date/Time:</div><div>*</div>
                        <div>
                            <input type="text" name="startdate" ref={inputStartRef} value={inputs.startdate} onBlur={StartDateBlur} onChange={handleChange} className={styles.edittext}></input>
                        </div>
                        <div onClick={goStartCal} style={{marginLeft:"12px",textAlign:"left"}}><CalendarIcon /></div>
                        <div><input type="time" name="starttime" value={inputs.starttime} onChange={handleChange} className={styles.edittext}></input></div>
                        <div></div>
                    </div>
                    <div className={styles.editrow}>
                        <div className={styles.editfirst}>End Date/Time:</div><div>*</div>
                        <div>
                            <input type="text" name="enddate" ref={inputEndRef} value={inputs.enddate} onChange={handleChange} onBlur={EndDateBlur} className={styles.edittext}></input>
                        </div>
                        <div onClick={goEndCal} style={{marginLeft:"12px",textAlign:"left"}}><CalendarIcon /></div>
                        <div><input type="time" name="endtime" value={inputs.endtime} onChange={handleChange} className={styles.edittext}></input></div>
                        <div></div>

                    </div>
                    <div className={styles.editblock}>
                        <div className={styles.editfirst}>Note:</div><div>*</div>
                        <div>
                            <textarea name="notes" value={inputs.notes} onChange={handleChange} className={styles.editarea}></textarea>
                        </div>
                    </div>
                    <div style={{width:"100%",marginTop:"20px",textAlign:"center",justifyContent:"center",display:"flex"}}>
                        <div>
                            <GenButton width="160" buttontext="Save" disabled={!dirtyflag || savedisabled} onclick={doSave} />
                        </div>
                        <div style={{marginLeft:"30px"}}>
                            <GenButton width="160" buttontext="Add New" disabled={inputs.pcnid===0} onclick={handleAddNew} />
                        </div>
                        <div style={{marginLeft:"30px"}}>
                            <GenButton width="160" buttontext="Delete" disabled={inputs.pcnid===0} onclick={handleDelete} />
                        </div>
                    
                    </div>
                </div>
                {showstartcal && <ShowCalendar value={toDate(inputs.startdate)} onChange={handleStartDate} />}
                {showendcal && <ShowCalendar value={toDate(inputs.enddate)} onChange={handleEndDate}/>}
                {showdelete && <GenConfirm 
                                    confirmText="Yes" 
                                    onCancel={handleDeleteCancel} 
                                    onConfirm={handleDeleteOK} 
                                    width={500}
                                    text={"Are you sure you want to delete this Call Note?"} 
                                    />}
            </div>
        </React.Fragment>
    )
}

export default CallNoteMgmt;