// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import { ButtonBase } from "./../components";
import styles from "./Button.module.scss";

const Button = (props) => {
  return (
    <div className={styles.button}>
      <ButtonBase globals={props.globals} methods={props.methods}/>
    </div>
  );
};

export default Button;