import React from "react";
import styles from './GenCheckbox.module.scss';
import cbicon from './../assets/checkbox.svg';
import cbchecked from './../assets/checkbox-checked.svg';

const GenCheckbox = (props) => {

    const chked = props.checked || false;
    const chktext = props.label || "";
    const isdisabled = props.disabled || false;
    let icon = chked ? cbchecked : cbicon;
    let opcolor = isdisabled ? "0.1" : "1";
    
    const DoCallback = (e) => {
        props.cbCallback(props.cbid);
    }

    return (
        <div className={styles.checkbox} style={{"--opcolor":`${opcolor}`}}>
            <img src={icon} alt="" className={styles.icon} onClick={DoCallback} />
            {chktext}
        </div>
    )
}

export default GenCheckbox;