import React from "react";
import styles from "./MessageContent.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import {ReactComponent as Closer} from './../../assets/close.svg';
var origmctypes = [];
var origmcinputs = {};

const MessageContent = (props) => {
    const [matrix,setMatrix] = React.useState({});
    const [inputs,setInputs] = React.useState({});
    const [dirtyflag,setDirtyFlag] = React.useState(false);
    const [showpm,setShowPM] = React.useState(false);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.msgcontentinit(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.matrix);
            setRefreshNeeded(false);
            const msgcontent = data.matrix.msgcontent || [];
            let inps = {};
            for (let i=0;i<msgcontent.length;i++) {
                let mc = msgcontent[i];
                if (mc.groupid===3) {
                    let mtid = mc.msgtypeid;
                    let seqnum = mc.value;
                    let itid = mc.infotypeid;
                    let sel = "sel_"+mtid+"_"+seqnum;
                    let cb = "cb_"+mtid+"_"+seqnum;
                    let chk = mc.includeintypevalue;
                    inps[sel] = itid;
                    inps[cb] = chk;
                }
            }
            setInputs(inps);
            origmctypes = JSON.parse(JSON.stringify(data.matrix.infotypes || []));
            origmcinputs = JSON.parse(JSON.stringify(inps));
        })
    },[props.globals.sessionguid,props.globals.practiceid,refreshneeded])

    const saveAll = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const obj = {infotypes:matrix.infotypes || [],inputs:inputs};
        dataservice.savemsgcontent(props.globals.practiceid,props.globals.useraccountid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            const itypes = matrix.infotypes || [];
            origmctypes = JSON.parse(JSON.stringify(itypes));
            origmcinputs = JSON.parse(JSON.stringify(inputs));
            setDirtyFlag(false);
        })
    }

    const compareObj = (o1, o2) => {
        var p,p1,p2;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                p1 = o1[p];
                p2 = o2[p];
                if(p1 !== p2){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };
    const compareLists = (l1,l2) => {
        var result = true;
        if (l1.length === l2.length) {
            for (let i=0;i<l1.length;i++) {
                let obj1 = l1[i];
                let obj2 = l2[i];
                let r = compareObj(obj1,obj2);
                if (!r) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        return result;
    }


    const ShowRequired = () => {
        let reqlist = [];
        const infotypes = matrix.infotypes || [];
        infotypes.forEach((obj)=> {
            if (obj.groupid===1) {
                reqlist.push(obj);
            }
        });

        return (
            <React.Fragment>
                <div className={styles.boxtitle}>ptSource Required - for all message types</div>
                {reqlist.map((req,i) => {
                    let rtext = ""; 
                    if (req.includeintypevalue > 0) rtext="Include in alpha page";
                    return (<div key={"req_"+i} className={styles.boxrow2}>
                        <div>{req.description}</div>
                        <div>{rtext}</div>
                    </div>)
                })}
            </React.Fragment>
        )
    }

    const handleRequested = (e) => {
        let oid = e.target.id;
        //let value = e.target.value;
        let chked = e.target.checked;
        let sa = oid.split("_");
        let oname = sa[0];
        let itid = 0;
        if (sa.length > 1) itid = sa[1];
        const infotypes = matrix.infotypes || [];
        let newtypes = JSON.parse(JSON.stringify(infotypes));

        for (let i=0;i<newtypes.length;i++) {
            let itype = newtypes[i];
            if (''+itype.info_typesid===itid) {
                if (oname === 'reqskip') {
                    itype.skipask = chked ? false : true;
                }
                if (oname === 'reqask') {
                    itype.skipask = chked ? true : false;
                }
                if (oname === 'reqcb') {
                    itype.currentinclude = chked ? true : false;
                } 
                break;               
            }
        }
        setMatrix(values=>({...values,infotypes:newtypes}));
        setDirtyFlag(compareObj(origmcinputs,inputs) | compareLists(origmctypes,infotypes));
    }

    const ShowRequested = () => {
        const includealpha = [4,5,7]; // HARD-CODED ID's - unbelievable in legacy code!
        let reqlist = [];
        const infotypes = matrix.infotypes || [];
        infotypes.forEach((obj)=> {
            if (obj.groupid===2) {
                reqlist.push(obj);
            }
        });

        return (
            <React.Fragment>
                <div className={styles.boxtitle}>ptSource Requested - for all message types</div>
                <div style={{fontSize:"14px",paddingBottom:"8px"}}>(Specify whether to <u>Ask</u> or <u>Skip</u> for each item listed below)</div>
                {reqlist.map((req,i) => {

                    return (<div key={"req_"+i} className={styles.boxrow3}>
                        <div>{req.description}</div>
                        <div>
                            <label><input type="radio" id={"reqskip_"+req.info_typesid} name={"reqask"+req.info_typesid} checked={!req.skipask} onChange={handleRequested}></input>Skip</label>
                            <label style={{marginLeft:"10px"}}><input type="radio" id={"reqask_"+req.info_typesid} name={"reqask"+req.info_typesid} checked={req.skipask} onChange={handleRequested}></input>Ask</label>
                        </div>
                        <div>
                            {includealpha.includes(req.info_typesid) && 
                                <label><input type="checkbox" id={"reqcb_"+req.info_typesid} checked={req.currentinclude} onChange={handleRequested}></input>Include in alpha page</label>
                            }
                        </div>
                    </div>)
                })}
            </React.Fragment>
        )

    }

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        const chked = e.target.checked;
        let tempinputs = JSON.parse(JSON.stringify(inputs))
        const sa = name.split("_");
        if (sa[0]==="cb") value = chked;
        setInputs(values=>({...values,[name]:value}));
        tempinputs[name] = value;
        const dirtyinputs = !compareObj(origmcinputs,tempinputs);
        const dirtytypes = !compareLists(origmctypes,matrix.infotypes || []);
        setDirtyFlag(dirtyinputs | dirtytypes);

    }

    const sortpm = (a,b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    const ShowPracticeTypes = () => {
        let pmtypes = matrix.pmtypes || [];
        pmtypes.sort(sortpm);
        return (
            <React.Fragment>
                <div className={styles.boxtitle}>Practice Message Types
                    <button style={{marginLeft:"30px"}} onClick={()=>setShowPM(true)}>Change</button>
                </div>
                {pmtypes.map((pm,i)=> {
                    return (
                        <div key={"pmt_"+i}>{pm.name}</div>
                    )
                })}
            </React.Fragment>
        )
    }



    const PMDialog = () => {
        let mtypes = matrix.msgtypes || [];
        mtypes.sort(sortpm);
        const pmtypes = matrix.pmtypes || [];
        let pmids = [];
        pmtypes.forEach((pm)=> {
            pmids.push(''+pm.msgtype);
        })
        const [pmt,setPMT] = React.useState(pmids);
        
        const handleCBChange = (e) => {
            const name = e.target.name.replace("pmcb_","");
            const val = '' + e.target.checked;
            let pm = JSON.parse(JSON.stringify(pmt));
            if (val==='true' || val===true) {
                if (!pm.includes(name)) {
                    pm.push(name);
                }
            } else {
                const idx = pm.indexOf(name);
                if (idx !== -1) {
                    pm.splice(idx,1);
                }
            }

            setPMT(pm);
        }

        const handleSave = (e) => {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.savepracticemsgtypes(props.globals.practiceid,props.globals.useraccountid,pmt).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                const newpm = data.pmtypes || [];
                if (newpm.length > 0) {
                    let newmatrix = JSON.parse(JSON.stringify(matrix));
                    newmatrix.pmtypes = newpm;
                    setMatrix(newmatrix);
                }
                setShowPM(false);
                setRefreshNeeded(true);
            })
        }

        

        return (
            <React.Fragment>
                <div className={styles.modal}>
                    <div className={styles.outer}>
                        <div className={styles.inner}>
                            <div className={styles.modalheader}>
                                <div>Practice Message Types</div>
                                <div className={styles.headerright} onClick={()=>setShowPM(false)}><Closer /></div>
                            </div>
                            <div className={styles.lefthalf}>
                                <div className={styles.typebox}>
                                {mtypes.map((mt,i) => {
                                    if (mt.msgtypeid==='0') return null;
                                    return (
                                    <div><label><input name={"pmcb_"+mt.msgtypeid} onChange={handleCBChange} type="checkbox" checked={pmt.includes(''+mt.msgtypeid)} />{mt.name}</label></div>
                                    )
                                })}
                                </div>
                            </div>
                            <div style={{marginTop:"30px"}}>Note: Save changes are immediate.</div>
                            <div style={{marginTop:"6px",display:"flex"}}>
                                <div><GenButton width="100" buttontext="Save" onclick={handleSave} /></div>
                                <div style={{marginLeft:"160px"}}><GenButton width="100" buttontext="Cancel" onclick={()=>setShowPM(false)} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ShowAdditional = () => {
        let pmitems = ['0']
        const pmtypes = matrix.pmtypes || [];
        pmtypes.forEach((pm)=> {
            pmitems.push(''+pm.msgtype);
        })
        let additems = [];
        const additemstype = matrix.msgtypes || [];
        additemstype.forEach((item)=> {
            if (pmitems.includes(item.msgtypeid)) {
                additems.push(item);
            }
        })

        const findContent = (mtid,seqnum) => {
            var content = null;
            const mlst = matrix.msgcontent || [];
            let x = 0;
            if (mtid===5) {
                x = 1;
            }
            for (let i=0;i<mlst.length;i++) {
                const mitem = mlst[i];
                if (mitem.groupid===3 && mitem.msgtypeid===mtid && mitem.value===seqnum) {
                    content = mitem;
                    break;
                }
            }
            return content;
        }

        const ShowContentItem = ({item,mtid,seqnum}) => {
            const snums = ["","1st","2nd","3rd"];
            const itypes = JSON.parse(JSON.stringify(matrix.infotypes || []));
            itypes.sort(function (a,b) {
                if (a.description.toLowerCase() < b.description.toLowerCase()) return -1;
                if (a.description.toLowerCase() > b.description.toLowerCase()) return 1;
                return 0;
            })
            let selid = "sel_"+mtid+"_"+seqnum;
            let cbid = "cb_"+mtid+"_"+seqnum;
            let itid = inputs[selid] || 0;
            let ivchk = true;
            if (inputs[cbid] !== 'undefined') ivchk=inputs[cbid];
            /*
            if (item !== null) {
                itid = item.infotypeid;
                ivchk = item.includeintypevalue;
            }
            */
            return (
                <div className={styles.boxrowadd}>
                    <div>{snums[seqnum]} selected item:</div>
                    <div>
                        <select name={selid} value={itid} onChange={handleChange}>
                            <option value="0"></option>
                            {itypes.map((itype,i)=> {
                                if (itype.groupid !== 3 || itype.active !== 'Y') {
                                    return null;
                                }
                                return <option key={"aiit_"+i} value={itype.info_typesid}>{itype.description}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name={cbid} checked={ivchk} onChange={handleChange}></input>
                            Include in alpha pages
                        </label>
                    </div>
                </div>
            )

        }

        return (
            <React.Fragment>
                {additems.map((item,i)=> {
                    const mtid = parseInt(item.msgtypeid || "0");
                    const content1 = findContent(mtid,1);
                    const content2 = findContent(mtid,2);
                    const content3 = findContent(mtid,3);
                    return (
                        <React.Fragment>
                            <div className={styles.boxtitle}>Additional Information - {item.desc}</div>
                            <div>(Select up to 3 items from the list)</div>
                            <ShowContentItem item={content1} mtid={mtid} seqnum={1} />
                            <ShowContentItem item={content2} mtid={mtid} seqnum={2} />
                            <ShowContentItem item={content3} mtid={mtid} seqnum={3} />
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <div className={styles.main}>
                {showpm && <PMDialog />}
                <ShowRequired />
                <ShowRequested />
                <ShowPracticeTypes />
                <ShowAdditional />
                <div style={{marginTop:"30px",marginLeft:"200px",paddingBottom:"30px"}}>
                    <GenButton width="100" buttontext="Save" onclick={saveAll} disabled={!dirtyflag} />
                </div>
            </div>
            
        </React.Fragment>
    )
}

export default MessageContent;