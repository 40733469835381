import React from "react";
import styles from "./OnCallDay.module.scss";
import GenSelect from './../../components/GenSelect';
import GenConfirm from "./../../components/GenConfirm";
import GenButton from './../../components/GenButton';
import CalButtons from './../../components/CalButtons';
import {DataService} from './../../store/DataService';
import {ReactComponent as XIcon} from './../../assets/XIcon.svg';
import {Date24, Date12, FmtTime, DateToMin, FmtDateTime, HourMinToDate} from './../../AppConstants';

var matrix = [];

const OnCallDay = (props) => {
    const [rowcol,setRowCol] = React.useState({row:0,col:0,name:"",groupname:""});
    const [showgroup,setShowGroup] = React.useState(false);
    const [showdel,setShowDel] = React.useState(false);
    const [showdelgroup,setShowDelGroup] = React.useState(false);
    const [showcleargroup,setShowClearGroup] = React.useState(false);
    const [bkseq,setBkSeq] = React.useState(0);
    const [hoverline,setHoverLine] = React.useState(99);
    
    const [canedit,setCanEdit] = React.useState(true);

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.validatesession();
    },[props.globals.sessionguid])

    // <GenButton buttontext="Actions..." onclick={DoActions} />
    const DoActions = (e) => {
        console.log("Actions...");
    }
    const setToday = (e) => {
        props.onDateChange(new Date());
    }
    const ShowHeader = () => {

        return (
            <div className={styles.header}>
                <div className={styles.leftside2}>
                    <div className={styles.title}>Call Groups & Calendars</div>
                    <div className={styles.subtitle}>View and manage on-call shifts and resource groups</div>
                </div>
                <div className={styles.rightside}>
                    <div>
                        
                    </div>
                </div>
            </div>
        )
    }

    const ShowOptions = () => {
        const txt = canedit ? "" : "Read Only";
        const seldis = props.methods.GetGlobalDirtyData() || false;
        const tt = seldis===true ? "Cannot change until Save" : "";
        const disaddbku = props.globals.oncallid===0;
        return (
            <div className={styles.header} style={{borderTop:"1px solid lightgray"}}>
                <div className={styles.leftside}>
                    <GenSelect 
                        options={props.groupoptions} 
                        callback={props.onGroupChange} 
                        placeholder="Select Group..."
                        globals={props.globals}
                        methods={props.methods}
                        disabled={seldis}
                        tooltip={tt}
                    />
                    <div style={{display:"flex",marginLeft:"12px",fontSize:"18px",color:"red"}}>{txt}</div>
                </div>
                <div className={styles.rightside}>
                    {props.undolen > 0 &&
                    <div style={{marginRight:"12px"}}>
                        {canedit && <GenButton buttontext="Undo" bgcolor="white" color="black" fontsize="16px" onclick={props.onUndo} />}
                    </div>
                    }
                    {props.isdirty &&
                    <div style={{marginRight:"12px"}}>
                        {canedit && <GenButton buttontext="Save" bgcolor="rgb(252, 86, 86)" color="black" fontsize="16px" onclick={props.onSave} />}
                    </div>
                    }
                    <div style={{marginRight:"12px"}}>
                        {canedit && <GenButton buttontext="Add Backup" bgcolor="white" disabled={disaddbku} color="black" fontsize="16px" onclick={props.onAddBackup} />}
                    </div>
                    <div>
                        <GenButton width="100" buttontext="Month View" bgcolor="white" color="black" fontsize="16px" onclick={props.onViewChange} />
                    </div>
                    <div style={{paddingLeft:"12px",display:"flex"}}>
                        <CalButtons curDate={props.globals.selecteddate} onSelect={props.onDateChange} showday />
                        <GenButton width="30" buttontext="Today" bgcolor="white" color="black" fontsize="14px" onclick={setToday} />
                    </div>
                    
                    
                </div>
            </div>
        )
    }

    const onSGCancel = (e) => {
        let row = matrix[rowcol.row];
        let cnum = rowcol.col;
        row[cnum].selected = false;
        row[cnum].cname = row[cnum].lastcname;
        setShowGroup(false);
    }


    const onSGConfirm = (e) => {
        let glist = [];
        const cg = props.globals.oncallid;
        props.oncalldata.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                glist = ocg.GroupList;
            }
        });
        let rnum = rowcol.row;
        let row = matrix[rnum];
        let cnum = rowcol.col;
        row[cnum].selected = false;
        row[cnum].cname = row[cnum].lastcname;
        let sel = document.getElementById("selgm");
        const resid = sel.value;
        let hm = row[0].name;
        const startdt = HourMinToDate(props.globals.selecteddate, hm, rnum > 23);
        let enddt = new Date(startdt);
        enddt.setHours(23);
        enddt.setMinutes(59);
        enddt.setSeconds(0);
        let bknum = cnum;
        
        let name = "";
        let groupname = "";
        glist.forEach(obj=>{
            if (obj.ResourceGroupId===resid) {
                if (resid.startsWith("R"))
                    name = obj.Description;
                else
                    groupname = obj.Description;
            }
        })
        //let options={year:"numeric",month:"numeric",day:"numeric",hour:"numeric",minute:"numeric"};
        let cell = {
            cnum:bknum,
            resid:resid,
            name:name,
            lastname:name,
            groupname:groupname,
            lastgroupname:groupname,
            startdate:Date24(startdt),
            enddate:Date24(enddt)
        }
        props.onAddResource(cell);
        setShowGroup(false);
    }

    const SetEndDate = () => {
        let memname = rowcol.name || rowcol.groupname;
        let rnum = rowcol.row;
        let bknum = rowcol.col;
        let row = matrix[rnum];
        let hm = row[0].name.trim();
        let sa = hm.split(":");
        let hr = parseInt(sa[0])
        let mn = sa[1];
        mn = mn.split(' ')[0];
        if (parseInt(mn)===30) {
            mn = "29";
        } else {
            mn = "59";
            if (rnum > 0) {
                rnum -= 1;
                hr -= 1;
            }
    
        }
        hm = hr+":"+mn;
        const enddt = HourMinToDate(props.globals.selecteddate, hm, rnum > 23);
        console.log("Person "+memname);
        console.log("Setting endtime to "+enddt);
        let obj = {
            name:memname,
            bknum:bknum,
            StartDate:row.StartDateTime,
            EndDate:row.EndDateTime,
            NewEnd:enddt
        }
        props.updateEnd(obj);
        setShowGroup(false);
    }

    const ShowGroup = () => {
        let glist = [];
        const cg = props.globals.oncallid;
        props.oncalldata.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                glist = ocg.GroupList;
            }
        });
        let memname = rowcol.name || rowcol.groupname;
        let rnum = rowcol.row;
        let row = matrix[rnum];
        let hm = row[0].name;
        const startdt = HourMinToDate(props.globals.selecteddate, hm, rnum > 23);
        //const opts = {month:"short",day:"numeric",year:"numeric",hour:"numeric",minute:"numeric",hour12:true};
        const starttxt = Date12(startdt);
        let defValue = "";
        glist.forEach(obj=> {
            if (memname===obj.Description) defValue=obj.ResourceGroupId;
        })
        return (
            <React.Fragment>
            <div className={styles.backdrop} />
            <div className={styles.modal}>
                
                <div className={styles.modleftside}>
                    <div style={{display:"block",fontSize:"16px",fontWeight:"bold",paddingBottom:"6px"}}>Select Group Member</div>
                    <select name="selgm" id="selgm" size="14" defaultValue={defValue}>
                        {glist.map((opt,i)=> {
                            return (
                                <option key={"opt_"+i} value={opt.ResourceGroupId}>{opt.Description}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={styles.modrightside}>
                    <div>Starting DateTime:</div>
                    <div>{starttxt}</div>
                    <button className={styles.btn} styles={{width:"110px"}} onClick={onSGConfirm}>
                        Confirm
                    </button>
                    <button className={styles.btn} styles={{width:"110px"}} onClick={SetEndDate}>
                        Set End Time
                    </button>
                    <button className={styles.btnalt} styles={{width:"110px"}} onClick={onSGCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </React.Fragment>
        )
    }

    const delCancel = (e) => {
        setShowDel(false);
    }

    const delConfirm = (e) => {
        const row = matrix[rowcol.row];
        const cell = row[rowcol.col];
        props.onDeleteResource(cell);
        setShowDel(false);
    }

    const ShowDel = () => {
        const row = matrix[rowcol.row];
        const cell = row[rowcol.col];
        const name = cell.lastname || cell.lastgroupname;
        console.log("SD start " + cell.startdate + ", end "+cell.enddate);
        const sdate = FmtDateTime(cell.startdate);
        const edate = FmtDateTime(cell.enddate);
        const txt = "Are you sure you want to delete " + name + "\n ("+sdate+" to "+edate+")?";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={delCancel}
                onConfirm={delConfirm}
            />
        )
    }

    const delGroupCancel = (e) => {
        setShowDelGroup(false);
    }

    const delGroupConfirm = (e) => {
        props.onDeleteBackup(bkseq);
        setShowDelGroup(false);
    }

    const ShowDelGroup = () => {
        const txt = "Are you sure you want to delete the Backup Group "+(bkseq-1)+"?";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={delGroupCancel}
                onConfirm={delGroupConfirm}
            />
        )

    }

    const DeleteGroup = (e) => {
        const bknum = parseInt(e.target.getAttribute('data-bks'));
        setBkSeq(bknum);
        setShowDelGroup(true);
    }

    const clearGroupCancel = (e) => {
        setShowClearGroup(false);
    }

    const clearGroupConfirm = (e) => {
        if (bkseq===1) {
            props.onClearPrimary();
        } else {
            props.onClearBackup(bkseq);
        }
        setShowClearGroup(false);
    }

    const ShowClearGroup = () => {
        let bkgrp = bkseq - 1;
        let txt = "Primary Group";
        if (bkgrp > 0) {
            txt = "Backup Group " + bkgrp;
        }

        const confirmtext = "Are you sure you want to clear\n"+txt+"?";
        return (
            <GenConfirm
                text={confirmtext}
                width={400}
                onCancel={clearGroupCancel}
                onConfirm={clearGroupConfirm}
            />
        )

    }

    const ClearGroup = (e) => {
        const bknum = parseInt(e.target.getAttribute('data-bks'));
        setBkSeq(bknum);
        setShowClearGroup(true);
    }

    const ShowMatrix = () => {
        let rownum = 0;
        let colnum = 0;
        matrix.forEach((row) => {
            if (row) {
                colnum = 0;
                row.forEach((cell) => {
                    if (cell) {
                        console.log("SM cell len "+Object.keys(cell).length+", rownum "+rownum+", colnum "+colnum+", rnum "+cell.rnum+", cnum "+cell.cnum+", name "+cell.name+", matrix[][] "+matrix[rownum][colnum].rnum);
                    }
                    colnum += 1;
                })
            }
            rownum += 1;
        })
    }

    const cellClick = (rnum,cnum) => {
        if (!canedit) return;
        if (props.globals.oncallid===0) return;
        let row = matrix[rnum];
        let name = row[cnum].lastname;
        let groupname = row[cnum].groupname || row[cnum].lastgroupname;
        row[cnum].lastcname = row[cnum].cname;
        row[cnum].cname="lightred";
        row[cnum].selected = true;
        setRowCol({row:rnum,col:cnum,name:name,groupname:groupname});
        setShowGroup(true);
    }

    const delClick = (rnum,cnum) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        //console.log("delclick rnum "+rnum+", cnum "+cnum);
        let row = matrix[rnum];
        let cell = row[cnum];
        let name = cell.lastname;
        let groupname = cell.lastgroupname;
        console.log("DC name "+name+", index "+cell.index);
        setRowCol({row:rnum,col:cnum,name:name,groupname:groupname});
        setShowDel(true);
    }

    const ShowRow = ({row,rownum}) => {
        //console.log("OCD - ShowRow, row="+row);
        const StartHover = (rownum) => {
            setHoverLine(rownum);
            //console.log('start hover '+rownum);
        }
        const StopHover = (rownum) => {
            setHoverLine(99);
            //console.log('stop hover '+rownum);

        }
        return (
            <tr style={{height:"20px"}}>
                {row.map((cell,i) => {
                    if (i===0) {
                        return <td key={'cell_'+i} className={rownum===hoverline ? styles.firstcellhover : styles.firstcell}>{cell.name}</td>
                    }
                    let nm = cell.name || cell.groupname;
                    return (
                        <td 
                            key={'cell_'+i} 
                            className={styles.daycell} 
                            style={{"--custom_color":cell.cname}}
                            onClick={(e)=>cellClick(rownum,i)}
                            onMouseEnter={(e)=>StartHover(rownum)}
                            onMouseLeave={(e)=>StopHover(rownum)}
                        >
                            {nm}
                            {canedit && nm.length > 0 && <button className={styles.xbtn} onClick={delClick(rownum,i)}><XIcon /></button>}
                        </td>
                    )

                })}
            </tr>
        )
    }

    const ShowToday = () => {
        const cg = props.globals.oncallid;
        const dy = props.globals.selecteddate.getDate();
        const disbtn = cg===0;
        const times = ['12:00 AM','12:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30',
                        '05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30',
                        '10:00','10:30','11:00','11:30','12:00 PM','12:30','01:00','01:30','02:00','02:30',
                        '03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00',
                        '08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00'
                    ]
        let cals = [];
        props.oncalldata.oncallgroups.forEach((ocg,i) => {
            if (ocg.OnCallID===cg) {
                cals = ocg.PrimaryCalendars.concat(ocg.BackupCalendars);
            }
        });
        
        let i = 0;
        let dayres = [];
        let maxseq=1;
        for (i=0;i<cals.length;i++) {
            let o = cals[i];
            
            if (o.OnCallID===cg && o.CalDay===dy) {
                let sdate = DateToMin(o.StartDateTime);
                let edate = DateToMin(o.EndDateTime);
                dayres.push({name:o.ResourceName,
                            groupname:o.GroupDescription,
                            start:sdate,
                            end:edate,
                            seq:o.BackupSequence,
                            index:o.Index,
                            startdate:o.StartDateTime,
                            enddate:o.EndDateTime
                        });

                if (o.BackupSequence > maxseq) maxseq = o.BackupSequence;
                //console.log("ShowToday - found "+o.ResourceName+", start "+sdate+", end "+edate);
            }
        }
        let lastres = [];
        let rowlist = [];
        let row = [];
        let cell = {};
        let bks = 0;
        let dtnow = new Date(props.globals.selecteddate);
        dtnow.setHours(0);
        dtnow.setMinutes(0);
        dtnow.setSeconds(0);
        //let dtval = DateToMin(dtnow); // this is really 0, but to be consistent, used datetomin()
        let dtval = 0;
        for (i=0;i<=maxseq;i++) lastres.push("");
        for (i=0;i<48;i++) {
            //let ft = FmtTime(dtnow);
            let ft = times[i];
            if (i !== 0 && i !== 24) {
                ft = ft.replace(" AM","");
                ft = ft.replace(" PM","");
            }
            if (ft.length < 5) ft = "0"+ft;
            row = [{rnum:i,cnum:0,cname:"white",lastcname:"",selected:false,name:ft,groupname:"",lastgroupname:"",index:-1,lastname:"",startdate:"",enddate:""}];
            
            for (bks=1;bks<=maxseq;bks++) {
                cell = {rnum:i,cnum:bks,cname:"lightgray",lastcname:"",selected:false,name:"",groupname:"",lastgroupname:"",index:-1,lastname:"",startdate:"",enddate:""};
                
                for (let j=0;j<dayres.length;j++) {
                    let obj = dayres[j];
                    if (bks===obj.seq && obj.index !== 9999) {
                        if (dtval===obj.start) {
                            if (lastres[bks] !== obj.name) {
                                
                                lastres[bks] = obj.name.trim();
                            }
                            cell.name = obj.name.trim();
                            cell.groupname = obj.groupname.trim();
                            cell.index = obj.index;
                            cell.cname = "lightgreen";
                            cell.lastname = obj.name.trim();
                            cell.lastgroupname = obj.groupname.trim();
                            cell.startdate = obj.startdate;
                            cell.enddate = obj.enddate;
                        } else {
                            if (dtval > obj.start && dtval < obj.end) {
                                cell.cname = "lightgreen";
                                cell.index = obj.index;
                                cell.lastname = obj.name.trim();
                                cell.lastgroupname = obj.groupname.trim();
                                cell.startdate = obj.startdate;
                                cell.enddate = obj.enddate;
                                }
                        }
                    }
                }
                row.push(cell);
            }
            rowlist.push(row);
            
            dtnow.setMinutes(dtnow.getMinutes()+30);
            //dtval = DateToMin(dtnow);
            dtval += 30;
        }

        matrix = JSON.parse(JSON.stringify(rowlist));
        //console.log("matrix len "+matrix.length);
        row = matrix[47];
        //console.log("row len " + row.length);
        //row = row[2];
        //console.log("cell "+row.startdate+", "+row.enddate);
        //maxseq = 3;
        let bkucols = [];
        let bknum = 1;
        while (maxseq > 1) {
            bkucols.push(
                <th key={"thbk_"+bknum} className={styles.dayheader}>
                <div className={styles.bkuheader}>
                    <div className={styles.bkuheadleft}>Backup Group {bknum}</div>
                    <div className={styles.bkuheadright}>
                        {canedit && <button className={styles.bkuclear} data-bks={bknum+1} onClick={ClearGroup}>Clear</button>}
                        {canedit && <button className={styles.bkudelete} data-bks={bknum+1} onClick={DeleteGroup}>Delete</button>}
                    </div>
                </div>
                </th>
            );
            maxseq -= 1;
            bknum += 1;
        }

        React.useEffect(()=> {
            let nd = new Date();
            nd.setHours(0);
            nd.setMinutes(0);
            nd.setSeconds(0);
            nd.setMilliseconds(0);
            setCanEdit(props.globals.selecteddate.valueOf() >= nd.valueOf());
            //console.log("RUE seldate "+props.globals.selecteddate.valueOf()+", now "+nd.valueOf()+", flag "+canedit);
        });


        return (
            <table style={{width:"auto",margin:"0px",padding:"0px",borderSpacing:"0px",borderCollapse:"0px"}}>
                <thead>
                    <tr>
                        <th className={styles.dayheader}>Time</th>
                        <th className={styles.dayheader}>
                            <div className={styles.bkuheader}>
                            <div className={styles.bkuheadleft}>Primary Group</div>
                                <div className={styles.bkuheadright}>
                                {canedit && <button className={styles.bkuclear} disabled={disbtn} data-bks={bknum} onClick={ClearGroup}>Clear</button>}
                                </div>
                            </div>
                        </th>
                        {bkucols}
                    </tr>
                </thead>
                <tbody>
                    {rowlist.map((row,i) => {
                        return <ShowRow key={'sr_'+i} row={row} rownum={i} />
                    })}
                </tbody>
            </table>
        )
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <React.Fragment>
            
            <div className={styles.main}>
                <ShowHeader />
                <ShowOptions />
                <ShowToday />
                {showgroup && <ShowGroup />}
                {showdel && <ShowDel />}
                {showdelgroup && <ShowDelGroup />}
                {showcleargroup && <ShowClearGroup />}
            </div>
        </React.Fragment>
    )
}

export default OnCallDay;