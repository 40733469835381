import React from "react";
import styles from "./HomeInternal.module.scss";
import HeaderInternal from "../../HeaderInternal";
import MenuInternal from "../../navigation/MenuInternal";


const HomeInternal = (props) => {

    return (
        <React.Fragment>
        <HeaderInternal globals={props.globals} methods={props.methods} resetlogin={props.resetlogin} />
        <MenuInternal globals={props.globals} methods={props.methods} resetlogin={props.resetlogin} />
        </React.Fragment>
    )

}

export default HomeInternal;