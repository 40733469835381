import React from "react";
import HeaderExternal from "../../HeaderExternal";
import MenuExternal from "../../navigation/MenuExternal";

const HomeExternal = (props) => {

    return (
        <React.Fragment>
        <HeaderExternal globals={props.globals} methods={props.methods} />
        <MenuExternal globals={props.globals} methods={props.methods} />
        
        </React.Fragment>
    )

}

export default HomeExternal;