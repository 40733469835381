import React from 'react';
import styles from './MessageHeader.module.scss';
import GenInput from './GenInput';
import GenButton from './GenButton';
import {ReactComponent as UpArrow} from './../assets/arrow-up.svg';
import {ReactComponent as DownArrow} from './../assets/arrow-down.svg';

const MessageSchedHeader = (props) => {
    const [datesort,setDateSort] = React.useState(0);
    const [inputs,setInputs] = React.useState({isearch:""});
    const dateSortID = [2,6];
    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("HC name="+name+",val="+value);
        setInputs(values => ({...values, [name]: value}))
    }

    const LeftButton = (bprop) => {
        return (
            <div className={styles.leftbutton} onClick={bprop.click}>
                {bprop.label}
                <span>
                    {datesort > 0 ? <UpArrow /> : <DownArrow />}
                </span>
            </div>
        )
    }

    const MiddleButton = (bprop) => {
        return (
            <div className={styles.middlebutton} onClick={bprop.click}>
                {bprop.label}
            </div>
        )
    }

    const RightButton = (bprop) => {
        return (
            <div className={styles.rightbutton} onClick={bprop.click}>
                {bprop.label}
            </div>
        )
    }

    const SortDate = (e) => {
        let ds = datesort ^ 1;
        setDateSort(ds);
        const sortid = dateSortID[ds];
        console.log('sort date '+ds+', '+sortid);
        props.sortCallback(sortid);
    }

    const SortType = (e) => {
        console.log('sort type');
        props.sortCallback(4);
    }

    const SortPatient = (e) => {
        console.log('sort patient');
        props.sortCallback(11);
    }

    const SortProvider = (e) => {
        console.log('sort provider');
        props.sortCallback(3);
    }

    const Dummy = (e) => {}

    const DoSearch = (e) => {
        const cb = props.searchCallback || Dummy;
        console.log("DoSearch isearch="+inputs.isearch);
        cb(inputs.isearch);
    }

    const OnKey = (e) => {
        if (e.key === 'Enter') {
            DoSearch(e);
        }
    }
    return (
        <React.Fragment>
            <div className={styles.header}>
                <div className={styles.buttonpanel}>
                    <LeftButton label="Sort by date" click={SortDate} />
                    <MiddleButton label="Type" click={SortType} />
                    <MiddleButton label="Patient" click={SortPatient} />
                    <RightButton label="Provider" click={SortProvider} />
                    <div style={{display:"flex",marginLeft:"22px",alignItems:"center",fontSize:"16px"}}>
                    <input type="checkbox" checked={props.showClosed} onChange={(e)=>props.showClosedCallback(!props.showClosed)} /> Show Closed Messages
                    </div>
                </div>
                <div className={styles.rightpanel}>
                    <GenInput 
                        globals={props.globals} 
                        methods={props.methods} 
                        inputname="isearch" 
                        placeholder="Search messages"
                        onKeyPress={OnKey}
                        callback={DoSearch}
                        change={HandleChange}
                        height="22px"
                        width="250px"
                        showsearch
                        />
                    <div style={{marginLeft:"12px"}}>
                        <GenButton globals={props.globals} methods={props.methods}
                            buttontext="Batch Close"
                            id="0"
                            bgcolor="white"
                            color="black"
                            width="100"
                            height="40"
                            onclick={props.batchClose}
                            disabled={props.showClosed}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MessageSchedHeader;