import React from "react";
import styles from "./MsgOther.module.scss";

const MsgOther = (props) => {
    const pinp = props.inputs || {};
    const [inputs,setInputs] = React.useState({...pinp});
    const locations = props.locations || [];
    const askdesc = props.askdesc || false;

    const DummyCB = (name,value) => {

    }

    const cbChange = (nm,v) => {
        let obj = {[nm]:v}

        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(nm,v);
    }

    const HandleCheck = (event) => {
        const name = event.target.name;
        let value = event.target.checked;
        
        if (name==='reschedule') {
            if (value===true) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value);
    }
    
    const HandleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name==='okleavemsg') {
            if (value===true) {
                value = 'Y';
            } else {
                value = 'N';
            }
        }
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
        const cb = props.onChange || DummyCB;
        cb(name,value); 
    }

    return (
        <React.Fragment>
            <div className={styles.formblock}>
                <div className={styles.formlabel}><div>Message:</div><div className={styles.req} style={{paddingLeft:"8px"}}>*</div></div>
                <div className={styles.formmsg}>
                    <textarea name="message" style={{resize:"none",outline:"0",borderColor:"red"}} cols="50" rows="5" maxLength="2000" onChange={HandleChange} value={inputs.message}></textarea>
                </div>
            </div> 
            <div className={styles.formctrl}>
                <div className={styles.formlabel}>Callback Phone:</div><div className={styles.req}>*</div>
                <input className={styles.forminputreq} type="text" name="dayphone1" maxLength="16" onChange={HandleChange} value={inputs.dayphone1} />
                <span className={styles.formlabelsm} style={{paddingLeft:"14px"}}>Ext:</span>
                <input className={styles.forminputsm} style={{marginLeft:"4px"}} type="text" name="dayext1" maxLength="4" onChange={HandleChange} value={inputs.dayext1} />
            </div>
        </React.Fragment>
    )
}

export default MsgOther;
