import React from "react";
import styles from "./PageFailed.module.scss";
import GenButton from "./GenButton";

const PageFailed = (props) => {

    const dummyhandler = (id) => {}
    const btnHandler = (bid) => {
        const parentcb = props.callback || dummyhandler;
        parentcb(bid);
    }

    const wt = 280;
    const isfinal = props.isfinal || false;
    return (
        <React.Fragment>
        <div className={styles.backdrop} />
            <div className={styles.modal} style={{width:`${wt}px`}}>
                <div>
                    <div>Select what to do next.</div>
                    <div className={styles.buttons}>
                        {!isfinal && <GenButton id="btnnextyes" buttontext="Next Method in 10 mins" width="160" onclick={btnHandler} />}
                        {!isfinal && <GenButton id="btnnextno" buttontext="Next Method Immediately" width="160" onclick={btnHandler} />}
                        <GenButton id="btnretryyes" buttontext="Retry in 10 mins" width="160" onclick={btnHandler} />
                        <GenButton id="btncancel" buttontext="Cancel" width="160" onclick={btnHandler} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PageFailed;