import React from "react";
import styles from "./LogOut.module.scss";

const LogOut = (props) => {
  return (
    <div className={styles.logOut}>
      <img
        alt=""
        className={styles.icon}
        src="https://static.overlay-tech.com/assets/ce38b1fa-6ca7-4732-8910-c57ca9f20dae.svg"
      />
    </div>
  );
};

export default LogOut;