import React from "react";
import styles from "./Search.module.scss";



const Search = (props) => {
  const DoClk = (e) => {
    e.preventDefault();
    console.log('search clicked!');
  }

  const cb = props.callback || DoClk;
  return (
    <div className={styles.search} onClick={cb}>
      <img
        alt=""
        className={styles.icon}
        src="https://static.overlay-tech.com/assets/0e5472dc-9d3e-4a86-a689-ad2d5f71bf3e.svg"
      />
    </div>
  );
};

export default Search;