import React from "react";
import styles from "./Login.module.scss";
import {useNavigate} from 'react-router-dom';  
import GenButton from './../components/GenButton';
import GenInput from './../components/GenInput';
import GenMsgBox from "../components/GenMsgBox";
import Alert from './../components/Alert';
import {DataService} from './../store/DataService';
import logo1 from './../assets/logo1.svg';
import logo2 from './../assets/logo2.svg';



const Login = (props) => {
    const dataservice = new DataService({guid:''});
    const history = useNavigate();
    const [errobj,setErrObj] = React.useState({show:false,errmsg:""});
    const [inputs, setInputs] = React.useState({});
    const [showforgot,setShowForgot] = React.useState(false);
    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const ForgotPwd = (e) => {
        e.preventDefault();
        setShowForgot(true);
    }

    const onOK = (e) => {
        setShowForgot(false);
    }

    const goToInfo = (e) => {
        window.location.href = "https://www.myptsource.com/solutions/";
    }

    const SetLogin = (e) => {
        console.log("SetLogin called");
        let uname = inputs.uname;
        let upwd = inputs.upwd;
        dataservice.checklogin(uname,upwd).then((data) => {
            console.log("received login data");
            const emsg = data.ERROR || '';
            if (emsg.length > 0) {
                setErrObj({show:true,errmsg:emsg});
            } else {
                data.mypractices = [{id:data.practiceid,name:data.practice.name}];
                data.dirtydata = false;
                data.intext = 2;
                if (data.roleid < 3) {
                    data.intext = 1;
                }
                data.selecteddate = new Date();
                data.oncallid = 0;
                data.sysusermsg = '';
                props.methods.SetObject(data);
                //props.methods.SetDirtyData(false);
                history("/");
            }
        }).catch(ex => {
            console.error(ex);
            setErrObj({show:true,errmsg:ex.message});
            return null;
        });        

    }

    const handleKeypress = e => {
        //console.log("hkp called, key="+e.key);
        if (e.key === 'Enter') {
            SetLogin(e);
        }
    };
    
    const sysum = props.globals.sysusermsg || "";

    return (
        <div className={styles.login}>
        <div className={styles.outer}>
            {sysum.length > 0 && <div className={styles.sysmsg}>{sysum}</div>}
            <div className={styles.inner}>
                <div style={{width:"100%",alignItems:"center",marginLeft:"auto",display:"flex"}}>
                <div className={styles.logoTwo}>
                <img
                alt=""
                className={styles.logo}
                src={logo1}
                //src="https://static.overlay-tech.com/assets/c4b68389-0a48-4dd1-aafb-19c41bc71d92.svg"
                />
                <img
                alt=""
                className={styles.portal}
                src={logo2}
                //src="https://static.overlay-tech.com/assets/68a7d334-9dc0-4d6d-9eec-c4fab957bcbc.svg"
                />
            </div></div>
            <form>
            <div className={styles.textAndSupportingText}>
            <p className={styles.text}>
              Log in to your account
            </p>
            <p className={styles.supportingText}>
              Welcome back! Please enter your details.
            </p>
          </div>
                    <p style={{display:"flex",alignItems:"flex-start",margin:"0",marginLeft:"12px",fontFamily:"Inter"}}>Username</p>
                    <div className={styles.control} style={{marginTop:'3px'}}>
                        <GenInput inputname="uname" maxlen="20" change={HandleChange} onKeyPress={handleKeypress} width="250px" height="20px" placeholder="Enter Username" />
                    </div>
                    <p style={{display:"flex",alignItems:"flex-start",margin:"0",marginTop:"12px",marginLeft:"12px",fontFamily:"Inter"}}>Password</p>
                    <div className={styles.control} style={{marginTop:'3px'}}>
                        <GenInput inputname="upwd" maxlen="30" change={HandleChange} onKeyPress={handleKeypress} inputtype="password" width="250px" height="20px" placeholder="Enter Password" />
                    </div>
                    {errobj.show && <div className={styles.control} style={{marginTop:'18px'}}><Alert msg={errobj.errmsg} /></div>}
                    <div className={styles.control} style={{marginTop:'28px'}}>
                        <GenButton onclick={SetLogin} buttontext="Sign-in" />
                    </div>
                    <p className={styles.forgotpwd} onClick={ForgotPwd}>
                        Forgot password
                    </p>
                    <div className={styles.control} style={{marginTop:"18px"}}>
                        <GenButton onclick={goToInfo} buttontext="See our Solutions" />
                    </div>
                </form>
            </div>
        </div>
        {showforgot && <GenMsgBox 
            globals={props.globals} 
            methods={props.methods}
            text="Please contact your Practice Administrator<br/>You can also email ptsourceops@patient-source.com"
            onOK = {onOK}
            buttonText = "Ok" />}
        </div>
    )

}

export default Login;