import React from "react";
import styles from "./ContactMethods.module.scss";
import {DataService} from './../store/DataService';
import GenButton from "./GenButton";
import GenConfirm from "./GenConfirm";
import {ReactComponent as Closer} from './../assets/close.svg';
import SecureMessage from "./SecureMessage";
import AlertBox from "./AlertBox";

const CM_NOCHANGE = 0;
const CM_ADDED = 1;
const CM_UPDATED = 2;
const CM_DELETED = 3;

let CMsavecms = [];
let CMnewcmlist = [];

const ContactMethods = (props) => {
    const [contactmethods,setContactMethods] = React.useState(JSON.parse(JSON.stringify(props.provider.ContactMethods)));
    const [selindex,setSelIndex] = React.useState(-1);
    const [ctvalue,setCTValue] = React.useState(0);
    const [delarray,setDelArray] = React.useState(new Array(contactmethods.length).fill(false));
    const [adderrors,setAddErrors] = React.useState("");
    const [showsecure,setShowSecure] = React.useState(false);
    const [showconfirm,setShowConfirm] = React.useState(false);
    const snref = React.useRef();
    const pnref = React.useRef();
    const emref = React.useRef();
    const formref = React.useRef();
    const contactTypeArray = [
        {ContactTypeID:7,Description:"Alpha Pager (email}"},
        {ContactTypeID:6,Description:"Cell Phone"},
        {ContactTypeID:10,Description:"Digital Pager to Caller"},
        {ContactTypeID:3,Description:"Digital Pager to ptSource"},
        {ContactTypeID:5,Description:"Email"},
        {ContactTypeID:4,Description:"Home Phone"},
        {ContactTypeID:9,Description:"Patch Caller"},
    ]

    const onSecure = (e) => {
        setShowSecure(true);
    }

    const onSecureClose = (cm,gnum=null,gname=null) => {
        setShowSecure(false);
        if (cm !== null) {
            let prov = JSON.parse(JSON.stringify(props.provider));
            prov.ContactMethods = cm;
            if (gnum !== null) prov.SecureGroupNum = gnum;
            if (gname != null) prov.SecureGroupDesc = gname;
            props.onUpdate(prov);
            setContactMethods(cm);
        }
    }

    const onClose = (e) => {
        props.onClose(e);
    }


    const onSaveYes = (nobox) => {

        if (nobox) {
            setShowConfirm(false);
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savecontactmethods(props.provider.PersonId,props.globals.useraccountid,props.globals.userpracticeid,CMsavecms).then((data) => {
            if (data.ERROR) {
                AlertBox(data.ERROR);
                return;
            }
            let prov = JSON.parse(JSON.stringify(props.provider));
            prov.ContactMethods = data.cms; //CMnewcmlist;
            props.onUpdate(prov);
            props.onClose(0);
        })
    }

    const onSaveNo = () => {
        setShowConfirm(false);
    }

    const onSave = (e) => {
        
        CMsavecms = [];
        CMnewcmlist = [];
        let tcount = delarray.filter(x=>x===true).length;
        if (tcount === contactmethods.length) {
            if (props.isoncall === 'Y') {
                AlertBox("Cannot delete ALL contact methods for OnCall Provider.");
                return;
            }
        }
        let delsecure = false;
        contactmethods.forEach((cm,i) => {
            let newcm = JSON.parse(JSON.stringify(cm))
            if (delarray[i]) {
                newcm.status=CM_DELETED;
                if (newcm.PagerEMail.indexOf('@ptsecure') > 0 && newcm.ContactTypeID==='7') delsecure = true;
            } else {
                CMnewcmlist.push(newcm);
            }
            CMsavecms.push(newcm);
        })

        if (delsecure) {
            setShowConfirm(true);
        } else {
            onSaveYes(false);
        }
    }

    const findContactMethod = (id) => {
        let i,obj;
        for (i=0;i<props.contacttypes.length;i++) {
            obj = props.contacttypes[i];
            if (obj.ContactTypeID===id) {
                return obj;
            }
        }
        return null;
    }
   
    const handleOnChange = (index) => {
        const updDelArray = delarray.map((item,i) => 
            i === index ? !item : item
        );
        setDelArray(updDelArray);
        
    }

    const onRowClick = (index) => {
        setSelIndex(index);
    }

    const doAdd = (e) => {
        const seqnum = snref.current.value.trim();
        const phone = pnref.current.value.trim();
        const email = emref.current.value.trim();
        if (ctvalue===0 || ctvalue==='0') {
            setAddErrors("Invalid Contact Type. Please select a Contact Type.");
            return;
        }
        if (ctvalue==='6' || ctvalue==='4') {
            if (!phone.length) {
                setAddErrors("No Phone Number. Please enter a Phone Number.");
                return;
            }
        }
        if ((ctvalue==='5' || ctvalue==='7') && email.length < 1) {
            setAddErrors("No Email. Please enter an Email address.");
            return;
        }
        if (email.length > 0) {
            if (email.search("@") < 0 || email.search(".") < 0) {
                setAddErrors("Email appears invalid. Please enter valid Email.");
                return;
            }
        }
        if (phone.length > 0 && phone.length < 10) {
            setAddErrors("Phone Number less than 10 digits. Please enter valid Phone Number.");
            return;
        }
        const obj = {
            PersonPhoneID:0,
            PIN:"",
            Network:"",
            PagerTypeId:0,
            ContactTypeSequence:seqnum,
            ContactTypeID:ctvalue,
            PhoneNumber:phone,
            PagerEMail:email,
            OnCallProtocolStepsID:0,
            status:CM_ADDED
        };
        let upd = JSON.parse(JSON.stringify(contactmethods));
        upd.push(obj);
        setContactMethods(upd);
        if (adderrors.length) setAddErrors("");
        formref.current.reset();
    }

    const doUpdate = (e) => {
        const seqnum = snref.current.value.trim();
        const phone = pnref.current.value.trim();
        const email = emref.current.value.trim();
        let ppid = 0;
        let ocpid = 0;
        let oldstatus = 0;
        if (selindex >= 0) {
            let sm = contactmethods[selindex];
            ppid = sm.PersonPhoneID;
            ocpid = sm.OnCallProtocolStepsID;
            oldstatus = sm.status;
        }

        let newstatus = CM_UPDATED;
        if (oldstatus === CM_ADDED) {
            newstatus = CM_ADDED;
        }
        if (oldstatus === CM_DELETED) {
            newstatus = CM_DELETED;
        }

        if (ctvalue===0 || ctvalue==='0') {
            setAddErrors("Invalid Contact Type. Please select a Contact Type.");
            return;
        }
        if (ctvalue==='6' || ctvalue==='4') {
            if (!phone.length) {
                setAddErrors("No Phone Number. Please enter a Phone Number.");
                return;
            }
        }
        if (ctvalue==='7' && email.length < 1) {
            setAddErrors("No Email. Please enter an Email address.");
            return;
        }
        if (email.length > 0) {
            if (email.search("@") < 0 || email.search(".") < 0) {
                setAddErrors("Email appears invalid. Please enter valid Email.");
                return;
            }
        }
        if (phone.length > 0 && phone.length < 10) {
            setAddErrors("Phone Number less than 10 digits. Please enter valid Phone Number.");
            return;
        }
        const obj = {
            PersonPhoneID:ppid,
            PIN:"",
            Network:"",
            PagerTypeId:0,
            ContactTypeSequence:seqnum,
            ContactTypeID:ctvalue,
            PhoneNumber:phone,
            PagerEMail:email,
            OnCallProtocolStepsID:ocpid,
            status:newstatus
        };
        let upd = [];
        contactmethods.forEach((method,i) => {
            i===selindex ? upd.push(obj) : upd.push(method);
        })
        setContactMethods(upd);
        formref.current.reset();
        setSelIndex(-1);
        if (adderrors.length) setAddErrors("");
    }

    const onCTValue = (e) => {
        setCTValue(e.target.value);
    }

    React.useEffect(()=> {
        setCTValue(selindex >= 0 ? contactmethods[selindex].ContactTypeID : 0);
    },[selindex,contactmethods])


    const selmethod = selindex >= 0 ? contactmethods[selindex] : null;

    const defSeq = selmethod===null ? "" : selmethod.ContactTypeSequence;
    //setCTValue(selmethod===null ? 0 : selmethod.ContactTypeID);
    const defphone = selmethod===null ? "" : selmethod.PhoneNumber;
    const defemail = selmethod===null ? "" : selmethod.PagerEMail;
    let nocellphone = true;
    let alreadysecure = false;
    let securegroupnum = props.provider.SecureGroupNum;
    if (securegroupnum === undefined) securegroupnum = -1000;
    let secureppid = 0;
    let securephone = "";
    let securetitle = "No Cell Phone contact method";
    contactmethods.forEach((cm) => {
        if (cm.ContactTypeID==='6' && parseInt(cm.PersonPhoneID) > 0) {
            nocellphone = false;
            securetitle = "Enroll in Secure Messaging";
        }
        if (cm.ContactTypeID==='7') {
            if (cm.PagerEMail.indexOf("@ptsecure.") > 0) {
                alreadysecure = true;
                secureppid = cm.PersonPhoneID;
                securephone = cm.PagerEMail.split('@')[0];
            }
        }
    })
    const confirmtext = "Deleting @ptsecure contact will\nremove Provider from Secure Messaging.\nAre you sure you want to do this?"
    //if (alreadysecure===true) {
    //    nocellphone = true;
    //    securetitle = "Already in Secure Messaging";
    //}
    return (
        <React.Fragment>
        <div className={styles.methods}>
        <div className={styles.outer}>
            <div className={styles.inner}>
                <div className={styles.methodsheader}>
                    <div>Contact Methods for {props.provider.LastName}, {props.provider.FirstName} {props.provider.MiddleInitial}</div>
                    <div className={styles.headerright} onClick={onClose}><Closer /></div>
                </div>
                <div className={styles.listrowhdr}>
                    <div>Seq #</div>
                    <div>Contact Type</div>
                    <div>Phone</div>
                    <div>Email</div>
                    <div>Delete</div>
                </div>
                {contactmethods.map((row,i) => {
                    let cobj = findContactMethod(row.ContactTypeID);
                    let dsc = "";
                    //let dscactive = row.OnCallProtocolStepsID > 0;
                    let dscactive=false;
                    if (cobj != null) {
                        dsc = cobj.Description; 
                    }
                    let issecure = row.PagerEMail.indexOf("@ptsecure.") > 0
                    return (
                        <div key={"cmrow_"+i} className={styles.listrow} onClick={()=>onRowClick(i)}>
                            <div>{row.ContactTypeSequence}</div>
                            <div>{dsc}</div>
                            <div>{row.PhoneNumber}</div>
                            <div>{row.PagerEMail}</div>
                            <div>
                                <input type="checkbox" data-id={"cb_"+i} onChange={()=>handleOnChange(i)} disabled={dscactive} onClick={(e)=>{e.stopPropagation()}}/>
                            </div>
                        </div>
                    )
                })}
                <div className={styles.listrowhdr} style={{paddingTop:"20px",borderTop:"1px solid gray"}}>
                <div>Seq #</div>
                <div>Contact Type</div>
                <div>Phone</div>
                <div>Email</div>
                <div></div>
                </div>
                <form ref={formref}>
                <div className={styles.listrowedit}>
                    <div><input ref={snref} defaultValue={defSeq} type="text" style={{width:"40px"}} maxLength="6" /></div>
                    <div>
                        <select value={ctvalue} style={{width:"150px"}} onChange={onCTValue}><option value="0"></option>
                            {contactTypeArray.map((opt,i)=> {
                                return <option key={"cmopt_"+i} value={opt.ContactTypeID}>{opt.Description}</option>;
                            })}
                        </select>
                    </div>
                    <div><input ref={pnref} defaultValue={defphone} disabled={ctvalue==='5' || ctvalue==='7'} type="text" style={{width:"110px"}} maxLength="30" /></div>
                    <div><input ref={emref} defaultValue={defemail} disabled={ctvalue==='4' || ctvalue==='6'} type="text" style={{width:"280px"}} maxLength="254" /></div>
                    <div>
                        {selindex === -1 ?
                            <GenButton onclick={doAdd} id={1} buttontext="Add" bgcolor="white" color="black" width="60" height="20" />
                            : <GenButton onclick={doUpdate} id={1} buttontext="Update" bgcolor="white" color="black" width="60" height="20" />
 
                    }
                    </div>
                </div>
                </form>
                <div className={styles.errors} style={{"--bk_color":adderrors.length > 0 ? "rgb(233, 204, 204)" : "white"}}>
                    {adderrors}
                </div>
                <div style={{display:"flex",width:"100%",marginTop:"30px",justifyContent:"center"}}>
                    <div><GenButton buttontext="Save all" width="70" onclick={onSave} /></div>
                    <div style={{marginLeft:"80px"}}><GenButton buttontext="Secure Messaging" width="140" disabled={nocellphone} buttontitle={securetitle} onclick={onSecure} /></div>
                    <div style={{marginLeft:"80px"}}><GenButton buttontext="Cancel" width="70" onclick={onClose}/></div>
                </div>

            </div>
            {showconfirm && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                text={confirmtext}
                width={620}
                onCancel = {onSaveNo}
                onConfirm = {(e)=>onSaveYes(true)}
                confirmText = "Yes" 
                cancelText = "No" />}
        </div>
        {showsecure && <SecureMessage 
                        globals={props.globals} 
                        methods={props.methods} 
                        securegroups={props.securegroups} 
                        contactmethods={contactmethods} 
                        onclose={onSecureClose} 
                        provider={props.provider}
                        secureppid={secureppid}
                        securephone={securephone}
                        securegroupnum={securegroupnum} 
                        alreadysecure={alreadysecure} />}
        </div>

        </React.Fragment>
    )
    
}

export default ContactMethods;