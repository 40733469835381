import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./GenCheckList.module.scss";

const GenCheckList = (props) => {
  // State with list of all checked item
  const [checked, setChecked] = useState(props.checked || []);
  const checkList = props.options || [];
  const checklistname = props.listName || "GenCheckList";
  const scrollname = checklistname + "div";
  const [scrollPosition, setScrollPosition] = useState(0);
  const DoChange = (uplist) => {

  }

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    const cb = props.onCallback || DoChange;
    cb(updatedList);
    /*
    cb(updatedList.length ? updatedList.reduce((total, item) => {
        return total + ", " + item;
      })
    : "")
      */
  };

  const DoScroll = (pos) => {
    setScrollPosition(pos);
  }

  const handleScroll = (event) => {
    const {scrollTop} = event.target;
    const cb = props.onScroll || DoScroll;
    cb(scrollTop);
  }

  const scrollToPos = (spos) => {
    const divElement = document.getElementById(scrollname);
    if (divElement) {
      divElement.scrollTo({
        top:spos
      })
    }
  }

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem(checklistname);
    if (storedScrollPosition) {
      setScrollPosition(Number(storedScrollPosition));
      scrollToPos(storedScrollPosition);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(checklistname, scrollPosition);
  }, [scrollPosition]);

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? styles.checkeditem : styles.notcheckeditem;

  const wt = props.width || 270;
  const ht = props.height || 350;
  const scrollpos = props.scrolltop || scrollPosition;
    return (
    <div className={styles.main} style={{border:"1px solid black"}}>
      <div id={scrollname} className={styles.checkList} onScroll={handleScroll} scrolltop={scrollPosition}>
        <div className={styles.listcontainer} style={{width:`${wt}px`,height:`${ht}px`}}>
          {checkList.map((item, index) => {
            let isdis = item.startsWith('--');
            if (isdis) item = item.slice(2);
            return (<div key={index}>
              <input value={item} type="checkbox" disabled={isdis} defaultChecked={checked.includes(item)} onChange={handleCheck} />
              <span className={isChecked(item)}>{item}</span>
            </div>)
            })}
        </div>
      </div>
    </div>
  );
}

export default GenCheckList;