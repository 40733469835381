import React from "react";
import styles from "./GenButton.module.scss";
import {ReactComponent as Plus} from './../assets/plus.svg';
import {ReactComponent as Minus} from './../assets/minus.svg';
import {ReactComponent as LeftArrow} from './../assets/leftarrow.svg';
import {ReactComponent as RightArrow} from './../assets/rightarrow.svg';
import {ReactComponent as Calendar} from './../assets/calendar.svg';

const GenButton = (props) => {
    const wt = props.width || (props.buttontext.length * 10);
    const ht = props.height || "2rem";
    const fontsize = props.fontsize || "14px";
    let bgcolor = props.bgcolor || "";
    let hovcolor = props.hovercolor || "lightblue";
    let color = props.color || "";
    let hovcursor = props.cursor || "pointer";
    const id = props.id || 0;
    const preicon = props.preicon || "";
    const posticon = props.posticon || "";
    const tcolor = props.iconcolor || "black";
    const isdisabled = props.disabled || false;
    const btntitle = props.buttontitle || "";
    let opcolor = "1";

    if (isdisabled) {
        bgcolor = "lightgray";
        color = "black";
        hovcolor = "lightgray";
        hovcursor = "default";
        opcolor = "0.5";
    }
    const handleClick = (e) => {
        if (!isdisabled) {
            props.onclick(id);
        }
    }

    return (
        <div 
            className={styles.genbutton} 
            onClick={handleClick} 
            title={btntitle}
            style={{fontSize:`${fontsize}`,width:`${wt}px`,height:`${ht}px`,backgroundColor:`${bgcolor}`,color:`${color}`,"--custom_color":`${hovcolor}`,"--hov_cursor":`${hovcursor}`,"--opcolor":`${opcolor}`}}
            >
            {preicon==="plus" && <Plus className={styles.preicon} stroke={tcolor} />}
            {preicon==="minus" && <Minus className={styles.preicon} stroke={tcolor} />}
            {preicon==="leftarrow" && <LeftArrow className={styles.preicon} stroke={tcolor} />}
            {preicon==="rightarrow" && <RightArrow className={styles.preicon} stroke={tcolor} />}
            {preicon==="calendar" && <Calendar className={styles.preicon} stroke={tcolor} />}
            {props.buttontext}
            {posticon==="plus" && <Plus className={styles.posticon} stroke={tcolor} />}
            {posticon==="minus" && <Minus className={styles.posticon} stroke={tcolor} />}
            {posticon==="leftarrow" && <LeftArrow className={styles.posticon} stroke={tcolor} />}
            {posticon==="rightarrow" && <RightArrow className={styles.posticon} stroke={tcolor} />}
            {posticon==="calendar" && <Calendar className={styles.posticon} stroke={tcolor} />}
        </div>
    )
}

export default GenButton;