import React from 'react';
import styles from './ViewMyMessages.module.scss';

const ViewMyMessages = (props) => {

    return (
        <div className={styles.content}>View My Messages</div>
    )
}

export default ViewMyMessages;