// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import {useNavigate} from 'react-router-dom'; 
import {
  InputDropdown,
  BadgeBasic,
  Bell,
  Settings,
  HelpCircle,
  Avatar,
  Button,
  GenInput,
  AutoComplete
} from "./components";
import styles from "./HeaderExternal.module.scss";
import logo1 from './assets/logo1.svg';
import logo2 from './assets/logo2.svg';
import {TEST_NOTICE, APP_VERSION} from './AppConstants';

const HeaderExternal = (props) => {
  const history = useNavigate();
  const goHome = (e) => {
    history("/");
  }
  return (
    <div className={styles.headerExternal}>
      <div className={styles.frame251}>
        <div className={styles.ptSourceLogoTwo} onClick={goHome}>
          <img
            alt=""
            className={styles.ptSourceLogo}
            src={logo1}
            //src="https://static.overlay-tech.com/assets/7c3b8f0d-3c4c-4c8c-96b9-0efc332caa27.svg"
          />
          <img
            alt=""
            className={styles.portal}
            src={logo2}
            //src="https://static.overlay-tech.com/assets/4a7ddd1b-9364-4ab3-a34f-416a720ae8c1.svg"
          />
        </div>
        <div style={{marginLeft:"20px",color:"red",fontSize:"32px",fontWeight:"bold"}}>{TEST_NOTICE}</div>
        <div className={styles.frame252}>
        <AutoComplete
        placeholder="Enter Practice"
        defvalue={props.globals.practice.name}
        suggestions={props.globals.mypractices}
        globals={props.globals} 
        methods={props.methods} 
        readonly={true}
        />
        {APP_VERSION !== 1 &&      
          <div className={styles.frame275}>
            <div className={styles.alertIcon}>
              <Bell globals={props.globals} methods={props.methods} />
              <BadgeBasic />
            </div>
            <div className={styles.alertIcon}>
            <Settings globals={props.globals} methods={props.methods} />
            <BadgeBasic className={styles.badgeBasicTwo} />
            </div>
            <div className={styles.alertIcon}>
            <HelpCircle globals={props.globals} methods={props.methods} />
            </div>
          </div>
        }
        {APP_VERSION===1 && <div style={{width:"30px"}}>&nbsp;</div>}
          <div className={styles.frame283}>
            <Avatar className={styles.avatar} />
            <p className={styles.text} style={{marginLeft:"4px"}}>{props.globals.name}</p>
          </div>
          <Button globals={props.globals} methods={props.methods} />
        </div>
      </div>
    </div>
  );
};

export default HeaderExternal;