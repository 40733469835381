// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import { LogOut } from "./../components";
import {useNavigate} from 'react-router-dom';   
import styles from "./ButtonBase.module.scss";
import GenConfirm from "./GenConfirm";
import { DataService } from "../store/DataService";

const ButtonBase = (props) => {
  const [showconfirm,setShowConfirm] = React.useState(false);
  const history = useNavigate();
  const DoClk = (e) => {
    e.preventDefault();
    console.log('clicked!');
    let dd = props.methods.GetGlobalDirtyData() || false;
    console.log("SubMenu dd="+dd);
    if (dd===true) {
        setShowConfirm(true);
        
    } else {
        DoClkCommon();
    }
  }

  const DoClkCommon = () => {
    const dataservice = new DataService({guid:props.globals.sessionguid});
    dataservice.dologout(props.globals.useraccountid).then((data)=> {
      props.methods.SetUseraccountid(0);
      history("/");
    })

  }

  const DoClkCancel = (e) => {
    setShowConfirm(false);
  }

const DoClkConfirm = (e) => {
    setShowConfirm(false);
    props.methods.SetDirtyData(false);
    DoClkCommon();
}

const ShowConfirm = () => {
    const txt = "You haven't saved your changes.\nSelect Cancel to go back and Save.\nSelect Confirm to disregard changes and continue.";
    return (
        <GenConfirm
            text={txt}
            width={400}
            onCancel={DoClkCancel}
            onConfirm={DoClkConfirm}
        />
    )
}  

  return (
    <React.Fragment>
    <div className={styles.buttonBase} onClick={DoClk} >
      <LogOut className={styles.logOut} />
      <p className={styles.text}>Log Out</p>
    </div>
    {showconfirm && <ShowConfirm />}
    </React.Fragment>
  );
};

export default ButtonBase;