import React from 'react';
import styles from './ViewSchedMessages.module.scss';
import MessageSchedHeader from './../../components/MessageSchedHeader';
import {DataService} from './../../store/DataService';
import MessageSchedListHeader from './../../components/MessageSchedListHeader';
import MessageSchedListRow from './../../components/MessageSchedListRow';
import ScheduleDetail from './../../components/ScheduleDetail';
import GenConfirm from './../../components/GenConfirm';
import GenMsgBox from './../../components/GenMsgBox';
import ContactScreen from '../internal/ContactScreen';
import ContactPopup from '../internal/ContactPopup';

const INITIAL_SORT = 2  // Sort by Date initially

const ViewSchedMessages = (props) => {
    
    const [msgs,setMsgs] = React.useState([]);
    const [msgcounts,setMsgCounts] = React.useState({total:1,open:1,closed:1});
    const [sortby,setSortBy] = React.useState(INITIAL_SORT);
    const [lastcheck,setLastCheck] = React.useState(false);
    const [showdetail,setShowDetail] = React.useState(0);
    const [showpracid,setShowPracID] = React.useState(0);
    const [contactmsg,setContactMsg] = React.useState(0);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [showcloseconfirm,setShowCloseConfirm] = React.useState(false);
    const [showok,setShowOK] = React.useState(false);
    const [searchstr,setSearchStr] = React.useState("");
    const [showclosed,setShowClosed] = React.useState(false);
    const [showalert,setShowAlert] = React.useState(false);
    const [alertdata,setAlertData] = React.useState({});

    const sortCallback = (v) => {
        setSortBy(v);
    }


    React.useEffect(()=> {
        console.log("VPM-useEffect...");
        let isMounted = true;
        if (refreshneeded) setRefreshNeeded(false);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const statid = showclosed ? 0 : 1;
        dataservice.getscheduledmessages(props.globals.practiceid,sortby,statid,0).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                if (isMounted) {
                    let m = data.MSGS || [];
                    let total = m.length;
                    let open = 0;
                    let closed = 0;
                    m.map((o,i) => {
                        o.checked=false;
                        o.Status===0 ? closed += 1 : open += 1;
                        return m;
                    })
                    setMsgs(m);
                    setMsgCounts({total:total,open:open,closed:closed});
                }
            }
        })
        return () => { isMounted = false };
    },[sortby,props.globals.practiceid,refreshneeded,props.globals.sessionguid,showclosed])

    const cbCallback = (id) => {
        msgs[id].checked = !msgs[id].checked;
    }

    const allCallback = (chked) => {
        console.log('AllCallBack chked='+chked);
        let tempmsgs = msgs.slice();
        tempmsgs.map((m,i) => {
            m.checked = chked;
            return m;
        });
        setMsgs(tempmsgs);
        setLastCheck(chked);
    }

    const handleShowClosed = (chked) => {
        allCallback(false);
        setShowClosed(chked);
    }

    const onOK = () => {
        setShowOK(false);
    }
    const onCancel = () => {
        setShowCloseConfirm(false);
    }
    const onConfirm = () => {
        setShowCloseConfirm(false);
        let msglst = [];
        let i = 0;
        for (i=0;i<msgs.length;i++) {
            if (msgs[i].checked===true) {
                msglst.push(msgs[i].MessageID);
            }
        }        
        let s = msglst.join(",");
        //console.log("msglst="+s);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.closemessages(props.globals.useraccountid,props.globals.userpracticeid,s).then((data)=> {
            let ed = data.ERROR || "";
            const cnt = data.COUNT || 0;
            const urgent = data.URGENT || 0;
            if (ed.length > 0) {
                ed += ", "+cnt+" messages were closed.";
                alert(ed);
                if (cnt > 0) {
                    setRefreshNeeded(true);
                }
            } else {
                setRefreshNeeded(true);
            }
            if (urgent > 0) {
                let msg = "Unable to close "+urgent;
                if (urgent===1) {
                    msg += " message that has ";
                } else {
                    msg += " messages that have ";
                }
                msg += "not been Acknowledged";
                alert(msg);
            }
        })

    }

    const batchClose = (e) => {
        const count = msgs.filter(item => item.checked===true).length;
        console.log("batchClose count="+count);
        if (count === 0) {
            //alert("At least 1 message must be checked to close");
            setShowOK(true);
            return;
        }
        //window.confirm('Are you sure you wish to Batch Close Messages?') ? onConfirm() : onCancel();
        setShowCloseConfirm(true);
    }

    const contactClose = (id) => {
        setContactMsg(0);
        //history("/");
        
    }

    const detailClose = (msgid) => {
        if (msgid >= 0) {
            setShowDetail(msgid);
        } else {
            setShowDetail(0);
            setContactMsg(-msgid);
        }
    }

    const alertClose = (id) => {
        setContactMsg(0);
        setShowAlert(false);
    }

    const updatePopup = (msgID) => {
        setShowAlert(false);
        if (props.globals.intext === 1) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.readalert(msgID).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setAlertData(data.QDATA);
                setShowAlert(true);
                setContactMsg(msgID);
            })
        }

    }

    const updateAlert = (msgID) => {
        if (props.globals.intext === 1) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.readalert(msgID).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setAlertData(data.QDATA);
                setShowAlert(true);
                setContactMsg(msgID);
            })
        }
    }

    const findMsg = (msgID) => {
        let msg = null;
        msgs.every((m)=> {
            if (m.MessageSchedID===msgID) {
                msg = m;
                return false;
            }
            return true;
        })
        return msg;
    }

    const updateDetail = (id) => {
        const msg = findMsg(id);
        if (msg) {
            setShowPracID(msg.PracticeID);
            setShowDetail(id);
        }
    }

    
    return (
            <div className={styles.viewmsg}>
                <h2 className={styles.title}>Scheduled Messages</h2>
                <div className={styles.content}>View and search current Scheduled Messages
                {props.globals.practiceid===0 && <span style={{marginLeft:"12px",fontSize:"18px",fontWeight:"bold"}}>for all Practices</span>}
                </div>
                <MessageSchedHeader 
                    globals={props.globals} 
                    methods={props.methods} 
                    sortCallback={sortCallback}
                    searchCallback={setSearchStr}
                    batchClose={batchClose}
                    showClosed={showclosed}
                    showClosedCallback={handleShowClosed}
                    />
                <MessageSchedListHeader 
                    globals={props.globals} 
                    methods={props.methods}
                    lastCheck={lastcheck}
                    allCallback={allCallback}
                    showClosed={showclosed} 
                    />
                {msgcounts.total < 1 && <div style={{marginLeft:"30px",marginTop:"12px",fontSize:"24px",fontWeight:"bold"}}>No Messages to display</div>}
                {msgcounts.open < 1 && showclosed===false && <div style={{marginLeft:"30px",marginTop:"12px",fontSize:"24px",fontWeight:"bold"}}>No OPEN Messages to display</div>}

                {msgs.map((msg,i) => {
                    if (!showclosed && msg.Status === 0) return null;
                    return <MessageSchedListRow 
                                key={i+"_"+msg.checked} 
                                cbid={i} 
                                globals={props.globals} 
                                methods={props.methods} 
                                cbCallback={cbCallback} 
                                msg={msg}
                                checked={msg.checked}
                                doDetail={updateDetail}
                                searchstr={searchstr}
                                showClosed={showclosed}
                                />
                })}
                {showdetail > 0 && <ScheduleDetail 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    doClose={detailClose}
                                    doRefresh={setRefreshNeeded}
                                    showClosed={showclosed}
                                    useactions={props.globals.intext===1}
                                    practiceid={showpracid} 
                                    msgnum={showdetail} />}
                {showcloseconfirm && <GenConfirm 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    text="Are you sure you want to Batch Close selected Messages?"
                                    onCancel = {onCancel}
                                    onConfirm = {onConfirm}
                                    confirmText = "Yes" />}
                {showok && <GenMsgBox 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    text="At least 1 Message must be checked to Batch Close."
                                    onOK = {onOK}
                                    buttonText = "Ok" />}

                </div>
        )
}

export default ViewSchedMessages;