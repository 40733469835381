import React from "react";
import styles from "./GenMsgBox.module.scss";

const GenMsgBox = (props) => {
    const doOK = (e) => {}
    const doBdClick = (e) => {}

    
    const onOK = props.onOK || doOK;
    const onBdClick = props.onBdClick || doBdClick;
    const buttonText = props.buttontext || "OK";
    const lines = props.text.split('<br/>');
    const bgcolor = props.bgcolor || "rgb(248, 245, 245)";
    const yoffset = props.yoffset || 0;
    const xoffset = props.xoffset || 0;
    let maxwt = 0;
    lines.forEach((ln)=>{
        if (ln.length > maxwt) maxwt = ln.length;
    })
    const wt = props.width || (maxwt * 10);
    return (
        <React.Fragment>
            <div className={styles.backdrop} onClick={onBdClick} />
            <div className={styles.modal} style={{width:`${wt}px`,backgroundColor:`${bgcolor}`,marginTop:`${yoffset}px`,marginLeft:`${xoffset}px`}}>
                {lines.map((ln,i) => {
                    return <p key={'ln_'+i}>{ln}</p>
                })}
                <button className={styles.btn} onClick={onOK}>
                    {buttonText}
                </button>
            </div>
        </React.Fragment>
    )
}

export default GenMsgBox;