import React from "react";
import styles from "./SysMsgMgmt.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";

var origsysmsg = {};

const SysMsgMgmt = (props) => {
    const [roles,setRoles] = React.useState([]);
    const [inputs,setInputs] = React.useState({
        subject:"",
        body:"",
        roleid:0
    });

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getroles().then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setRoles(data.rolelist);
            origsysmsg = {
                subject:"",
                body:"",
                roleid:0
            }
        })

    },[props.globals.sessionguid])


    const saveMsg = () => {
        if (inputs.subject.length < 1) {
            alert("Subject, (and Body) are required.");
            return;
        }
        if (inputs.body.length < 1) {
            alert("Body, (and Subject) are required.");
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savesysmsg(props.globals.useraccountid,inputs).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newinp = {
                subject:"",
                body:"",
                roleid:0
            }
            setInputs(newinp);
        })
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setInputs(values => ({...values,[name]:value}));
    }
    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>System Messages</div>
                <div className={styles.box}>
                    <div className={styles.boxrow}>
                        <div className={styles.col1}>Send to Role:</div><div></div>
                        <div>
                            <select name="roleid" style={{width:"200px"}} value={inputs.roleid} onChange={handleChange}>
                                <option value="0">All Roles</option>
                                {roles.map((role,i)=> {
                                    if (role.roletype==='2') return null;
                                    return <option key={"role_"+i} value={role.roleid}>{role.roledesc}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={styles.boxrow}>
                        <div className={styles.col1}>Subject:</div><div>*</div>
                        <div>
                            <input name="subject" className={styles.boxctrl} maxLength="150" value={inputs.subject} onChange={handleChange}></input>
                        </div>
                    </div>
                    <div className={styles.boxrow}>
                        <div className={styles.col1}>Body:</div><div>*</div>
                        <div>
                            <textarea className={styles.boxctrl} style={{resize:"none",height:"80px"}} maxLength="2000" name="body" value={inputs.body} onChange={handleChange}></textarea>
                        </div>
                    </div>
                </div>
                <div style={{width:"80%",marginTop:"20px",marginLeft:"auto"}}>
                    <GenButton buttontext="Send Message" onclick={saveMsg}></GenButton>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SysMsgMgmt;