import React from "react";
import styles from "./ProviderDetail.module.scss";
import GenButton from "./GenButton";
import {ReactComponent as Closer} from './../assets/close.svg';
import {DataService} from './../store/DataService';
import GenConfirm from './GenConfirm';
import GenCheckList from "./GenCheckList";
import ContactMethods from "./ContactMethods";


const ProviderDetail = (props) => {
    const [currprovider,setCurrProvider] = React.useState(props.provider);
    const [showcontactmethods,setShowContactMethods] = React.useState(false);
    const [lasterror,setLastError] = React.useState("");
    const [isoncall,setIsOnCall] = React.useState(currprovider.IsOnCallProvider);
    const [specialties,setSpecialties] = React.useState([]);
    const [credentials,setCredentials] = React.useState([]);
    const [oncallgroups,setOnCallGroups] = React.useState([]);
    const [dirtygroup,setDirtyGroup] = React.useState({});
    const dataservice = new DataService({guid:props.globals.sessionguid});
    const lnref = React.useRef();
    const fnref = React.useRef();
    const miref = React.useRef();
    //const ocrefy = React.useRef();
    const anrefy = React.useRef();
    const acrefy = React.useRef();
    //const ocrefn = React.useRef();
    const anrefn = React.useRef();
    const acrefn = React.useRef();
    const dearef = React.useRef();
    const pmrefy = React.useRef();
    const pmrefn = React.useRef();
    var orgspecialties = currprovider.SpecialtyList.map((x)=>x.desc);
    var orgcredentials = currprovider.CredentialList.map((x)=>x.desc);
    var orgoncallgroups = currprovider.OnCallGroups.map((x)=>x.Description);

    const newinst='To add a new provider, please enter basic information then “Save Changes”. After changes have been saved, select the provider and you will be able to update “OnCall Provider” status along with entering contact methods.'
    const showinst = currprovider.ProviderId < 1;

    const SetFlag = (name,val) => {
        let newgrp = JSON.parse(JSON.stringify(dirtygroup));
        newgrp[name] = val;
        setDirtyGroup(newgrp);
        console.log("SetFlag "+name+" = "+val);
    }

    const conmeth = currprovider.ContactMethods || [];
    //const sccnt = currprovider.SchedCount || 0;
    const sccnt = conmeth.length;
    let ocdisabled = sccnt < 1;
    if (!ocdisabled) ocdisabled = currprovider.ProviderId === 0;
    if (!ocdisabled) {
        let grps = currprovider.SchedGroup || [];
        ocdisabled = grps.length > 0;
    }
    const ocgcnt = oncallgroups.length || 0;
    let actdisabled = ocgcnt > 0;
    if (!actdisabled) actdisabled = currprovider.ProviderId === 0;

    (()=> {

        window.addEventListener("input",(evt)=> {
            //console.log("during input "+evt.target.id);
        });
        window.addEventListener("beforeunload",(evt)=> {
            
            console.log("before unload "+evt.target.name);
        });
    })();

    React.useEffect(() => {
        setSpecialties(currprovider.SpecialtyList.map((x)=>x.desc));
        setCredentials(currprovider.CredentialList.map((x)=>x.desc));
        setOnCallGroups(currprovider.OnCallGroups.map((x)=>x.Description));

    },[currprovider.SpecialtyList, currprovider.CredentialList, currprovider.OnCallGroups])


    const onClose = (e) => {
        props.onClose(null);
    }

    const ShowOnCallGroups = () => {

        const DoChange = (updlist) => {
            console.log("ShowOnCallGroups updlist "+updlist);
            setOnCallGroups(updlist);
            SetFlag('oncall',updlist.join(",") !== orgoncallgroups.join(","));

        }
        let allcg = props.callgroups;
        let provcg = oncallgroups;
        let cgarray = [];
        let grps = currprovider.SchedGroup || [];
        allcg.forEach((cg) => {
            let dsc = cg.desc.trim();
            if (grps.some(e=> e.desc.trim()===dsc)) {
                dsc = '--'+dsc;
            }
            cgarray.push(dsc);
        })

        return (
            <React.Fragment>
                <div style={{display:"block",marginTop:"30px",width:"330px"}}>
                    <div style={{fontSize:"18px"}}>
                    Call Groups
                    </div>
                    <GenCheckList options={cgarray} width="320" height="150" checked={provcg} onCallback={DoChange} />
                </div>
            </React.Fragment>
        )

    }

    const ShowSpecialties = () => {

        const DoChange = (updlist) => {
            console.log("ShowSpec updlist "+updlist);
            setSpecialties(updlist);
            SetFlag('specs',updlist.join(",") !== orgspecialties.join(","));

        }

        let allspecs = props.allspecialties || [];
        console.log("ProviderDetail - allspecs len="+allspecs.length);
        let provspecs = specialties;
        let specarray = [];
        allspecs.forEach((sp,i) => {
            let desc = sp.desc.trim();
            specarray.push(desc)
        });
        

        return (
            <React.Fragment>
                <div style={{display:"block",marginTop:"30px",width:"200px"}}>
                    <div style={{fontSize:"18px"}}>
                    Specialties
                    
                    </div>
                    <GenCheckList options={specarray} width="190" height="150" checked={provspecs} onCallback={DoChange} listName={"SpecsList"} />
                </div>
            </React.Fragment>
        )
    }

    const ShowCredentials = () =>  {
        const DoChange = (updlist) => {
            console.log("ShowCred updlist "+updlist);
            setCredentials(updlist);
            SetFlag('creds',updlist.join(",") !== orgcredentials.join(","));
        }
        let allcreds = props.globals.allcredentials;
        let provcreds = credentials;
        let credarray = [];
        allcreds.forEach((sp,i) => {
            credarray.push(sp.desc.trim());
        })
        
        return (
            <React.Fragment>
                <div style={{display:"block",marginTop:"30px",width:"200px"}}>
                    <div style={{fontSize:"18px"}}>
                        Credentials
                        
                    </div>
                    <GenCheckList options={credarray} width="190" height="150" checked={provcreds} onCallback={DoChange} listName={"CredsList"} />
                </div>
            </React.Fragment>
        )
        
    }

    const CheckDiff = () => {
        let diff=false;
        Object.keys(dirtygroup).forEach((obj) => {
            diff = diff | dirtygroup[obj];
        })
        if (!diff) {
            diff = currprovider.ProviderId===0;
        }
        return diff;
    }

    const DoSave = (id) => {
        //let isoncall = ocrefy.current.checked ? 'Y' : 'N';
        let newpt = anrefy.current.checked ? 'Y' : 'N';
        let active = acrefy.current.checked ? 'Y' : 'N';
        let pracmem = pmrefy.current.checked ? 'Y' : '';
        let specs = [];
        let creds = [];
        let callgrps = [];
        let allcallgroups = props.callgroups;

        if (isoncall==='Y') {
            if (currprovider.ContactMethods.length < 1) {
                setLastError("At least 1 Contact Method is needed for On Call Provider");
                return;
            }
        }

        oncallgroups.forEach((obj)=> {
            allcallgroups.forEach((cg) => {
                if (cg.desc===obj) {
                    callgrps.push({OnCallID:cg.id,OnCallSequence:0,Description:cg.desc});
                }
            })
        })

        specialties.forEach(spec=> {
            let result = props.allspecialties.find(({desc})=> desc === spec);
            if (result) specs.push(result);
        })

        credentials.forEach(cred=> {
            let result = props.globals.allcredentials.find(({desc})=> desc === cred);
            if (result) creds.push(result);
        })

        let obj = {
            ID:currprovider.ID,
            ProviderId:currprovider.ProviderId,
            PersonId:currprovider.PersonId,
            LastName:lnref.current.value,
            FirstName:fnref.current.value,
            MiddleInitial:miref.current.value,
            DEANumber:dearef.current.value,
            ProvName:currprovider.ProvName,
            IsOnCallProvider:isoncall,
            AcceptsNewPatients:newpt,
            DisplayOnInfo:currprovider.DisplayOnInfo,
            ActiveFlag:active,
            PracticeMember:pracmem,
            SpecialtyList:specs,
            CredentialList:creds,
            OnCallGroups:callgrps,
            ContactMethods:currprovider.ContactMethods
        }
        dataservice.updateprovider(props.globals.practiceid,obj).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            props.onClose(obj);
        });
    }

    const closeMethods = (e) => {
        setShowContactMethods(false);
    }

    const doContactUpdate = (prov) => {
        props.onUpdate(prov);
        setCurrProvider(prov);
    }

    const Buttons = () => {
        //const discm = CheckDiff();
        const discm = currprovider.ProviderId === 0;
        const cmtitle = discm === true ? "Must save changes first" : "";
        console.log("discm="+discm+",title="+cmtitle);
        return (
            <React.Fragment>
            <div style={{display:"block"}}>
                <GenButton 
                onclick={DoSave} 
                id={currprovider.ID} 
                buttontext="Save Changes"
                bgcolor="white"
                color="black"
                width="100"
                height="30"
                />
                <div style={{marginTop:"30px"}}>
                    <GenButton 
                        onclick={onClose} 
                        id={currprovider.ID} 
                        buttontext="Cancel Changes"
                        bgcolor="white"
                        color="black"
                        width="100"
                        height="30"
                    />
                </div>
                <div style={{marginTop:"30px"}}>
                    <GenButton 
                        onclick={()=>setShowContactMethods(true)} 
                        id={currprovider.ID} 
                        buttontext="Contact Methods"
                        bgcolor="white"
                        color="black"
                        width="100"
                        height="30"
                        buttontitle={cmtitle}
                        disabled={discm}
                    />
                </div>
            </div>
            </React.Fragment>
        )
    }

    const handleOnCallYes = (e) => {
        let ocflag = 'N';
        if (e.target.checked) {
            ocflag = 'Y';
            setIsOnCall('Y');
        }
        SetFlag("isoncall",ocflag !== currprovider.IsOnCallProvider);
    }
    const handleOnCallNo = (e) => {
        let ocflag = 'Y';
        if (e.target.checked) {
            setIsOnCall('N');
            ocflag = 'N';
        }
        SetFlag("isoncall",ocflag !== currprovider.IsOnCallProvider);
    }

    const handleChange = (e) => {
        let cid = e.target.id;
        let v = e.target.value;
        if (cid==='lastname') {
            SetFlag(cid,v !== currprovider.LastName);
            return;
        }
        if (cid==='firstname') {
            SetFlag(cid,v !== currprovider.FirstName);
            return;
        }
        if (cid==='midinit') {
            SetFlag(cid,v !== currprovider.MiddleInitial);
            return;
        }
        if (cid==='deanumber') {
            SetFlag(cid,v !== currprovider.DEANumber);
            return;
        }
        if (cid==='activeyes') {
            SetFlag("active",currprovider.ActiveFlag === 'N');
            return;
        }
        if (cid==='activeno') {
            SetFlag("active",currprovider.ActiveFlag === 'Y');
            return;
        }
        if (cid==='pracmemyes') {
            SetFlag("pracmem",currprovider.PracticeMember === 'Y');
            return;
        }
        if (cid==='pracmemno') {
            SetFlag("pracmem",currprovider.PracticeMember === '');
            return;
        }
        if (cid==='acceptsnewyes') {
            SetFlag("acceptsnew",currprovider.AcceptsNewPatients==='N');
            return;
        }
        if (cid==='acceptsnewno') {
            SetFlag("acceptsnew",currprovider.AcceptsNewPatients==='Y');
        }
    }

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.validatesession();
    },[props.globals.sessionguid])

    
    let octitle = "Requires Contact Methods";
    if (ocdisabled) {
        octitle = "Disabled when on future calendars";
    }

    if (showcontactmethods) {
        return <ContactMethods 
                globals={props.globals} 
                methods={props.methods} 
                provider={currprovider} 
                onClose={closeMethods} 
                onUpdate={doContactUpdate} 
                contacttypes={props.contacttypes} 
                isoncall={isoncall}
                securegroups={props.securegroups}
                />
    } else {

    return (
        <div className={styles.detail}>
            <div className={styles.outer}>
                <div className={styles.inner}>
                    <div className={styles.detailheader}>
                        <div>Provider details</div>
                        <div className={styles.headerright} onClick={onClose}><Closer /></div>
                    </div>
                    {showinst && <div className={styles.instruct}>{newinst}</div>}
                    <div className={styles.lefthalf}>
                        <div className={styles.listrowhdr}>
                            <div>Last Name</div>
                            <div>First Name</div>
                            <div>Middle Initial</div>
                        </div>
                        <div className={styles.listrow}>
                            <div><input type="text" name="lastname" id="lastname" onChange={handleChange} maxLength="30" ref={lnref} defaultValue={currprovider.LastName} /></div>
                            <div><input type="text" name="firstname" id="firstname" maxLength="20" onChange={handleChange} ref={fnref} defaultValue={currprovider.FirstName} /></div>
                            <div><input type="text" name="midinit" id="midinit" maxLength="1" onChange={handleChange} ref={miref} defaultValue={currprovider.MiddleInitial} /></div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>DEA Number</div>
                            <div>Member of Practice</div>
                        </div>
                        <div className={styles.listrow}>
                            <div><input type="text" name="deanumber" id="deanumber" onChange={handleChange} maxLength="10" ref={dearef} defaultValue={currprovider.DEANumber} /></div>
                            <div>
                                <label title={octitle}>
                                <input type="radio" name="pracmem" id="pracmemyes" ref={pmrefy} onChange={handleChange} defaultChecked={currprovider.PracticeMember==="Y"} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}} title={octitle}>
                                <input type="radio" name="pracmem" id="pracmemno" ref={pmrefn} onChange={handleChange} defaultChecked={currprovider.PracticeMember !== "Y"} />
                                No
                                </label>                        
                            </div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>OnCall Provider</div>
                            <div>Accepts New Patients</div>
                            <div>Active</div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>
                                <label title={octitle}>
                                <input type="radio" name="oncall" id="oncallyes" disabled={ocdisabled} onChange={handleOnCallYes} defaultChecked={isoncall==='Y'} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}} title={octitle}>
                                <input type="radio" name="oncall" id="oncallno" disabled={ocdisabled} onChange={handleOnCallNo} defaultChecked={isoncall==="N"} />
                                No
                                </label>
                            </div>
                            <div>
                                <label>
                                <input type="radio" name="acceptsnew" id="acceptsnewyes" onChange={handleChange} ref={anrefy} defaultChecked={currprovider.AcceptsNewPatients==="Y"} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}}>
                                <input type="radio" name="acceptsnew" id="acceptsnewno" onChange={handleChange} ref={anrefn} defaultChecked={currprovider.AcceptsNewPatients==="N"} />
                                No
                                </label>
                            </div>
                            <div>
                                <label title="Disabled if member of Call Group" >
                                <input type="radio" name="active" id="activeyes" onChange={handleChange} disabled={actdisabled} ref={acrefy} defaultChecked={currprovider.ActiveFlag==="Y"} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}} title="Disabled if member of Call Group" >
                                <input type="radio" name="active" id="activeno" onChange={handleChange} disabled={actdisabled} ref={acrefn} defaultChecked={currprovider.ActiveFlag==="N"} />
                                No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.righthalf}>
                        <Buttons />
                    </div>
                </div>
                {lasterror.length > 0 ? <div className={styles.error}>{lasterror}</div> : <div></div>}
                <div className={styles.selecthdr} style={{display:"flex"}}>
                    <div><ShowSpecialties /></div>
                    <div><ShowCredentials /></div>
                    <div><ShowOnCallGroups /></div>
                </div>

            </div> 
        </div> 
    )
    }
}

export default ProviderDetail;