import React from "react";
import styles from "./GenTertiary.module.scss";

const GenTertiary = (props) => {

    const cancelText = props.cancelText || "Cancel";
    const yesText = props.yesText || "Yes";
    const noText = props.noText || "No";

    const doCancel = (e) => {}
    const doYes = (e) => {}
    const doNo = (e) => {}
    const doBdClick = (e) => {}

    const onCancel = props.onCancel || doCancel;
    const onYes = props.onYes || doYes;
    const onNo = props.onNo || doNo;
    const onBdClick = props.onBdClick || doBdClick;
    const wt = props.width || (props.text.length * 10);
    const btwt = props.buttonwidth || 130;
    const lines = props.text.split('\n');

    return (
        <React.Fragment>
            <div className={styles.backdrop} onClick={onBdClick} />
            <div className={styles.modal} style={{width:`${wt}px`}}>
                {lines.map((ln,i) => {
                    return <p key={'ln_'+i}>{ln}</p>
                })}
                
                <button className={styles.btn} styles={{width:`${btwt}px`}} onClick={onYes}>
                    {yesText}
                </button>
                <button className={styles.btn} styles={{width:`${btwt}px`}} onClick={onNo}>
                    {noText}
                </button>
                <button className={styles.btnalt} styles={{width:`${btwt}px`}} onClick={onCancel}>
                    {cancelText}
                </button>
            </div>
        </React.Fragment>
    )
}

export default GenTertiary;