import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './ShowCalendar.module.scss';

const ShowCalendar = (props) => {
    const doNothing = (v) => {

    }
    const onClose = (e) => {
        let cb = props.onChange;
        if (!cb) cb = doNothing;
        cb(null);
    }

    return (
        <React.Fragment>
            <div className={styles.backdrop}></div>
            <div className={styles.modal}>
                <div style={{width:"100%",textAlign:"right"}}><button onClick={onClose}>X</button></div>
                <Calendar {...props} calendarType={"US"} />
            </div>
        </React.Fragment>
    )
}

export default ShowCalendar;