import React from "react";
import { useState } from "react";
import styles from './AutoComplete.module.scss';
import {ReactComponent as Clear} from "./../assets/close.svg";

const AutoComplete = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState(props.defvalue || "");

    const ph = props.placeholder || "Submit";
    const inpwt = props.inputwidth || "300px";
    const inpht = props.inputheight || "30px";
    const sugwt = props.sugwidth || "275px";
    const sught = props.sugheight || "30px";
    const readonly = props.readonly || false;
    
    const onChange = (e) => {
        const userInput = e.target.value;
        const suggestions = props.suggestions || [];
        
        // Filter our suggestions that don't contain the user's input
        let unLinked = suggestions.filter(
          (suggestion) =>
            suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        if (!unLinked.length) {
          const v = ","+userInput+",";
          unLinked = suggestions.filter(
            (suggestion) => suggestion.acctnums.indexOf(v) > -1
          );
        }
    
        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
      };

      const doNothing = (v,anum='') => {
        console.log("Autocomplete - doNothing "+v+", anum="+anum);
      }

      const tellParent = (v,anum='') => {
        const tp = props.update || doNothing;
        tp(v,anum);
      }

      const onClick = (e) => {
        const anum = input;
        setFilteredSuggestions([]);
        setInput(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        tellParent(e.target.innerText,anum);
      };

      const doClear = (e) => {
        setInput("");
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        tellParent("");
      }

      const onKeyDown = (e) => {
        const ky = e.keyCode; // Make sure to look at .key names instead of keyCode (13=='Enter' for example)
        if (ky===13) {
            const anum = input;
            const v = filteredSuggestions[activeSuggestionIndex].name;
            setInput(v);
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            tellParent(v,anum);
        }
        if (ky===27) {
          doClear(e);
        }
        if (ky===38) {
            let x = activeSuggestionIndex - 1;
            if (x < 0) x = 0;
            setActiveSuggestionIndex(x);
        }
        if (ky===40) {
            let x = activeSuggestionIndex + 1;
            if (x >= filteredSuggestions.length) x = filteredSuggestions.length-1;
            setActiveSuggestionIndex(x);
        }
      }

      const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
          <ul className={styles.suggestions} style={{width:sugwt,minHeight:sught}}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestionIndex) {
                className = styles.suggestionactive;
              }
              return (
                <li className={className} key={suggestion.id} onClick={onClick}>
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.nosuggestions}>
            <em>No suggestions</em>
          </div>
        );
      };

      return (
        <div className={styles.input} tabIndex="0" style={{width:inpwt,height:inpht}}>
          <input className={styles.innerinput}
            type="text"
            placeholder={ph}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={input}
            readOnly={readonly}
          />
          {!readonly && <Clear onClick={doClear} style={{cursor:"pointer"}} />}
          {showSuggestions && input && <SuggestionsListComponent />}
        </div>
      );
};

export default AutoComplete;