import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './AlertBox.module.scss';
import GenButton from './GenButton';

const AlertComponent = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const closeAlert = () => {
      setIsVisible(false);
      onClose();
    };

    // Automatically close the alert after 30 seconds
    const timeout = setTimeout(closeAlert, 30000);

    // Clear timeout on component unmount
    return () => clearTimeout(timeout);
  }, [onClose]);

  let modshow = styles.modal;
  if (isVisible) {
    modshow = styles.modalshow;
  }
  return (
    <div className={modshow}>
        <div className={styles.modalcontent}>
            <p>{message}</p>
            <div className={styles.modalbutton}>
                <GenButton buttontext={"OK"} onclick={() => setIsVisible(false)} />
            </div>
        </div>
    </div>
  );
};

const AlertBox = (message) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
  };

  ReactDOM.render(
    <AlertComponent message={message} onClose={handleClose} />,
    div
  );
};

export default AlertBox;
