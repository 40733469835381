import React from "react";
import styles from "./OnCallEdit.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import GenConfirm from "./../../components/GenConfirm";
import GenPrompt from "../../components/GenPrompt";
import GenMsgBox from "../../components/GenMsgBox";

var origgroupinputs = {};
var pendinggroup = 0;
var newgroupname = "";

const OnCallEdit = (props) => {
    const [matrix,setMatrix] = React.useState({oncall:[],providers:[],pocs:[]});
    const [currentgroup,setGroup] = React.useState(0);
    const [selectconfirm,setShowSelectConfirm] = React.useState(false);
    const [deleteconfirm,setDeleteConfirm] = React.useState(false);
    const [addnew,setAddNew] = React.useState(false);
    const [showerr,setShowErr] = React.useState(false);
    const [inputs,setInputs] = React.useState({oncallid:0,description:"",finalcontactproviderid:0,finalinstructions:"",sthour:7,stmin:"AM",stampm:"AM"});

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.oncallinit(props.globals.practiceid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.matrix);
            if (data.matrix.oncall.length > 0) {
                const oncall = data.matrix.oncall[0];
                const ocid = oncall.oncallid;
                setSelectedGroup(ocid,data.matrix);
            }
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const setSelectedGroup = (oncallid,data=null) => {
        let m = data;
        if (m===null) m = matrix;
        const ocid = "" + oncallid;
        m.oncall.every((oncall)=> {
            if (oncall.oncallid===ocid) {
                let fcpid = oncall.finalcontactproviderid;
                if (fcpid.length < 1) fcpid = "0";
                fcpid = parseInt(fcpid);
                const sa = oncall.shiftchangetime.split(':');
                let sthour = 0;
                let stmin = "00";
                let stampm = "AM";
                if (sa.length > 1) {
                    sthour = parseInt(sa[0]);
                    stmin = sa[1];
                    if (sthour > 11) {
                        stampm = "PM";
                        if (sthour > 12) sthour -= 12;
                    }
                }
                const obj = {
                    oncallid:ocid,
                    description:oncall.description,
                    finalcontactproviderid:fcpid,
                    finalinstructions:oncall.finalinstructions,
                    sthour:sthour,
                    stmin:stmin,
                    stampm:stampm
                    }
                origgroupinputs = obj;
                if (obj.finalcontactproviderid !== undefined) {
                    origgroupinputs.finalcontactproviderid=""+origgroupinputs.finalcontactproviderid;
                    origgroupinputs.sthour=""+origgroupinputs.sthour;
                }
                setInputs(obj);
                return false;
            }
            return true;
        })
        setGroup(ocid);
    }

    const clearProviders = () => {
        let provs = JSON.parse(JSON.stringify(matrix.providers));
        provs.forEach((prov)=> {
            prov.willadd = false;
        })
        let temppoc = JSON.parse(JSON.stringify(matrix.pocs));
        temppoc.forEach((p)=> {
            p.willdelete = false;
        })
        setMatrix(values=>({...values,providers:provs,pocs:temppoc}));
    }

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const isDirty = () => {
        if (matrix.oncall.length < 1) return false;
        if (!compareObj(inputs,origgroupinputs)) return true;
        let result = false;
        matrix.oncall.every((oncall)=> {
            if (oncall.willdelete) {
                result = true;
                return false;
            }
            return true;
        })
        if (!result) {
            matrix.pocs.every((poc)=> {
                if (poc.oncallid===currentgroup) {
                    if (poc.willdelete) {
                        result = true;
                        return false;
                    }
                }
                return true;
            })    
        }
        if (!result) {
            matrix.providers.every((prov)=> {
                if (prov.willadd) {
                    result = true;
                    return false;
                }
                return true;
            })
        }
        return result;
    }

    const selectGroupConfirm = (e) => {
        setSelectedGroup(pendinggroup,matrix);
        clearProviders();
        setShowSelectConfirm(false);

    }

    const selectGroupCancel = (e) => {
        setShowSelectConfirm(false);
    }

    const ShowSelectConfirm = () => {
        const txt = "You haven't saved your changes.\nSelect Cancel to go back and Save.\nSelect Confirm to disregard changes and continue.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={selectGroupCancel}
                onConfirm={selectGroupConfirm}
            />
        )
    }

    const ShowGroups = () => {

        const handleSelect = (e) => {
            const name = e.target.parentNode.attributes.name.value;
            const sa = name.split("_");
            if (sa.length > 1) {
                const ocid = sa[1];
                if (isDirty()) {
                    pendinggroup = ocid;
                    setShowSelectConfirm(true);
                } else {
                    setSelectedGroup(ocid,matrix);
                    clearProviders();    
                }
            }
        }

        const handleDel = (e) => {
            const name = e.target.attributes.name.value;
            const chk = e.target.checked;
            const sa = name.split("_");
            if (sa.length > 1) {
                const ocid = sa[1];
                let newoncall = JSON.parse(JSON.stringify(matrix.oncall));
                newoncall.every((oncall)=> {
                    if (oncall.oncallid===ocid) {
                        oncall.willdelete = chk;
                        return false;
                    }
                    return true;
                })
                setMatrix(values=>({...values,oncall:newoncall}));
            }
        }
        
        return (
            <div className={styles.groups}>
                <div className={styles.grouprow}>
                    <div className={styles.groupheader}>Group Name</div>
                    <div className={styles.groupheader}>Delete</div>
                </div>
                {matrix.oncall.map((group,i)=> {
                    const oddeven = i & 1;
                    let bgcolor = ["white","rgb(230, 227, 227)"][oddeven];
                    if (group.oncallid===currentgroup) bgcolor="lightgreen";
                    return (
                        <div name={"gd_"+group.oncallid} key={"grow_"+i} className={styles.grouprow} style={{backgroundColor:bgcolor}} onClick={handleSelect}>
                        <div style={{userSelect:"none"}}>{group.description}</div>
                        <div><input type="checkbox" name={"grp_"+group.oncallid} checked={group.willdelete} onChange={handleDel} /></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const ShowGroupDetail = () => {

        const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setInputs(values=>({...values,[name]:value}));
        }

        const showShiftChange = false;
        return (
            <div>
                <div style={{width:"902px",textAlign:"center",height:"24px",marginLeft:"18px"}} className={styles.groupheader}>Group Detail</div>
                <div className={styles.groupdetail}>
                    <div style={{textAlign:"right",paddingRight:"4px",marginTop:"12px"}}>Group Description:*</div>
                    <div style={{marginTop:"12px"}}><input type="text" name="description" value={inputs.description} onChange={handleChange}></input></div>
                    <div style={{textAlign:"right",paddingRight:"4px",marginTop:"8px"}}>Final Contact:</div>
                    <div style={{marginTop:"8px"}}>
                        <select name="finalcontactproviderid" value={inputs.finalcontactproviderid} onChange={handleChange}>
                            <option value={0}></option>
                            {matrix.providers.map((prov,i)=> {
                                return <option value={prov.providerid}>{prov.name}</option>
                            })}
                        </select>
                    </div>
                    <div style={{textAlign:"right",paddingRight:"4px",marginTop:"8px"}}>Final Contact Instructions:</div>
                    <div style={{marginTop:"8px"}}><textarea rows="8" cols="90" style={{resize:"none"}} name="finalinstructions" value={inputs.finalinstructions} onChange={handleChange}></textarea></div>
                    {showShiftChange &&
                    <React.Fragment>
                    <div style={{textAlign:"right",paddingRight:"4px"}}>Shift Change Time:*</div>
                    <div>
                        <span>
                            <select name="sthour" value={inputs.sthour} onChange={handleChange}>
                                {[1,2,3,4,5,6,7,8,9,10,11,12].map((opt,i) => {
                                    return <option key={"sth_"+i} value={opt}>{opt}</option>
                                })}
                            </select>
                        </span>:<span>
                                <select name="stmin" value={inputs.stmin} onChange={handleChange}>
                                    <option value="00">00</option>
                                    <option value="30">30</option>
                                </select>
                        </span><span>
                                <select name="stampm" value={inputs.stampm} onChange={handleChange}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                        </span>
                    </div>
                    </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    const ShowProvidersInGroup = () => {

        const findProvider = (pid) => {
            let prov = {};
            const prid = "" + pid;
            
            matrix.providers.every((p)=> {
                const provid = "" + p.providerid;
                
                if (provid===prid) {
                    prov = p;
                    return false;
                }
                return true;
            })
            return prov;
        }

        const setRemove = (e) => {
            const sa = e.target.attributes.name.value.split("_");
            const chk = e.target.checked;
            console.log("sa="+sa);
            if (sa.length > 1) {
                const pocid = sa[1];
                let newpocs = JSON.parse(JSON.stringify(matrix.pocs));
                newpocs.every((poc) => {
                    if (poc.provideroncallid===pocid) {
                        poc.willdelete = chk;
                        setMatrix(values=>({...values,pocs:newpocs}));
                        return false;
                    }
                    return true;
                })
            }
        }

        return (
            <React.Fragment>
                <div className={styles.ingroup}>
                <div className={styles.groupheader}>Providers In Group</div>
                <div>
                    <div className={styles.ingrouprow}>
                        <div className={styles.ingrouphdr}>Provider</div>
                        <div className={styles.ingrouphdr}>Credentials</div>
                        <div className={styles.ingrouphdr}>Specialty</div>
                        <div className={styles.ingrouphdr}>Remove</div>
                    </div>
                    {matrix.pocs.map((poc,i)=> {
                        if (poc.oncallid !== currentgroup) return null;
                        const prov = findProvider(poc.providerid);
                        return (
                            <div key={"poc_"+i} className={styles.ingrouprow}>
                                <div>{prov.name}</div>
                                <div>{prov.creds}</div>
                                <div>{prov.specs}</div>
                                <div style={{marginLeft:"18px"}}>
                                    <input type="checkbox" name={"rem_"+poc.provideroncallid} checked={poc.willdelete} onChange={setRemove} />
                                </div>
                            </div>
                        )

                    })}
                </div>
                </div>
            </React.Fragment>
        )

    }

    const ShowProvidersNotInGroup = () => {

        const setAdd = (e) => {
            const sa=e.target.attributes.name.value.split("_");
            const chk = e.target.checked;
            if (sa.length > 1) {
                const pid = sa[1];
                let newprovs = JSON.parse(JSON.stringify(matrix.providers));
                newprovs.every((prov)=> {
                    if (prov.providerid===pid) {
                        prov.willadd = chk;
                        setMatrix(values=>({...values,providers:newprovs}));
                        return false;
                    }
                    return true;
                })
            }
        }

        let notprovs = [];
        let pocids = [];
        matrix.pocs.forEach((poc)=> {
            if (poc.oncallid===currentgroup) {
                pocids.push(poc.providerid);
            }
        })
        matrix.providers.forEach((prov)=> {
            if (!pocids.includes(prov.providerid)) {
                notprovs.push(prov);
            }
        })

        return (
            <React.Fragment>
                <div className={styles.outgroup}>
                <div className={styles.groupheader}>Providers NOT In Group</div>
                <div>
                    <div className={styles.outgrouprow}>
                        <div className={styles.outgrouphdr}>Provider</div>
                        <div className={styles.outgrouphdr}>Add</div>
                    </div>
                    {notprovs.map((prov,i)=> {
                        return (
                            <div key={"poc_"+i} className={styles.outgrouprow}>
                                <div>{prov.name}</div>
                                <div style={{marginLeft:"10px"}}>
                                    <input type="checkbox" name={"add_"+prov.providerid} checked={prov.willadd} onChange={setAdd} />
                                </div>
                            </div>
                        )

                    })}
                </div>
                </div>
            </React.Fragment>
        )
    }

    const doSave = (e) => {
        let result = false;
        matrix.oncall.every((oncall)=> {
            if (oncall.willdelete) {
                result = true;
                return false;
            }
            return true;
        })

        if (result) {
            setDeleteConfirm(true);
        } else {
            saveGroups();
        }
    }

    const saveGroups = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        let groupdel = [];
        let deletinggroup = false;
        matrix.oncall.forEach((oncall)=> {
            if (oncall.willdelete) {
                if (oncall.oncallid===currentgroup) deletinggroup = true;
                groupdel.push(oncall.oncallid);
            }
        })
        let provadd = [];
        matrix.providers.forEach((prov)=> {
            if (prov.willadd) {
                provadd.push(prov.providerid);
            }
        })
        let provrem = [];
        matrix.pocs.forEach((poc)=> {
            if (poc.willdelete) {
                provrem.push(poc.providerid);
            }
        })
        let obj = {
            detail:inputs,
            oncallid:parseInt(currentgroup),
            groupdel:groupdel,
            provadd:provadd,
            provrem:provrem
        }

        let tempgroup = currentgroup;
        dataservice.savegroups(props.globals.practiceid,
            props.globals.useraccountid,
            props.globals.userpracticeid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.matrix);
            let ocid = 0;
            if (!deletinggroup) {
                ocid = tempgroup;
                
            } else {
                if (data.matrix.oncall.length > 0) {
                    const oncall = data.matrix.oncall[0];
                    ocid = oncall.oncallid;
                }
            }
            setSelectedGroup(ocid,data.matrix);
        })
    }

    const doSaveConfirm = (e) => {
        setDeleteConfirm(false);
        saveGroups();
    }

    const doSaveCancel = (e) => {
        setDeleteConfirm(false);
    }

    const ShowDeleteConfirm = () => {
        const txt = "You are about to Delete On-Call Group(s).\nThis will delete everything including calendars and protocols.\nAre you sure you want to do this?";
        return (
            <GenConfirm
                text={txt}
                width={460}
                onCancel={doSaveCancel}
                onConfirm={doSaveConfirm}
                confirmText="Yes, Delete Selected Groups"
            />
        )
    }

    const doAdd = (e) => {
        newgroupname = "";
        setAddNew(true);
    }

    const ShowButtons = () => {
        
        return (
            <div className={styles.buttons}>
                <div><GenButton buttontext="Save" disabled={!isDirty()} onclick={doSave} /></div>
                <div><GenButton buttontext="Add New Group" disabled={isDirty()} onclick={doAdd} /></div>
            </div>
        )
    }

    const addNewConfirm = (desc) => {
        let result = false;
        matrix.oncall.every((oncall)=> {
            if (oncall.description.toLowerCase()===desc.toLowerCase()) {
                newgroupname = desc;
                setAddNew(false);
                setShowErr(true);
                result = true;
                return false;
            }
            return true;
        })

        if (!result) {
            setAddNew(false);
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.addnewgroup(props.globals.practiceid,
                props.globals.useraccountid,
                props.globals.userpracticeid,desc).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                } 
                let temponcall = JSON.parse(JSON.stringify(matrix.oncall));
                let newoncall = temponcall.concat(data.oncall);
                newoncall.sort(function compareFn(a,b) {
                    if (a.description.toLowerCase() < b.description.toLowerCase()) return -1;
                    if (a.description.toLowerCase() > b.description.toLowerCase()) return 1;
                    return 0;
                });
                
                setMatrix(values=>({...values,oncall:newoncall}));
                let ocid = 0;
                newoncall.every((obj) => {
                    if (obj.description.toLowerCase()===desc.toLowerCase()) {
                        ocid = obj.oncallid;
                        return false;
                    }
                    return true;
                })
                const tmp = {oncall:newoncall}
                setSelectedGroup(ocid,tmp);
                clearProviders();
            })
        }

    }

    const addNewCancel = (e) => {
        setAddNew(false);
    }

    const showErrOK = (e) => {
        setShowErr(false);
        setAddNew(true);
    }

    return (
        <React.Fragment>
            <div className={styles.title}>OnCall Groups for {props.globals.practice.name}</div>
            <div className={styles.page}>
                <ShowGroups />
                {ShowGroupDetail()}
                {ShowProvidersNotInGroup()}
                {ShowProvidersInGroup()}
                <ShowButtons />
                <div></div>
            </div>
            {selectconfirm && <ShowSelectConfirm />}
            {addnew && <GenPrompt text="New Group Description:" onConfirm={addNewConfirm} onCancel={addNewCancel} inpvalue={newgroupname} width={300} />}
            {showerr && <GenMsgBox text="Duplicate Group Description.<br/>Please change the description." onOK={showErrOK} />}
            {deleteconfirm && <ShowDeleteConfirm />}
        </React.Fragment>
    )
}

export default OnCallEdit;