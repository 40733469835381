import React from 'react';
import styles from './MessageHeaderInt.module.scss';
import GenInput from './GenInput';
import GenButton from './GenButton';
import PCSelect from "./PCSelect";
import {ReactComponent as UpArrow} from './../assets/arrow-up.svg';
import {ReactComponent as DownArrow} from './../assets/arrow-down.svg';
import {ReactComponent as Calendar} from "./../assets/calendar.svg";
import ShowCalendar from './ShowCalendar';
import {DataService} from './../store/DataService';
import { DateOnly } from '../AppConstants';

var lastsearch = "";
var lastmsg = "";

const MessageHeaderInt = (props) => {
    const [showdates,setShowDates] = React.useState(false);
    const [datesort,setDateSort] = React.useState(0);
    const [showfromcal,setShowFromCal] = React.useState(false);
    const [showtocal,setShowToCal] = React.useState(false);
    const [headerlists,setHeaderLists] = React.useState({recips:[],types:[],sorts:[],users:[]});
    const [inputs,setInputs] = React.useState({isearch:"",recip:"",status:1,msgtype:"",msgsort:"",dtfrom:"",dtto:"",msgnum:props.defmsgnum===0 ? '' : props.defmsgnum});
    const dateSortID = [2,6];

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("HC name="+name+",val="+value);
        setInputs(values => ({...values, [name]: value}))
    }

    const Dummy = (e) => {}

    const doSearch = (e) => {
        const cb = props.searchCallback || Dummy;
        console.log("doSearch isearch="+inputs.isearch);
        cb(inputs.isearch);
        lastsearch = inputs.isearch;
    }

    const doFindBlur = (e) => {
        if (inputs.isearch != lastsearch) {
            doSearch(null);
        }
    }

    const doMsgSearch = (e) => {
        const cb = props.msgnumCallback || Dummy;
        cb(inputs.msgnum);
        lastmsg = inputs.msgnum;
    }

    const doMsgBlur = (e) => {
        if (inputs.msgnum != lastmsg) {
            doMsgSearch(null);
        }
    }

    const setFromDate = (v) => {
        setShowFromCal(false);
        
        if (v !== null) {
            setInputs(values => ({...values,dtfrom:DateOnly(v)}));
            readDates(DateOnly(v),inputs.dtto);
        }
    }

    const setToDate = (v) => {
        setShowToCal(false);
        
        if (v !== null) {
            setInputs(values => ({...values,dtto:DateOnly(v)}));
            readDates(inputs.dtfrom,DateOnly(v));
        }
    }
    const readDates = (dfrom,dto) => {
        const cb = props.readDates || Dummy;
        cb(dfrom,dto);
    }
    const doRefresh = (e) => {
        //const cb = props.doRefresh || Dummy;
        //cb();
        readDates(inputs.dtfrom,inputs.dtto);
    }

    const OnKey = (e) => {
        if (e.key === 'Enter') {
            doSearch(e);
        }
    }

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const pracid = props.overridepractice || props.globals.practiceid;
        dataservice.getmessageheaderint(pracid).then((data) => {
            setHeaderLists(data);
        })
    },[props.globals.sessionguid,props.globals.practiceid,props.overridepractice])

    const opencount = props.msgcounts.open;
    const closecount = props.msgcounts.closed;
    const transfercount = props.msgcounts.transfer;
    const allcount = opencount+closecount+transfercount;
    const openclosedcount = opencount+closecount;
    const statusoptions = [
        {value:2,text:"All Modified by IC Rep ("+allcount+")"},
        {value:5,text:"Created by IC Rep ("+openclosedcount+")"},
        {value:0,text:"Closed by IC Rep ("+closecount+")"},
        {value:6,text:"Transferred by IC Rep ("+transfercount+")"}
    ]

    let mindate = inputs.dtfrom || "";
    if (mindate.length) mindate = new Date(mindate);
    let maxdate = inputs.dtto || "";
    if (maxdate.length) maxdate = new Date(maxdate);
    const fromdate = inputs.dtfrom || new Date();
    const todate = inputs.dtto || new Date();
    const nouser = parseInt(props.defuserid || 0) === 0;

    return (
        <React.Fragment>
            <div className={styles.headercontainer}>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{width:"60px",textAlign:"right"}}>Users: </div>
                        <div className={styles.selects}><PCSelect options={headerlists.users} minwidth={250} onchange={props.userCallback} /></div>
                    </div>
                    <div style={{display:"flex",marginTop:"10px"}}>
                    <div style={{width:"60px",textAlign:"right"}}>Type:</div>
                        <div className={styles.selects}><PCSelect options={headerlists.types} minwidth={250} onchange={props.msgtypeCallback} /></div>
                    </div>
                </div>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{width:"50px",textAlign:"right"}}>Status:</div>
                        <div className={styles.selects}><PCSelect options={statusoptions} defvalue={inputs.status} minwidth={230} onchange={props.statusCallback} /></div>
                    </div>
                    <div style={{display:"flex",marginTop:"10px"}}>
                    <div style={{width:"50px",textAlign:"right"}}>Sort:</div> 
                        <div className={styles.selects}><PCSelect options={headerlists.sorts} minwidth={230} onchange={props.sortCallback} /></div>
                    </div>
                </div>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{width:"90px",textAlign:"right"}}>Search:</div>
                        <div className={styles.inputs}><input disabled={nouser} style={{height:"20px",border:"none",padding:"0",margin:"",width:"120px"}} type="text" name="isearch" onBlur={doFindBlur} onChange={handleChange} value={inputs.isearch} /></div>
                        <button disabled={nouser} className={styles.mediumbutton} onClick={doSearch}>Find text</button>
                    </div>
                    <div style={{display:"flex",marginTop:"10px"}}>
                        <div style={{width:"90px",textAlign:"right"}}>Message #:</div> 
                        <div className={styles.inputs}>
                            <input disabled={nouser} style={{height:"20px",border:"none",padding:"0",margin:"",width:"120px"}} type="text" name="msgnum" onChange={handleChange} onBlur={doMsgBlur} value={inputs.msgnum} />
                        </div>
                        <button disabled={nouser} className={styles.mediumbutton} onClick={doMsgSearch}>Find message</button>
                        
                    </div>
                </div>
                
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{width:"60px",textAlign:"right"}}>From:</div>
                        <div className={styles.inputs}><input disabled={nouser} style={{height:"20px",border:"none",padding:"0",margin:"",width:"100px"}} type="text" name="dtfrom" onChange={handleChange} value={inputs.dtfrom} /></div>
                        {!nouser && <Calendar onClick={(e)=>setShowFromCal(true)} />}
                        <button className={styles.smallbutton} onClick={doRefresh} >Refresh</button>
                    </div>                
                    <div style={{display:"flex",marginTop:"10px"}}>
                        <div style={{width:"60px",textAlign:"right"}}>To:</div>
                        <div className={styles.inputs}><input disabled={nouser} style={{height:"20px",border:"none",padding:"0",margin:"",width:"100px"}} type="text" name="dtto" onChange={handleChange} value={inputs.dtto} /></div>
                        {!nouser && <Calendar onClick={(e) =>setShowToCal(true)} />}
                        {showdates &&
                        <button className={styles.smallbutton} onClick={(e)=>readDates(inputs.dtfrom,inputs.dtto)} disabled={!inputs.dtfrom.length && !inputs.dtto.length}>Read Dates</button>
                        }
                    </div>                
                </div>
            </div>
            {showfromcal && <ShowCalendar value={new Date(fromdate)} onChange={setFromDate} maxDate={maxdate} />}
            {showtocal && <ShowCalendar value={new Date(todate)} onChange={setToDate} minDate={mindate} />}
        </React.Fragment>
    )
}

export default MessageHeaderInt;