import React, { useState, useEffect, useCallback } from 'react';
import GenButton from "./GenButton";

const CountdownTimer = ({ initialSeconds, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(true);

  const handleButtonClick = useCallback(() => {
    setIsActive(false);
    onComplete(true);
  }, [onComplete]);

  useEffect(() => {
    if (!isActive) return;


    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setIsActive(false);
          onComplete(false);
          return 0;
        }
        return prevTime -1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isActive, onComplete]);

  return (
    <div>
      <h2>Time Remaining: {timeLeft} seconds</h2>
      {isActive && (
            <GenButton 
            onclick={handleButtonClick} 
            id={0} 
            buttontext={"Work on this Ticket"}
            bgcolor="white"
            color="black"
            width="220"
            height="30"
        />
      )}
    </div>
  );
};

export default CountdownTimer;