import React from "react";
import styles from "./ShowOnCall.module.scss";
import { DataService } from "./../store/DataService";
import {formatPhoneNumber} from "./../AppConstants";

    const ShowOnCall = (props) => {
        const [practiceproviders,setPracticeProviders] = React.useState({oncall:[],groups:[]});
        const [showalloncall,setShowAllOnCall] = React.useState(false);

        React.useEffect(()=> {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.getpracticeproviders(props.globals.practiceid).then((data) => {
                let err = data.ERROR || "";
                if (err.length) {
                    alert(err);
                } else {
                    setPracticeProviders(data);
                }
            })
        },[])

        const oncall = practiceproviders.oncall || [];
        const orggroups = practiceproviders.groups || [];
        let groups = {};
        orggroups.forEach((grp)=>{
            groups[grp.ocid] = {used:0,ocdesc:grp.ocdesc};
        })
        if (oncall.length < 1 && groups.length > 1) {
            return (
                <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>
                On-Call Providers

                </div>
                <div className={styles.oncallbox}>
                    <div style={{marginTop:"2px",fontSize:"16px"}}>No On-Call providers or groups.</div>
                </div>
                </React.Fragment>
            )
        }
        //console.log("ShowOnCall");

        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>
                On-Call Providers
                <span style={{fontSize:"14px",marginLeft:"10px"}}>
                    <label>
                    <input type="checkbox" name="cballoncall" checked={showalloncall} onChange={(e)=>{setShowAllOnCall(!showalloncall)}}></input>
                    Show All Providers
                    </label>
                </span>
                {showalloncall && <span style={{fontSize:"16px",marginLeft:"6px"}}>(ALL Providers)</span>}
                {!showalloncall && <span style={{fontSize:"16px",marginLeft:"6px"}}>(ACTIVE only Providers)</span>}
                </div>
                <div className={styles.oncallbox}>
                    <div className={styles.oncallrow} style={{fontWeight:"bold"}}>
                        <div>Provider</div>
                        <div>OnCall Group</div>
                        <div>Start Time</div>
                        <div>End Time</div>
                    </div>
                    {oncall.map((obj,i) => {
                        let pn = obj.homephone;
                        if (pn.length) {
                            pn = formatPhoneNumber(pn);
                            pn += " (Home)";
                        } else {
                            pn = obj.cellphone;
                            if (pn.length) {
                                pn = formatPhoneNumber(pn);
                                pn += " (Cell)";
                            }
                        }
                        let firstname = obj.firstname || '';
                        let lastname = obj.lastname || '';
                        let name = firstname + ' ' + lastname;
                        if (name.length > 25) {
                            name = firstname.substring(0,3) + '. ' + lastname;
                            if (name.length > 25) {
                                name = name.substring(0,23) + '..';
                            }
                        }
                        let active = obj.active;
                        if (active==='undefined') active = 0;
                        active = parseInt(active);
                        if (!showalloncall && active===0) return null;
                        let rowstyle = styles.oncallrow;
                        groups[obj.ocallid].used = 1;
                        if (active === 1 && showalloncall===true) {
                            rowstyle = styles.oncallrowactive;
                        }
                        let ocdesc = obj.ocdesc || '';
                        let sdate = obj.sdate || ' -';
                        let edate = obj.edate || ' -';
                        sdate = obj.sdate.split(' ')[1];
                        edate = obj.edate.split(' ')[1];
                        return (
                            <div key={"ocr_"+i} className={rowstyle}>
                                <div>{name}</div>
                                <div>{ocdesc}</div>
                                <div>{sdate}</div>
                                <div>{edate}</div>
                            </div>
                        )
                    })}
                    {Object.keys(groups).map((ky,i) => {
                        let obj = groups[ky];
                        if (obj.used===1) return null;
                        let rowstyle = styles.oncallrowempty;
                        return (
                            <div key={"ocr_"+i} className={rowstyle}>
                                <div>No Providers</div>
                                <div>{obj.ocdesc}</div>
                                <div></div>
                                <div></div>
                            </div>                            
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

export default ShowOnCall;