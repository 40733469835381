
import React from 'react';
import styles from './ViewPracticeMessagesInt.module.scss';
import MessageHeaderInt from './../../components/MessageHeaderInt';
import {DataService} from './../../store/DataService';
import MessageListHeader from './../../components/MessageListHeader';
import MessageListRow from './../../components/MessageListRow';
import MessageDetail from './../../components/MessageDetail';
import GenConfirm from './../../components/GenConfirm';
import GenMsgBox from './../../components/GenMsgBox';

const USE_PRACTICE = 1;


const ViewPracticeMessagesInt = (props) => {
    const [practiceid,setPracticeID] = React.useState(props.globals.practiceid || USE_PRACTICE);
    const [msgs,setMsgs] = React.useState([]);
    //const [sortby,setSortBy] = React.useState(1);
    const [lastcheck,setLastCheck] = React.useState(false);
    const [showdetail,setShowDetail] = React.useState(0);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [showcloseconfirm,setShowCloseConfirm] = React.useState(false);
    const [showok,setShowOK] = React.useState(false);
    const [searchstr,setSearchStr] = React.useState("");
    const [showclosed,setShowClosed] = React.useState(false);
    const [counts,setCounts] = React.useState({open:0,closed:0,transfer:0});
    const [filter,setFilter] = React.useState({sortby:1,statusid:1,msgtype:0,respid:0,resptype:'A',userid:0,msgnum:0,dtfrom:"",dtto:""});
    const archive = props.archive || 'N';
    const doRefresh = () => {
        setRefreshNeeded(true);
    }

    const sortCallback = (v) => {
        let obj = {sortby:parseInt(v),msgnum:0};
        setFilter({...filter,...obj});
    }
    const statusCallback = (v) => {
        let obj = {statusid:v,msgnum:0};
        setFilter({...filter,...obj});
    }
    const msgtypeCallback = (v) => {
        let obj = {msgtype:v,msgnum:0};
        setFilter({...filter,...obj});
    }
    const respCallback = (rid) => {
        let rtype = 'A';
        if (rid.length > 1) {
            rtype = rid[0];
            rid = rid.substr(1);
        }
        let obj = {respid:rid,resptype:rtype,msgnum:0};
        setFilter({...filter,...obj});
    }
    const userCallback = (v) => {
        let obj = {userid:v,msgnum:0};
        setFilter({...filter,...obj});
    }
    const msgnumCallback = (v)=> {
        setFilter({...filter,msgnum:v});
    }
    const dateCallback = (dfrom,dto) => {
        let obj={dtfrom:dfrom,dtto:dto}
        setFilter({...filter,...obj});
    }
    const filterCallback = (name,value) => {
        setFilter({...filter,[name]:value});
    }

    React.useEffect(()=> {
        console.log("VPM-useEffect...");
        let isMounted = true;
        
        if (refreshneeded) setRefreshNeeded(false);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setWait();
        
        //let archive = props.archive || 'N';
        dataservice.getreviewmessages(practiceid,
                                        filter.sortby,
                                        filter.statusid,
                                        filter.msgtype,
                                        filter.respid,
                                        filter.resptype,
                                        filter.userid,
                                        filter.msgnum,
                                        false,
                                        filter.dtfrom,
                                        filter.dtto,
                                        archive).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                if (isMounted) {
                    let m = data.MSGS;
                    let opencount = data.COUNTS.open || 0;
                    let closecount = data.COUNTS.close || 0;
                    let trans = data.COUNTS.trans || 0;
                    m.forEach(o => {
                        o.checked = false;
                    })
                    setMsgs(m);
                    setCounts({open:opencount,closed:closecount,transfer:trans});
                }
            }
        }).finally(() => clearWait())

        return () => { isMounted = false };
    },[filter.sortby,
        filter.statusid,
        filter.msgtype,
        filter.respid,
        filter.resptype,
        filter.userid,
        filter.msgnum,
        practiceid,
        props.archive,
        refreshneeded,
        props.globals.sessionguid])

    const setWait = () => {
        var css = "* { cursor: wait; !important}";
        var style = document.createElement("style");
        style.setAttribute('type', 'text/css');
        style.id = "mywaitcursorstyle";
        style.appendChild(document.createTextNode(css));
        document.head.appendChild(style);
    }

    const clearWait = () => {
        var style = document.getElementById("mywaitcursorstyle");
        if (style) {
            style.parentNode.removeChild(style);
        }
    }

    const readAllMsgs = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setWait();
        //let archive = props.archive || 'N';
        dataservice.getreviewmessages(practiceid,
                                        filter.sortby,
                                        filter.statusid,
                                        filter.msgtype,
                                        filter.respid,
                                        filter.resptype,
                                        filter.userid,
                                        filter.msgnum,
                                        true,
                                        filter.dtfrom,
                                        filter.dtto,
                                        archive).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let m = data.MSGS;
                let opencount = data.COUNTS.open || 0;
                let closecount = data.COUNTS.close || 0;
                let trans = data.COUNTS.trans || 0;
                m.forEach(o => {
                    o.checked = false;
                })
                setMsgs(m);
                setCounts({open:opencount,closed:closecount,transfer:trans});
            }
        }).finally(() => clearWait())

    }

    React.useEffect(()=> {
        readAllMsgs({})
    },[filter])

    const readDateMsgs = (dfrom,dto) => {
        let newfilter = JSON.parse(JSON.stringify(filter));
        newfilter.dtfrom = dfrom;
        newfilter.dtto = dto;
        setFilter(newfilter);
        //let obj={dtfrom:dfrom,dtto:dto}
        //setFilter({...filter,...obj});
    }

    const cbCallback = (id) => {
        msgs[id].checked = !msgs[id].checked;
    }

    const allCallback = (chked) => {
        console.log('AllCallBack chked='+chked);
        let tempmsgs = msgs.slice();
        tempmsgs.map((m,i) => {
            m.checked = chked;
            return m;
        });
        setMsgs(tempmsgs);
        setLastCheck(chked);
    }

    const handleShowClosed = (chked) => {
        allCallback(false);
        setShowClosed(chked);
    }

    const onOK = () => {
        setShowOK(false);
    }
    const onCancel = () => {
        setShowCloseConfirm(false);
    }
    const onConfirm = () => {
        setShowCloseConfirm(false);
        let msglst = [];
        let i = 0;
        for (i=0;i<msgs.length;i++) {
            if (msgs[i].checked===true) {
                msglst.push(msgs[i].MessageID);
            }
        }        
        let s = msglst.join(",");
        //console.log("msglst="+s);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.closemessages(props.globals.useraccountid,props.globals.userpracticeid,s).then((data)=> {
            let ed = data.ERROR || "";
            const cnt = data.COUNT || 0;
            const urgent = data.URGENT || 0;
            if (ed.length > 0) {
                ed += ", "+cnt+" messages were closed.";
                alert(ed);
                if (cnt > 0) {
                    setRefreshNeeded(true);
                }
            } else {
                setRefreshNeeded(true);
            }
            if (urgent > 0) {
                let msg = "Unable to close "+urgent;
                if (urgent===1) {
                    msg += " message that has ";
                } else {
                    msg += " messages that have ";
                }
                msg += "not been Acknowledged";
                alert(msg);
            }
        })

    }

    const batchClose = (e) => {
        const count = msgs.filter(item => item.checked===true).length;
        console.log("batchClose count="+count);
        if (count === 0) {
            //alert("At least 1 message must be checked to close");
            setShowOK(true);
            return;
        }
        //window.confirm('Are you sure you wish to Batch Close Messages?') ? onConfirm() : onCancel();
        setShowCloseConfirm(true);
    }
    let tmsg = "Review Messages";
    if (archive==='Y') {
        tmsg = 'Archive Messages';
    }
    //let archive = props.archive || 'N';
    return (
            <div className={styles.viewmsg}>
                <h2 className={styles.title}>{tmsg}</h2>
                <div className={styles.content}>View and filter all Messages.
                </div>
                <MessageHeaderInt 
                    globals={props.globals} 
                    methods={props.methods} 
                    sortCallback={sortCallback}
                    searchCallback={setSearchStr}
                    statusCallback={statusCallback}
                    msgtypeCallback={msgtypeCallback}
                    msgnumCallback={msgnumCallback}
                    defmsgnum={filter.msgnum}
                    defuserid={filter.userid}
                    doRefresh={doRefresh}
                    respCallback={respCallback}
                    userCallback={userCallback}
                    batchClose={batchClose}
                    showClosed={showclosed}
                    showClosedCallback={handleShowClosed}
                    msgcounts={counts}
                    readAll={readAllMsgs}
                    readDates={readDateMsgs}
                    overridepractice={practiceid}
                    />
                <MessageListHeader 
                    globals={props.globals} 
                    methods={props.methods}
                    lastCheck={lastcheck}
                    allCallback={allCallback}
                    showClosed={showclosed}
                    defsortby={filter.sortby}
                    noCheck={true} 
                    />
                {msgs.map((msg,i) => {
                    
                    return <MessageListRow 
                                key={i+"_"+msg.checked} 
                                cbid={i} 
                                globals={props.globals} 
                                methods={props.methods} 
                                cbCallback={cbCallback} 
                                msg={msg}
                                checked={msg.checked}
                                doDetail={setShowDetail}
                                searchstr={searchstr}
                                showClosed={showclosed}
                                defsortby={filter.sortby}
                                Archive={archive}
                                noCheck={true}
                                Review={'Y'} 
                                />
                })}
                
                {showdetail > 0 && <MessageDetail 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    doClose={setShowDetail}
                                    doRefresh={setRefreshNeeded}
                                    showClosed={showclosed}
                                    archive={archive}
                                    msgnum={showdetail} />}
                {showcloseconfirm && <GenConfirm 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    text="Are you sure you want to Batch Close selected Messages?"
                                    onCancel = {onCancel}
                                    onConfirm = {onConfirm}
                                    confirmText = "Yes" />}
                {showok && <GenMsgBox 
                                    globals={props.globals} 
                                    methods={props.methods}
                                    text="At least 1 Message must be checked to Batch Close."
                                    onOK = {onOK}
                                    buttonText = "Ok" />}
            </div>
        )
}

export default ViewPracticeMessagesInt;