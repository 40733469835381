import React from "react";
import styles from "./ContactPopup.module.scss";
import { DataService } from "../../store/DataService";
import {ReactComponent as Closer} from "./../../assets/close.svg";
import { formatPhoneNumber, DateOnly, Date12, calculateAgeInYears, PhoneTest, RELATIONSHIPS } from "../../AppConstants";
import {INFOTYPE_DOB,INFOTYPE_CALLERNAME,INFOTYPE_RELATIONSHIP,INFOTYPE_DAYPHONE1,INFOTYPE_DAYPHONE2,INFOTYPE_EVEPHONE1,INFOTYPE_EVEPHONE2} from '../../AppConstants';
import GenMsgBox from "../../components/GenMsgBox";
import GenConfirm from "../../components/GenConfirm";
import GenPrompt from "../../components/GenPrompt";
import GenButton from "../../components/GenButton";
import PageFailed from "../../components/PageFailed";
import {useNavigate} from 'react-router-dom'; 

const ALLOW_OVERFLOW = false;

var cpcontactmethods = []

const ContactPopup = (props) => {
    const [groupid,setGroupID] = React.useState("0");
    const [providerid,setProviderID] = React.useState("0");
    const [firsttime,setFirstTime] = React.useState(false);
    const [methodid,setMethodID] = React.useState("0");
    const [notes,setNotes] = React.useState("");
    const [formerrors,setFormErrors] = React.useState({errflag:false,errmsg:""});
    const [escalationseq,setEscalationSeq] = React.useState("1");
    const [isfinal,setIsFinal] = React.useState(false);
    //const [qualneeded,setQualNeeded] = React.useState(false);
    //const [showmultigroup,setShowMultiGroup] = React.useState(false);
    const [showcloseconfirm,setShowCloseConfirm] = React.useState(false);
    const [showfailed,setShowFailed] = React.useState(false);
    const [showdelayed,setShowDelayed] = React.useState(false);
    const adata = props.alertdata || {};
    const adatalen = Object.keys(adata).length > 0;
    const history = useNavigate();
    const [isalert,setIsAlert] = React.useState(adatalen);
    const pa = props.globals.provideraccount || {};
    let cbphone = pa.accountphone || "";
    if (adatalen > 0) {
        cbphone = adata.callbacknumber || "";
    }
    const [cbnumber,setCBNumber] = React.useState(formatPhoneNumber(cbphone));
    const [refreshneeded,setRefresh] = React.useState(false);
    const [showspinner,setShowSpinner] = React.useState(true);
    const [pagedisabled,setPageDisabled] = React.useState(false);
    const [matrix,setMatrix] = React.useState({
        firststepprovider:0,
        firststepgroup:0,
        ptproviderfirst:'',
        ptproviderlast:'',
        ptprovidername:'',
        oncallprovider:0,
        oncallgroup:0,
        oncalltype:'',
        activegroups:{},
        message:{},
        msghist:[],
        provgroup:{},
        oncallprov:{},
        contactmethods:{},
        firststeps:{},
        primaries:{},
        callsteps:{},
        callnotes:[],
        oncalldata:[],
        qualquestions:[]
    });

    const Dummy = (action) => {}

    const buildContactMethods = (provid,mdata = null) => {
        const pid = parseInt(provid);
        cpcontactmethods = [];
        if (mdata===null) mdata = matrix;
        for (const [key, obj] of Object.entries(mdata.contactmethods)) {
            if (parseInt(obj.providerid)===pid) {
                let desc = obj.desc;
                if (obj.contacttypeid===5 || obj.contacttypeid===7) {
                    desc += " " + obj.pageremail;
                } else {
                    if (obj.contacttypeid !== 9 && obj.contacttypeid !== 6 && obj.contacttypeid !== 4 && obj.contacttypeid !== 1) {
                        desc += " " + obj.phonenumber;
                    } else {
                        desc += " " + formatPhoneNumber(obj.phonenumber);
                    }
                    
    
                }
                cpcontactmethods.push({value:obj.personphoneid,text:desc,contacttypeid:obj.contacttypeid})
            }
        }
    }

    React.useEffect(() => {
        const timer = setInterval(()=> {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.alertowned(props.globals.useraccountid,props.alertdata.qaid).then((data)=> {
                let status = data.STATUS || 'x';
                if (status==='abort') {
                    const cb = props.onClose || Dummy;
                    cb(status);
                }
                if (status==='logout') {
                    const cb = props.onClose || Dummy;
                    cb(status);
                }
            })
        },30000)
        return ()=> clearInterval(timer);
    },[props.globals.sessionguid,props.globals.useraccountid,props.alertdata.qaid])


    const getAlertData = (data) => {
        let d = JSON.parse(JSON.stringify(data));
        d.oncallgroup = props.alertdata.oncallid;
        d.oncallprovider = props.alertdata.pageproviderid;
        setGroupID(d.oncallgroup);
        setProviderID(d.oncallprovider);
        //const pid = parseInt(d.oncallprovider);
        //let cm = props.alertdata.pagenumber;
        //let cmid = props.alertdata.pagetype;
        d.contactmethodid = props.alertdata.personphoneid;
        d.callbacknumber = props.alertdata.callbacknumber;

        /*
        if (props.alertdata.deliverytype === 1 || props.alertdata.deliverytype === 2) {
            cm = props.alertdata.email;
        }
        for (const [key, obj] of Object.entries(d.contactmethods)) {
            if (parseInt(obj.providerid)===pid) {
                
                if (obj.contacttypeid===7) {
                    if (cm===obj.pageremail) {
                        cmid = obj.personphoneid;
                        break;
                    }
                } else {
                    if (cm===obj.phonenumber) {
                        cmid = obj.personphoneid;
                        break;
                    }
                }

            }
        }
        */

        //if (cmid > 0) {
        //    setMethodID(cmid);
        //}
        //setMatrix(d);
        return d;
    }

    const findFinal = (oncallid,groups=null) => {
        let finalgroup = null;
        if (groups===null) groups = matrix.activegroups;
        for (const [key, obj] of Object.entries(groups)) {
            if (obj.oncallid===oncallid) {
                finalgroup = obj;
                break;
            }
        }
        return finalgroup;
    }

    React.useEffect(()=> {
        const msgid = props.msgID || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        if (refreshneeded) {
            setRefresh(false);
        }
        
        dataservice.urgentmessageinit(props.alertdata.practiceid,msgid).then((data)=> {
            data = getAlertData(data);
            setShowSpinner(false);
            let pid = data.oncallprovider;
            if (pid < 1) {
                setIsFinal(true);
                let fg = findFinal(props.alertdata.oncallid,data.activegroups);
                if (fg) {
                    pid = parseInt(fg.finalcontact);
                    data.oncallprovider = pid;
                    data.contactmethodid = 0;
                    if (pid > 0) {
                        let cm = data.firststeps[pid] || {};
                        let cmid = cm.personphoneid;
                        data.contactmethodid = cmid;
                        //setMethodID(cmid);
                    }
                }
            }
            setMatrix(data);
            setCBNumber(data.callbacknumber);
            if (data.oncalltype==='G') {
                setGroupID(pid);
            } else {
                setNewProvider(pid,1,data);
                setNewContactMethod(data.contactmethodid,data);
            }
            
        })

    },[props.globals.sessionguid,props.globals.practiceid,props.msgID,isalert,refreshneeded])



    const closeContact = () => {
        const cb = props.onClose || Dummy;
        cb();
    }

    const endContact = () => {
        const cb = props.onClose || Dummy;
        let qaid = adata.qaid || 0;
        cb("end:"+qaid);
    }

    const onCloseNo = () => {
        setShowCloseConfirm(false);
    }

    const onClose = (e) => {
        setShowCloseConfirm(true);
    }

    const findPhoneID = (providerid, stepnumber = 1, mdata = null) => {
        stepnumber -= 1;
        let provid = "" + providerid;
        let phoneid = 0;
        if (providerid > 0) {
            if (mdata===null) mdata = matrix;
            mdata.callsteps.every((steplist)=> {
                let step = steplist[0];
                const stepprovid = "" + step.providerid;
                if (stepprovid===provid) {
                    if (steplist.length > stepnumber) {
                        step = steplist[stepnumber];
                    }
                    phoneid = step.personphoneid;
                    return false;
                }
                return true;
            })
        }
        return phoneid;
    }

    const setNewContactMethod = (v,mdata = null) => {
        setMethodID(v);       
        let acctcbnum = pa.accountphone || "";
        
        if (adatalen > 0) {
            acctcbnum = adata.callbacknumber || "";
        }
        if (mdata===null) mdata = matrix;
    
        let val = parseInt(v);
        cpcontactmethods.every((obj)=> {
            if (obj.value===val) {
                if (obj.contacttypeid===10) {
                    const evephone2 = mdata.message.evephone2 || "";
                    const evephone1 = mdata.message.evephone1 || "";
                    const dayphone2 = mdata.message.dayphone2 || "";
                    const dayphone1 = mdata.message.dayphone1 || "";
                    let newcbnum = "";
                    if (dayphone1.length) {
                        newcbnum = dayphone1;
                    } else if (dayphone2.length) {
                        newcbnum = dayphone2;
                    } else if (evephone1.length) {
                        newcbnum = evephone1;
                    } else if (evephone2.length) {
                        newcbnum = evephone2;
                    }
                    setCBNumber(formatPhoneNumber(newcbnum));
                } else {
                    setCBNumber(acctcbnum);
                }
                return false;
            }
            return true;
        })

    }

    const setNewProvider = (providerid, stepnumber = 1, mdata = null) => {
        let provid = "" + providerid;
        let phoneid = findPhoneID(providerid, stepnumber, mdata);
        setProviderID(provid);
        buildContactMethods(provid,mdata);
        setNewContactMethod(phoneid, mdata);

    }

    const setNewGroup = (g) => {
        let sgroup = "" + g;
        let provid = 0;
        let p = matrix.primaries[sgroup] || {};
        if (Object.keys(p).length) {
            provid = p.providerid || 0;
        }

        setNewProvider(provid);
        setGroupID(sgroup);
        if (parseInt(provid)===0) {
            setFirstTime(false);
        }
    }

    const HandleGroupChange = (e) => {
        const v = e.target.value;
        setNewGroup(v);
        //setGroupID(v);
    }

    const HandleProviderChange = (e) => {
        const v = e.target.value;
        //setProviderID(v);
        setNewProvider(v);
    }

    const HandleMethodChange = (e) => {
        setNewContactMethod(e.target.value);
    }

    const HandleCallBackChange = (e) => {
        const v = e.target.value;
        setCBNumber(v);
    }

    const HandleNotesChange = (e) => {
        const v = e.target.value;
        setNotes(v);
    }

    const HandleFormOK = (e) => {
        setFormErrors({errflag:false,errmsg:""});
    }

    const findRef = (cref) => {
        let rindex = -1;
        matrix.qualquestions.forEach((r,i) => {
            if (r.treenode===cref) {
                rindex = i;
            }
        })
        return rindex;
    }

    const findOpts = (cref) => {
        var opts = [];
        var oindex = 1;
        var aindex = findRef(cref+oindex+'.');
        while (aindex >= 0) {
            opts.push(aindex);
            oindex += 1;
            aindex = findRef(cref+oindex+'.');
        }
        return opts;
    }

    const ShowFinal = () => {
        let finalinst = "";
        let instlines = [];
        let fg = findFinal(props.alertdata.oncallid,matrix.activegroups);
        if (fg) {
            finalinst = fg.finalinst || "";
        }
        instlines = finalinst.split('\n');

        const onRestart = (e) => {
            var obj = GetData("RESTART",true);
            obj.isfinal = true;
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.contactaction(obj).then((data)=> {
                let cb = props.onUpdate || closeContact;
                cb(props.msgID);
            })
        }

        return (
            <React.Fragment>
                <GenButton onclick={onRestart} buttontext="Restart Protocol"></GenButton>
                <div style={{marginTop:"10px",fontFamily:"Verdana",fontSize:"16px"}}>Final Instructions:</div>
                <div style={{padding:"0",margin:"0",paddingBottom:"10px",lineHeight:"16px"}}>
                    {instlines.map((line,i) => {
                        let ln = line.trim();
                        if (!ln.length) {
                            return <div className={styles.final} key={'instline_'+i} style={{height:"14px"}}> </div>
                        }
                        return <div className={styles.final} key={'instline_'+i}>{ln}</div>
                        
                    })}
                </div>
            </React.Fragment>
        )
    }

    const ShowNotes = () => {
        let warning = "";
        if (matrix.oncallprovider===0 && parseInt(providerid)===0 && firsttime===false) {
            warning = "WARNING: The on-call provider could not be determined. Check practice calendar and on-call group setup";
        } else {
            setFirstTime(true);
        }
    
        return (
            // Call Notes
            <div className={styles.noteblock}>
                <div className={styles.notehdr}>Call Notes for this contact:</div>
                {matrix.callnotes.map((obj,i) => {
                    let ocid = obj.oncallid || 0;
                    let prid = obj.providerid || 0;
                    if (prid > 0 && prid !== providerid) {
                        return null;
                    }
                    if (ocid > 0 && ocid !== groupid) {
                        return null;
                    }                    
                    return <div key={"nt_"+i} className={styles.notes} dangerouslySetInnerHTML={{ __html:obj.notes}}></div>
                })}
                {warning.length > 0 && <div style={{marginTop:"10px",color:"red"}}>{warning}</div>}
            </div>            
        )
    }

    const ShowMessage = () => {
        const msgbody = matrix.message.messagebody || "";
        const msglines = msgbody.split("\r\n")
        const msginfo = matrix.msginfo || [];
        const callerfirst = matrix.message.callerfirstname || "";
        const callerlast = matrix.message.callerlastname || "";
        const relid = parseInt(matrix.message.callerrelationship);
        const rel = RELATIONSHIPS[relid] || "";
        const caller = callerfirst + " " + callerlast + "<br/><br/><b>Rel:</b> " + rel;
        const evephone2 = matrix.message.evephone2 || "";
        const evephone1 = matrix.message.evephone1 || "";
        const dayphone2 = matrix.message.dayphone2 || "";
        const dayphone1 = matrix.message.dayphone1 || "";
        let msgtypeid = matrix.message.messagetypeid || 0;
        msgtypeid = parseInt(msgtypeid);
        var dphone1 = 0;
        var dphone2 = 0;
        var ephone1 = 0;
        var ephone2 = 0;
        var showdob = 0;
        var showcaller = 0;
        var showrel = 0;
        var lastcontent = matrix.pcontent || [];
        const findInfoType = (mtid,itid) => {
            let val = 0;
            lastcontent.every((cobj)=> {
                if (cobj.mtid===mtid && cobj.itid===itid) {
                    val = cobj.val;
                    return false;
                }
                return true;
            })
            return parseInt(val);
        }

        dphone1 = dphone2 = ephone1 = ephone2 = showdob = showcaller = showrel = 0;
        if (lastcontent.length) {
            dphone1 = findInfoType(msgtypeid,INFOTYPE_DAYPHONE1);
            if (dphone1===0) dphone1 = findInfoType(0,INFOTYPE_DAYPHONE1);
            dphone2 = findInfoType(msgtypeid,INFOTYPE_DAYPHONE2);
            if (dphone2===0) dphone2 = findInfoType(0,INFOTYPE_DAYPHONE2);
            ephone1 = findInfoType(msgtypeid,INFOTYPE_EVEPHONE1);
            if (ephone1===0) ephone1 = findInfoType(0,INFOTYPE_EVEPHONE1);
            ephone2 = findInfoType(msgtypeid,INFOTYPE_EVEPHONE2);
            if (ephone2===0) ephone2 = findInfoType(0,INFOTYPE_EVEPHONE2);
            showdob = findInfoType(msgtypeid,INFOTYPE_DOB);
            if (showdob===0) showdob = findInfoType(0,INFOTYPE_DOB);
            showcaller = findInfoType(msgtypeid,INFOTYPE_CALLERNAME);
            if (showcaller===0) showcaller = findInfoType(0,INFOTYPE_CALLERNAME);
            showrel = findInfoType(msgtypeid,INFOTYPE_RELATIONSHIP);
            if (showrel===0) showrel = findInfoType(0,INFOTYPE_RELATIONSHIP);
        }


        let phone = " ";
        if (dphone1>0)
            phone += "<b>Day1:</b> " + PhoneTest(dayphone1,false) + "<br/>";
        if (dphone2>0)
            phone += "<b>Day2:</b> " + PhoneTest(dayphone2,false) + "<br/>";
        if (ephone1>0)
            phone += "<b>Eve1:</b> " + PhoneTest(evephone1,false) + "<br/>";
        if (ephone2>0)
            phone += "<b>Eve2:</b> " + PhoneTest(evephone2,false) + "<br/>";

        if (!matrix.message.okleavemessage) {
            phone += "DO NOT LEAVE MESSAGE";
        }
        const ptfirst = matrix.message.patientfirstname || "";
        const ptlast = matrix.message.patientlastname || "";
        let dob = matrix.message.patientdob || "";
        let age = "";
        if (dob.length) {
            age = "<br/><b>Age:</b> " + calculateAgeInYears(dob);
            dob = DateOnly(dob);
        }
        let patient = ptfirst + " " + ptlast 
        if (showdob)
            patient += "<br/><br/><b>DOB:</b> "+dob+age;

        const provider = matrix.ptprovidername || "";
        let actions = []
        const mhist = matrix.msghist || [];
        mhist.forEach((mh)=> {
            let s = mh.actiontaken + " on " + Date12(mh.MessageDate) + " by " + mh.uafirstname + " " + mh.ualastname;
            s = s.trim() + ".";
            if (mh.ContactMethod.length) {
                s += " " + mh.ContactMethod;
                if (mh.cplastname.length) {
                    s += " " + mh.cpfirstname + " " + mh.cplastname;
                }
                s = s.trim() + ".";
            }
            if (mh.Reason.length) {
                s += " " + mh.Reason;
            }
            if (mh.Comment.length) {
                s += "-" + mh.Comment;
            }
            
            actions.push(s);
        })

        return (
            <React.Fragment>
                <div className={`${styles.msgblock} ${styles.msgblocktop}`}>
                    <div className={styles.msghdr}>Message</div>
                    <div className={styles.msghdr}>Caller</div>
                    <div className={styles.msghdr}>Phone</div>
                    <div className={styles.msghdr}>Patient</div>
                    <div className={styles.msghdr}>Provider</div>
                    <div className={styles.msghdr}>Action</div>
                </div>
                <div className={styles.msgblock}>
                    <div style={{paddingRight:"6px"}}>
                        {msglines.map((ln,i)=> {
                            return <div key={i}>{ln}</div>
                        })}
                        {msginfo.length > 0 && <div style={{height:"30px"}}>&nbsp;</div>}
                        {msginfo.map((info,i)=> {
                        if (info.mtypeid===msgtypeid || info.mtypeid===0) {
                            return <div key={"inf_"+i}>{info.desc}: {info.value}</div>
                        } else {
                        return null;
                        }
                        })}
                    </div>
                    {showcaller>0 && <div dangerouslySetInnerHTML={{ __html:caller}}></div>}
                    {showcaller<1 && <div></div>}
                    <div dangerouslySetInnerHTML={{ __html:phone}}></div>
                    <div dangerouslySetInnerHTML={{ __html:patient}}></div>
                    <div>{provider}</div>
                    <div style={{maxHeight:"280px",overflowY:"auto"}}>
                        {actions.map((act,i)=> {
                            return <div style={{marginTop:"5px"}} key={"act_"+i}>{act}</div>
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const GetData = (cmd,manual) => {
        let valid = true;
        let meid = parseInt(methodid);
        if (parseInt(groupid)===0 || parseInt(providerid)===0 || meid===0) {
            valid = false;
        }
        let qaid = 0;
        const adata = props.alertdata || {};
        if (Object.keys(adata).length > 0) {
            qaid = adata.qaid;
        }
        let contactmethod = "";
        
        cpcontactmethods.every((obj)=> {
            if (parseInt(obj.value) === meid) {
                contactmethod = obj.text;
                return false;
            }
            return true;
        })
        var obj = {
            cmd:cmd,
            alertid:qaid,
            msgid:props.msgID,
            oncallgroupid:groupid,
            oncallproviderid:providerid,
            personphoneid:methodid,
            contactmethod:contactmethod,
            callbacknum:formatPhoneNumber(cbnumber || ""),
            comments:notes,
            ismanual:manual,
            isvalid:valid,
            isfinal:providerid===0,
            uaid:props.globals.useraccountid,
            uapracticeid:props.globals.userpracticeid,
            escalationseq:1,
            schedoncallpersonid:0,
        }

        return obj;
    }

    const setErrors = (msg) => {
        setFormErrors({errflag:true,errmsg:msg});
    }

    const STD_ERROR = "Invalid On-Call Group, Provider, or Contact Method.<br/>Please enter all values.";

    const pageProvider = (e) => {
        if (pagedisabled) return;
        setPageDisabled(true);
        var obj = GetData("PAGE",false);
        if (!obj.isvalid) {
            setErrors(STD_ERROR);
            setPageDisabled(false);
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.pageprovider(obj).then((data)=> {
            closeContact();            
        })
    }
    
    const providerContacted = (e) => {
        var obj = GetData("PROVCON",false);

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.contactaction(obj).then((data)=> {
            closeContact();            
        })
    }

    const findStepData = (provid) => {
        var steps = null;
        let pid = parseInt(provid);
        let callsteps = matrix.callsteps || [];
        callsteps.every((obj)=> {
            let cs = obj[0] || {};
            if (cs.providerid===pid) {
                steps = obj;
                return false;
            }
            return true;
        })
        return steps;
    }

    const findOCData = (backupnum) => {
        var ocdata = null;
        let grpid = "" + groupid;
        for (let x=0;x<matrix.oncalldata.length;x++) {
            let ocdgroup = matrix.oncalldata[x];
            for (let y=0;y < ocdgroup.length;y++) {
                let ocd = ocdgroup[y];
                if (ocd.oncallid===grpid && ocd.backupnum===backupnum) {
                    ocdata = ocd;
                    y = ocdgroup.length;
                    x = matrix.oncalldata.length;
                }
            }
        }

        return ocdata;
    }

    const dummyUpdate = (e) => {
        setRefresh(true);
    }

    const pageFailedHandler = (id) => {
        console.log("page failed: id="+id);
        if (id==='btncancel') {
            setShowFailed(false);
        } else {
            var obj = GetData("CONFAIL",true);
            obj["btnid"] = id;
            const adata = props.alertdata || {};
            let waittime = 0;
            let backupnum = adata.backupnum || 0;
            if (backupnum < 1) backupnum = 1;
            let ocdata = findOCData(backupnum) || {};
            //let steps = ocdata.steps || [];
            let steps = findStepData(providerid) || [];
            let sequencenum = adata.sequencenum || 0;
            let phoneid = obj.personphoneid;
            if (sequencenum < 1) sequencenum = 1;
            if (id==='btnretryyes' || id==='btnnextyes') {
                waittime = 10; // Need to get from system config
            }
            if (id==='btnnextyes' || id==='btnnextno') {
                sequencenum += 1;
                if (sequencenum > steps.length) {
                    sequencenum = 1;
                    backupnum += 1;
                    ocdata = findOCData(backupnum) || {};
                    //steps = ocdata.steps || [];
                    let pid = ocdata.providerid || 0;
                    obj["oncallproviderid"] = pid;
                    steps = findStepData(pid) || [];
                } /* else {
                    if (id==='btnnextyes') {
                        waittime = steps[sequencenum-1].waittime;
                    }
                } */
                phoneid = findPhoneID(obj["oncallproviderid"],sequencenum);
            }

            
            obj["personphoneid"] = phoneid;
            obj["backupnum"] = backupnum;
            obj["sequencenum"] = sequencenum;
            obj["waittime"] = waittime;
            
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.contactaction(obj).then((data)=> {
                setShowFailed(false);
                if (id==='btnretryyes' || id==='btnnextyes') {
                    closeContact();
                }
                if (id==='btnnextno') {
                    let cbupd = props.onUpdate || dummyUpdate;
                    cbupd(props.msgID);
                }
            })
        }
        
    }

    const contactFailed = (e) => {
        setShowFailed(true);
    }

    const manuallyPaged = (e) => {
        var obj = GetData("PAGEMAN",true);

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.contactaction(obj).then((data)=> {
            closeContact();
        })

    }

    const callerPatched = (e) => {
        var obj = GetData("PATCHED",false);

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.contactaction(obj).then((data)=> {
            closeContact();            
        })
    }

    const overflow = (e) => {

    }
    const returnQueue = (e) => {
        let adata = JSON.parse(JSON.stringify(props.alertdata) || {});
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.returntoqueue(props.globals.useraccountid,adata).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                closeContact();            
            }
        })
    }

    const handleDelayOK = (v) => {
        console.log("Delay value="+v);
        setShowDelayed(false);
        var obj = GetData("DELAY",false);
        obj["waittime"] = v;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.contactaction(obj).then((data)=> {
            closeContact();            
        })
    }

    const handleDelayCancel = (e) => {
        setShowDelayed(false);
    }

    //let contactmethods = [];
    const getContactType = () => {
        let cti = 0;
        let mid = parseInt(methodid);
        cpcontactmethods.forEach((obj) => {
            if (obj.value===mid) {
                cti = parseInt(obj.contacttypeid);
            }
        })
        return cti;
    }

    const isManual = () => {
        let cti = getContactType();
        if (cti===1 || cti===4 || cti===6 || cti===9) {
            return false;
        }
        return true;
    }

    const isPager = () => {
        let cti = getContactType();
        if (cti===0 || cti===1 || cti===4 || cti===6 || cti===9) {
            return true;
        }
        return false;

    }

    const ShowButtons = () => {
        //console.log("Show Buttons methodid="+methodid+", ismanual="+isManual());
        const btnwt = 114;
        return (
            <React.Fragment>
            <div style={{display:"flex",marginTop:"20px"}}>
                <div><GenButton id="btnpage" disabled = {isPager() || pagedisabled} buttontext="Page Provider" width={btnwt} onclick={pageProvider} /></div>
                <div style={{marginLeft:"15px"}}><GenButton id="btncontacted" disabled={isManual()} buttontext="Provider Contacted" width={btnwt} onclick={providerContacted} /></div>
                <div style={{marginLeft:"15px"}}><GenButton id="btnfailed" disabled={getContactType()===0} buttontext="Contact Attempt Failed" width={(btnwt+50)} onclick={contactFailed} /></div>
                <div style={{marginLeft:"15px"}}><GenButton id="btnmanual" disabled = {isPager()} buttontext="Manually Paged" width={btnwt} onclick={manuallyPaged} /></div>
                <div style={{marginLeft:"15px"}}><GenButton id="btnpatched" disabled={isManual()} buttontext="Caller Patched" width={btnwt} onclick={callerPatched} /></div>
                <div style={{marginLeft:"15px"}}><GenButton id="btndelay" disabled={getContactType()===0} buttontext="Delay Protocol" width={btnwt} onclick={(e)=>setShowDelayed(true)} /></div>

                {ALLOW_OVERFLOW && <div style={{marginLeft:"15px"}}><GenButton id="btnoverflow" buttontext="Overflow" width={btnwt} onclick={overflow} /></div>}
                {isalert && <div style={{marginLeft:"15px"}}><GenButton id="btrtq" buttontext="Return to Queue" width={btnwt} onclick={returnQueue} /></div>}
            </div>            
            </React.Fragment>
        )
    }

    let groups = [];
    for (const [key, obj] of Object.entries(matrix.activegroups)) {
        groups.push({value:obj.oncallid,text:obj.desc});
    }
    let gid = parseInt(groupid);
    if (groups.length === 1 && groupid < 1) {
        gid = groups[0].value;
        if (gid > 0) {
            setGroupID(gid);
        }
    }
    let providers = [];
    let currentprovidername = "Could NOT be determined";

    if (matrix.groupproviders) {
        matrix.groupproviders.forEach((obj)=> {
            if (obj.gponcallid===gid) {
                if (matrix.oncallprov[obj.gpproviderid] !== undefined) {
                    providers.push({value:obj.gpproviderid,text:obj.gpprovname});
                    if (obj.gpproviderid===providerid) {
                        currentprovidername = obj.gpprovname;
                    }
                }
            }
        })
    }

    //const callbacknumber = formatPhoneNumber(props.globals.practiceaccount.accountphone || "");
        
    const pracname = matrix.pracname || props.globals.practice.name;
    if (showspinner) {
        return (
            <React.Fragment>
            <div className={styles.backdrop} />
            <div className={styles.modal}>
                <div className={styles.innerpart}>
                    <div className={styles.loader}>
                        <img alt="Loading..." style={{width:"400px"}} src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
                    </div>
                </div>
            </div>
        </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <div className={styles.backdrop} />
            <div className={styles.modal}>
            <div className={styles.innerpart}>
                <div className={styles.title}>{pracname}</div>
                <div className={styles.headerright} onClick={onClose}><Closer /></div>
                <div style={{marginTop:"0px"}}>
                    <div className={styles.ctrlgroup}>
                        <div className={styles.ctrllabel}>On-Call Groups:</div>
                        <div>
                            <select name="ocgroup" className={styles.ctrlselect} onChange={HandleGroupChange} value={groupid}>
                                <option value="0"></option>
                                {groups.map((obj,i)=> {
                                    return <option key={"grp_"+i} value={obj.value}>{obj.text}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={styles.ctrlgroup}>
                        <div className={styles.ctrllabel}>On-Call Provider:</div>
                        <div>
                            {isfinal && <div>{currentprovidername} (final method)</div>}
                            {!isfinal && <select name="ocprovider" className={styles.ctrlselect} onChange={HandleProviderChange} value={providerid}>
                                <option value="0"></option>
                                {providers.map((obj,i)=> {
                                    return <option key={"ocp_"+i} value={obj.value}>{obj.text}</option>
                                })}
                            </select>}
                        </div>
                    </div>
                    <div className={styles.ctrlgroup}>
                        <div className={styles.ctrllabel}>Contact Methods:</div>
                        <div>
                            <select name="ocmethods" className={styles.ctrlselect} onChange={HandleMethodChange} value={methodid}>
                                <option value="0"></option>
                                {cpcontactmethods.map((obj,i)=> {
                                    return <option key={"grp_"+i} value={obj.value}>{obj.text}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={styles.ctrlgroup}>
                        <div className={styles.ctrllabel}>Callback Number:</div>
                        <div>
                            <input type="text" name="callbacknumber" value={cbnumber} onChange={HandleCallBackChange} />
                        </div>
                    </div>
                    <div className={styles.ctrlgroup}>
                        <div className={styles.ctrllabel}>Notes:</div>
                        <div>
                            <textarea name="notes" rows="3" cols="54" maxLength="255" style={{resize:"none"}} onChange={HandleNotesChange} value={notes}></textarea>
                        </div>
                    </div>
                    {isfinal && <ShowFinal />}
                </div>
                <div>
                    <ShowNotes />
                </div>
                </div>
                <ShowMessage />
                <ShowButtons />
            </div>
            {showcloseconfirm && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                text="Are you sure you wish to End this Contact?"
                onCancel = {onCloseNo}
                onConfirm = {endContact}
                confirmText = "Yes" 
                cancelText = "No" />}
            {formerrors.errflag && <GenMsgBox text={formerrors.errmsg} onOK={HandleFormOK} bgcolor={"lightyellow"} />}
            {showfailed && <PageFailed isfinal={isfinal} callback={pageFailedHandler} />}
            {showdelayed && <GenPrompt text="Delay in minutes:" onConfirm={handleDelayOK} onCancel={handleDelayCancel} inpvalue={10} width={300} />}
        </React.Fragment>
    )
   
}   

export default ContactPopup;