import React from "react";
import styles from "./SystemStatus.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import ShowCalendar from "../../components/ShowCalendar";
import {ReactComponent as Calendar} from "./../../assets/calendar.svg"
import { DateOnly } from './../../AppConstants';

const SystemStatus = (props) => {
    const [inputs,setInputs] = React.useState({dtfrom:"",dtto:""});
    const [cbalerts,setCBAlerts] = React.useState({});
    const [showfromcal,setShowFromCal] = React.useState(false);
    const [showtocal,setShowToCal] = React.useState(false);
    const [matrix,setMatrix] = React.useState({
        QUEUE:[],
        ALERTS:[],
        ACTIVITY:[],
        CLOSED:0,
        OPEN:0
    })
    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getsystemstatus().then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                if (data.DATA) {
                    setMatrix(data.DATA);
                }
            }
        })
    },[props.globals.sessionguid])

    const doRefresh = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getsystemstatus(inputs.dtfrom,inputs.dtto).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                if (data.DATA) {
                    setMatrix(data.DATA);
                }
            }
        })
    }

    const clearAlerts = (e) => {
        let clst = [];
        for (let ky in cbalerts) {
            let value = cbalerts[ky];
            if (value===true) {
                clst.push(ky.replace('cb_',''));
            }
        }
        console.log("Alerts to clear: "+clst);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.clearalerts(props.globals.useraccountid,clst).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let rlst = data.RESULTS || [];
                let newalerts = [];
                matrix.ALERTS.forEach((obj)=> {
                    if (!rlst.includes(obj.qaid)) {
                        newalerts.push(obj);
                    }
                })
                setMatrix(values => ({...values,"ALERTS":newalerts}));
            }
        })
    }

    const handleCBChange = (e) => {
        const id = e.target.id;
        const value = e.target.checked;
        console.log("HCBC id="+id+",val="+value);
        setCBAlerts(values => ({...values,[id]:value}));
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("HC name="+name+",val="+value);
        setInputs(values => ({...values, [name]: value}))
    }

    const setFromDate = (v) => {
        setShowFromCal(false);
        
        if (v !== null) {
            setInputs(values => ({...values,dtfrom:DateOnly(v)}));
        }
    }

    const setToDate = (v) => {
        setShowToCal(false);
        
        if (v !== null) {
            setInputs(values => ({...values,dtto:DateOnly(v)}));
        }
    }

    const ShowQueue = () => {
        const queue = matrix.QUEUE;
        
        return (
            <React.Fragment>
            <div className={styles.subtitle}>Outbound Queue Status</div>
            <div className={styles.queueblockheader}>
                <div>Message #</div>
                <div>DateTime</div>
                <div>Deliver By</div>
                <div>Status</div>
                <div>Provider</div>
                <div>Phone Number</div>
                <div>Callback Number</div>
                <div>Email</div>
            </div>
            {queue.map((obj,i)=> {
                return (<div key={"qu_"+i} className={styles.queueblock}>
                    <div>{obj.msgid}</div>
                    <div>{obj.createdate}</div>
                    <div>{obj.deliverytype}</div>
                    <div>{obj.status}</div>
                    <div>{obj.provname}</div>
                    <div>{obj.pagenumber}</div>
                    <div>{obj.cbnumber}</div>
                    <div>{obj.email}</div>
                </div>)
            })}
            
            </React.Fragment>
        )
    }

    const ShowAlerts = () => {
        const alerts = matrix.ALERTS;
        
        return (
            <React.Fragment>
            <div className={styles.subtitle}>Alert Status <button className={styles.smallbutton} style={{marginLeft:"10px"}} onClick={clearAlerts} >Clear Selected</button></div>
                <div className={styles.alertblockheader}>
                    <div>Message #</div>
                    <div>DateTime</div>
                    <div>Scheduled</div>
                    <div>Status</div>
                    <div>Provider</div>
                    <div>Phone Number</div>
                    <div>Callback Number</div>
                    <div>Email</div>
                </div>
                {alerts.map((obj,i)=> {
                    let cbid = "cb_"+obj.qaid;
                    return (<div key={"al_"+i} className={styles.alertblock}>
                        <div>
                            <input type="checkbox" id={cbid} checked={cbalerts[cbid] || false} onChange={handleCBChange}></input> {obj.msgid}
                        </div>
                        <div>{obj.createdate}</div>
                        <div>{obj.deliverydate}</div>
                        <div>{obj.status}</div>
                        <div>{obj.provname}</div>
                        <div>{obj.pagenumber}</div>
                        <div>{obj.cbnumber}</div>
                        <div>{obj.email}</div>
                    </div>)
                })}
            </React.Fragment>
        )
    }

    const ShowActivity = () => {
        const acts = matrix.ACTIVITY;
        
        return (
            <React.Fragment>
            <div className={styles.subtitle} style={{display:"flex",paddingBottom:"8px"}}>User Activity
                <div style={{fontSize:"16px",marginTop:"5px",width:"60px",textAlign:"right"}}>From:</div>
                <div className={styles.inputs}><input style={{height:"20px",border:"none",padding:"0",margin:"",width:"100px"}} type="text" name="dtfrom" onChange={handleChange} value={inputs.dtfrom} /></div>
                <Calendar onClick={(e)=>setShowFromCal(true)} />
                <div style={{fontSize:"16px",marginTop:"5px",width:"50px",textAlign:"right"}}>To:</div>
                <div className={styles.inputs}><input style={{height:"20px",border:"none",padding:"0",margin:"",width:"100px"}} type="text" name="dtto" onChange={handleChange} value={inputs.dtto} /></div>
                <Calendar onClick={(e) =>setShowToCal(true)} />
                <button className={styles.smallbutton} style={{marginLeft:"10px"}} onClick={doRefresh} >Refresh</button>                
            </div>


                <div className={styles.actblockheader}>
                    <div>DateTime</div>
                    <div>Type</div>
                    <div>User</div>
                    <div>Account</div>
                    <div>Remote IP</div>
                    <div>Description</div>
                </div>
                {acts.map((obj,i)=> {
                    return (<div key={"al_"+i} className={styles.actblock}>
                        <div>{obj.createdate}</div>
                        <div>{obj.activitytype}</div>
                        <div>{obj.personname}</div>
                        <div>{obj.pracname}</div>
                        <div>{obj.ipaddr}</div>
                        <div>{obj.desc}</div>
                    </div>)
                })}
            </React.Fragment>
        )
    }

    let mindate = inputs.dtfrom || "";
    if (mindate.length) mindate = new Date(mindate);
    let maxdate = inputs.dtto || "";
    if (maxdate.length) maxdate = new Date(maxdate);
    const fromdate = inputs.dtfrom || new Date();
    const todate = inputs.dtto || new Date();
    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>System Status</div>
                <div>
                    <ShowAlerts />
                    <ShowQueue />
                    {ShowActivity()}
                </div>
            </div>
            {showfromcal && <ShowCalendar value={new Date(fromdate)} onChange={setFromDate} maxDate={maxdate} />}
            {showtocal && <ShowCalendar value={new Date(todate)} onChange={setToDate} minDate={mindate} />}
        </React.Fragment>
    )
}

export default SystemStatus;