import React from "react";
import styles from "./SystemConfig.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";

var origclist = [];
const SystemConfig = (props) => {
    const [clist,setCList] = React.useState([]);
    const [dirtyflag,setDirtyFlag] = React.useState(false);

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid})
        dataservice.getsystemconfig().then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setCList(data.matrix.clist);
            origclist = JSON.parse(JSON.stringify(data.matrix.clist));
        })
    },[props.globals.sessionguid])

    const compareObj = (o1, o2) => {
        var p,p1,p2;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                p1 = o1[p];
                p2 = o2[p];
                if(p1 !== p2){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };
    const compareLists = (l1,l2) => {
        var result = true;
        if (l1.length === l2.length) {
            for (let i=0;i<l1.length;i++) {
                let obj1 = l1[i];
                let obj2 = l2[i];
                let r = compareObj(obj1,obj2);
                if (!r) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        return result;
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let found = false;
        let objs = JSON.parse(JSON.stringify(clist));
        for (let i=0;i<objs.length;i++) {
            let obj = objs[i];
            if (name===obj.configname) {
                obj.configvalue = value;
                found = true;
                break;
            }
        }
        if (found) {
            setCList(objs);
            setDirtyFlag(!compareLists(origclist,objs));
        }
    }

    const saveAll = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savesystemconfig(props.globals.useraccountid,clist).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setCList(data.matrix.clist);
            origclist = JSON.parse(JSON.stringify(data.matrix.clist));
            setDirtyFlag(false);
        })
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>System Configuration</div>
                <hr/>
                <div className={styles.hdr3}>
                    <div className={styles.hdr}>Config Name</div>
                    <div className={styles.hdr}>Config Value</div>
                    <div className={styles.hdr}>Description</div>
                </div>
                {clist.map((obj,i) => {
                    return (<div key={"scs_"+i} className={styles.row3}>
                        <div>{obj.configname}</div>
                        <div><input className={styles.inputval} type="text" name={obj.configname} value={obj.configvalue} onChange={handleChange}></input></div>
                        <div>{obj.description}</div>
                    </div>)
                })}
                <div className={styles.savebutton}>
                    <GenButton width="100" buttontext="Save" onclick={saveAll} disabled={!dirtyflag} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SystemConfig;