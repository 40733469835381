import React from "react";
import styles from "./PracticeReporting.module.scss";
import { DataService } from "../store/DataService";

const PracticeReporting = (props) => {

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Practice Reporting</div>
                <div className={styles.content}>
                    Under Construction
                </div>
            </div>
        </React.Fragment>
    )
}

export default PracticeReporting;