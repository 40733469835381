import React from "react";
import styles from './Framework.module.scss';
import SysMsg from "./SysMsg";
import RouteMgr from "../navigation/RouteMgr";
import HomeExternal from "./external/HomeExternal";
import HomeInternal from "./internal/HomeInternal";
import {DataService} from './../store/DataService';
import GenMsgBox from "../components/GenMsgBox";
import { Beforeunload } from 'react-beforeunload';

const Framework = (props) => {
    
    
    let smcount = props.globals.sysmsgs.length;

    React.useEffect(()=> {
        if (!props.globals.sessionexpired) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.validatesession().then((data)=> {
                console.log("Valid "+data.valid);
                if (!data.valid) {
                    console.log("Session NOT valid");
                    props.methods.SetSessionExpired(true);
                }
            })
        }
    },[props.methods, props.globals.sessionguid,props.globals.sessionexpired])

    const dummyReset = () => {
        const pobj = {sessionexpired:false,dirtydata:false,oncallid:0,useraccountid:0}
		props.methods.SetObject(pobj);
    }

    const resetLogin = props.resetlogin || dummyReset;

    const handleExpired = (e) => {
        resetLogin();
    }

    return (
        <Beforeunload onBeforeunload={(e)=> {
            return (
                props.globals.dirtydata && e.preventDefault()
            )
        }} >
        <div className={styles.framework}>
            {props.globals.intext === 2 && <HomeExternal globals={props.globals} methods={props.methods} resetlogin={resetLogin} />}
            {props.globals.intext === 1 && <HomeInternal globals={props.globals} methods={props.methods} resetlogin={resetLogin} />}
            {smcount > 0 && <SysMsg globals={props.globals} methods={props.methods} /> }
            <RouteMgr globals={props.globals} methods={props.methods} resetlogin={resetLogin} />
            {props.globals.sessionexpired && <GenMsgBox text="Session has expired. Please login again." onOK={handleExpired} />}
        </div>
        </Beforeunload>
    )    
}

export default Framework;