import React from "react";
import styles from "./GenConfirm.module.scss";

const GenConfirm = (props) => {

    const cancelText = props.cancelText || "Cancel";
    const confirmText = props.confirmText || "Confirm";
    const cbtext = props.checkText || "";
    const hascb = cbtext.length > 0;
    const doCancel = (e) => {}
    const doConfirm = (v) => {}
    const doBdClick = (e) => {}
    const cbref = React.useRef();
    const onCancel = props.onCancel || doCancel;
    
    const onBdClick = props.onBdClick || doBdClick;
    const wt = props.width || (props.text.length * 10);
    const fsize = props.fsize || 24;
    const btwt = props.buttonwidth || 130;
    const lines = props.text.split('\n');

    const onConfirm = (e) => {
        let cb = props.onConfirm || doConfirm;
        let v = e;
        if (hascb) {
            v = cbref.current.checked;
        }
        cb(v);
    }

    return (
        <React.Fragment>
            <div className={styles.backdrop} onClick={onBdClick} />
            <div className={styles.modal} style={{width:`${wt}px`,fontSize:`${fsize}px`}}>
                {lines.map((ln,i) => {
                    return <p key={'ln_'+i}>{ln}</p>
                })}
                {hascb && <div style={{paddingBottom:"12px"}}><label><input type="checkbox" ref={cbref} />{cbtext}</label></div>}
                <button className={styles.btnalt} styles={{width:`${btwt}px`}} onClick={onCancel}>
                    {cancelText}
                </button>
                <button className={styles.btn} styles={{width:`${btwt}px`}} onClick={onConfirm}>
                    {confirmText}
                </button>
            </div>
        </React.Fragment>
    )
}

export default GenConfirm;