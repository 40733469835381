import React from "react";
import styles from "./ChevronDown.module.scss";
import downarrow from './../assets/down-arrow.svg'
const ChevronDown = (props) => {
  return (
    <div className={styles.chevronDown} onClick={props.onClick}>
      <img
        alt=""
        className={styles.icon}
        style={{textColor:"blue"}}
        src={downarrow}
      />
    </div>
  );
};

export default ChevronDown;